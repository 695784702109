import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const eventsBaseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/events`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const eventsApi = createApi({
  reducerPath: "eventApi",
  baseQuery: eventsBaseQuery,
  tagTypes: ["Event States", "Event Instances"],
  endpoints: (builder) => ({
    getEventStatesForProduct: builder.query({
      query: (productId) => `/states/values?product_id=${productId}`,
      providesTags: ["Event States"],
    }),
    upsertEventStateValue: builder.mutation({
      query: (eventStateValue) => ({
        url: "/states/values",
        method: "POST",
        body: eventStateValue,
      }),
      invalidatesTags: ["Event States"],
    }),
    deleteEventStateValue: builder.mutation({
      query: (value_id) => ({
        url: "/states/values",
        method: "DELETE",
        body: { value_id },
      }),
      invalidatesTags: ["Event States"],
    }),
    // getEventsForCustomer: builder.query({
    //   query: ({ customerId, productId, timestamp_recorded }) =>
    //     `/?customer_id=${customerId}&product_id=${productId}&timestamp_recorded=${timestamp_recorded}`,
    //   providesTags: ["Event Instances"],
    // }),
  }),
  invalidatesTags: ["Event States"],
});

export const {
  // useGetEventsForCustomerQuery, (unused?)
  useGetEventStatesForProductQuery,
  useUpsertEventStateValueMutation,
  useDeleteEventStateValueMutation,
} = eventsApi;
