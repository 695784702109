import styled from "styled-components";
import { UNDERLAY_BG_COLOR } from "utils/defines";

export const ModalUnderlayBG = styled.div`
  background: ${({backgroundColor}) =>
    backgroundColor ? backgroundColor : UNDERLAY_BG_COLOR};
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  opacity: 1;
  &[disabled] {
    visibility: hidden;
    opacity: 0;
  }
  transition: all .5s ease;
`;
