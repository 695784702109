import { Breadcrumb, Button, Flex, Modal, Table, Typography } from "antd";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  FirmwareModal,
  FirmwareUploadModal,
} from "features/applications/components/ApplicationFirmwareModal";
import { axios } from "axios/axios";
import { uploadS3 } from "utils/uploadS3";
import { firmwareService } from "../services";
import { notify } from "features/notifications";
import { useGetDeviceTypeQuery } from "features/application-devices/redux";
import { Spinner } from "common/Spinner";
import { getLatestFirmwareVersion } from "../utils/firmwareUtils";

const FileManagementView = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [firmwareFiles, setFirmwareFiles] = useState([]);
  const [firmwareLoading, setFirmwareLoading] = useState(true);
  // const [currentOpenFirmware, setCurrentOpenFirmware] = useState(null);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});
  const history = useHistory();
  const { id } = useParams();
  const { data: deviceType, isLoading: deviceTypeIsLoading } =
    useGetDeviceTypeQuery(id);

  const onUpload = async (firmware) => {
    if (!firmware.file) {
      return notify("File required");
    }
    if (!firmware.version_number) {
      return notify("Version number required");
    }
    // 1. get presigned url
    let { status, signedRequestUrl } = await firmwareService.upload({
      ...firmware,
      fileName: firmware?.file?.name,
      fileType: firmware?.file?.type,
    });
    // 2. post to s3 signed url
    await uploadS3(firmware.file, signedRequestUrl);

    notify(status);
    // setCurrentOpenFirmware(null);
    setUploadModalOpen(null);
    reloadFiles();
  };

  const onUpdateFirmware = (firmware) => {
    axios
      .put("/api/firmware/files", { firmware })
      .then((json) => notify(json.message))
      // .then(() => setCurrentOpenFirmware(null))
      .then(() => reloadFiles());
  };

  const onDeleteFirmware = (firmware_id) => {
    axios
      .delete("/api/firmware/files", { data: { firmware_id, yo: "yeet" } })
      .then((json) => notify(json.message))
      // .then(() => setCurrentOpenFirmware(null))
      .then(() => reloadFiles());
  };

  useEffect(() => {
    if (deviceType) reloadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceType]);

  const reloadFiles = () => {
    axios.get(`/api/firmware/files/${deviceType.name}`).then((json) => {
      setFirmwareFiles(json.result);
      setFirmwareLoading(false);
    });
  };

  const DeviceTypeColumns = [
    {
      title: "Device Type",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: false,
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
    },
    {
      title: "Latest Version",
      dataIndex: "version",
      key: "version",
      disabledCheckbox: false,
      default: true,
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Latest Notes",
      dataIndex: "latestNotes",
      key: "latestNotes",
      disabledCheckbox: false,
      default: true,
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
  ];

  const firmwareColumns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      disabledCheckbox: false,
      default: false,
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            setModalInfo(record);
            setModalOpen(true);
          },
          style: {
            textDecoration: hoveredRow === rowIndex ? "underline" : "none",
            cursor: "pointer",
            color: "blue",
          },
          onMouseEnter: () => setHoveredRow(rowIndex),
          onMouseLeave: () => setHoveredRow(),
        };
      },
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.version.localeCompare(b.version),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Last Modified",
      dataIndex: "lastModified",
      key: "lastModified",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      disabledCheckbox: false,
      default: false,
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
  ];

  return (
    <>
      {deviceTypeIsLoading || firmwareLoading ? (
        <Spinner />
      ) : (
        <Flex vertical>
          <Breadcrumb
            separator=">"
            items={[
              {
                title: (
                  <a
                    href
                    onClick={() =>
                      history.push("/admin/firmware/file_management/overview")
                    }
                  >
                    File Management Overview
                  </a>
                ),
              },
              {
                title: deviceType.name,
              },
            ]}
            style={{ marginBottom: "40px" }}
          />
          <Table
            dataSource={[
              {
                ...deviceType,
                version:
                  getLatestFirmwareVersion(firmwareFiles)?.version || "n/a",
              },
            ]}
            columns={DeviceTypeColumns}
            pagination={false}
            rowKey={(record) => record.name}
          />{" "}
          <Flex
            justify={"space-between"}
            style={{ marginTop: "50px", marginBottom: "10px" }}
          >
            <Typography.Title level={3}>
              {deviceType?.name} Available Firmware
            </Typography.Title>
            <Button onClick={() => setUploadModalOpen(true)}>Upload</Button>
          </Flex>
          <Table
            dataSource={firmwareFiles}
            columns={firmwareColumns}
            rowKey={(record) => record.fileName}
          />
          <Modal
            title={`${deviceType?.name}`}
            centered
            open={modalOpen}
            onOk={() => setModalOpen(false)}
            onCancel={() => setModalOpen(false)}
            footer={[
              <Button
                key="link"
                type="primary"
                onClick={() => setModalOpen(false)}
              >
                OK
              </Button>,
            ]}
          >
            <p>Version: {modalInfo?.version}</p>
            <p>Last Modified: {modalInfo?.lastModified}</p>
          </Modal>
          {uploadModalOpen ? (
            <FirmwareUploadModal
              show={uploadModalOpen}
              hide={() => setUploadModalOpen(false)}
              deviceType={deviceType?.name}
              onUpload={onUpload}
              notify={notify}
              latestFirmware={getLatestFirmwareVersion(firmwareFiles)}
              allFirmware={firmwareFiles}
            />
          ) : null}
          {/* {currentOpenFirmware !== null ? (
            <FirmwareModal
              show={currentOpenFirmware !== null}
              hide={() => setCurrentOpenFirmware(null)}
              firmware={firmwareFiles[currentOpenFirmware]}
              onUpdateFirmware={onUpdateFirmware}
              onDeleteFirmware={onDeleteFirmware}
              notify={notify}
            />
          ) : null} */}
        </Flex>
      )}
    </>
  );
};

export default FileManagementView;
