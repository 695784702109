import styled from "styled-components";

export const WidgetWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  white-space: nowrap;
  overflow-y: hidden;
`;

export const WidgetTitle = styled.div`
  width: 100%;
  padding: 4px 8px;
  text-align: center;
  font-size: ${(props) =>
    props.bigMode ? "2em" : "1.2em"}; // bigMode from ProductCounter
  font-weight: ${(props) => (props.thinFont ? "300" : "bold")};
`;

export const WidgetBody = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  text-align: center;
`;

export const BigNumber = styled.span`
  font-size: 3em;
  font-family: Rubik;

  & span.signal-unit {
    font-size: 60%;
  }
`;
