import { Space, Table } from "antd";
import { Spinner } from "common/Spinner";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

export const TCUViewPairingHistoryTable = ({ qTCUPairings, isPageLoaded }) => {
  const [tcuPairings, setTCUPairings] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedTCUPairings = qTCUPairings?.map((obj, index) => {
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          customer_name: obj.telematic_control_unit?.customer?.name,
          application_unit_name: obj.product_sn?.product_sn_name,
          created_datetime: new Date(obj.createdAt),
          updated_datetime: new Date(obj.updatedAt),
        };
      });
      setTCUPairings(updatedTCUPairings);
    }
  }, [isPageLoaded, qTCUPairings]);

  if (!isPageLoaded) return <Spinner />;

  const tcuPairingsColumns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
    },
    {
      title: "Application Unit",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
    },
    {
      title: "Created",
      dataIndex: "created_datetime",
      key: "created_datetime",
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.created_datetime.getTime() - b.created_datetime.getTime(),
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Updated",
      dataIndex: "updated_datetime",
      key: "updated_datetime",
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.updated_datetime.getTime() - b.updated_datetime.getTime(),
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
  ];

  return (
    <>
      <Space direction="vertical" size={"middle"} style={{ display: "flex" }}>
        <Table
          columns={tcuPairingsColumns}
          dataSource={tcuPairings}
          pagination={false}
        />
      </Space>
    </>
  );
};
