import { useState } from "react";
import Select from "react-select";
import styled, { useTheme } from "styled-components";
import { useCurrentCustomer } from "features/customers/hooks/customersHooks";

import { Icon } from "common";
import { GetFilter } from "utils/Color";
import { Modal, ModalBody, ModalFooter } from "common/Modal";
import * as Defines from "utils/defines";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useUpdateTCUMutation } from "../redux";
import { Button } from "common/elements";

const TCUDetailList = styled.div``;

const TCUDetailItem = styled.div`
  display: flex;
  font-size: ${(props) => (props.small ? "80%" : "100%")};
`;

const TCUDetailKey = styled.div`
  font-weight: bold;
  width: 150px;
`;

const TCUDetailValue = styled.div`
  flex: 1;
`;

export const SuccessCheckIcon = () => (
  <Icon
    style={{ filter: GetFilter(Defines.COLOR_SUCCESS_GREEN) }}
    hostedImage={Defines.S3_CHECKMARK}
  />
);

export const ErrorExIcon = (props) => (
  <Icon
    {...props}
    style={{ ...props.style, filter: GetFilter(Defines.COLOR_ERROR_RED) }}
    hostedImage={Defines.S3_CLOSE_X}
  />
);

export const ConnectedIcon = ({
  color = Defines.COLOR_CONNECTED_BLUE,
  style,
}) => (
  <svg height="20" width="20" viewBox="0 0 20 20" style={style}>
    <circle cx="10" cy="10" r="3" fill={color} />
  </svg>
);

export const DisconnectedIcon = (props) => (
  <ConnectedIcon color={Defines.COLOR_ERROR_RED} {...props} />
);

export const TCUModal = ({ show, onHide, selectedTCU, allTCUs }) => {
  const customerId = useCustomerId();
  const { data: productSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });
  const { data: products } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const [updateTCU] = useUpdateTCUMutation();

  const pairedSNIds = allTCUs
    .map((tcu) => tcu.product_sn_id)
    .filter((psnId) => psnId);
  const currentCustomer = useCurrentCustomer();

  const theme = useTheme();

  const productSNforSelectedTCU =
    selectedTCU && productSNs?.length
      ? productSNs.find((psn) => psn.id === selectedTCU.product_sn_id)
      : null;
  const productForSelectedTCU =
    productSNforSelectedTCU && products?.length
      ? products.find(
          (product) => productSNforSelectedTCU.product_id === product.id
        )
      : null;
  const productImageForSelectedTCU = productForSelectedTCU
    ? productForSelectedTCU.image
    : null;

  const [changesMade, setChangesMade] = useState(false);
  const [linkedSnId, setLinkedSnId] = useState();

  const handleOptionChange = ({ value }) => {
    setLinkedSnId(value);
    setChangesMade(true);
  };

  const customSelectStyle = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "0",
      backgroundColor: "transparent",
      borderBottom: "1.5px solid rgba(0, 0, 0, 0.25)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.25)",
      },
      "&:focus": {
        borderColor: "green",
      },
    }),
  };

  const toSelectOptions = (sn) => ({
    value: parseInt(sn.id),
    label: sn.product_sn_name,
  });

  const selectOptions = productSNs?.length
    ? [{ label: "Unlinked", value: -1 }, ...productSNs.map(toSelectOptions)]
    : [{ label: "No Product SNs", value: -1 }];

  const onCloseModal = () => {
    setChangesMade(false);
    onHide();
  };

  const saveChanges = async () => {
    const selectedSnId = linkedSnId > 0 ? linkedSnId : null;
    const tcuChanges = {
      id: selectedTCU.id,
      product_sn_id: selectedSnId,
      customer_id: currentCustomer.id,
    };
    updateTCU(tcuChanges);
    onHide();
  };

  return (
    <Modal
      className="modal"
      show={show}
      onHide={onCloseModal}
      size={"lg"}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h3 style={{ fontFamily: "OpenSans", fontSize: "24px", margin: "0" }}>
          TCU Settings & Information
        </h3>
        <Icon
          width={Defines.ICON_SIZE_MD}
          height={Defines.ICON_SIZE_MD}
          filename="x_button_icon.svg"
          onClick={onCloseModal}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <ModalBody style={{ padding: "0 20px" }}>
        {selectedTCU ? (
          <div>
            <div
              style={{
                display: "flex",
                padding: "20px 0",
                position: "relative",
              }}
            >
              <div
                style={{
                  width: "300px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div style={{ width: "300px", height: "200px" }}>
                  <img
                    src={Defines.TCU_PRODUCT_IMAGE}
                    alt="product"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "stretch",
                }}
              >
                <TCUDetailList>
                  <TCUDetailItem>
                    <TCUDetailKey>Serial #</TCUDetailKey>
                    <TCUDetailValue>{selectedTCU.unit_number}</TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem>
                    <TCUDetailKey>
                      Custom Name{" "}
                      <div style={{ fontSize: "60%", marginBottom: "5px" }}>
                        (Optional)
                      </div>
                    </TCUDetailKey>
                    <TCUDetailValue>{selectedTCU.name}</TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem>
                    <TCUDetailKey>Connectivity</TCUDetailKey>
                    <TCUDetailValue>
                      <ConnectedIcon />
                      {selectedTCU.status || "Connected"}
                    </TCUDetailValue>
                  </TCUDetailItem>
                  <br />
                  <TCUDetailItem small>
                    <TCUDetailKey>Device Model</TCUDetailKey>
                    <TCUDetailValue>
                      {selectedTCU.model || "TCU1500"}
                    </TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem small>
                    <TCUDetailKey>IMEI</TCUDetailKey>
                    <TCUDetailValue>{selectedTCU.imei}</TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem small>
                    <TCUDetailKey>SIM</TCUDetailKey>
                    <TCUDetailValue>{selectedTCU.sim}</TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem small>
                    <TCUDetailKey>Token</TCUDetailKey>
                    <TCUDetailValue>
                      {selectedTCU.token || "N/A"}
                    </TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem small>
                    <TCUDetailKey>Firmware</TCUDetailKey>
                    <TCUDetailValue>
                      {selectedTCU.firmware_version || "N/A"}
                    </TCUDetailValue>
                  </TCUDetailItem>
                </TCUDetailList>
              </div>
              <div
                style={{
                  position: "absolute",
                  width: "0",
                  height: "35px",
                  border: "1px dashed grey",
                  bottom: "0",
                  left: "150px",
                }}
              ></div>
            </div>
            <div style={{ borderBottom: "1px solid grey" }}></div>
            <div style={{ display: "flex", padding: "10px 0" }}>
              <div style={{ width: "300px" }}>
                <div
                  style={{
                    width: "200px",
                    height: "125px",
                    margin: "0 auto",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {productImageForSelectedTCU ? (
                    <img
                      src={productImageForSelectedTCU}
                      alt="product"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        border: "2px dashed #ccc",
                        padding: "10px 30px",
                        borderRadius: "20px",
                        textAlign: "center",
                      }}
                    >
                      <ErrorExIcon style={{ width: "75px", height: "auto" }} />
                    </div>
                  )}
                </div>
              </div>
              <div
                style={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "stretch",
                }}
              >
                <TCUDetailList>
                  <TCUDetailItem>
                    <TCUDetailKey>Unit Link Status</TCUDetailKey>
                    <TCUDetailValue>
                      {productSNforSelectedTCU ? (
                        <span>
                          <SuccessCheckIcon /> Linked
                        </span>
                      ) : (
                        <span>
                          <ErrorExIcon /> Unlinked
                        </span>
                      )}
                    </TCUDetailValue>
                  </TCUDetailItem>
                  <TCUDetailItem>
                    <TCUDetailKey>Linked Unit</TCUDetailKey>
                    <TCUDetailValue>
                      <Select
                        styles={customSelectStyle}
                        options={selectOptions.filter(
                          (so) =>
                            !pairedSNIds.includes(so.value) ||
                            so.value === productSNforSelectedTCU?.id
                        )}
                        defaultValue={
                          productSNforSelectedTCU
                            ? selectOptions.find(
                                (so) => so.value === productSNforSelectedTCU.id
                              )
                            : selectOptions.find((so) => so.value === -1)
                        }
                        onChange={handleOptionChange}
                      />
                    </TCUDetailValue>
                  </TCUDetailItem>
                </TCUDetailList>
              </div>
            </div>
          </div>
        ) : (
          <div>No TCU Selected</div>
        )}
      </ModalBody>
      <ModalFooter style={{ minHeight: "70px" }}>
        <Button
          disabled={!changesMade}
          // style={{
          //   backgroundColor: changesMade ? theme.themePrimary : "#999999",
          // }}
          // className="accept"
          onClick={saveChanges}
        >
          Accept Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
