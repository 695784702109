import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { Card, Row, Col, Space, Breadcrumb, Form, Table, Button } from "antd";
import { Spinner } from "common/Spinner";
import { ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES } from "features/application-devices/utils/Constants";
import {
  ApplicationDeviceInstanceViewDetailsTable,
  ApplicationDeviceInstancesFormModal,
} from "../components";
import { debounce } from "lodash";
import { useGetApplicationDeviceInstanceByIDQuery } from "features/application-devices/redux";

export const ApplicationDeviceInstanceView = () => {
  const { id: applicationDeviceInstanceID } = useParams();
  const [
    applicationDeviceInstanceDisplay,
    setApplicationDeviceInstanceDisplay,
  ] = useState("");
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [editDetailsForm] = Form.useForm();

  /**
   * Fetching Application Unit & related information
   */
  const {
    data: qApplicationDeviceInstance,
    isLoading: isQApplicationDeviceInstanceLoading,
    refetch: qApplicationDeviceInstanceRefetch,
  } = useGetApplicationDeviceInstanceByIDQuery(applicationDeviceInstanceID);

  useEffect(() => {
    setIsPageLoaded(!isQApplicationDeviceInstanceLoading);
    if (isPageLoaded) {
      setApplicationDeviceInstanceDisplay(
        `${qApplicationDeviceInstance.product_sn.product.product_name} -- ${qApplicationDeviceInstance.application_device.device_type.name} -- ${qApplicationDeviceInstance.serial_number}`
      );
    }
  }, [
    isPageLoaded,
    isQApplicationDeviceInstanceLoading,
    qApplicationDeviceInstance,
  ]);

  if (!isPageLoaded) return <Spinner />;

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "Application Device Instances",
      href: `/admin/${ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES}`,
    },
    {
      title: `${applicationDeviceInstanceDisplay}`,
    },
  ];

  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute?.title}</span>
    ) : (
      <Link to={`${currentRoute?.href}`}>{currentRoute?.title}</Link>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`${applicationDeviceInstanceDisplay} Details`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsEditDetailsModalOpen(true);
                    }, 100)}
                  >
                    Edit Details
                  </Button>
                </Space>
              </>
            }
          >
            <ApplicationDeviceInstanceViewDetailsTable
              isPageLoaded={isPageLoaded}
              qApplicationDeviceInstance={qApplicationDeviceInstance}
            />
          </Card>
        </Col>
      </Row>
      <ApplicationDeviceInstancesFormModal
        form={editDetailsForm}
        title={"Edit Application Device Instance Form"}
        refetch={qApplicationDeviceInstanceRefetch}
        qApplicationDeviceInstance={qApplicationDeviceInstance}
        isPageLoaded={isPageLoaded}
        isModalOpen={isEditDetailsModalOpen}
        setIsModalOpen={setIsEditDetailsModalOpen}
        isEditForm={true}
      />
    </>
  );
};
