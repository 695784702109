import { useSelector, useDispatch } from "react-redux";
import { storeEvents as storeEventsAction } from "features/events/redux";
import { useCurrentSNId } from "features/units/hooks";
import store from "store";

export const dispatchStoreEvents = (events) =>
  store.dispatch(storeEventsAction(events));

export const useStoreEvents = () => {
  const dispatch = useDispatch();
  const storeEvents = (data) => dispatch(storeEventsAction(data));
  return storeEvents;
};

export const useEventHistory = () => {
  return useSelector((state) => state.events.eventHistory);
};

export const useEventHistoryForCurrentSN = () => {
  const currentSNId = useCurrentSNId();
  const eventHistory = useEventHistory();
  return eventHistory[currentSNId] || [];
};
