import { useDispatch } from "react-redux";
import {
  // setCurrentProduct,
  // setApplicationsIsEditingProduct,
  setApplicationsSelectedTab,
} from "../redux/productsSlice";
import styled from "styled-components";
import { Icon, ThemedIcon } from "common";
// import { setActiveMapMarker } from "features/map/api/mapSlice";
import { S3_MAP_PIN_UNIT_A } from "utils/defines";
import { Skeleton } from "antd";

const ApplicationTilesContainer = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  padding: 30px;
  height: 100%:
`;

const BlankApplicationTile = styled.div`
  box-sizing: border-box;
  flex: 1 1 calc(50% - 30px);
  margin-right: 30px;
  margin-bottom: 30px;
  height: 300px;
  background-color: #dcdcdc;
`;

const AddApplicationTile = styled(BlankApplicationTile)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 60px;
  background: none;
  color: #bfbfbf;

  & img {
    width: 50px;
    height: 50px;
    opacity: 0.5;
  }
  & span {
    font-size: 30px;
    margin-top: 20px;
    display: block;
    font-weight: 400;
  }

  &:hover {
    background: #f0f0f0;
    cursor: not-allowed;
    color: inherit;
  }
  &:hover img {
    opacity: 0.9;
  }
`;

const ApplicationTile = styled(BlankApplicationTile)`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #ddd;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 15%);
  border-radius: 5px;

  &:hover {
    cursor: pointer;
  }
`;

const ApplicationTileAction = styled.span`
  padding: 0 8px;
  color: #777;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
`;

const ApplicationTileBody = styled.div``;

const ApplicationTileTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 30px;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  font-size: 1.7em;
  border-radius: 5px 5px 0 0;
  & span {
    font-weight: bold;
    margin-left: 15px;
  }
  & .maximize-button {
    opacity: 0.7;
  }
  & .maximize-button:hover {
    opacity: 1;
  }
`;

const UnitsInfo = styled.div`
  padding: 20px 0;
  margin: 0 auto;
  max-width: 490px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
`;

const ProductImage = styled.div`
  text-align: center;
  width: 150px;
  height: 150px;
  margin: 0 25px;

  & img.application-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const UnitsCount = styled.div`
  flex: 1;
  font-size: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .units-label {
    font-size: 18px;
    display: block;
  }
`;

const UnitsSecondary = styled.div`
  flex: 1;
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px solid #ccc;
  text-align: left;
  font-size: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > div {
    display: flex;
    align-items: center;
  }
  & .secondary-units-count {
    width: 40px;
    text-align: right;
  }
  & .units-label {
    font-size: 14px;
    margin-left: 20px;
    text-align: left;
  }
`;

const Number = styled.span`
  font-family: "Rubik";
`;

const ApplicationTileFooter = styled.div`
  padding: 0px 2px 5px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

// const TileFooterAction = styled.div`
//   display: flex;
//   flex: 1;
//   text-align: left;
//   align-items: center;
//   & a { text-decoration: none; }
//   & span {
//     opacity: 0.7;
//     color: #333;
//     padding-left: 10px;
//   }
//   & img { width: 20px; height; auto; opacity: 0.4; }
//   &:hover span { opacity: 1 }
//   &:hover img { opacity: 0.6; }
// `;

const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  background-color: #e5e5e5;
`;

const ApplicationsQuickOverview = ({
  products,
  serialNumbers,
  onProductSelected,
}) => {
  let defaultId = products.length ? products[0].id : -1; // but we assume only one ID for demo

  const dispatch = useDispatch();
  const setSelectedTab = (tab) => dispatch(setApplicationsSelectedTab(tab));
  // const clearAnySelectedMapMarkers = () => {
  //   // clear any selected product SNs on the map view
  //   dispatch(setActiveMapMarker({ marker: null }));
  //   dispatch(setCurrentProduct(null));
  // };
  // const setEditing = (isEditing) =>
  //   dispatch(setApplicationsIsEditingProduct(isEditing));

  // const enableEditingProduct = (product) => () => {
  //   // select the product
  //   onProductSelected(product);
  //   // open the modal
  //   setEditing(true);
  // };

  // gather data points
  const snCount = serialNumbers.filter(
    (sn) => sn.product_id === defaultId
  ).length;
  const product =
    defaultId > -1
      ? products.find((product) => product.id === defaultId)
      : null;
  const faultCount = 0;
  const warningCount = 0;

  return (
    <Wrapper className="applications-page-wrapper">
      <ApplicationTilesContainer>
        {/* Main Tile */}
        <ApplicationTile onClick={() => onProductSelected(product)}>
          <section>
            <ApplicationTileTitle>
              <ThemedIcon
                hostedImage={S3_MAP_PIN_UNIT_A}
                width={30}
                height={30}
              />
              <span>{product ? product.product_name : "no product"}</span>
            </ApplicationTileTitle>
            <ApplicationTileBody>
              <UnitsInfo>
                <ProductImage>
                  {product && product.image ? (
                    <img
                      src={product.image}
                      className="application-image"
                      alt={product ? product.product_name : ""}
                    />
                  ) : (
                    <Skeleton.Image
                      style={{
                        width: "150px",
                        height: "150px",
                        padding: "auto",
                      }}
                    />
                  )}
                </ProductImage>
                <UnitsCount>
                  <Number>{snCount}</Number>
                  <span className="units-label">Units</span>
                </UnitsCount>
                <UnitsSecondary>
                  <div style={{ width: "175px" }}>
                    <Number className="secondary-units-count">{snCount}</Number>{" "}
                    <span className="units-label">Active in 24 hrs</span>
                  </div>
                  <div style={{ width: "175px" }}>
                    <Number className="secondary-units-count">
                      {faultCount}
                    </Number>{" "}
                    <span className="units-label">Faults</span>
                  </div>
                  <div style={{ width: "175px" }}>
                    <Number className="secondary-units-count">
                      {warningCount}
                    </Number>{" "}
                    <span className="units-label">Warnings</span>
                  </div>
                </UnitsSecondary>
              </UnitsInfo>
            </ApplicationTileBody>
          </section>
          <ApplicationTileFooter>
            <ApplicationTileAction onClick={() => setSelectedTab("settings")}>
              Application Settings
            </ApplicationTileAction>
            <ApplicationTileAction onClick={() => setSelectedTab("units")}>
              Unit Settings
            </ApplicationTileAction>
            <ApplicationTileAction onClick={() => setSelectedTab("signals")}>
              CAN Signals
            </ApplicationTileAction>
            <ApplicationTileAction onClick={() => setSelectedTab("parameters")}>
              Parameters
            </ApplicationTileAction>
            <ApplicationTileAction
              onClick={() => setSelectedTab("eventStates")}
            >
              Event States
            </ApplicationTileAction>
          </ApplicationTileFooter>
        </ApplicationTile>

        {/* Add Application Tile */}
        <AddApplicationTile>
          {/* @TODO: Replace with S3 URL */}
          <Icon filename="StaflSystemsFleet_ICONSET_Plus-Small.svg" />
          <span>(Add New Application)</span>
        </AddApplicationTile>

        {/* Fake blank tiles */}
        <BlankApplicationTile />
        <BlankApplicationTile />
        <BlankApplicationTile />
        <BlankApplicationTile />
      </ApplicationTilesContainer>
    </Wrapper>
  );
};

export default ApplicationsQuickOverview;
