import { Table, ColorPicker } from "antd";
import { Spinner } from "common/Spinner";
import moment from "moment-timezone";
import { useEffect, useState } from "react";

export const CustomersViewDetailsTable = ({
  qCustomer,
  qCustomerUsers,
  isPageLoaded,
}) => {
  const [customerDetails, setCustomerDetails] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedCustomerDetails = {
        ...qCustomer,
        key: qCustomer?.id,
        num_of_customer_users: qCustomerUsers?.length || 0,
      };
      setCustomerDetails([updatedCustomerDetails]);
    }
  }, [isPageLoaded, qCustomer, qCustomerUsers]);

  if (!isPageLoaded) return <Spinner />;

  const customerDetailsColumns = [
    {
      title: "Customer Name",
      dataIndex: "name",
      key: "name",
      default: true,
    },
    {
      title: "Number of Users",
      dataIndex: "num_of_customer_users",
      key: "num_of_customer_users",
    },
    {
      title: "Fleet Plan",
      dataIndex: "fleet_plan",
      key: "fleet_plan",
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Updated On",
      dataIndex: "updatedAt",
      key: "updatedAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Primary Color",
      dataIndex: "primary_color",
      key: "primary_color",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <ColorPicker
              format="hex"
              value={record.color_primary}
              disabled={true}
            />
          </>
        );
      },
    },
    {
      title: "Secondary Color",
      dataIndex: "secondary_color",
      key: "secondary_color",
      align: "center",
      render: (text, record) => {
        return (
          <>
            <ColorPicker
              format="hex"
              value={record.color_secondary}
              disabled={true}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <Table
        columns={customerDetailsColumns}
        dataSource={customerDetails}
        pagination={false}
      />
    </>
  );
};
