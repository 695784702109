import { Table } from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW } from "features/application-devices/utils/Constants";
import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";
import { Spinner } from "common/Spinner";

export const ApplicationDevicesTable = ({
  qApplicationDevices,
  isPageLoaded,
}) => {
  const { path } = useRouteMatch();
  const [applicationDevices, setApplicationDevices] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplicationDevices = qApplicationDevices?.map(
        (obj, index) => {
          return {
            ...obj,
            key: obj.id,
            index: index + 1,
            application: obj.product?.product_name,
            application_device_type: obj.device_type?.name,
            customer: obj.customer?.name,
          };
        }
      );
      setApplicationDevices(updatedApplicationDevices);
    }
  }, [qApplicationDevices, isPageLoaded]);

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`${path}/view/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.product_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Application Device Type",
      dataIndex: "application_device_type",
      key: "application_device_type",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW}/${record.device_type_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.customer_id}`}>
          {text}
        </Link>
      ),
    },
  ];

  return <Table columns={columns} dataSource={applicationDevices} />;
};
