import Modal from "react-bootstrap/esm/Modal";
import styled from "styled-components";
import * as Defines from "utils/defines";
import dayjs from "utils/dayjs";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Icon } from "common/Icon";
import { useGetClientLogsQuery } from "features/changelog/redux";

const ModalBody = styled.div`
  height: 80vh;
  padding: 30px;
  overflow-y: scroll;
`;

const ModalHeaderTitle = styled.h3`
  font-family: OpenSans;
  font-size: 24px;
  margin: 0;
`;

const PostContainer = styled.div`
  margin-bottom: 3em;
`;

const PostDate = styled.p``;

const PostHeadline = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
`;

const PostTitle = styled.h1``;

const ChangelogModal = ({ show, onHide }) => {
  const { data: clientPosts } = useGetClientLogsQuery();

  const options = {
    renderNode: {
      "embedded-asset-block": (node) => (
        <img alt={"post"} src={node.data.target.fields.file.url} />
      ),
    },
  };

  return (
    <Modal show={show} onHide={onHide} className="modal" size="lg" centered>
      <Modal.Header>
        <ModalHeaderTitle>Changelog</ModalHeaderTitle>
        <Icon
          width={Defines.ICON_SIZE_MD}
          height={Defines.ICON_SIZE_MD}
          hostedImage={Defines.S3_CLOSE_X}
          onClick={onHide}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>
      <ModalBody>
        {clientPosts?.map((post) => (
          <PostContainer key={post.id}>
            <PostHeadline>
              <PostTitle>{post?.versionNumber}</PostTitle>
              <PostDate>
                {dayjs(post?.releaseDate).format("MMMM D, YYYY")}
              </PostDate>
            </PostHeadline>
            {post?.body?.content.map((document, i) => (
              <div key={i}>{documentToReactComponents(document, options)}</div>
            ))}
          </PostContainer>
        ))}
      </ModalBody>
    </Modal>
  );
};

export default ChangelogModal;
