import {
  Alert,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  ColorPicker,
} from "antd";
import { Spinner } from "common/Spinner";
import { useUpdateCustomerMutation } from "features/customers/redux";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

export const CustomersFormModal = ({
  form,
  title,
  qCustomer,
  qUsers,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  refetch,
  isNewForm = false,
  isEditForm = false,
}) => {
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  /**
   * Hooks
   */
  const [updateCustomer] = useUpdateCustomerMutation();

  /**
   * Form Values
   */
  const [ownersFormSelect, setOwnersFormSelect] = useState([]);
  const [isCustomerVerifiedFormSelect, setIsCustomerVerifiedFormSelect] =
    useState([
      { label: "No", value: 0 },
      { label: "Yes", value: 1 },
    ]);

  const [primaryColorHex, setPrimaryColorHex] = useState("#C0C0C0");
  const [secondaryColorHex, setSecondaryColorHex] = useState("#4B4B4B");

  const handlePrimaryColorChange = (selectedColor) => {
    setPrimaryColorHex(`#${selectedColor.toHex()}`);
  };
  const handleSecondaryColorChange = (selectedColor) => {
    setSecondaryColorHex(`#${selectedColor.toHex()}`);
  };

  /**
   * On Finish
   * @param {*} values
   */
  const onFinishFormModal = async (values) => {
    const {
      formName,
      formPrimaryColor,
      formSecondaryColor,
      formOwnerSelect,
      formIsCustomerVerifiedSelect,
    } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };

      const selectedOwner = qUsers?.find((item) => item.id === formOwnerSelect);

      if (isNewForm && !isEditForm) {
      } else if (isEditForm) {
        result = await updateCustomer({
          id: qCustomer?.id,
          name: formName,
          color_primary: formPrimaryColor,
          color_secondary: formSecondaryColor,
          owner_id: selectedOwner?.id,
          is_verified: formIsCustomerVerifiedSelect,
        });
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      let ownerSelectOptions = [{ label: "None", value: 0 }];
      const loadedUsersSelectOptions = qUsers?.map((obj) => {
        return {
          value: obj.id,
          label: obj.name,
        };
      });
      ownerSelectOptions = ownerSelectOptions.concat(loadedUsersSelectOptions);
      setOwnersFormSelect(ownerSelectOptions);
    }
  }, [isPageLoaded]);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  if (!isPageLoaded) return <Spinner />;

  /**
   * Show Form Error Message
   * @returns
   */
  const showFormErrorMessage = () => {
    if (isShowFormErrorMessage) {
      return (
        <>
          <Alert
            message="Error"
            description={formErrorMessage}
            type="error"
            showIcon
          />
          <br />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Modal
        title={title}
        width={500}
        open={isModalOpen}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-customer-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {showFormErrorMessage()}
        <Row>
          <Col span={24}>
            <Form.Item
              label="Customer Name"
              name="formName"
              initialValue={qCustomer?.name}
              rules={[
                {
                  required: true,
                  message: "Missing Customer Name!",
                },
              ]}
            >
              <Input disabled={isOkButtonLoading} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10} offset={1}>
            <Form.Item
              label="Primary Color"
              name="formPrimaryColor"
              initialValue={qCustomer?.color_primary}
              rules={[
                {
                  required: true,
                  message: "Missing Primary Color!",
                },
              ]}
            >
              <ColorPicker
                format="hex"
                value={primaryColorHex}
                defaultValue={"#C0C0C0"}
                showText
                onChange={handlePrimaryColorChange}
              />
            </Form.Item>
          </Col>
          <Col span={10} offset={2}>
            <Form.Item
              label="Secondary Color"
              name="formSecondaryColor"
              initialValue={qCustomer?.color_secondary}
              rules={[
                {
                  required: true,
                  message: "Missing Secondary Color!",
                },
              ]}
            >
              <ColorPicker
                format="hex"
                value={secondaryColorHex}
                defaultValue={"#4B4B4B"}
                showText
                onChange={handleSecondaryColorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={10} offset={1}>
            <Form.Item
              label="Owner"
              name="formOwnerSelect"
              initialValue={qCustomer?.owner_id || 0}
            >
              <Select disabled={isOkButtonLoading} options={ownersFormSelect} />
            </Form.Item>
          </Col>
          <Col span={10} offset={2}>
            <Form.Item
              label="Is Verified"
              name="formIsCustomerVerifiedSelect"
              initialValue={qCustomer?.is_verified || 0}
            >
              <Select
                disabled={isOkButtonLoading}
                options={isCustomerVerifiedFormSelect}
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
