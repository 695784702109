import { useSelector, useDispatch } from "react-redux";

import { setCurrentProduct } from "features/applications/redux";
import {
  setDataHistory,
  setDataInterval,
  setIsLiveMode,
} from "features/units/redux";

import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { getMaxTimestampForHistory } from "features/applications/hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { STATUS_LIVE_THRESHOLD, STATUS_RECENT_THRESHOLD } from "utils/defines";
/**
 * Product SNs
 */

export const useCurrentSNId = () => {
  return useSelector((state) => state.products.product);
};

export const useCurrentProductSN = () => {
  const currentProductSN = useGetApplicationUnitsByFilterQuery(
    useCustomerId()
  ).data;
  const dispatch = useDispatch();
  const setCurrentProductSN = (psn_id) => dispatch(setCurrentProduct(psn_id));
  return [currentProductSN, setCurrentProductSN];
};

export const useProductSNs = () => {
  return useSelector((state) => state.products.productSNs);
};

const statusFromTimestamp = (timestamp) => {
  if (!timestamp) return "unknown";
  const timeDiff = Date.now() - timestamp;
  if (timeDiff < STATUS_LIVE_THRESHOLD) return "live";
  if (timeDiff < STATUS_RECENT_THRESHOLD) return "recent";
  return "awhile";
};

export const useDeviceStatuses = () => {
  const customerId = useCustomerId();
  const { data: productSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });
  const dataHistory = useDataHistory();
  const productSNIds = productSNs?.length
    ? productSNs.map((psn) => psn.id)
    : [];
  const deviceStatuses = {
    live: [],
    recent: [],
    awhile: [],
    unknown: [],
  };
  for (let productSNId of productSNIds) {
    const dataHistoryForPSN = dataHistory[productSNId];
    if (!dataHistoryForPSN) {
      deviceStatuses["unknown"].push(productSNId);
      continue;
    }
    const unitLastUpdated = getMaxTimestampForHistory(dataHistoryForPSN);

    const deviceStatus = statusFromTimestamp(unitLastUpdated);
    deviceStatuses[deviceStatus].push(productSNId);
  }
  return deviceStatuses;
};

/**
 * Data History
 */

export const useSetDataHistory = () => {
  const dispatch = useDispatch();
  return (data) => dispatch(setDataHistory(data));
};

export const useDataHistory = () => {
  return useSelector((state) => state.productSNData.dataHistory);
};

export const useSetDataInterval = () => {
  const dispatch = useDispatch();
  return (dataInterval) => dispatch(setDataInterval(dataInterval));
};

export const useDataHistoryForCurrentSN = () => {
  const currentProductSNId = useCurrentSNId();
  return useDataHistoryForSNId(currentProductSNId);
};

export const useDataHistoryForSNId = (productSNId) => {
  const dataHistory = useDataHistory();
  if (!productSNId || !dataHistory || !dataHistory[productSNId]) return null;
  return dataHistory[productSNId];
};

export const useLiveMode = () => {
  const dispatch = useDispatch();
  const isLive = useSelector((state) => state.productSNData.isLive);
  const setIsLive = (isLive) => dispatch(setIsLiveMode(isLive));
  return [isLive, setIsLive];
};
