import { Breadcrumb, Button, Card, Col, Form, Row, Space } from "antd";
import { Spinner } from "common/Spinner";
import { ADMIN_ROUTE_TCUS } from "features/tcu/utils/Constants";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  TCUFormModal,
  TCUViewDetailsTable,
  TCUViewPairingHistoryTable,
} from "../components";
import { debounce } from "lodash";
import {
  useGetTCUsByIDQuery,
  useGetTCUPairingHistoryQuery,
} from "features/tcu/redux";
import { useGetCustomersQuery } from "features/customers/redux";
import { useGetUnpairedApplicationUnitsQuery } from "features/units/redux";

export const TCUView = () => {
  const { id: tcuID } = useParams();

  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [form] = Form.useForm();

  const {
    data: qTCU,
    isLoading: isQTCULoading,
    refetch: qTCURefetch,
  } = useGetTCUsByIDQuery({
    id: tcuID,
    skip: tcuID === "",
  });

  const { isLoading: isQCustomersLoading, data: qCustomers } =
    useGetCustomersQuery();

  const {
    isLoading: isQTCUPairingHistoryLoading,
    data: qTCUPairingHistory,
    refetch: qTCUPairingHistoryRefetch,
  } = useGetTCUPairingHistoryQuery({
    tcu_id: tcuID,
    skip: tcuID === "",
  });

  const {
    isLoading: isQUnpairedApplicationUnitLoading,
    data: qUnpairedApplicationUnits,
    refetch: qUnpairedApplicationUnitsRefetch,
  } = useGetUnpairedApplicationUnitsQuery();

  useEffect(() => {
    setIsPageLoaded(
      !isQTCULoading &&
        !isQCustomersLoading &&
        !isQUnpairedApplicationUnitLoading &&
        !isQTCUPairingHistoryLoading
    );
  }, [
    isPageLoaded,
    isQTCULoading,
    isQCustomersLoading,
    isQUnpairedApplicationUnitLoading,
    isQTCUPairingHistoryLoading,
  ]);

  if (!isPageLoaded) return <Spinner />;

  const refetchBulk = () => {
    qTCURefetch();
    qUnpairedApplicationUnitsRefetch();
    qTCUPairingHistoryRefetch();
  };

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "TCUs Overview",
      href: `/admin/${ADMIN_ROUTE_TCUS}`,
    },
    {
      title: `${qTCU?.name}`,
    },
  ];

  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute?.title}</span>
    ) : (
      <Link to={`${currentRoute?.href}`}>{currentRoute?.title}</Link>
    );
  };

  return (
    <>
      {/* Breadcrumb */}
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      {/* TCU Details */}
      <Row>
        <Col span={24}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`TCU: ${qTCU?.name} Details`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsEditDetailsModalOpen(true);
                    }, 100)}
                  >
                    Edit Details
                  </Button>
                </Space>
              </>
            }
          >
            <TCUViewDetailsTable qTCU={qTCU} isPageLoaded={isPageLoaded} />
          </Card>
        </Col>
      </Row>
      {/* TCU x Application Unit Pairing History */}
      <Row style={{ marginTop: "16px" }}>
        <Col span={16}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`TCU - Application Unit Pairing History`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
          >
            <TCUViewPairingHistoryTable
              qTCUPairings={qTCUPairingHistory}
              isPageLoaded={isPageLoaded}
            />
          </Card>
        </Col>
      </Row>
      {/* TCU Edit Form Modal */}
      <TCUFormModal
        form={form}
        title={"Edit TCU Details Form Modal"}
        refetch={refetchBulk}
        qTCU={qTCU}
        qCustomers={qCustomers}
        qApplicationUnits={qUnpairedApplicationUnits}
        isPageLoaded={isPageLoaded}
        isModalOpen={isEditDetailsModalOpen}
        setIsModalOpen={setIsEditDetailsModalOpen}
        isEditForm={true}
      />
    </>
  );
};
