import {
  memo,
  useState,
  useEffect,
  useCallback,
  useRef,
  useMemo,
  useContext,
  useLayoutEffect,
} from "react";
import { SizeMe } from "react-sizeme";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import * as d3 from "d3";
import * as Defines from "utils/defines";
import * as ChartUtilities from "./chartUtilities";
import * as ChartComponents from "./ChartComponents";
import * as ChartStyles from "./chartStyles";
import dayjs from "utils/dayjs";
import {
  convertMilliseconds,
  convertMillisecondsEstimated,
  useInterval,
} from "utils";
import { Icon } from "common";
import { productSNDataService } from "features/units/services";
import { Tooltip } from "react-tooltip";
import {
  useCurrentSNId,
  useDataHistoryForCurrentSN,
  useSetDataHistory,
  useSetDataInterval,
} from "features/units/hooks";
import { useCustomerTheme } from "features/customers/hooks/customersHooks";
import LineChartButton from "./LineChartButton";
import RelativeTimeSelector from "./RelativeTimeSelector";
import EventsSidebar from "./ChartComponents/EventsSidebar/EventsSidebar";
import { setLayoutsSettings } from "features/grid-layout";
import { useEventHistoryForCurrentSN } from "features/events/hooks/eventHooks";
import { useStoreEvents } from "features/events/hooks/eventHooks";
// import { LTTBDownsampler } from "utils/lttb";
import { TcuUploadHandler } from "features/tcu/services";
import { useCurrentTCU } from "features/tcu/hooks";
import { useMqttSubscription } from "features/widgets/hooks/widgetsHooks";
import { notify } from "features/notifications";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetEventStatesForProductQuery } from "features/events/redux";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import _ from "lodash";
import { axios } from "axios/axios";
import { AuthContext } from "features/auth/contexts";
import { batch } from "react-redux";
import moment from "moment-timezone";
import { useGetProductSettingsForCustomerQuery } from "features/applications/redux";

// const Downsampler = new LTTBDownsampler({
//   timeAccessor: (point) => point.date.valueOf(),
//   valueAccessor: (point) => parseFloat(point.value),
// });

const IconToolTip = ({ children, content }) => (
  <ChartStyles.LabelToolTip
    data-tooltip-id="linechart-tooltip"
    data-tooltip-place="top"
    data-tooltip-content={content}
  >
    {children}
  </ChartStyles.LabelToolTip>
);

/**
 * BEGIN Linechart
 */
const LineChartWithCursors = ({
  chartData = [],
  onManualDurationChange,
  onDurationChange,
  config = Defines.DEFAULT_LINECHART_CONFIG,
  layout = Defines.DEFAULT_LINECHART_LAYOUT,
  isFiltering,
  setIsFiltering,
  isFetching,
  canvasWidth,
  canvasHeight,
  lineChartDateRange,
  events,
  eventStateValues,
  getEventStateValueForCustomEvent,
  handleFetchEvents,
  layoutId,
  isLineChartSidebarActive,
  setLineChartDateRange,
  fetchData,
  product_sn_id,
}) => {
  // from props > layout
  const {
    margin,
    sizeXAxis,
    sizeYAxis,
    tooltipOffsetX,
    tooltipOffsetY,
    tooltipWidth: userTooltipWidth,
  } = layout;

  /* ============= VALIDATION =========== */

  if (!Defines.VALID_Y_AXIS_SCALING_MODES.includes(config.yAxisScaleMode))
    throw new Error("Invalid Y axis scaling mode");

  /* ============= INIT =========== */

  const { isSuperAdmin } = useContext(AuthContext);
  // init - customer theme
  const theme = useCustomerTheme();
  // init - layout
  const containerRef = useRef(null);
  const listenerRef = useRef(null);

  // render dimensions
  const shouldFitToContainer = layout.autoFitContainer && containerRef.current;
  const renderWidth = shouldFitToContainer
    ? canvasWidth || containerRef.current.clientWidth
    : layout.width;

  const renderHeight = shouldFitToContainer
    ? canvasHeight - 50 || containerRef.current.clientHeight
    : layout.height;

  const numYAxes = chartData.length ? chartData.length : 1;
  const plotWidth =
    renderWidth - margin.left - margin.right - numYAxes * sizeYAxis;
  const plotHeight = renderHeight - margin.top - margin.bottom - sizeXAxis;

  // init - zoom
  const initialYZoomStates = ChartUtilities.initializeYZoomStates(numYAxes);
  const [yZoomStates, setYZoomStates] = useState(initialYZoomStates);
  const [dragging, setDragging] = useState(false);
  const [dragScrollDirection, setDragScrollDirection] = useState(
    Defines.DRAGSCROLLDIRECTION_NONE
  );
  const zoomBehavior = ChartUtilities.generateNewZoomBehavior();
  const translateExtentMin = [
    -config.translateDistance,
    -config.translateDistance,
  ];
  const translateExtentMax = [
    plotWidth + config.translateDistance,
    plotHeight + config.translateDistance,
  ];
  const translateExtent = [translateExtentMin, translateExtentMax];
  if (config.enableZoomConstraints)
    zoomBehavior.translateExtent(translateExtent);

  const dispatch = useDispatch();

  const selectLayoutSettings = (state) => state.layout?.layoutSettings;

  const [isTabFocus, setIsTabFocus] = useState(true);
  const [isTabFocusChanged, setIsTabFocusChanged] = useState(false);

  /**
   * [ REGION ] Component Visibility Change Event
   * --> TODO: Move to App Wide and include to Redux State
   */
  const onVisibilityChange = () => {
    if (document.visibilityState === "visible") {
      setIsTabFocus(true);
    } else {
      setIsTabFocus(false);
    }
    setIsTabFocusChanged(true);
  };

  useLayoutEffect(() => {
    document.addEventListener("visibilitychange", onVisibilityChange);

    return () =>
      document.removeEventListener("visibilitychange", onVisibilityChange);
  }, []);
  /**
   * [ END_REGION ] Component Visibility Change Event
   */

  /**
   * [ REGION ] Managing Scroll Zoom State per Layout
   */
  const makeScrollZoomState = (layoutId) =>
    createSelector([selectLayoutSettings], (layoutSettings) => {
      const state = layoutSettings?.[layoutId]?.[Defines.ZOOM_SCROLL_STATE];
      return state
        ? { k: state.k, x: state.x, y: state.y }
        : { k: d3.zoomIdentity.k, x: d3.zoomIdentity.x, y: d3.zoomIdentity.y };
    });
  const useScrollZoomState = (layoutId) => {
    const selectScrollZoomState = useMemo(
      () => makeScrollZoomState(layoutId),
      [layoutId]
    );
    const scrollZoomState = useSelector(selectScrollZoomState);

    const setScrollZoomState = useCallback(
      (zoomState) => {
        const serializedZoomState = {
          k: zoomState.k,
          x: zoomState.x,
          y: zoomState.y,
        };
        dispatch(
          setLayoutsSettings({
            layoutId: layoutId,
            settings: {
              [Defines.ZOOM_SCROLL_STATE]: serializedZoomState,
            },
          })
        );
      },
      [dispatch, layoutId]
    );
    const updatedScrollZoomState = useMemo(
      () =>
        d3.zoomIdentity
          .translate(scrollZoomState?.x, scrollZoomState?.y)
          .scale(scrollZoomState?.k),
      [scrollZoomState]
    );

    return [updatedScrollZoomState, setScrollZoomState];
  };
  const [scrollZoomState, setScrollZoomState] = useScrollZoomState(layoutId);

  /**
   * [ END_REGION ] Managing Scroll Zoom State per Layout
   */

  /**
   * [ REGION ] Determining if Line Chart's load is the first time
   *
   * -> Trigger first fetch
   */
  const makeSelectIsLineChartFirstLoad = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.IS_LINE_CHART_FIRST_LOAD]
    );
  const useIsLineChartFirstLoad = (layoutId) => {
    const selectIsLineChartFirstLoad = useMemo(
      () => makeSelectIsLineChartFirstLoad(layoutId),
      [layoutId]
    );
    const isLineChartFirstLoad = useSelector(selectIsLineChartFirstLoad);
    const setIsLineChartFirstLoad = useCallback(
      (isLoaded) => {
        dispatch(
          setLayoutsSettings({
            layoutId: layoutId,
            settings: {
              [Defines.IS_LINE_CHART_FIRST_LOAD]: isLoaded,
            },
          })
        );
      },
      [dispatch, layoutId]
    );

    return [isLineChartFirstLoad, setIsLineChartFirstLoad];
  };

  const [isLineChartFirstLoad, setIsLineChartFirstLoad] =
    useIsLineChartFirstLoad(layoutId);

  /**
   * [ END_REGION ] Line Chart's First Load
   */

  /**
   * [ REGION ] Set Viewable Duration [ Date Time Range]
   *
   */
  const makeSelectViewableDuration = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.VIEWABLE_DURATION] ??
        config.initialRelativeDuration
    );

  const selectViewableDuration = useMemo(
    () => makeSelectViewableDuration(layoutId),
    [layoutId]
  );
  const viewableDuration = useSelector(selectViewableDuration);

  const setViewableDuration = (newViewableDuration) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.VIEWABLE_DURATION]: newViewableDuration,
        },
      })
    );

  const fitViewportToData = !config.enableLiveMode && !config.enableTimeSelect;

  // replace dayjs datetime calculation (non-millisecond duration times)
  const calculatedViewable = (duration, unitType) => {
    return unitType === "minute" ? duration * 60000 : duration;
  };

  const initialViewable = calculatedViewable(
    viewableDuration,
    config.initialRelativeUnit
  );
  const initialXDomain =
    lineChartDateRange[0] && lineChartDateRange[1]
      ? [lineChartDateRange[0], lineChartDateRange[1]]
      : [
          new Date(Date.now() - initialViewable / 2),
          new Date(Date.now() + initialViewable / 2),
        ];

  const [xDomain] = useState(initialXDomain);
  const xReference = useMemo(
    () => d3.scaleTime().domain(xDomain).range([0, plotWidth]),
    [plotWidth, xDomain]
  );
  const xScale = useRef();
  xScale.current = ChartUtilities.rescaleFromZoom(xReference, scrollZoomState);
  if (fitViewportToData)
    ChartUtilities.fitXScaleToData(xScale.current, chartData);

  // y domains and scales
  const initialYScale = d3.scaleLinear().range([plotHeight, 0]);
  const yScales = ChartUtilities.initializeYScales(numYAxes, initialYScale);
  const shouldYAxisScaleToZero = config.yAxisScaleMode === "toZero";
  // -- take note, if chartData changes, an unchanged zoom will apply on the changed data, not be change invariant

  const makeSelectIsLive = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.LINE_CHART_IS_LIVE] ??
        config.initialRelativeDuration
    );

  const selectIsLive = useMemo(() => makeSelectIsLive(layoutId), [layoutId]);
  const isLive = useSelector(selectIsLive);

  const setIsLive = useCallback(
    (newIsLive) => {
      dispatch(
        setLayoutsSettings({
          layoutId: layoutId,
          settings: {
            [Defines.LINE_CHART_IS_LIVE]: newIsLive,
          },
        })
      );
    },
    [dispatch, layoutId]
  );

  const makeSelectStackMode = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.STACK_MODE] ?? true
    );

  const selectStackMode = useMemo(
    () => makeSelectStackMode(layoutId),
    [layoutId]
  );

  const stackMode = useSelector(selectStackMode);

  const setStackMode = (newStackMode) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.STACK_MODE]: newStackMode,
        },
      })
    );

  const makeSelectRealTimeTextDisplay = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.REAL_TIME_TEXT_DISPLAY] ??
        Defines.REALTIMETEXT_NONE
    );

  const memoMakeSelectRealTimeTextDisplay = useMemo(
    () => makeSelectRealTimeTextDisplay(layoutId),
    [layoutId]
  );
  const realTimeTextDisplay = useSelector(memoMakeSelectRealTimeTextDisplay);

  const setRealTimeTextDisplay = (newRealTimeTextDisplayMode) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.REAL_TIME_TEXT_DISPLAY]: newRealTimeTextDisplayMode,
        },
      })
    );

  const makeSelectIsTooltipActive = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.IS_TOOLTIP_ACTIVE] ?? false
    );

  const selectIsTooltipActive = useMemo(
    () => makeSelectIsTooltipActive(layoutId),
    [layoutId]
  );

  const isTooltipActive = useSelector(selectIsTooltipActive);
  const setIsTooltipActive = (newIsTooltipActive) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.IS_TOOLTIP_ACTIVE]: newIsTooltipActive,
        },
      })
    );

  const LIVE_COLOR_FILTER =
    "invert(52%) sepia(84%) saturate(823%) hue-rotate(9deg) brightness(106%) contrast(105%)";

  ChartUtilities.fitYScalesToData(
    yScales,
    chartData,
    config.extendYAxisBounds,
    shouldYAxisScaleToZero,
    stackMode
  );
  ChartUtilities.fitYScalesToZooms(yScales, yZoomStates);

  const makeSelectPlayMode = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.PLAY_MODE] ?? false
    );

  const memoPlayMode = useMemo(() => makeSelectPlayMode(layoutId), [layoutId]);
  const playMode = useSelector(memoPlayMode);

  const setPlayMode = (newPlayMode) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.PLAY_MODE]: newPlayMode,
        },
      })
    );

  const skipToEndIsActive = !playMode && isLive;

  const toggleLiveMode = () => {
    setNowPos(
      ChartUtilities.dateToPositionFromScale(new Date(), xScale.current)
    );
    if (isLive) {
      batch(() => {
        setPlayMode(false);
        setIsLive(false);
      });
      return;
    }
    batch(() => {
      setPlayMode(true);
      setIsLive(true);
    });
  };

  const [filteredEvents, setFilteredEvents] = useState([...events]);

  // cursors
  const measureCursorRef = useRef(null);
  const diffCursorRef = useRef(null);
  const horizCursorRef = useRef(null);
  const chartTooltipRef = useRef(null);
  const measureFetcherRef = useRef(null);
  const diffFetcherRef = useRef(null);

  const makeSelectActiveEvent = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.ACTIVE_EVENT] ?? null
    );
  const memoActiveEvent = useMemo(
    () => makeSelectActiveEvent(layoutId),
    [layoutId]
  );
  const activeEvent = useSelector(memoActiveEvent);

  const setActiveEvent = (newActiveEvent) => {
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.ACTIVE_EVENT]: newActiveEvent,
        },
      })
    );
  };

  const makeSelectDiffDate = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.DIFF_DATE] ??
        new Date(Date.now() + 60000)
    );

  const memoDiffDate = useMemo(() => makeSelectDiffDate(layoutId), [layoutId]);
  const diffDate = useSelector(memoDiffDate);

  const setDiffDate = (newDiffDate) => {
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.DIFF_DATE]: newDiffDate.valueOf(),
        },
      })
    );
  };

  const makeSelectMeasureDate = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.MEASURE_DATE] ?? new Date()
    );

  const memoMeasureDate = useMemo(
    () => makeSelectMeasureDate(layoutId),
    [layoutId]
  );
  const measureDate = useSelector(memoMeasureDate);

  const setMeasureDate = (newMeasureDate) => {
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.MEASURE_DATE]: newMeasureDate.valueOf(),
        },
      })
    );
  };

  const makeSelectActiveCursor = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.ACTIVE_CURSOR] || null
    );

  const memoActiveCursor = useMemo(
    () => makeSelectActiveCursor(layoutId),
    [layoutId]
  );
  const activeCursor = useSelector(memoActiveCursor);

  const setActiveCursor = (newActiveCursor) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.ACTIVE_CURSOR]: newActiveCursor,
        },
      })
    );

  const makeSelectIsDiffCursorEnabled = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.IS_DIFF_CURSOR_ENABLED] ??
        config.diffCursorInitiallyVisible
    );

  const memoIsDiffCursorEnabled = useMemo(
    () => makeSelectIsDiffCursorEnabled(layoutId),
    [layoutId]
  );
  const isDiffCursorEnabled = useSelector(memoIsDiffCursorEnabled);

  const setDiffCursorEnabled = (newDiffCursorEnabled) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.IS_DIFF_CURSOR_ENABLED]: newDiffCursorEnabled,
        },
      })
    );

  const measurePos = ChartUtilities.dateToPositionFromScale(
    measureDate,
    xScale.current
  );

  const diffPos = ChartUtilities.dateToPositionFromScale(
    diffDate,
    xScale.current
  );

  const [nowPos, setNowPos] = useState(
    ChartUtilities.dateToPositionFromScale(new Date(), xScale.current)
  );

  const formattedMeasureDate = ChartUtilities.formatDateforTooltip(measureDate);
  const formattedDiffDate = ChartUtilities.formatDateforTooltip(diffDate);

  const handleDrag = (tooltipRef, onCursorDrag, cursorType) => {
    const throttledHandler = _.throttle(({ x: eventX, y: eventY }) => {
      batch(() => {
        setIsLive(false);
        setDragging(true);
        setActiveCursor(cursorType);
        setIsTooltipActive(true);
      });

      // Reveal and position horizontal cursor
      ChartUtilities.revealAndPositionHorizontalCursor(horizCursorRef, eventY);

      // Calculate and position tooltip
      const { posTop, posLeft } = calculateTooltipPositionFromEvent(
        eventX,
        eventY
      );
      ChartUtilities.revealAndPositionTooltip(tooltipRef, posTop, posLeft);

      // Calculate new cursor position and emit new value
      const newDate = xScale.current.invert(eventX);
      onCursorDrag(newDate);

      // Handle side scroll drag
      if (eventX < 20) {
        setDragScrollDirection(Defines.DRAGSCROLLDIRECTION_LEFT);
        if (eventX < 0) {
          onCursorDrag(xScale.current.invert(7));
        }
      } else if (eventX > plotWidth - 20) {
        setDragScrollDirection(Defines.DRAGSCROLLDIRECTION_RIGHT);
        if (eventX > plotWidth) {
          onCursorDrag(xScale.current.invert(plotWidth - 7));
        }
      } else {
        setDragScrollDirection(Defines.DRAGSCROLLDIRECTION_NONE);
      }
    }, 50);

    return throttledHandler;
  };

  const handleDragEnd = () => () => {
    ChartUtilities.hideHorizontalCursor(horizCursorRef);
    batch(() => {
      setDragging(false);
      setIsLive(false);
      setDragScrollDirection(Defines.DRAGSCROLLDIRECTION_NONE);
    });
  };

  const handleMeasureDrag = handleDrag(
    chartTooltipRef,
    setMeasureDate,
    Defines.CURSORTYPE_MEASURE
  );
  const handleMeasureDragEnd = handleDragEnd();
  const handleDiffDrag = handleDrag(
    chartTooltipRef,
    setDiffDate,
    Defines.CURSORTYPE_DIFF
  );
  const handleDiffDragEnd = handleDragEnd();
  d3.select(measureCursorRef.current).call(
    d3.drag().on("drag", handleMeasureDrag).on("end", handleMeasureDragEnd)
  );
  d3.select(diffCursorRef.current).call(
    d3.drag().on("drag", handleDiffDrag).on("end", handleDiffDragEnd)
  );

  // fetch bar functionality
  d3.select(measureFetcherRef.current)
    .on("mouseover", function () {
      d3.select(measureFetcherRef.current).attr("opacity", 1);
    })
    .on("mouseout", function () {
      d3.select(measureFetcherRef.current).attr("opacity", 0.5);
    })
    .on("click", function (event) {
      const coordinates = d3.pointer(event, this);
      const xPos = coordinates[0];
      const xDate = xScale.current.invert(xPos);
      setMeasureDate(xDate);
    })
    .call(
      d3.drag().on("drag", handleMeasureDrag).on("end", handleMeasureDragEnd)
    );

  d3.select(diffFetcherRef.current)
    .on("mouseover", function () {
      d3.select(diffFetcherRef.current).attr("opacity", 1);
    })
    .on("mouseout", function () {
      d3.select(diffFetcherRef.current).attr("opacity", 0.5);
    })
    .on("click", function (event) {
      const coordinates = d3.pointer(event, this);
      const xPos = coordinates[0];
      const xDate = xScale.current.invert(xPos);
      batch(() => {
        setDiffCursorEnabled(true);
        setDiffDate(xDate);
      });
    })
    .call(d3.drag().on("drag", handleDiffDrag).on("end", handleDiffDragEnd));
  // tooltips
  const tooltipWidth = isDiffCursorEnabled
    ? userTooltipWidth
    : userTooltipWidth - 160;
  const calculateTooltipPositionFromEvent = (eventX, eventY) => {
    const padding = 100; // padding to avoid tooltip's box shadow being cut off;
    const tooltipTimeRowHeight = 21;
    const leftAxisOffset = margin.left + numYAxes * sizeYAxis;
    const tooltipRightEdge =
      eventX + leftAxisOffset + tooltipOffsetX + tooltipWidth;
    const offsetFromLeft = Math.max(
      eventX + leftAxisOffset + tooltipOffsetX,
      tooltipOffsetX
    );
    const offsetFromRight = Math.min(
      eventX + leftAxisOffset - tooltipOffsetX - tooltipWidth,
      renderWidth - tooltipOffsetX - tooltipWidth - padding
    );
    const offsetFromBottom =
      plotHeight - (measurePoints.length * 21 + tooltipTimeRowHeight) - padding;
    const posTop =
      eventY <= padding - 80
        ? padding - 80
        : Math.min(eventY + tooltipOffsetY + margin.top, offsetFromBottom);
    const posLeft =
      tooltipRightEdge < renderWidth - padding
        ? offsetFromLeft
        : offsetFromRight;
    return { posTop, posLeft };
  };

  // plot points

  const makeSelectSelectedChart = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.SELECTED_CHART] ?? 0
    );

  const selectSelectedChart = useMemo(
    () => makeSelectSelectedChart(layoutId),
    [layoutId]
  );
  const selectedChart = useSelector(selectSelectedChart);

  const makeSelectArePlotPointsVisible = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.ARE_PLOT_POINTS_VISIBLE] || false
    );

  const selectArePlotPointsVisible = useMemo(
    () => makeSelectArePlotPointsVisible(layoutId),
    [layoutId]
  );
  const arePlotPointsVisible = useSelector(selectArePlotPointsVisible);

  const setSelectedChart = (newIsTooltipActive) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.SELECTED_CHART]: newIsTooltipActive,
        },
      })
    );

  const setArePlotPointsVisible = (newPlotPointsVisibleBoolean) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.ARE_PLOT_POINTS_VISIBLE]: newPlotPointsVisibleBoolean,
        },
      })
    );

  const makeSelectIsTimeSelectVisible = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.IS_TIME_SELECTED_VISIBLE] ?? false
    );

  const selectIsTimeSelectVisible = useMemo(
    () => makeSelectIsTimeSelectVisible(layoutId),
    [layoutId]
  );
  const isTimeSelectVisible = useSelector(selectIsTimeSelectVisible);

  const setIsTimeSelectVisible = (newIsTooltipActive) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.IS_TIME_SELECTED_VISIBLE]: newIsTooltipActive,
        },
      })
    );

  const setXZoomToNewDomain = useCallback(
    (newDomain) => {
      // k = width of range needed for data set / width of range needed for area of interest
      const k =
        (xReference.range()[1] - xReference.range()[0]) /
        (xReference(newDomain[1]) - xReference(newDomain[0]));
      // translate to account for starting point of area of interest.
      const tx = xReference(newDomain[0]);
      const newZoomState = d3.zoomIdentity.scale(k).translate(-tx, 0);
      d3.select(listenerRef.current).call(zoomBehavior.transform, newZoomState);
      setScrollZoomState(newZoomState);
    },
    [xReference, zoomBehavior.transform]
  );

  // get domain of current viewable plot
  const getCurrentDomain = useCallback(() => {
    let currentXScale = xScale.current;
    let startDate = currentXScale.invert(0);
    let endDate = currentXScale.invert(plotWidth);
    return [startDate, endDate];
  }, [plotWidth]);

  const handleFetchAndDisplayStartAndEndDate = (
    presetOption = false,
    interval = null,
    domain = null
  ) => {
    if (presetOption) return interval;

    const [startDate, endDate] = domain ? domain : getCurrentDomain();
    const dateFormatOptions = {
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    const timeFormatOptions = {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    };

    const formattedStartDate = new Date(startDate).toLocaleDateString(
      undefined,
      dateFormatOptions
    );
    const formattedStartTime = new Date(startDate).toLocaleTimeString(
      undefined,
      timeFormatOptions
    );
    const formattedEndDate = new Date(endDate).toLocaleDateString(
      undefined,
      dateFormatOptions
    );
    const formattedEndTime = new Date(endDate).toLocaleTimeString(
      undefined,
      timeFormatOptions
    );

    const msDiff = endDate - startDate;
    const startTimeAndEndTimeDelta = convertMillisecondsEstimated(msDiff);

    return `
      <span>
        <span style="font-weight: 400; ">${formattedStartDate}</span>,<span style="margin-left: 3px">${formattedStartTime}</span> 
        <span style="margin:0 5px">→</span> 
        <span style="font-weight: 400; ">${formattedEndDate}</span>,<span style="margin-left: 3px">${formattedEndTime}</span>
        &nbsp;(<span>${startTimeAndEndTimeDelta}</span>)
      </span>
    `;
  };

  const initialDropdownText = handleFetchAndDisplayStartAndEndDate(
    true,
    `Last 2.5 minutes`
  );

  const makeSelectDurationDropdownText = (layoutId, initialDropdownText) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.DURATION_DROPDOWN_TEXT] ??
        initialDropdownText
    );

  const selectDurationDropdownText = useMemo(
    () => makeSelectDurationDropdownText(layoutId, initialDropdownText),
    [layoutId, initialDropdownText]
  );
  const durationDropdownText = useSelector(selectDurationDropdownText);

  const setDurationDropdownText = (newDropdownText) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.DURATION_DROPDOWN_TEXT]: newDropdownText,
        },
      })
    );

  const measureCursorIsInPast = measureDate < getCurrentDomain()[0];
  const measureCursorIsInFuture = measureDate > getCurrentDomain()[1];
  const isMeasureCursorOnScreen =
    !measureCursorIsInPast && !measureCursorIsInFuture;

  const diffCursorIsInPast = diffDate < getCurrentDomain()[0];
  const diffCursorIsInFuture = diffDate > getCurrentDomain()[1];
  const isDifferenceCursorOnScreen =
    !diffCursorIsInPast && !diffCursorIsInFuture;

  /**
   * [ REGION ] Periodic Updating of Line Chart DateTime Range
   */
  useInterval(() => {
    const nowDate = new Date();
    setNowPos(ChartUtilities.dateToPositionFromScale(nowDate, xScale.current));

    if (!isLive) return;

    const [startDate, endDate] = getCurrentDomain();

    const currentDiff = endDate - startDate;
    let newStartDate = new Date(nowDate.getTime() - currentDiff / 2);
    let newEndDate = new Date(nowDate.getTime() + currentDiff / 2);
    let newMeasureCursorDate = nowDate;

    if (playMode) {
      newStartDate = new Date(startDate.getTime() + config.refreshRate);
      newEndDate = new Date(endDate.getTime() + config.refreshRate);
      newMeasureCursorDate = new Date(measureDate + config.refreshRate);
    }
    setDurationDropdownText(handleFetchAndDisplayStartAndEndDate());
    const shiftedDomain = [newStartDate, newEndDate];
    batch(() => {
      setLineChartDateRange(shiftedDomain);
      setXZoomToNewDomain(shiftedDomain);
      setMeasureDate(newMeasureCursorDate);
    });
  }, config.refreshRate);
  /**
   * [ END_REGION ]
   */

  // side scroll functionality
  const SIDESCROLL_SHIFT_FACTOR = 20;
  const SIDESCROLL_REFRESH_RATE = 200;

  /**
   * [ REGION ] Handle Periodic Updates when Drag Scrolling
   */
  useInterval(() => {
    if (dragScrollDirection === Defines.DRAGSCROLLDIRECTION_NONE) return;
    // move viewable range over
    const [startDate, endDate] = getCurrentDomain();
    const shiftInterval = (endDate - startDate) / SIDESCROLL_SHIFT_FACTOR;

    // Calculate shiftInterval outside the interval (if domain hasn't changed)
    const dateToUse =
      activeCursor === Defines.CURSORTYPE_MEASURE ? measureDate : diffDate;
    requestAnimationFrame(() => {
      // Batch state updates
      let newStartDate = new Date(startDate.getTime() - shiftInterval);
      let newEndDate = new Date(endDate.getTime() - shiftInterval);
      let newCursorDate = new Date(dateToUse - shiftInterval);

      if (dragScrollDirection === Defines.DRAGSCROLLDIRECTION_RIGHT) {
        newStartDate = new Date(startDate.getTime() + shiftInterval);
        newEndDate = new Date(endDate.getTime() + shiftInterval);
        newCursorDate = new Date(dateToUse + shiftInterval);
      }

      activeCursor === Defines.CURSORTYPE_MEASURE
        ? setMeasureDate(newCursorDate)
        : setDiffDate(newCursorDate);

      setXZoomToNewDomain([newStartDate, newEndDate]);
      const newViewableRange = handleFetchAndDisplayStartAndEndDate();
      setDurationDropdownText(newViewableRange);
    });
  }, SIDESCROLL_REFRESH_RATE);

  /**
   * [ END_REGION ] Handle Periodic Updates when Drag Scrolling
   */

  // configure zoom behavior
  const zoomTimeoutRef = useRef(null);

  // check zoom limits by evaluating proposed time limits
  const checkZoomLimits = (event) => {
    if (!event.sourceEvent) return;

    const zoomInLimit = Defines.LINE_REDUX_ZOOM_IN_LIMIT_DURATION;
    const zoomOutLimit = Defines.LINE_REDUX_ZOOM_OUT_LIMIT_DURATION;

    //const proposedTransform = d3.zoomIdentity.translate(event.transform.x, event.transform.y).scale(event.transform.k);
    const proposedScale = ChartUtilities.rescaleFromZoom(
      xReference,
      event.transform
    );

    const [startDate, endDate] = getCurrentDomain();
    const dateDiff = endDate.valueOf() - startDate.valueOf();
    const proposedDateDiff =
      proposedScale.invert(plotWidth).valueOf() -
      proposedScale.invert(0).valueOf();

    if (isNaN(dateDiff) || isNaN(proposedDateDiff)) return false;
    if (proposedDateDiff < zoomInLimit || proposedDateDiff > zoomOutLimit)
      return false;
    return true;
  };

  const handleZoom = _.throttle((event) => {
    // only do the thing if this was a user-generated zoom
    if (!event.sourceEvent) return;
    if (!Defines.ALLOWED_ZOOM_EVENT_TYPES.includes(event.sourceEvent.type))
      return;

    if (!checkZoomLimits(event)) {
      // reframe window if transform zoom is past limit, using zoomstate before transition
      // prevents unlimited zoom in or out
      d3.select(listenerRef.current).call(
        zoomBehavior.transform,
        scrollZoomState
      );
      return;
    }

    // cache new zoom state for next calculation
    const newZoomState = event.transform;
    setScrollZoomState(newZoomState);
    batch(() => {
      setIsTooltipActive(false);
      setIsLive(false);
    });

    zoomTimeoutRef.current && clearTimeout(zoomTimeoutRef.current);

    // after timeout debounce, (handle new domain)
    const performZoomAction = () => {
      // broadcast new range
      const newDomain = getCurrentDomain();
      onManualDurationChange(newDomain);
      setNowPos(
        ChartUtilities.dateToPositionFromScale(new Date(), xScale.current)
      );
    };
    zoomTimeoutRef.current = setTimeout(performZoomAction, 500);
    const newViewableRange = handleFetchAndDisplayStartAndEndDate();
    setDurationDropdownText(newViewableRange);
  }, 10);

  if (config.allowScrollZoom)
    d3.select(listenerRef.current).call(zoomBehavior.on("zoom", handleZoom));

  /**
   * [ REGION ] Handle Line Chart Changes
   */
  useEffect(() => {
    if (!isLineChartFirstLoad) {
      setIsLineChartFirstLoad(true);
      onManualDurationChange(getCurrentDomain());
    }
    if (isTabFocusChanged) {
      setIsTabFocusChanged(false);
      if (isTabFocus) {
        onManualDurationChange(getCurrentDomain());
      }
    }

    setDurationDropdownText(handleFetchAndDisplayStartAndEndDate());
    setNowPos(
      ChartUtilities.dateToPositionFromScale(new Date(), xScale.current)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    layoutId,
    activeEvent,
    product_sn_id,
    isLive,
    isTabFocusChanged,
    isTabFocus,
  ]);
  /**
   * [ END_REGION ] Handle Line Chart Changes
   */

  // updates nowPos state on scroll
  useEffect(() => {
    setNowPos(
      ChartUtilities.dateToPositionFromScale(new Date(), xScale.current)
    );
  }, [scrollZoomState]);

  const drawYAxes = useMemo(() => {
    const throttledDrawYAxes = _.throttle(
      () =>
        chartData.length ? (
          chartData.map((chartObj, i) => {
            const { chartColor, chartLabel, unitPrecision } = chartObj;
            return (
              <ChartComponents.YAxis
                key={i}
                x={(chartData.length - 1 - i) * -1 * sizeYAxis}
                scale={yScales[i]}
                color={chartColor}
                label={chartLabel}
                axisWidth={sizeYAxis}
                plotWidth={plotWidth}
                plotHeight={plotHeight}
                onAxisTransformed={(newTransform) => {
                  const newZoomStates = { ...yZoomStates, [i]: newTransform };
                  batch(() => {
                    setYZoomStates(newZoomStates);
                    setIsTooltipActive(false);
                  });
                }}
                isSelected={selectedChart === i}
                onAxisSelected={() => setSelectedChart(i)}
                previousToSelected={selectedChart - 1 === i}
                lastElement={chartData.length - 1 === i}
                precision={unitPrecision}
              />
            );
          })
        ) : (
          <ChartComponents.YAxis
            axisWidth={sizeYAxis}
            plotWidth={plotWidth}
            plotHeight={plotHeight}
            scale={initialYScale}
          />
        ),
      100
    );
    return throttledDrawYAxes;
  }, [
    chartData,
    initialYScale,
    plotHeight,
    plotWidth,
    scrollZoomState,
    selectedChart,
    setIsTooltipActive,
    setSelectedChart,
    sizeYAxis,
    yScales,
    yZoomStates,
  ]);

  const measurePoints = useMemo(
    () => ChartUtilities.getChartPointsClosestToDate(chartData, measureDate),
    [chartData, measureDate]
  );

  const latestDataPoints = useMemo(
    () =>
      ChartUtilities.getChartPointsClosestToDate(chartData, new Date(), true),
    [chartData]
  );

  const diffPoints = useMemo(
    () => ChartUtilities.getChartPointsClosestToDate(chartData, diffDate),
    [chartData, diffDate]
  );

  const drawViewablePlots = useMemo(() => {
    const throttledDraw = _.throttle(
      () =>
        chartData.map((chartObj, i) => {
          const { data, chartColor } = chartObj;
          return (
            <g key={`plot-${i}`}>
              {arePlotPointsVisible && (
                <ChartComponents.PlotPointSet
                  data={data}
                  xScale={xScale.current}
                  yScale={yScales[i]}
                  strokeColor={chartColor}
                />
              )}
              <ChartComponents.PlotPath
                selected={selectedChart === i}
                data={data}
                xScale={xScale.current}
                yScale={yScales[i]}
                strokeColor={chartColor}
                realTimeTextDisplay={realTimeTextDisplay}
                dragging={dragging}
                symbol={chartObj.unitSymbol}
                isLatest={true}
                measureCursorData={measurePoints[i]}
                diffCursorData={diffPoints[i]}
                isDiffCursorEnabled={isDiffCursorEnabled}
                isTooltipActive={isTooltipActive}
                isLive={isLive}
                lineChartDateRange={
                  getCurrentDomain()[1] - getCurrentDomain()[0]
                }
              />
            </g>
          );
        }),
      50
    );
    return throttledDraw;
  }, [
    arePlotPointsVisible,
    chartData,
    diffPoints,
    dragging,
    isDiffCursorEnabled,
    isLive,
    isTooltipActive,
    measurePoints,
    realTimeTextDisplay,
    selectedChart,
    yScales,
  ]);

  const drawHighlightedCursorPoints = (
    cursorPoints,
    xScale,
    yScales,
    selectedChart
  ) => {
    return cursorPoints.map((cursorPoint) => {
      const { chartColor, signal_id, date, value, chartIndex } = cursorPoint;
      const yScaleFn = yScales[chartIndex];
      if (!xScale(date) && !yScaleFn(value)) return;
      const squareSize = 6;
      const isSelected = chartIndex === selectedChart;
      return (
        <g key={`${signal_id}`}>
          <rect
            width={squareSize + 6}
            height={squareSize + 6}
            fill={chartColor}
            x={xScale(date) - squareSize / 2 - 3}
            y={yScaleFn(value) - squareSize / 2 - 3}
            fillOpacity={isSelected ? 0.3 : 0}
          />
          <rect
            width={squareSize}
            height={squareSize}
            fill={isSelected ? chartColor : "white"}
            stroke={chartColor || "black"}
            x={xScale(date) - squareSize / 2}
            y={yScaleFn(value) - squareSize / 2}
            strokeWidth="2"
          />
        </g>
      );
    });
  };

  const getCursorPoints = useCallback(
    (points) =>
      drawHighlightedCursorPoints(
        points,
        xScale.current,
        yScales,
        selectedChart
      ),
    [selectedChart, yScales]
  );

  const getThrottledCursorPoints = _.throttle(
    (
      measurePoints,
      diffPoints,
      latestDataPoints,
      isDiffCursorEnabled,
      realTimeTextDisplay
    ) => {
      let renderedPoints;
      if (isDiffCursorEnabled) {
        renderedPoints = [
          getCursorPoints(measurePoints),
          getCursorPoints(diffPoints),
        ];
      } else if (
        realTimeTextDisplay === Defines.REALTIMETEXT_NONE &&
        !isDiffCursorEnabled
      ) {
        renderedPoints = [getCursorPoints(measurePoints)];
      }
      renderedPoints = renderedPoints
        ? [...renderedPoints, getCursorPoints(latestDataPoints)]
        : [];
      return renderedPoints?.length > 0 ? renderedPoints : null;
    },
    1000, // Throttle time in milliseconds
    { leading: true, trailing: true }
  );

  const highlightedCursorPoints = () => {
    return getThrottledCursorPoints(
      measurePoints,
      diffPoints,
      latestDataPoints,
      isDiffCursorEnabled,
      realTimeTextDisplay
    );
  };

  /** ======================================== TCU UPLOAD ===================================  */

  const [tcu] = useCurrentTCU();
  const tcuToken = tcu ? tcu.token : null;
  const tcuUploadHandler = new TcuUploadHandler({ token: tcuToken });
  const [isTcuUploading, setIsTcuUploading] = useState(false);

  const makeSelectIsTCUUploadButtonDisabled = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.IS_TCU_UPLOAD_BUTTON_DISABLED] ??
        false
    );

  const selectIsTCUUploadButtonDisabled = useMemo(
    () => makeSelectIsTCUUploadButtonDisabled(layoutId),
    [layoutId]
  );
  const isTCUUploadButtonDisabled = useSelector(
    selectIsTCUUploadButtonDisabled
  );

  const setIsTCUUploadingButtonDisabled = (newTcuUploadButtonDisabled) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.IS_TCU_UPLOAD_BUTTON_DISABLED]: newTcuUploadButtonDisabled,
        },
      })
    );

  const stickyNotify = (message) => notify(message, { autoClose: false });

  const handleTcuResponseAck = (payload) => {
    const { result, reason } = payload;
    let message = `TCU acknowledged with result ${Defines.TCUUPLOAD_RESULT_TRANSLATIONS[result]}`;
    if (reason) message += `, reason: ${reason}`;
    const parsedResult = parseInt(result);
    switch (parsedResult) {
      case Defines.TCUUPLOAD_RESULT_VALID:
        setIsTcuUploading(true);
        break;
      case Defines.TCUUPLOAD_RESULT_CANCELLED:
        break;
      case Defines.TCUUPLOAD_RESULT_FINISHED:
        setIsTcuUploading(false);
        stickyNotify(message);
        break;
      case Defines.TCUUPLOAD_RESULT_INVALID:
      case Defines.TCUUPLOAD_RESULT_BUSY:
      default:
        stickyNotify(message);
    }
  };

  const handleUnknownTcuResponse = (payload) => {
    stickyNotify(`Unknown TCU Response: ${JSON.stringify(payload)}`);
  };

  const handleTcuUploadMessage = ({ value: payload }) => {
    setIsTCUUploadingButtonDisabled(false);
    const { action } = payload;
    const parsedAction = parseInt(action);
    switch (parsedAction) {
      case Defines.TCUUPLOAD_RESPONSE_ACK:
        return handleTcuResponseAck(payload);
      default:
        return handleUnknownTcuResponse(payload);
    }
  };

  let tcuUploadSubscription = useMqttSubscription({
    topic: `dtm/${tcuToken}/data_log_upload_info`,
    onMessageReceived: handleTcuUploadMessage,
  });

  useEffect(() => {
    if (!tcu) return;
    tcuUploadSubscription.subscribe();
    return () => tcuUploadSubscription.unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tcu]);

  const sendTcuStartCommand = async () => {
    setIsTCUUploadingButtonDisabled(true);
    const dateRange = [measureDate.valueOf(), diffDate.valueOf()];
    await tcuUploadHandler.requestDataForDateRange(dateRange);
    // @TODO: style notify text
    stickyNotify(
      `TCU upload requested for 
      ${dayjs(dateRange[0]).local().format("YYYY MMM DD HH:mm:ss")} -> 
        ${dayjs(dateRange[1]).local().format("YYYY MMM DD HH:mm:ss")}`
    ); //dateRange.join(" -> ")
  };

  const sendTcuCancelCommand = async () => {
    setIsTCUUploadingButtonDisabled(false);
    await tcuUploadHandler.sendCancel();
    stickyNotify(`Sent cancel request to TCU`);
  };

  /** ====================================== END TCU UPLOAD =================================  */

  /***
   * [ REGION ] Line Chart Button Logic
   ***/

  const liveDataIcon = () => {
    switch (realTimeTextDisplay) {
      case Defines.REALTIMETEXT_LIVE:
        return Defines.S3_PLOT_LABEL_LIVE;
      case Defines.REALTIMETEXT_ALL:
        return Defines.S3_PLOT_LABEL_ON;
      case Defines.REALTIMETEXT_NONE:
      default:
        return Defines.S3_PLOT_LABEL_OFF;
    }
  };

  const handleToggleDifferenceCursorClick = () => {
    if (!isDiffCursorEnabled) {
      setDiffCursorEnabled(true);
      setDiffDate(xScale.current.invert((2 * plotWidth) / 3));
    } else {
      setDiffCursorEnabled(false);
    }
  };

  const handleShowSampleValuesClick = () => {
    switch (realTimeTextDisplay) {
      case Defines.REALTIMETEXT_LIVE:
        setRealTimeTextDisplay(Defines.REALTIMETEXT_ALL);
        break;
      case Defines.REALTIMETEXT_ALL:
        setRealTimeTextDisplay(Defines.REALTIMETEXT_NONE);
        break;
      case Defines.REALTIMETEXT_NONE:
        setRealTimeTextDisplay(Defines.REALTIMETEXT_LIVE);
        break;
      default:
        setRealTimeTextDisplay(Defines.REALTIMETEXT_LIVE);
        break;
    }
  };

  const enableStackMode = () => {
    batch(() => {
      setYZoomStates(initialYZoomStates);
      setStackMode(true);
    });
  };
  const enableOverlayMode = () => {
    batch(() => {
      setYZoomStates(initialYZoomStates);
      setStackMode(false);
    });
  };

  const handleSkipToEnd = () => {
    const [startDate, endDate] = getCurrentDomain();
    const dateDiff = endDate - startDate;

    const shiftedStart = new Date(Date.now() - dateDiff / 2);
    const shiftedEnd = new Date(Date.now() + dateDiff / 2);

    const shiftedDomain = [shiftedStart, shiftedEnd];
    batch(() => {
      setIsLive(true);
      setPlayMode(false);
      setDiffCursorEnabled(false);
      setMeasureDate(new Date());
      setNowPos(
        ChartUtilities.dateToPositionFromScale(new Date(), xScale.current)
      );
      setXZoomToNewDomain(shiftedDomain);
      onManualDurationChange(shiftedDomain);
    });
  };

  const goToMeasureCursor = () => {
    const [startDate, endDate] = getCurrentDomain();
    const dateDiff = endDate - startDate;
    const shiftedStartDate = new Date(measureDate - dateDiff / 2);
    const shiftedEndDate = new Date(measureDate + dateDiff / 2);
    setXZoomToNewDomain([shiftedStartDate, shiftedEndDate]);
  };

  const goToDifferenceCursor = () => {
    const [startDate, endDate] = getCurrentDomain();
    const dateDiff = endDate - startDate;
    const shiftedStartDate = new Date(diffDate - dateDiff / 2);
    const shiftedEndDate = new Date(diffDate + dateDiff / 2);
    batch(() => {
      setIsLive(false);
      setXZoomToNewDomain([shiftedStartDate, shiftedEndDate]);
    });
  };

  const goToEventDate = useCallback(
    (event) => {
      const [startDate, endDate] = getCurrentDomain();
      const dateDiff = endDate - startDate;
      const eventTimestampDate = new Date(event.timestamp_recorded);
      const shiftedStartDate = new Date(
        eventTimestampDate.getTime() - dateDiff / 2
      );
      const shiftedEndDate = new Date(
        eventTimestampDate.getTime() + dateDiff / 2
      );

      batch(() => {
        setIsLive(false);
        setActiveEvent(event);
        setMeasureDate(eventTimestampDate);
        setXZoomToNewDomain([shiftedStartDate, shiftedEndDate]);
      });
      onManualDurationChange([shiftedStartDate, shiftedEndDate]);
    },
    [
      fetchData,
      getCurrentDomain,
      setActiveEvent,
      setIsLive,
      setMeasureDate,
      setXZoomToNewDomain,
    ]
  );

  const makeSelectTcuUploadButtonHovered = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.TCU_UPLOAD_BUTTON_HOVERED] ?? false
    );

  const selectTcuUploadButtonHovered = useMemo(
    () => makeSelectTcuUploadButtonHovered(layoutId),
    [layoutId]
  );
  const tcuUploadButtonHovered = useSelector(selectTcuUploadButtonHovered);

  const setTcuUploadButtonHovered = (newTcuUploadButtonHovered) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.TCU_UPLOAD_BUTTON_HOVERED]: newTcuUploadButtonHovered,
        },
      })
    );

  // Toggling Show/Hide Events on Line Chart Logic
  const makeSelectShowEvents = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) => layoutSettings?.[layoutId]?.[Defines.SHOW_EVENTS]
    );
  const selectShowEvents = useMemo(
    () => makeSelectShowEvents(layoutId),
    [layoutId]
  );
  const showEvents = useSelector(selectShowEvents);

  const setShowEvents = useCallback(
    (newShowEventsBoolean) => {
      dispatch(
        setLayoutsSettings({
          layoutId: layoutId,
          settings: {
            [Defines.SHOW_EVENTS]: newShowEventsBoolean,
          },
        })
      );
    },
    [dispatch, layoutId]
  );

  const handleToggleEvents = () => {
    setShowEvents(!showEvents);
  };

  const makeSelectSidebarMode = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) => layoutSettings?.[layoutId]?.[Defines.SIDEBAR_MODE]
    );
  const selectSidebarMode = useMemo(
    () => makeSelectSidebarMode(layoutId),
    [layoutId]
  );
  const sidebarMode = useSelector(selectSidebarMode);

  const handleSidebarClick = () => {
    batch(() => {
      dispatch(
        setLayoutsSettings({
          layoutId: layoutId,
          settings: {
            [Defines.LINE_CHART_SIDEBAR_ACTIVE]: !isLineChartSidebarActive,
            [Defines.SIDEBAR_MODE]: sidebarMode
              ? sidebarMode
              : Defines.SIDEBAR_MAP,
          },
        })
      );
    });
  };

  const handleSidebarModeClick = (mode) => {
    batch(() => {
      dispatch(
        setLayoutsSettings({
          layoutId: layoutId,
          settings: {
            [Defines.SIDEBAR_MODE]: mode,
          },
        })
      );
    });
  };

  /***
   * [ END_REGION ] Line Chart Button Logic
   ***/

  /***
   * [ REGION ] Line Chart Buttons
   ***/
  const LineChartButtonsSet = {
    ToggleDifferenceCursor: {
      dataTooltipContent: "Toggle Difference Cursor",
      disabled: false,
      selected: isDiffCursorEnabled,
      onClick: handleToggleDifferenceCursorClick,
      hostedURL: Defines.S3_CURSOR_DIFFERENCE,
    },
    RequestFullResolutionData: {
      dataTooltipContent: isTcuUploading
        ? "Cancel Full Resolution Data" // @TODO: use tcuUploadTooltipText to render text based on TCU response
        : "Request Full Resolution Data",
      hidden: !isSuperAdmin,
      disabled: !isDiffCursorEnabled || isTCUUploadButtonDisabled,
      selected: false,
      onMouseEnter: () => isTcuUploading && setTcuUploadButtonHovered(true),
      onMouseLeave: () => isTcuUploading && setTcuUploadButtonHovered(false),
      onClick: () =>
        !isTcuUploading ? sendTcuStartCommand() : sendTcuCancelCommand(),
      hostedURL:
        tcuUploadButtonHovered && isTcuUploading
          ? Defines.S3_HIGH_RES_REQUEST_CANCEL
          : isTcuUploading
          ? Defines.S3_HIGH_RES_REQUEST_ANIM_2
          : Defines.S3_HIGH_RES_REQUEST,
    },
    FilterSignalValues: {
      dataTooltipContent: "Filter Signal Data",
      disabled: false,
      selected: isFiltering,
      onClick: () => {
        setIsFiltering((prevState) => !prevState);
      },
      hostedURL: Defines.S3_FILTER,
    },
    ShowSamples: {
      dataTooltipContent: "Show Samples",
      disabled: false,
      selected: arePlotPointsVisible,
      onClick: () => {
        setArePlotPointsVisible(!arePlotPointsVisible);
      },
      hostedURL: Defines.S3_SAMPLE_TOGGLE,
    },
    ShowSampleValues: {
      dataTooltipContent: `Toggle Sample Values (${realTimeTextDisplay})`,
      disabled: false,
      selected:
        realTimeTextDisplay === Defines.REALTIMETEXT_LIVE ||
        realTimeTextDisplay === Defines.REALTIMETEXT_ALL,
      onClick: handleShowSampleValuesClick,
      hostedURL: liveDataIcon(),
    },
    StackGraphPlots: {
      dataTooltipContent: "Stack Graph Plots",
      disabled: false,
      selected: false,
      onClick: () => enableStackMode(),
      hostedURL: Defines.S3_PLOT_STACKED,
    },
    OverlayGraphPlots: {
      dataTooltipContent: "Overlay Graph Plots",
      disabled: false,
      selected: false,
      onClick: () => enableOverlayMode(),
      hostedURL: Defines.S3_PLOT_OVERLAY,
    },
    ToggleYAxis: {
      dataTooltipContent: "Linechart Tooltip",
      selected: false,
      disabled: true,
      hostedURL: Defines.S3_LIST_TOGGLE,
    },
    ToggleEvents: {
      dataTooltipContent: "Toggle Events",
      selected: showEvents,
      disabled: false,
      onClick: handleToggleEvents,
      hostedURL: Defines.S3_EVENTS,
    },
  };
  /***
   * [ END_REGION ] Line Chart Buttons
   ***/

  /**
   * [ REGION ] Line Charts Icon Sets
   */
  const LineChartIconSets = {
    RewindClock: {
      selected: false,
      disabled: false,
      dataToolTipContent: "Historical Mode",
      hostedURL: Defines.S3_TIME_REWIND,
    },
    Live: {
      dataTooltipContent: "Live Mode",
      selected: false,
      disabled: false,
      hostedURL: Defines.S3_BROADCAST_ANIMATION,
    },
    Play: {
      dataToolTipContent: "Toggle Playback (Playing)",
      selected: false,
      disabled: false,
      onClick: () => toggleLiveMode(),
      hostedURL: Defines.S3_TRACK_PLAY,
    },
    Pause: {
      dataToolTipContent: "Toggle Playback (Paused)",
      selected: false,
      disabled: false,
      onClick: () => toggleLiveMode(),
      hostedURL: Defines.S3_TRACK_PAUSE,
    },
    LastTrack: {
      dataToolTipContent: "Activate 'Live' Mode",
      selected: skipToEndIsActive,
      disabled: false,
      onClick: handleSkipToEnd,
      hostedURL: Defines.S3_TRACK_LAST,
    },
    ToggleSidebar: {
      dataToolTipContent: "Activate Sidebar",
      selected: isLineChartSidebarActive,
      disabled: false,
      onClick: handleSidebarClick,
      hostedURL: Defines.S3_SIDEBAR_RIGHT_ACTIVATE,
    },
  };
  /**
   * [ END_REGION ] Line Charts Icon Sets
   */

  const makeSelectFilteredEventIds = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.FILTERED_EVENT_IDS] ?? [
          ...Defines.TCU_EVENTS.map((tcu_event) => tcu_event.getIdx()),
        ]
    );

  const selectFilteredEventIds = useMemo(
    () => makeSelectFilteredEventIds(layoutId),
    [layoutId]
  );

  const initialFilteredEventIds = useSelector(selectFilteredEventIds);

  const [filteredEventIds, setFilteredEventIds] = useState(
    initialFilteredEventIds
  );

  const setReduxFilteredEventIds = (newFilteredEventIds) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.FILTERED_EVENT_IDS]: newFilteredEventIds,
        },
      })
    );

  /**
   * [ REGION ] Line Chart Sidebar Buttons
   */
  const LineChartSidebarButtonsSet = {
    ShowMap: {
      dataTooltipContent: "Map View",
      selected: sidebarMode === Defines.SIDEBAR_MAP,
      disabled: false,
      onClick: () => handleSidebarModeClick(Defines.SIDEBAR_MAP),
      hostedURL: Defines.S3_GLOBE,
    },
    ShowEvents: {
      dataTooltipContent: "Events",
      selected: sidebarMode === Defines.SIDEBAR_EVENTS,
      disabled: false,
      onClick: () => handleSidebarModeClick(Defines.SIDEBAR_EVENTS),
      hostedURL: Defines.S3_EVENTS,
    },
    ShowStackedMapEvents: {
      dataTooltipContent: "Stacked View",
      selected: sidebarMode === Defines.SIDEBAR_STACKED,
      disabled: false,
      onClick: () => handleSidebarModeClick(Defines.SIDEBAR_STACKED),
      hostedURL: Defines.S3_SIDEBAR_STACKED,
    },
    ShowEventFilterList: {
      dataTooltipContent: "Filter Events",
      selected: sidebarMode === Defines.SIDEBAR_FILTER,
      disabled: false,
      onClick: () => handleSidebarModeClick(Defines.SIDEBAR_FILTER),
      hostedURL: Defines.S3_FILTER,
    },
  };
  /**
   * [ END_REGION ] Line Chart Sidebar Buttons
   */

  const LineChartEvent = ({ event }) => {
    const [isHovered, setIsHovered] = useState(false);
    const xPos = xScale.current(event?.timestamp_recorded);
    const { title, icon } = Defines.getInfoForEvent(
      event.event_id,
      getEventStateValueForCustomEvent(event, eventStateValues)
    );
    const iconSize = 20;
    const popupWidth = 220;

    const handleEventClick = () => {
      const eventElement = document.getElementById(
        "eventcard" + event?.timestamp_recorded
      );
      eventElement?.scrollIntoView({ behavior: "smooth", block: "center" });
      const selectedEvent = {
        ...event,
        index: eventElement?.dataset.top / Defines.EVENT_CARD_HEIGHT,
      };
      goToEventDate(selectedEvent);
      batch(() => {
        setActiveEvent(selectedEvent);
        setIsLive(false);
        setMeasureDate(event.timestamp_recorded);
      });
    };

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    return (
      <g style={{ cursor: "default" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x={xPos - iconSize / 2}
          y={-iconSize - 2.5}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleEventClick}
          opacity={
            activeEvent?.timestamp_recorded === event.timestamp_recorded ||
            isHovered
              ? "1"
              : "0.15"
          }
          style={{ cursor: "pointer" }}
        >
          {/* event icon */}
          <image href={icon} width={iconSize} height={iconSize} />
        </svg>
        <line
          x1={xPos}
          y1={-4.5}
          x2={xPos}
          y2={plotHeight}
          strokeWidth="1.5"
          stroke={"#444444"}
          opacity={
            activeEvent?.timestamp_recorded === event.timestamp_recorded ||
            isHovered
              ? "1"
              : "0.2"
          }
        />
        <g
          className={"event-popup-hidden"}
          id={event.timestamp_recorded}
          style={{ display: isHovered ? "block" : "none" }}
        >
          <svg height={20} width={100} x={xPos - 100 / 2} y={0}>
            <polygon points="0,100 50,0 100,100" fill="black" />
          </svg>
          <rect
            width={popupWidth}
            height={50}
            x={xPos - popupWidth / 2}
            y={10}
            fill="black"
            rx={5}
            ry={5}
          />
          <text
            x={xPos}
            y={35}
            width={popupWidth}
            height={50}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
          >
            {title}
          </text>
        </g>
      </g>
    );
  };

  const drawEventNavigationArrows = useMemo(() => {
    const iconSize = Defines.ICON_SIZE_MD;
    const scrollEventCardIntoView = (event) => {
      const eventElement = document.getElementById(
        "eventcard" + event?.timestamp_recorded
      );
      eventElement?.scrollIntoView({ behavior: "smooth", block: "center" });
    };

    const findPreviousEvent = () => {
      if (
        filteredEvents.findIndex((event) => event.id === activeEvent.id) <= 0 ||
        !activeEvent
      )
        return;
      const previousEvent = {
        ...filteredEvents[
          filteredEvents.findIndex((event) => event.id === activeEvent.id) - 1
        ],
      };
      const newDomain = getCurrentDomain();
      batch(() => {
        setActiveEvent(previousEvent);
        setIsLive(false);
        setShowEvents(true);
      });
      scrollEventCardIntoView(previousEvent);
      goToEventDate(previousEvent);
      onManualDurationChange(newDomain);
    };

    const findNextEvent = () => {
      if (
        filteredEvents.findIndex((event) => event.id === activeEvent?.id) >=
        filteredEvents.length - 1
      )
        return;
      const nextEvent = !activeEvent
        ? {
            ...filteredEvents[0],
          }
        : {
            ...filteredEvents[
              filteredEvents.findIndex((event) => event.id === activeEvent.id) +
                1
            ],
          };
      const newDomain = getCurrentDomain();
      goToEventDate(nextEvent);
      scrollEventCardIntoView(nextEvent);

      onManualDurationChange(newDomain);
      batch(() => {
        setActiveEvent(nextEvent);
        setIsLive(false);
        setShowEvents(true);
      });
    };
    const eventScrollHitboxSize = 20;
    const verticalOffset = 0.5;
    return (
      <g>
        <rect
          width={eventScrollHitboxSize}
          height={eventScrollHitboxSize}
          fill={"white"}
          fillOpacity="0.4"
          x={0}
          y={-eventScrollHitboxSize - verticalOffset}
          onClick={findNextEvent}
          style={{ cursor: "pointer" }}
        />
        <rect
          width={eventScrollHitboxSize}
          height={eventScrollHitboxSize}
          fill={"white"}
          fillOpacity="0.4"
          x={plotWidth - eventScrollHitboxSize}
          y={-eventScrollHitboxSize - verticalOffset}
          onClick={findPreviousEvent}
          style={{ cursor: "pointer" }}
        />
        <svg
          x={0}
          y={-iconSize - verticalOffset}
          onClick={findNextEvent}
          style={{
            cursor: "pointer",
            opacity:
              filteredEvents.findIndex(
                (event) => event.id === activeEvent?.id
              ) >=
              filteredEvents.length - 1
                ? "0.2"
                : "1",
          }}
        >
          <image
            href={Defines.S3_TRACK_PREV}
            width={iconSize}
            height={iconSize}
          />
        </svg>
        <svg
          x={plotWidth - iconSize}
          y={-iconSize - verticalOffset}
          onClick={findPreviousEvent}
          style={{
            cursor: "pointer",
            opacity:
              filteredEvents.findIndex(
                (event) => event.id === activeEvent?.id
              ) <= 0 || !activeEvent
                ? "0.2"
                : "1",
          }}
        >
          <image
            href={Defines.S3_TRACK_NEXT}
            width={iconSize}
            height={iconSize}
          />
        </svg>
      </g>
    );
  }, [
    activeEvent,
    filteredEvents,
    getCurrentDomain,
    goToEventDate,
    onDurationChange,
    onManualDurationChange,
    plotWidth,
    setActiveEvent,
    setIsLive,
    setShowEvents,
  ]);

  const filterEvents = (event_id) => {
    let arrayOfFilteredEventIds = [...filteredEventIds];
    if (arrayOfFilteredEventIds.includes(event_id)) {
      arrayOfFilteredEventIds = arrayOfFilteredEventIds.filter(
        (filteredEvent) => filteredEvent !== event_id
      );
      setFilteredEvents(
        filteredEvents.filter((event) =>
          arrayOfFilteredEventIds.includes(event[event_id])
        )
      );
    } else {
      arrayOfFilteredEventIds.push(event_id);
      setFilteredEvents(
        events.filter((event) =>
          arrayOfFilteredEventIds.includes(event[event_id])
        )
      );
    }
    batch(() => {
      setFilteredEventIds(arrayOfFilteredEventIds);
      setReduxFilteredEventIds(arrayOfFilteredEventIds);
      setXZoomToNewDomain(getCurrentDomain());
    });
  };

  const clearFilters = useCallback(() => {
    setFilteredEventIds([]);
    setFilteredEvents([]);
    setXZoomToNewDomain(getCurrentDomain());
  }, [getCurrentDomain, setXZoomToNewDomain]);

  const selectAllFilters = () => {
    setFilteredEventIds(
      Defines.TCU_EVENTS.map((tcu_event) => tcu_event.getIdx())
    );
    setFilteredEvents(events);
    setXZoomToNewDomain(getCurrentDomain());
  };

  useEffect(() => {
    setFilteredEvents(
      events.filter((event) => filteredEventIds.includes(event.event_id))
    );
    setXZoomToNewDomain(getCurrentDomain());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [events, filteredEventIds]);

  // Retains line chart range on sidebar toggle
  useEffect(() => {
    if (lineChartDateRange.length > 1) {
      setXZoomToNewDomain(lineChartDateRange);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLineChartSidebarActive]);

  // ~~~~~~~~~~~~~~~~~~~ EVENTS GROUPING ~~~~~~~~~~~~~~~~~~~

  const drawEvents = useMemo(
    () =>
      _.throttle(() => {
        const eventIsInViewableRange = (event) => {
          const [startDate, endDate] = getCurrentDomain();
          const eventTimestampDate = new Date(event.timestamp_recorded);
          const isAfterStart = eventTimestampDate >= startDate;
          const isBeforeEnd = eventTimestampDate <= endDate;
          return isAfterStart && isBeforeEnd;
        };

        const eventsInViewRange = filteredEvents?.filter(
          eventIsInViewableRange
        );

        const distanceThreshold = 10; // Adjust this value based on your requirement
        const groupedEvents = groupEventsByDistance(
          eventsInViewRange,
          xScale.current,
          distanceThreshold
        );

        return groupedEvents?.map((group, index) =>
          group.length > 1 ? (
            <LineChartEventGroup events={group} key={`group-${index}`} />
          ) : (
            <LineChartEvent
              event={group[0]}
              key={`${group[0].timestamp_recorded}_${group[0].id}`}
            />
          )
        );
      }, 25),
    [filteredEvents, getCurrentDomain, activeEvent]
  );

  const LineChartEventGroup = ({ events }) => {
    const [isHovered, setIsHovered] = useState(false);
    const xPos = xScale.current(events[0]?.timestamp_recorded);
    const iconSize = 20;
    const popupWidth = 220;

    const handleMouseOver = () => setIsHovered(true);
    const handleMouseOut = () => setIsHovered(false);

    const handleGroupClick = () => {
      const dateStart = events[events.length - 1].timestamp_recorded;
      const dateEnd = events[0].timestamp_recorded;
      const dateDiff = dateEnd - dateStart;
      const rangeDiff = dateDiff < 7000 ? 4000 : dateDiff * 0.25; // Zooms in to (8 second) range
      const adjustedStart = dateStart - rangeDiff;
      const adjustedEnd = dateEnd + rangeDiff;
      const shiftedDomain = [adjustedStart, adjustedEnd];
      setXZoomToNewDomain(shiftedDomain);
      onDurationChange(shiftedDomain);
      setDurationDropdownText(
        handleFetchAndDisplayStartAndEndDate(false, null, shiftedDomain)
      );
    };

    return (
      <g style={{ cursor: "default" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          x={xPos - iconSize / 2}
          y={-iconSize - 2.5}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onClick={handleGroupClick}
          opacity={isHovered ? "1" : "0.15"}
          style={{ cursor: "pointer" }}
        >
          {/* group icon */}
          <image
            href={Defines.S3_ARROWS_EXPAND}
            width={iconSize}
            height={iconSize}
          />
        </svg>
        <line
          x1={xPos}
          y1={-4.5}
          x2={xPos}
          y2={plotHeight}
          strokeWidth="1.5"
          stroke={"black"}
          opacity={isHovered ? "1" : "0.2"}
        />
        <g style={{ display: isHovered ? "block" : "none" }}>
          {events.map((event) => (
            <text key={event.id} x={xPos} y={35} fill="white">
              {event.title}
            </text>
          ))}
        </g>
        <g
          className={"event-popup-hidden"}
          id={`group-${events[0].timestamp_recorded}`}
          style={{ display: isHovered ? "block" : "none" }}
        >
          <svg height={20} width={100} x={xPos - 100 / 2} y={0}>
            <polygon points="0,100 50,0 100,100" fill="black" />
          </svg>
          <rect
            width={popupWidth}
            height={50}
            x={xPos - popupWidth / 2}
            y={10}
            fill="black"
            rx={5}
            ry={5}
          />
          <text
            x={xPos}
            y={35}
            width={popupWidth}
            height={50}
            textAnchor="middle"
            dominantBaseline="middle"
            fill="white"
          >
            Click to expand
          </text>
        </g>
      </g>
    );
  };

  const groupEventsByDistance = (events, xScale, distanceThreshold) => {
    if (events.length === 0) return [];

    const groupedEvents = [];
    let currentGroup = [events[0]];

    for (let i = 1; i < events.length; i++) {
      const prevEvent = events[i - 1];
      const currentEvent = events[i];

      const prevX = xScale(prevEvent.timestamp_recorded);
      const currentX = xScale(currentEvent.timestamp_recorded);

      if (Math.abs(currentX - prevX) < distanceThreshold) {
        currentGroup.push(currentEvent);
      } else {
        groupedEvents.push(currentGroup);
        currentGroup = [currentEvent];
      }
    }

    if (currentGroup.length > 0) {
      groupedEvents.push(currentGroup);
    }

    return groupedEvents;
  };

  /**
   * Date Time Picker (Range Picker) Submit Event Handler
   *  */
  const handleDateTimePickerSubmit = (newDomain) => {
    onManualDurationChange(newDomain);
    batch(() => {
      setXZoomToNewDomain(newDomain);
      setIsLive(false);
      setIsTimeSelectVisible(false);
      setDurationDropdownText(
        handleFetchAndDisplayStartAndEndDate(false, null, newDomain)
      );
    });
  };

  /**
   * Setting Relative DateTime Duration/Range for Line Chart Event Handler
   */
  const handleSetRelativeDateTimeRange = (durationTime, durationText) => {
    const calculatedDuration = calculatedViewable(
      durationTime,
      config.initialRelativeUnit
    );

    const newStart = new Date(Date.now() - calculatedDuration / 2);
    const newEnd = new Date(Date.now() + calculatedDuration / 2);

    const newDomain = [newStart, newEnd];
    onManualDurationChange(newDomain);

    batch(() => {
      setIsLive(true);
      setViewableDuration(durationTime / 2);
      setDurationDropdownText(durationText);
      setIsTimeSelectVisible(false);
      setXZoomToNewDomain(newDomain);
    });
  };

  return (
    <ChartStyles.ChartAndSidebarContainer>
      <ChartStyles.LineChartOuter>
        <ChartStyles.LineChartBody>
          <ChartStyles.LineChartToolBar>
            <ChartStyles.IconButtonActionsContainer>
              {LineChartButtonsSet &&
                Object.entries(LineChartButtonsSet).map((obj, i) => {
                  const button = obj.at(1);
                  return !button.hidden ? (
                    <IconToolTip
                      content={button.dataTooltipContent}
                      key={button.dataTooltipContent + i}
                    >
                      <LineChartButton button={button} />
                    </IconToolTip>
                  ) : null;
                })}
            </ChartStyles.IconButtonActionsContainer>
            <ChartStyles.TimeControlsContainer>
              <ChartStyles.FetchingIndicator
                className={
                  isFetching ? "animation-start-invisible fade-in" : "fade-out"
                }
              >
                <Icon
                  width={Defines.ICON_SIZE_SM}
                  height={Defines.ICON_SIZE_SM}
                  hostedImage={Defines.S3_HIGH_RES_REQUEST_ANIM}
                />
              </ChartStyles.FetchingIndicator>
              <IconToolTip
                content={
                  skipToEndIsActive
                    ? LineChartIconSets.Live.dataTooltipContent
                    : LineChartIconSets.RewindClock.dataTooltipContent
                }
              >
                <LineChartButton
                  button={
                    skipToEndIsActive
                      ? LineChartIconSets.Live
                      : LineChartIconSets.RewindClock
                  }
                  iconButtonStyle={{ cursor: "default" }}
                  iconStyle={{
                    height: "25px",
                    width: "25px",
                  }}
                />
              </IconToolTip>
              <ChartStyles.TimeSelectorDropdown
                enabled={config.enableTimeSelect}
                onClick={() => setIsTimeSelectVisible(!isTimeSelectVisible)}
              >
                <div
                  dangerouslySetInnerHTML={{ __html: durationDropdownText }}
                />
                <Icon
                  style={{
                    marginLeft: "5px",
                  }}
                  width={10}
                  height={10}
                  hostedImage={Defines.S3_CARET_DOWN}
                />
              </ChartStyles.TimeSelectorDropdown>
              <ChartStyles.TimeSelectorClickCatcher
                active={isTimeSelectVisible}
                onClick={() => setIsTimeSelectVisible(false)}
              />
              {isTimeSelectVisible && (
                <ChartStyles.TimeSelector show={isTimeSelectVisible}>
                  <ChartStyles.TimeSelectorRelative>
                    <RelativeTimeSelector
                      handleDurationSelection={handleSetRelativeDateTimeRange}
                      handleFetchAndDisplayStartAndEndDate={
                        handleFetchAndDisplayStartAndEndDate
                      }
                    />
                  </ChartStyles.TimeSelectorRelative>
                  <ChartStyles.TimeSelectorAbsolute>
                    <ChartComponents.RangePicker
                      onSubmit={handleDateTimePickerSubmit}
                      startAndEndDate={getCurrentDomain()}
                    />
                  </ChartStyles.TimeSelectorAbsolute>
                </ChartStyles.TimeSelector>
              )}
              <IconToolTip
                content={
                  isLive
                    ? LineChartIconSets.Pause.dataToolTipContent
                    : LineChartIconSets.Play.dataToolTipContent
                }
              >
                <LineChartButton
                  button={
                    isLive ? LineChartIconSets.Pause : LineChartIconSets.Play
                  }
                />
              </IconToolTip>
              <IconToolTip
                content={LineChartIconSets.LastTrack.dataToolTipContent}
              >
                <LineChartButton button={LineChartIconSets.LastTrack} />
              </IconToolTip>

              <div
                style={{
                  width: "2px",
                  backgroundColor: "lightgray",
                  marginRight: "10px",
                }}
              ></div>
              {isLineChartSidebarActive && (
                <>
                  {LineChartSidebarButtonsSet &&
                    Object.entries(LineChartSidebarButtonsSet).map((obj, i) => {
                      const button = obj.at(1);
                      return !button.hidden ? (
                        <IconToolTip
                          content={button.dataTooltipContent}
                          key={button.dataTooltipContent + i}
                          onClick={button.onClick}
                        >
                          <LineChartButton
                            button={button}
                            isSidebarButton={true}
                          />
                        </IconToolTip>
                      ) : null;
                    })}
                </>
              )}
              <IconToolTip
                content={LineChartIconSets.ToggleSidebar.dataToolTipContent}
                key={LineChartIconSets.ToggleSidebar.dataTooltipContent}
                onClick={LineChartIconSets.ToggleSidebar.onClick}
              >
                <LineChartButton button={LineChartIconSets.ToggleSidebar} />
              </IconToolTip>
            </ChartStyles.TimeControlsContainer>
          </ChartStyles.LineChartToolBar>

          <div
            style={{
              display: "flex",
              position: "relative",
            }}
            ref={containerRef}
          >
            {!!canvasHeight && !!canvasWidth && (
              <svg height={renderHeight} width={renderWidth}>
                <defs>
                  <clipPath className="clip-path" id="clip">
                    <rect x="0" y="0" width={plotWidth} height={plotHeight} />
                  </clipPath>
                  <clipPath className="clip-path" id="clipHigh">
                    <rect
                      x="0"
                      y="-22"
                      width={plotWidth}
                      height={plotHeight + 22}
                    />
                  </clipPath>
                </defs>
                <g
                  className="plot-area"
                  style={{
                    transform: `translate(${
                      margin.left + numYAxes * sizeYAxis
                    }px,${margin.top}px)`,
                    cursor: "grab",
                  }}
                  ref={listenerRef}
                  onClick={() => setIsTooltipActive(false)}
                >
                  <ChartComponents.XAxis
                    y={plotHeight}
                    scale={xScale.current}
                    plotHeight={plotHeight}
                    plotWidth={plotWidth}
                  />
                  {drawYAxes()}
                  {drawEventNavigationArrows}
                  {/* <g> onClick will not work without this rect */}
                  <rect
                    opacity="0"
                    x={0}
                    y={0}
                    width={plotWidth}
                    height={plotHeight}
                    style={{ cursor: "grab" }}
                  />
                  <g clipPath="url(#clipHigh) ">
                    <pattern
                      id="diagonalHatch"
                      key="diagonal-hatch"
                      width="5"
                      height="5"
                      patternTransform="rotate(45 0 0)"
                      patternUnits="userSpaceOnUse"
                    >
                      <line
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="10"
                        stroke="black"
                        strokeWidth={1}
                      />
                    </pattern>
                    <rect
                      x={nowPos}
                      y={0}
                      fill={"#636363"}
                      fillOpacity={"20%"}
                      width={99999}
                      height={plotHeight}
                    />
                  </g>

                  <g clipPath="url(#clip)">
                    {/* highlighted area between cursors */}
                    {isDiffCursorEnabled && (
                      <rect
                        x={Math.min(measurePos, diffPos)}
                        y="0"
                        fill={theme.themePrimary}
                        fillOpacity="15%"
                        width={Math.abs(measurePos - diffPos)}
                        height={plotHeight}
                      >
                        <animate
                          attributeName="opacity"
                          values={isTcuUploading ? "0;1;0" : "1;1;1"}
                          dur="1s"
                          repeatCount="indefinite"
                        />
                      </rect>
                    )}

                    {/* measure cursor */}
                    {config.enableMeasureCursor && (
                      <ChartComponents.CursorLine
                        passedRef={measureCursorRef}
                        isLive={skipToEndIsActive}
                        cursorType={Defines.CURSORTYPE_MEASURE}
                        x={measurePos}
                        height={plotHeight - 2}
                      />
                    )}
                    {/* diff cursor */}
                    {config.enableDiffCursor && isDiffCursorEnabled && (
                      <ChartComponents.CursorLine
                        passedRef={diffCursorRef}
                        cursorType={Defines.CURSORTYPE_DIFF}
                        x={diffPos}
                        height={plotHeight}
                      />
                    )}
                    {/* fetch measure cursor bar */}
                    <rect
                      x={0}
                      y={0}
                      width={plotWidth}
                      height={20}
                      fill={"rgba(68,68,68,.15)"}
                      opacity={0.5}
                      ref={measureFetcherRef}
                      style={{ cursor: "pointer" }}
                    />
                    {/* fetch diff cursor bar */}
                    <rect
                      x={0}
                      y={plotHeight - 20}
                      width={plotWidth}
                      height={20}
                      fill={"rgba(68,68,68,.15)"}
                      opacity={0.5}
                      ref={diffFetcherRef}
                      style={{ cursor: "pointer" }}
                    />
                    {/* measure indicator */}
                    {!isMeasureCursorOnScreen && (
                      <ChartComponents.CursorLine
                        passedRef={null}
                        isLive={isLive}
                        liveColorFilter={LIVE_COLOR_FILTER}
                        cursorType={Defines.CURSORTYPE_MEASURE}
                        x={measureCursorIsInPast ? 7 : plotWidth - 7}
                        height={plotHeight}
                        indicatorMode={true}
                        onClick={goToMeasureCursor}
                      />
                    )}
                    {/* diff cursor indicator */}
                    {!isDifferenceCursorOnScreen && isDiffCursorEnabled && (
                      <ChartComponents.CursorLine
                        passedRef={null}
                        isLive={isLive}
                        liveColorFilter={LIVE_COLOR_FILTER}
                        cursorType={Defines.CURSORTYPE_DIFF}
                        x={diffCursorIsInPast ? 7 : plotWidth - 7}
                        height={plotHeight}
                        indicatorMode={true}
                        onClick={goToDifferenceCursor}
                      />
                    )}
                    {drawViewablePlots()}
                    {highlightedCursorPoints()}
                    <ChartComponents.HorizontalCursor
                      passedRef={horizCursorRef}
                      width={plotWidth}
                    />
                  </g>
                  {showEvents && drawEvents()}
                </g>
              </svg>
            )}
            {isTooltipActive && (
              <ChartComponents.LineChartToolTip
                width={tooltipWidth}
                passedRef={chartTooltipRef}
              >
                {measurePoints.map((mp, i) => (
                  <ChartComponents.LineChartToolTipItem
                    key={i}
                    measurePoint={mp}
                    index={i}
                    isDiffCursorEnabled={isDiffCursorEnabled}
                    selected={selectedChart === i}
                    diffPointData={diffPoints[i]}
                  />
                ))}
                <div style={{ padding: "10px 5px 0 5px", display: "flex" }}>
                  <div style={{ width: "200px" }}>
                    <strong>t:</strong>{" "}
                    {activeCursor === Defines.CURSORTYPE_MEASURE
                      ? formattedMeasureDate
                      : formattedDiffDate}
                  </div>
                  {isDiffCursorEnabled && (
                    <>
                      <div>Δ {convertMilliseconds(diffDate - measureDate)}</div>
                    </>
                  )}
                </div>
              </ChartComponents.LineChartToolTip>
            )}
            {canvasHeight && (
              <EventsSidebar
                active={isLineChartSidebarActive}
                activeEvent={activeEvent}
                clearFilters={clearFilters}
                events={filteredEvents}
                eventStateValues={eventStateValues}
                filteredEventIds={filteredEventIds}
                filterEvents={filterEvents}
                getEventStateValueForCustomEvent={
                  getEventStateValueForCustomEvent
                }
                goToEventDate={goToEventDate}
                handleFetchEvents={handleFetchEvents}
                height={renderHeight}
                layoutId={layoutId}
                lineChartDateRange={lineChartDateRange}
                measureCursorDate={measureDate}
                oldestEvent={events ? events[events.length - 1] : null}
                selectAllFilters={selectAllFilters}
                setShowEvents={setShowEvents}
                showEvents={showEvents}
                sidebarMode={sidebarMode}
                onManualDurationChange={onManualDurationChange}
              />
            )}
          </div>
        </ChartStyles.LineChartBody>
      </ChartStyles.LineChartOuter>
      <Tooltip place="top" id="linechart-tooltip" />
    </ChartStyles.ChartAndSidebarContainer>
  );
};

export const LineRedux = ({ instance_signals, instanceId, layoutId }) => {
  const dataForCurrentSN = useDataHistoryForCurrentSN();
  const chartDataRef = useRef([]);
  const abortControllerRef = useRef(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isSkip, setIsSkip] = useState(false);
  const [isFiltering, setIsFiltering] = useState(false);
  const setEvents = useStoreEvents();
  const events = useEventHistoryForCurrentSN();
  const chartData = ChartUtilities.composeChartDataFromInstanceSignals({
    dataForCurrentSN,
    instance_signals,
    isFiltering,
  });
  chartDataRef.current = chartData;
  const product_sn_id = useCurrentSNId();
  const setDataHistory = useSetDataHistory();
  const setDataInterval = useSetDataInterval();
  const customerId = useCustomerId();
  const dispatch = useDispatch();
  const selectLayoutSettings = (state) => state.layout?.layoutSettings;

  const makeSelectLineChartDateRange = (layoutId) =>
    createSelector([selectLayoutSettings], (layoutSettings) => {
      const dateRange =
        layoutSettings?.[layoutId]?.[Defines.LINE_CHART_DATE_RANGE];
      return dateRange ? [new Date(dateRange[0]), new Date(dateRange[1])] : [];
    });

  const selectLineChartDateRange = useMemo(
    () => makeSelectLineChartDateRange(layoutId),
    [layoutId]
  );
  const lineChartDateRange = useSelector(selectLineChartDateRange);

  const setLineChartDateRange = (newLineChartDateRange) =>
    dispatch(
      setLayoutsSettings({
        layoutId: layoutId,
        settings: {
          [Defines.LINE_CHART_DATE_RANGE]: [
            newLineChartDateRange?.[0]?.valueOf(),
            newLineChartDateRange?.[1]?.valueOf(),
          ],
        },
      })
    );

  const makeSelectIsLineChartSidebarActive = (layoutId) =>
    createSelector(
      [selectLayoutSettings],
      (layoutSettings) =>
        layoutSettings?.[layoutId]?.[Defines.LINE_CHART_SIDEBAR_ACTIVE]
    );

  const selectIsLineChartSidebarActive = useMemo(
    () => makeSelectIsLineChartSidebarActive(layoutId),
    [layoutId]
  );
  const isLineChartSidebarActive = useSelector(selectIsLineChartSidebarActive);

  const eventCache = useRef({});

  const handleFetchEvents = _.debounce(async (timestamp_recorded) => {
    const timestampRecordedDate = new Date(timestamp_recorded);

    const formattedTimestamp = timestamp_recorded
      ? moment(timestampRecordedDate).format("YYYY-MM-DD HH:mm:ss")
      : null;

    const epochTime = timestampRecordedDate.valueOf();

    const fetchEvents = async (timestamp) => {
      try {
        const response = await axios.get(
          `/api/events/?customer_id=${customerId}${
            timestamp ? `&timestamp_recorded=${timestamp}` : ""
          }`
        );
        setEvents(response);
        // createSessions(response);
        // cache timestamp of last event of response
        eventCache.current["cachedDate"] =
          response[response.length - 1].timestamp_recorded;
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    if (formattedTimestamp) {
      // if cache is empty, fetch events with provided timestamp
      fetchEvents(formattedTimestamp);
      if (!eventCache.current["cachedDate"]) {
      } else {
        // check if provided date is older than cached date
        // if so, fetch events with provided timestamp
        if (epochTime <= eventCache.current["cachedDate"]) {
          fetchEvents(formattedTimestamp);
        }
      }
    } else {
      fetchEvents();
    }
  }, 1000);

  const { isLoading: allSNsIsLoading, data: allSNs } =
    useGetApplicationUnitsByFilterQuery({ customer_id: customerId });

  const {
    isLoading: isQApplicationSettingsLoading,
    data: qApplicationSettings,
  } = useGetProductSettingsForCustomerQuery(customerId);

  const currentProductSNProductId = allSNs?.length
    ? allSNs.find((productSN) => productSN.id === product_sn_id)?.product_id
    : null;

  if (currentProductSNProductId && isSkip) {
    setIsSkip(false);
  } else if (!currentProductSNProductId && !isSkip) {
    setIsSkip(true);
  }

  const { data: eventStateValues } = useGetEventStatesForProductQuery(
    currentProductSNProductId,
    { skip: isSkip }
  );

  const getEventStateValueForCustomEvent = (event, eventStateValues) => {
    if (allSNsIsLoading) return;
    const eventStateValue = eventStateValues?.[
      event.event_state_id
    ]?.values.find(
      (stateValue) => stateValue.value === event.event_state_value
    );
    return eventStateValue?.label || "Custom Event";
  };

  const fetchData = useMemo(
    () =>
      _.debounce(async (startDate, endDate) => {
        endDate = endDate > new Date() ? new Date() : endDate;
        handleFetchEvents(startDate);
        const rangeDiff = (endDate - startDate) * 0.1;

        const adjustedStartMs = new Date(
          startDate.getTime() - rangeDiff
        ).valueOf();

        // get Application Unit's Signal IDs featured in Line Redux
        let signal_ids = instance_signals.map((d) => d.signal_id);
        if (
          isLineChartSidebarActive &&
          !isQApplicationSettingsLoading &&
          currentProductSNProductId
        ) {
          // if Sidebar is active, include GPS signals if not already included
          const { lat_signal_id, lng_signal_id } =
            qApplicationSettings?.find(
              (application) =>
                application?.product_id === currentProductSNProductId
            ) || {};
          if (!signal_ids.includes(lat_signal_id))
            signal_ids.push(lat_signal_id);
          if (!signal_ids.includes(lng_signal_id))
            signal_ids.push(lng_signal_id);
        }

        const adjustedEndMs = endDate.valueOf();
        const fullRangeMs = Math.abs(adjustedEndMs - adjustedStartMs);
        const timeChunkMs = Math.round(fullRangeMs / 5);

        // Aborts asynchronous operation
        // https://developer.mozilla.org/en-US/docs/Web/API/AbortController/abort
        if (abortControllerRef.current || isFetching) {
          abortControllerRef.current.abort();
        }

        setIsFetching(true);
        abortControllerRef.current = new AbortController();
        const { signal } = abortControllerRef.current;

        let i = adjustedEndMs;
        const results = [];
        try {
          while (i > adjustedStartMs) {
            const chunkEnd = i;
            const chunkStart = Math.max(
              adjustedStartMs,
              chunkEnd - timeChunkMs
            );
            results.push({ chunkStart, chunkEnd });
            i -= timeChunkMs;
            try {
              const res = await productSNDataService.historical({
                product_sn_id,
                date_start: new Date(chunkStart),
                date_end: new Date(chunkEnd),
                signal_ids,
                limit: 100,
                signal,
              });
              setDataHistory(res.data);
            } catch (fetchError) {
              if (fetchError.name === "AbortError") {
                console.error("Fetch aborted");
                return; // Exit early if the fetch was aborted
              } else {
                console.error("Fetch error:", fetchError);
                setIsFetching(false);
                // Optionally handle other fetch errors or rethrow
                throw fetchError;
              }
            }
          }
        } catch (error) {
          console.error("Error in fetchData loop:", error);
        } finally {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
          setIsFetching(false);
        }
      }, 500),
    [
      handleFetchEvents,
      instance_signals,
      isFetching,
      product_sn_id,
      setDataHistory,
    ]
  );

  useEffect(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    return () => {
      abortController.abort(); // Cleanup on unmount
    };
  }, [layoutId]);

  const handleManualDurationChange = ([startDate, endDate]) => {
    fetchData(startDate, endDate);
    setLineChartDateRange([startDate, endDate]);
    handleGeneralDurationChange([startDate, endDate]);
  };

  const handleGeneralDurationChange = ([startDate, endDate]) => {
    const startMs = startDate.valueOf();
    const endMs = endDate.valueOf();
    const diffMs = endMs - startMs;
    const adjustedStartMs = startMs - diffMs;
    const adjustedEndMs = endMs + diffMs;
    const adjustedDateRange = [adjustedStartMs, adjustedEndMs];
    setDataInterval({
      instanceId,
      dateRange: adjustedDateRange,
    });
  };

  return (
    <>
      <SizeMe monitorHeight={true} refreshMode="debounce">
        {({ size }) => (
          <MemoLineChart
            isFiltering={isFiltering}
            setIsFiltering={setIsFiltering}
            chartData={chartDataRef.current}
            fetchData={fetchData}
            isFetching={isFetching}
            canvasWidth={
              isLineChartSidebarActive
                ? size.width - Defines.LINECHART_SIDEBAR_WIDTH
                : size.width
            }
            canvasHeight={size.height}
            onManualDurationChange={handleManualDurationChange}
            onDurationChange={handleGeneralDurationChange}
            lineChartDateRange={lineChartDateRange}
            events={events}
            // sessions={sessions}
            eventStateValues={eventStateValues}
            getEventStateValueForCustomEvent={getEventStateValueForCustomEvent}
            handleFetchEvents={handleFetchEvents}
            layoutId={layoutId}
            isLineChartSidebarActive={isLineChartSidebarActive}
            setLineChartDateRange={setLineChartDateRange}
            product_sn_id={product_sn_id}
          />
        )}
      </SizeMe>
    </>
  );
};

const MemoLineChart = memo(
  LineChartWithCursors,
  (prevProps, nextProps) =>
    _.isEqual(prevProps.chartData, nextProps.chartData) &&
    _.isEqual(prevProps.events, nextProps.events) &&
    prevProps.canvasWidth === nextProps.canvasWidth &&
    prevProps.canvasHeight === nextProps.canvasHeight &&
    prevProps.isFetching === nextProps.isFetching &&
    prevProps.isLineChartSidebarActive === nextProps.isLineChartSidebarActive
);
