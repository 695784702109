import { Component } from "react";
import { connect } from "react-redux";
import { default as LoadingSpinner } from "react-spinners/BarLoader";
import {
  applicationsApi,
  setCurrentProduct,
} from "features/applications/redux";
import GoogleMapReact from "google-map-react";
import Supercluster from "supercluster";
import MapModal from "./MapModal";
import MapMarker from "./MapMarker";
import { Icon } from "common";
import { MapStyling } from "styles/GlobalStyles";
import ProgressBar from "common/ProgressBar";
import { GetFilter } from "utils/Color";
import { withTheme } from "styled-components";
import _ from "lodash";
import fastEqual from "fast-deep-equal";
import styled from "styled-components";
import { productService } from "features/applications/services/";
import { GOOGLE_MAPS_API_KEY } from "config";
import {
  setActiveMapMarker,
  setMapCenter,
  setOverlayShown,
  setUnitsMapType,
} from "features/map/redux";
import * as Defines from "utils/defines";
import store from "store";
import { hexToRgba } from "utils/Color";
import { applicationUnitsApi } from "features/units/redux";
import { MapTypeButtons } from "./MapTypeButtons";

// import { Lines } from "./MapObjects";

// @TODO investigate default zoomed state
const { GoogleMapStyle_Big } = MapStyling;

const CLUSTER_RADIUS = 90; // in px

const OverlayWarning = styled.div`
  align-items: center;
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  border: 1px solid black;
  display: flex;
  height: 50px;
  justify-content: center;
  left: 50%;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const MapMarkerIcon = styled.div`
  cursor: pointer;
  transform: translate(-50%, -50%);
  width: max-content;
  z-index: 2;
`;

const MapMarkerLine = styled.div`
  transform: translate(7px, -107px);
  width: max-content;
`;

const MapMarkerLabel = styled.div`
  position: absolute;
  transform: translate(84px, -241px);
  width: max-content;
  padding: 10px 10px 13px 10px;
  background-color: white;
  border: ${({ active, themePrimary }) =>
    active ? `2px solid ${themePrimary}` : `2px solid lightgray`};
  border-radius: 8px;
  z-index: ${({ active }) => (active ? `10` : `2`)};
  cursor: pointer;
`;

const SVGLine = ({ active, themePrimary }) => (
  <svg height="80" width="80">
    <line
      x1="0"
      y1="80"
      x2="80"
      y2="0"
      style={{
        stroke: active ? themePrimary : "rgb(255,255,255, .8)",
        strokeWidth: "5",
      }}
    />
    <line
      x1="0"
      y1="80"
      x2="80"
      y2="0"
      style={{ stroke: "rgb(0,0,0)", strokeWidth: "3" }}
    />
  </svg>
);

class GoogleMap extends Component {
  render() {
    const filteredCenter =
      !this.props.center ||
      isNaN(this.props.center.lat) ||
      isNaN(this.props.center.lng)
        ? null
        : this.props.center;
    return (
      <GoogleMapReact
        defaultCenter={Defines.DEFAULT_MAP_CENTER}
        bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
        draggable={this.props.draggable}
        center={filteredCenter}
        zoom={this.props.mapZoom}
        options={{
          styles: GoogleMapStyle_Big,
          mapTypeId: this.props.unitsMapType,
        }}
        onChange={this.props.onChange}
        onGoogleApiLoaded={this.props.onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals
        style={this.props.style}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </GoogleMapReact>
    );
  }
}

class MapContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      center: this.props.mapCenter
        ? this.props.mapCenter
        : Defines.DEFAULT_MAP_CENTER,
      zoom: 5,
      activeMarker: null,
      draggable: true,
      clusters: [],
      productSettings: {},
      enableAutoZoom: true,
      mapType: this.props.unitsMapType
        ? this.props.unitsMapType
        : Defines.MAP_TYPE_TERRAIN,
    };
    this.refreshSignalSettings = this.refreshSignalSettings.bind(this);
  }

  componentDidMount() {
    const psnsWithCoords = this.getValidPSNsWithCoords();
    if (psnsWithCoords.length) return;
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const newCenter = { lat: latitude, lng: longitude };
          this.props.setMapCenter(newCenter);
        },
        (error) => {
          console.error(error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported in this browser.");
    }
  }

  // load in signal id mappings for coordinates
  // DEPRECATE - UNDO WHEN SETTINGS MOVED TO REDUX
  refreshSignalSettings() {
    const { allProducts } = this.props;
    if (!allProducts || !allProducts.length) return null;
    allProducts.forEach((product) => {
      productService.getSettingsForId(product.id).then((productSettingsForId) =>
        this.setState({
          productSettings: {
            ...this.state.productSettings,
            [product.id]: productSettingsForId,
          },
        })
      );
    });
  }

  componentDidUpdate(prevProps, prevState) {
    // if datahistory changes, redraw points / clusters
    if (!fastEqual(prevProps.dataHistory, this.props.dataHistory)) {
      this.createClusters();
      const psnsWithCoords = this.getValidPSNsWithCoords();
      const coords = psnsWithCoords.map((psn) => psn.coordinates);
      let bounds = this.getMapBounds(coords);
      if (bounds && this.state.enableAutoZoom) {
        this.googleMapRef.fitBounds(bounds);
        this.setState({ enableAutoZoom: false });
      }
    }

    // if products change (on load), get signal settings
    // DEPRECATE - UNDO WHEN SIGNAL SETTINGS MOVED TO REDUX
    if (!fastEqual(prevProps.allProducts, this.props.allProducts)) {
      // refresh list of lat lng signal ids
      this.refreshSignalSettings();
    }
  }

  // save map objects to state
  setMapRefs = (map, maps) => {
    this.googleMapRef = map;
    this.googleRef = maps;
  };

  getMapBounds = (points) => {
    const map = this.googleMapRef;
    const maps = this.googleRef;
    if (!map || !maps) return null;
    const bounds = new maps.LatLngBounds();
    const extendBoundsToPoint = (point) =>
      bounds.extend(new maps.LatLng(point.lat, point.lng));
    points.forEach(extendBoundsToPoint);

    // Don't zoom in too far on only one marker
    // ref: https://stackoverflow.com/a/5345708
    var extendPoint1 = new maps.LatLng(
      bounds.getNorthEast().lat() + Defines.MAP_EXPANSION_FACTOR,
      bounds.getNorthEast().lng() + Defines.MAP_EXPANSION_FACTOR
    );
    var extendPoint2 = new maps.LatLng(
      bounds.getNorthEast().lat() - Defines.MAP_EXPANSION_FACTOR,
      bounds.getNorthEast().lng() - Defines.MAP_EXPANSION_FACTOR
    );
    bounds.extend(extendPoint1);
    bounds.extend(extendPoint2);
    return bounds;
  };

  // generate clusters using current props
  createClusters = () => {
    // filters productSNs and attaches coordinates
    const validProductSNs = this.getValidPSNsWithCoords();

    // create marker data from remapped PSNs
    let markersData = _.map(validProductSNs, (psn, index) => ({
      type: "Feature",
      properties: {
        cluster: false,
        psn_id: psn.id,
        product_id: psn.product_id,
        psn_name: psn.product_sn_name,
      },
      geometry: {
        type: "Point",
        coordinates: [psn.coordinates.lng, psn.coordinates.lat],
      },
    }));

    // initialize cluster generator with marker data
    const superCluster = new Supercluster({
      radius: CLUSTER_RADIUS, // distance between icons before reveal. default 40 = too much label overlap
      maxZoom: 18, // default 16, max 22
    });
    superCluster.load(markersData);

    // see what's in view
    let { bounds, zoom } = this.state;
    if (!bounds) return null;

    // create clusters for the stuff in view
    let loadedClusters = superCluster.getClusters(bounds, zoom);

    // save to state
    this.setState({ clusters: loadedClusters, superCluster });
  };

  // save change to state then trigger cluster re-render
  handleMapChange = ({ center, zoom, bounds }) => {
    // set new map center
    this.props.setMapCenter(center);
    this.setState(
      {
        center,
        zoom,
        bounds: [bounds.nw.lng, bounds.se.lat, bounds.se.lng, bounds.nw.lat],
      },
      () => {
        this.createClusters();
      }
    );
  };

  // filter PSNs and attach coordinates
  getValidPSNsWithCoords = () => {
    const fullState = store.getState();
    const customerId = fullState.user.customerId;
    const { data: allProductSNs } =
      applicationUnitsApi.endpoints.getApplicationUnitsByFilter.select({
        customer_id: customerId,
      })(fullState);
    const { data: productSettings } =
      applicationsApi.endpoints.getProductSettingsForCustomer.select(
        customerId
      )(fullState);

    // shim for old code
    // get PSNs to place on map
    // 20220727 - removing filter; sidebar filter is currently broken
    const filteredPSNs = allProductSNs;

    // get product settings
    const noProductSettings = !Object.keys(productSettings).length;
    if (noProductSettings) return [];

    // get data history
    const { dataHistory } = this.props;

    // given a PSN, return a { lat, lng } of its latest position or null
    const getCoordinatesForPSN = (psn, dataHistory) => {
      // see if this psn has some data history
      const dataHistoryForPSN = dataHistory[psn.id];

      // if not, don't attach anything
      if (!dataHistoryForPSN || !(Object.keys(dataHistoryForPSN).length > 0))
        return null;

      // get lat and lng signal ids for this sn given its product
      const { lat_signal_id = null, lng_signal_id = null } =
        productSettings?.find(
          (productSettingsObj) =>
            productSettingsObj?.product_id === psn?.product_id
        ) || {};

      // gather lat and lng points for this psn
      // this latPoints and lngPoints subroutine is useful
      const latPoints = dataHistoryForPSN[lat_signal_id] || [];
      const lngPoints = dataHistoryForPSN[lng_signal_id] || [];

      // if there arent points, don't attach anything
      const noGPSPoints = !(latPoints.length && lngPoints.length);
      if (noGPSPoints) return null;

      // assume that if there are points and there are equal length, data should be correlated enough
      // todo: verify this assumption
      const latPoint = latPoints[latPoints.length - 1]?.data_value;
      const lngPoint = lngPoints[lngPoints.length - 1]?.data_value;

      // return these given points
      return {
        lat: latPoint,
        lng: lngPoint,
      };
    };

    // remap latest positions on all filtered PSNs
    const remappedPSNs = _.map(filteredPSNs, function (psn) {
      return {
        ...psn,
        coordinates: getCoordinatesForPSN(psn, dataHistory),
      };
    });

    // we only want the ones that have coordinates
    const validRemappedPSNs = remappedPSNs.filter((psn) => psn.coordinates);
    // return results
    return validRemappedPSNs;
  };

  // get lat and lng from chosen marker
  getPointFromActiveMarker(activeMarker) {
    const activePSN = this.getValidPSNsWithCoords().find(
      (psn) => psn.id === activeMarker
    );
    if (!activePSN || !activePSN.coordinates) return null;
    const { lat, lng } = activePSN.coordinates;
    return { lat, lng };
  }

  render() {
    const {
      activeMarker,
      mapZoom,
      setActiveMapMarker,
      theme,
      dataHistory,
      currentProductSNId,
    } = this.props;

    // default center if given
    const mapCenterFromProps = this.props.mapCenter;

    const fullState = store.getState();
    const customerId = fullState.user.customerId;
    const { data: productSNs } =
      applicationUnitsApi.endpoints.getApplicationUnitsByFilter.select({
        customer_id: customerId,
      })(fullState);
    const { data: productSettings } =
      applicationsApi.endpoints.getProductSettingsForCustomer.select(
        customerId
      )(fullState);

    // this map is still loading if the relevant PSNs havent loaded anything into dataHistory,
    const currentPSNIds = productSNs?.map((psn) => psn.id);
    const dataHistoryForRelevanPSNs = Object.keys(dataHistory).filter(
      (psn_id) => currentPSNIds?.includes(parseInt(psn_id))
    ).length;

    // or if we havent figured out which signal ids to check yet
    const productSettingsLoaded = !!Object.keys(productSettings).length;
    const isLoading = !(dataHistoryForRelevanPSNs && productSettingsLoaded);
    // we have no location data if we've loaded but no valid psns to render
    const noLocationData = !this.getValidPSNsWithCoords().length;
    // render it
    return (
      <>
        {/* {isLoading && (
          <OverlayWarning>
            <div style={{ marginBottom: "10px", textAlign: "center" }}>
              Loading location data
            </div>
            <LoadingSpinner height={6} width={160} color={theme.themePrimary} />
          </OverlayWarning>
        )} */}
        <div
          className="map-wrapper"
          style={{
            height: "100%",
            overflow: "hidden",
            position: "relative",
            filter: currentProductSNId
              ? "brightness(0.8)"
              : isLoading
              ? "brightness(1.3) contrast(0.9) saturate(0.5)"
              : "",
          }}
        >
          <div
            style={{
              alignContent: "center",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              cursor: "pointer",
              display: "flex",
              fontSize: "15px",
              justifyContent: "center",
              left: "50px",
              padding: "7px 10px",
              position: "absolute",
              top: "55px",
              zIndex: !this.props.overlayShown ? "1" : 0,
            }}
            onClick={() => this.props.setOverlayShown(true)}
          >
            <Icon
              width={18}
              height={18}
              style={{ marginTop: "2px", marginLeft: "1px" }}
              hostedImage={Defines.S3_SIDEBAR_LEFT_UNITS_LIST}
            />
            <span style={{ marginLeft: "5px" }}>Units</span>
          </div>
          <GoogleMap
            draggable={this.state.draggable}
            center={mapCenterFromProps}
            mapZoom={mapZoom}
            onGoogleApiLoaded={({ map, maps }) => {
              this.setMapRefs(map, maps);
            }}
            onChange={this.handleMapChange}
            onClick={(ev) => {
              setActiveMapMarker(null);
            }}
            unitsMapType={this.props.unitsMapType}
          >
            {this.state.clusters.map((item, i) => {
              let lat = item.geometry.coordinates[1];
              let lng = item.geometry.coordinates[0];

              if (item.properties.cluster) {
                let { cluster_id } = item.properties;
                return (
                  <MapMarker
                    key={item.id}
                    lat={lat}
                    lng={lng}
                    onClick={() => {
                      const expansionZoom = Math.min(
                        this.state.superCluster.getClusterExpansionZoom(
                          cluster_id
                        ),
                        20
                      );
                      this.googleMapRef.setZoom(expansionZoom);
                      this.googleMapRef.panTo({ lat, lng });
                    }}
                    style={{
                      padding: ".5em",
                      backgroundColor: hexToRgba(
                        this.props.theme.themePrimary,
                        0.65
                      ),
                      width: `${CLUSTER_RADIUS}px`,
                      height: `${CLUSTER_RADIUS}px`,
                      border: `2px solid ${hexToRgba(
                        this.props.theme.themePrimary,
                        0.3
                      )}`,
                      transform: "translate(-50%, -50%)",
                      borderRadius: "50%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      fontFamily: "Rubik",
                      fontWeight: "800",
                      fontSize: "20px",
                    }}
                  >
                    {item.properties.point_count}
                  </MapMarker>
                );
              }
              let { psn_id, psn_name, product_id } = item.properties;
              const { soc_signal_id, soh_signal_id } = productSettings.find(
                (productSettingsObj) =>
                  productSettingsObj.product_id === product_id
              );
              // DRY
              const unitSOCData = dataHistory[psn_id]
                ? dataHistory[psn_id][soc_signal_id]
                : null;
              const unitSOCVal = unitSOCData
                ? unitSOCData[unitSOCData.length - 1].data_value
                : null;
              // const unitSOHData = dataHistory[psn_id]
              //   ? dataHistory[psn_id][soh_signal_id]
              //   : null;
              // const unitSOHVal = unitSOHData
              //   ? unitSOHData[unitSOHData.length - 1].data_value
              //   : null;
              return (
                <MapMarker
                  key={`${i}`}
                  lat={lat}
                  lng={lng}
                  style={{ position: "relative" }}
                  // onClick={() => setActiveMapMarker(psn_id, { lat, lng })}
                  onClick={() => setActiveMapMarker(psn_id)}
                >
                  <MapMarkerIcon>
                    <Icon
                      style={{
                        filter: GetFilter(theme.themePrimary),
                        zIndex: "2",
                      }}
                      hostedImage={
                        activeMarker === psn_id
                          ? Defines.S3_MAP_PIN_UNIT_A_FOCUS
                          : Defines.S3_MAP_PIN_UNIT_A
                      }
                    />
                  </MapMarkerIcon>
                  <div
                    className="crosshairV"
                    style={{
                      pointerEvents: "none",
                      display: activeMarker === psn_id ? "block" : "none",
                      zIndex: "0",
                      position: "absolute",
                      border: "1px dashed rgba(200,200,200, 0.8)",
                      height: "200vh",
                      width: "1px",
                      transform: "translate(-1px, -50%)",
                    }}
                  >
                    &nbsp;
                  </div>
                  <div
                    className="crosshairH"
                    style={{
                      pointerEvents: "none",
                      display: activeMarker === psn_id ? "block" : "none",
                      zIndex: "0",
                      position: "absolute",
                      top: "0px",
                      border: "1px dashed rgba(127, 127, 127, 0.4)",
                      height: "1px",
                      width: "200vw",
                      transform: "translate(-50%, -1px)",
                    }}
                  >
                    &nbsp;
                  </div>

                  <MapMarkerLine style={{ pointerEvents: "none" }}>
                    <SVGLine
                      style={{
                        pointerEvents: "none",
                      }}
                      active={activeMarker === psn_id}
                      themePrimary={theme.themePrimary}
                    />
                  </MapMarkerLine>
                  <MapMarkerLabel
                    onClick={(e) => {
                      e.stopPropagation();
                      setActiveMapMarker(psn_id);
                    }}
                    active={activeMarker === psn_id}
                    themePrimary={theme.themePrimary}
                  >
                    <h4 style={{ fontSize: "1rem", marginBottom: "2px" }}>
                      {psn_name}
                    </h4>
                    <div style={{ width: "100px" }}>
                      <ProgressBar
                        width="30px"
                        soc={unitSOCVal || 100}
                        soh={100}
                        mapMode={true}
                      />
                    </div>
                  </MapMarkerLabel>
                </MapMarker>
              );
            })}
          </GoogleMap>
          <div
            style={{
              position: "absolute",
              bottom: "-20px",
              right: "25px",
              left: "25px",
            }}
          >
            <MapModal
              setDraggable={(draggable) => this.setState({ draggable })}
            />
          </div>
          {noLocationData && (
            <OverlayWarning>
              <p
                style={{
                  margin: "auto",
                  fontSize: "18px",
                  fontWeight: "bold",
                  margin: "0 20px",
                }}
              >
                No recent location data
              </p>
            </OverlayWarning>
          )}
        </div>
        <MapTypeButtons
          activeButton={this.props.unitsMapType}
          setUnitsMapType={this.props.setUnitsMapType}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  allProducts: state.products.products,
  productSNs: state.products.productSNs,
  productsData: state.products.productsData,
  activeMarker: state.map.marker,
  filteredProducts: state.products.filteredProducts,
  filteredStates: state.products.filteredStatusTypes,
  mapCenter: state.map.mapCenter,
  mapZoom: state.map.mapZoom,
  dataHistory: state.productSNData.dataHistory,
  productSettings: state.products.productSettings,
  currentProductSNId: state.products.product,
  overlayShown: state.map.overlayShown,
  unitsMapType: state.map.unitsMapType,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveMapMarker: (marker, coordinates) =>
    dispatch(setActiveMapMarker({ marker, coordinates })),
  setCurrentProduct: (product) => dispatch(setCurrentProduct(product)),
  setMapCenter: (coordinates) => dispatch(setMapCenter(coordinates)),
  setOverlayShown: (overlayShown) => dispatch(setOverlayShown(overlayShown)),
  setUnitsMapType: (newMapType) => dispatch(setUnitsMapType(newMapType)),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(MapContainer));
