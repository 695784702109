import * as API from "axios/axios";

export const productService = {
  index: (customerId) => API.get(`/api/products?customer_id=${customerId}`),
  uploadImage: (data) => API.post("/api/products/image", data),
  create: (params) => API.post("/api/products", params),
  update: (params) => API.put(`/api/products`, params),
  getSettingsForId: (product_id) =>
    API.post(`/api/products/settings`, { product_id }),
};

export const productLevelService = {
  index: () => API.get("/api/products/level", { params: {} }),
};
