// Application Devices Constants

// Routes
export const ADMIN_ROUTE_APPLICATION_UNITS = "applications-units";
export const ADMIN_ROUTE_APPLICATION_UNITS_VIEW = `${ADMIN_ROUTE_APPLICATION_UNITS}/view`;
export const ADMIN_ROUTE_APPLICATION_UNITS_FORM = `${ADMIN_ROUTE_APPLICATION_UNITS}/form`;

export const ADMIN_ROUTE_APPLICATION_DEVICES = "application-devices";
export const ADMIN_ROUTE_APPLICATION_DEVICES_VIEW = `${ADMIN_ROUTE_APPLICATION_DEVICES}/view`;
export const ADMIN_ROUTE_APPLICATION_DEVICES_FORM = `${ADMIN_ROUTE_APPLICATION_DEVICES}/form`;

export const ADMIN_ROUTE_APPLICATION_DEVICE_TYPES = "application-device-types";
export const ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW = `${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES}/view`;
export const ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_FORM = `${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES}/form`;

export const ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES =
  "application-device-instances";
export const ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES_VIEW = `${ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES}/view`;
export const ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES_FORM = `${ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES}/form`;
