import Plot from "react-plotly.js";
import styled from "styled-components";
import { withSize } from "react-sizeme";

import { useDeviceStatuses } from "features/units/hooks";
import * as Defines from "utils/defines";

const PieChartWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PieChartFigure = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PieChartLegend = styled.div`
  flex: 1;
`;

const LegendItem = styled.div`
  max-width: 150px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & span.count {
    font-weight: bold;
  }
`;

const DashboardStatusPieChartWidget = ({ size }) => {
  const deviceStatuses = useDeviceStatuses();
  const liveCount = deviceStatuses["live"].length;
  const recentCount = deviceStatuses["recent"].length;
  const awhileCount = deviceStatuses["awhile"].length;
  const unknownCount = deviceStatuses["unknown"].length;
  const dataColors = Defines.PIECHART_DATA_COLORS;
  const chartConfig = { ...Defines.DEFAULT_PIECHART_CONFIG };
  const pieWidth = 0.41 * size.width;
  const chartLayout = {
    ...Defines.DEFAULT_PIECHART_LAYOUT,
    width: pieWidth,
    height: pieWidth,
    annotations: [
      {
        ...Defines.PIECHART_ANNOTATION_FORMAT,
        font: {
          size: 0.1 * pieWidth,
        },
        text: "Status",
        x: 0.5,
        y: 0.5,
      },
    ],
  };
  const chartData = [
    {
      ...Defines.PIECHART_DATA_FORMAT,
      values: [liveCount, recentCount, awhileCount, unknownCount],
    },
  ];
  const legendItems = [
    {
      label: "Live",
      color: dataColors[0],
      count: liveCount,
    },
    {
      label: "Recent",
      color: dataColors[1],
      count: recentCount,
    },
    {
      label: "Awhile Ago",
      color: dataColors[2],
      count: awhileCount,
    },
    {
      label: "Unknown",
      color: dataColors[3],
      count: unknownCount,
    },
  ];
  return (
    <PieChartWrapper>
      <PieChartFigure>
        <Plot layout={chartLayout} config={chartConfig} data={chartData} />
      </PieChartFigure>
      <PieChartLegend>
        {legendItems.map((item) => (
          <LegendItem key={item.label}>
            <span>{item.label}</span>
            <span className="count" style={{ color: item.color }}>
              {item.count}
            </span>
          </LegendItem>
        ))}
      </PieChartLegend>
    </PieChartWrapper>
  );
};

export const DashboardStatusPieChart = withSize()(
  DashboardStatusPieChartWidget
);
