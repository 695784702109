import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import moment from "moment-timezone";
import { Link } from "react-router-dom";
import { Col, Input, Row, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import {
  ADMIN_ROUTE_CUSTOMERS,
  ADMIN_ROUTE_CUSTOMERS_VIEW,
} from "features/customers/utils/Constants";
import { ADMIN_ROUTE_APPLICATION_UNITS_VIEW } from "features/application-devices/utils/Constants";
import { ADMIN_ROUTE_TCU_VIEW } from "features/tcu/utils/Constants";

export const CustomersOverviewTable = ({
  qCustomers,
  qCustomerUsers,
  qApplications,
  isPageLoaded,
}) => {
  const [customers, setCustomers] = useState([]);
  const [filteredCustomers, setFilteredCustomers] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedCustomers = qCustomers?.map((obj, index) => {
        const customerUsers = qCustomerUsers?.filter(
          (item) => item.customer_id === obj.id
        );
        const customerApplications = qApplications?.filter(
          (item) => item.customer_id === obj.id
        );
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          num_of_customer_users: customerUsers?.length || 0,
          num_of_customer_apps: customerApplications?.length || 0,
        };
      });
      setCustomers(updatedCustomers);
      setFilteredCustomers(updatedCustomers);
    }
  }, [qCustomers, qCustomerUsers, isPageLoaded]);

  if (!isPageLoaded) return <Spinner />;
  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Number of Users",
      dataIndex: "num_of_customer_users",
      key: "num_of_customer_users",
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.num_of_customer_users - b.num_of_customer_users,
    },
    {
      title: "Number of Applications",
      dataIndex: "num_of_customer_apps",
      key: "num_of_customer_apps",
      sorter: (a, b) => a.num_of_customer_apps - b.num_of_customer_apps,
    },
    {
      title: "Created On",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      title: "Fleet Plan",
      dataIndex: "fleet_plan",
      key: "fleet_plan",
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              //   handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table columns={columns} dataSource={filteredCustomers} />
        </Col>
      </Row>
    </>
  );
};
