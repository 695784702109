import { Col, Input, Row, Table } from "antd";
import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";

export const CustomersViewCustomersApplicationsTable = ({
  qCustomersApplications,
  isPageLoaded,
}) => {
  const [customersApplications, setCustomersApplications] = useState([]);
  const [filteredCustomersApplications, setFilteredCustomersApplications] =
    useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedCustomersApplications = qCustomersApplications?.map(
        (obj, index) => {
          return {
            ...obj,
            key: obj.id,
            index: index + 1,
          };
        }
      );
      setCustomersApplications(updatedCustomersApplications);
      setFilteredCustomersApplications(updatedCustomersApplications);
    }
  }, [qCustomersApplications, isPageLoaded]);

  if (!isPageLoaded) return <Spinner />;

  const customersApplicationsColumns = [
    {
      title: "Application Name",
      dataIndex: "product_name",
      key: "product_name",
      render: (text, record) => {
        return (
          <Link to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.id}`}>
            {text}
          </Link>
        );
      },
    },
    {
      title: "Number of App Units",
      dataIndex: "num_of_app_units",
      key: "num_of_app_units",
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              //   handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            columns={customersApplicationsColumns}
            dataSource={filteredCustomersApplications}
          />
        </Col>
      </Row>
    </>
  );
};
