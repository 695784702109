import { useState, useContext, createContext } from "react";
import { useHistory } from "react-router-dom";
import {
  Elements,
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { customerService } from "../services";
import {
  Modal,
  ModalBody,
  ModalFieldWrapper,
  ModalLabel,
  ModalField,
} from "common/Modal";
import { Button } from "common/elements";
import LoadingSpinner from "common/LoadingSpinner";
import { STRIPE_PUBLISHABLE_KEY } from "config";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const CompanyCreationContext = createContext();

const CompanyCreationStateProvider = ({ children }) => {
  const [companyName, setCompanyName] = useState("");
  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(1);

  const value = {
    companyName,
    setCompanyName,
    error,
    setError,
    isLoading,
    setIsLoading,
    step,
    setStep,
    customer,
    setCustomer,
  };
  return (
    <CompanyCreationContext.Provider value={value}>
      {children}
    </CompanyCreationContext.Provider>
  );
};

const CompanyDetailsForm = () => {
  const {
    companyName,
    setCompanyName,
    error,
    setError,
    isLoading,
    setIsLoading,
    setStep,
    setCustomer,
  } = useContext(CompanyCreationContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);

    try {
      const customer = await customerService.Create({ name: companyName });
      await customerService.AddCurrentUserToCustomer(customer.id);
      setCustomer(customer);
      setStep(2);
    } catch (err) {
      setError(err.message || err.error);
    }
    setIsLoading(false);
  };
  return (
    <div>
      <h2>Create your company</h2>
      <form onSubmit={handleSubmit}>
        {error && <p style={{ color: "red" }}>{error}</p>}
        {isLoading ? (
          "loading"
        ) : (
          <div className="form-group">
            <ModalFieldWrapper>
              <ModalLabel>Company Name</ModalLabel>
              <ModalField>
                <input
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </ModalField>
            </ModalFieldWrapper>
          </div>
        )}
        <Button className="accept" type="submit">
          Save
        </Button>
      </form>
    </div>
  );
};

const SelectPlanForm = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const history = useHistory();

  const stripe = useStripe();
  const elements = useElements();

  const { customer } = useContext(CompanyCreationContext);

  const customerId = customer ? customer.id : null;

  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe) return;
    if (!customerId) return;

    setLoading(true);

    const { error: submitError } = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }

    // dont create the subscription until we've verified and activated them
    // just save the plan selection
    await customerService.Update(customerId, { fleet_plan: selectedPlan });

    // instead of confirmPayment, only create a setup intent.
    // do not confirmPayment until we're ready to make the subscription active
    const intentResult = await customerService.CreateIntent(customerId);
    const { client_secret: clientSecret } = intentResult;
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `${window.location.origin}/account`, // need to figure out where this goes for a redirected payment
      },
      redirect: "if_required",
    });

    if (error) {
      handleError(error);
      return;
    }

    const { payment_method } = setupIntent;
    const setupRes = await customerService.SetPaymentMethodAsDefault(
      customerId,
      { payment_method }
    );
    history.go(0);
  };
  return (
    <div>
      <h2>Select a plan</h2>
      {errorMessage && <div>{errorMessage}</div>}
      <form onSubmit={handleSubmit}>
        <fieldset
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0 30px",
          }}
        >
          <div>
            <label style={{ textAlign: "center" }} htmlFor="basic">
              <strong>Basic</strong>
              <br />
              ($50/mo)
            </label>
            <input
              style={{ display: "inline" }}
              type="radio"
              checked={selectedPlan === "basic"}
              onChange={(e) => setSelectedPlan(e.target.value)}
              id="basic"
              name="plan"
              value="basic"
            />
          </div>
          <div>
            <label style={{ textAlign: "center" }} htmlFor="professional">
              <strong>Professional</strong>
              <br />
              ($100/mo)
            </label>
            <input
              style={{ display: "inline" }}
              type="radio"
              checked={selectedPlan === "professional"}
              onChange={(e) => setSelectedPlan(e.target.value)}
              id="professional"
              name="plan"
              value="professional"
            />
          </div>
          <div>
            <label style={{ textAlign: "center" }} htmlFor="enterprise">
              <strong>Enterprise</strong>
              <br />
              ($200/mo)
            </label>
            <input
              style={{ display: "inline" }}
              type="radio"
              checked={selectedPlan === "enterprise"}
              onChange={(e) => setSelectedPlan(e.target.value)}
              id="enterprise"
              name="plan"
              value="enterprise"
            />
          </div>
        </fieldset>
        {selectedPlan && (
          <div style={{ padding: "20px 0" }}>
            <PaymentElement />
            <p>
              <small>
                Your card will not be charged until after the free trial ends,
                and your free trial will not begin until your company is
                verified.
              </small>
            </p>
          </div>
        )}
        {loading && <LoadingSpinner />}
        <Button type="submit" disabled={!stripe || !selectedPlan || loading}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export const CreateCompanyModal = () => {
  const { step, isLoading } = useContext(CompanyCreationContext);
  //console.log("CreateCompanyModal -- Step: ", step);
  const renderStep = (step) => {
    switch (step) {
      case 2:
        return <SelectPlanForm />;
      case 1:
      default:
        return <CompanyDetailsForm />;
    }
  };

  return (
    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <ModalBody>{isLoading ? <LoadingSpinner /> : renderStep(step)}</ModalBody>
    </Modal>
  );
};

export default function CreateCompanyModalWithContext() {
  const stripeOptions = {
    mode: "setup",
    currency: "usd",
    paymentMethodTypes: ["card"],
  };

  return (
    <CompanyCreationStateProvider>
      <Elements stripe={stripePromise} options={stripeOptions}>
        <CreateCompanyModal />
      </Elements>
    </CompanyCreationStateProvider>
  );
}
