import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseApplicationDeviceQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const applicationDevicesApi = createApi({
  reducerPath: "applicationDevicesApi",
  baseQuery: baseApplicationDeviceQuery,
  tagTypes: ["applicationDevices"],
  endpoints: (builder) => ({
    getAllDeviceTypes: builder.query({
      query: () => `/device_types`,
      providesTags: ["Device Types"],
    }),
    addDeviceType: builder.mutation({
      query: ({ name, creator_id, stafl_device_type_code }) => ({
        url: "/device_types",
        method: "POST",
        body: { name, creator_id, stafl_device_type_code },
      }),
      invalidatesTags: ["Device Types"],
    }),
    getDeviceType: builder.query({
      query: (deviceTypeId) => `/device_types/${deviceTypeId}`,
      providesTags: ["Device Types"],
    }),
    updateDeviceType: builder.mutation({
      query: (deviceTypeData) => ({
        url: `/device_types/${deviceTypeData.id}`,
        method: "PUT",
        body: { ...deviceTypeData },
      }),
      invalidatesTags: ["Device Types"],
    }),
    deleteDeviceType: builder.mutation({
      query: (deviceTypeId) => ({
        url: `/device_types/${deviceTypeId}`,
        method: "DELETE",
        body: deviceTypeId,
      }),
      invalidatesTags: ["Device Types"],
    }),
    getAllApplicationDevices: builder.query({
      query: () => `/application_devices`,
      providesTags: ["Application Devices"],
    }),
    getApplicationDevice: builder.query({
      query: (applicationDeviceId) =>
        `/application_devices/${applicationDeviceId}`,
      providesTags: ["Application Devices"],
    }),
    addApplicationDevice: builder.mutation({
      query: (applicationDeviceData) => ({
        url: "/application_devices",
        method: "POST",
        body: applicationDeviceData,
      }),
      invalidatesTags: ["Application Devices"],
    }),
    updateApplicationDevice: builder.mutation({
      query: (applicationDevice) => ({
        url: `/application_devices/${applicationDevice.device_type_id}`,
        method: "PUT",
        body: { ...applicationDevice },
      }),
      invalidatesTags: ["Application Devices"],
    }),
    deleteApplicationDevice: builder.mutation({
      query: (applicationDeviceId) => ({
        url: `/application_devices/${applicationDeviceId}`,
        method: "DELETE",
        body: applicationDeviceId,
      }),
      invalidatesTags: ["Application Devices"],
    }),
    getDeviceInfoForSN: builder.query({
      query: (product_sn_id) => `/product_sns/${product_sn_id}/device-info`,
    }),
  }),
});

export const {
  useGetAllDeviceTypesQuery,
  useAddDeviceTypeMutation,
  useGetDeviceTypeQuery,
  useUpdateDeviceTypeMutation,
  useDeleteDeviceTypeMutation,
  useGetAllApplicationDevicesQuery,
  useGetApplicationDeviceQuery,
  useAddApplicationDeviceMutation,
  useUpdateApplicationDeviceMutation,
  useDeleteApplicationDeviceMutation,
  useGetDeviceInfoForSNQuery,
} = applicationDevicesApi;
