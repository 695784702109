import { createSlice } from "@reduxjs/toolkit";
import * as Defines from "utils/defines";

const initialState = {
  marker: null,
  mapCenter: Defines.DEFAULT_MAP_CENTER,
  mapZoom: 12,
  overlayShown: false,
  overlayVertical: true,
  unitsMapType: Defines.MAP_TYPE_ROADMAP,
};

const _areValidCoords = (coords) => {
  const result =
    coords && coords.lat && coords.lng && coords.lat !== 0 && coords.lng !== 0;
  return result;
};

export const mapSlice = createSlice({
  name: "map",
  initialState,
  reducers: {
    setActiveMapMarker: (state, action) => {
      const { marker, coordinates } = action.payload ? action.payload : {};
      state.marker = marker;
      state.mapCenter = _areValidCoords(coordinates)
        ? {
            lat: parseFloat(coordinates.lat),
            lng: parseFloat(coordinates.lng),
          }
        : state.mapCenter;
    },
    setMapCenter: (state, action) => {
      state.mapCenter = action.payload;
    },
    setOverlayVertical: (state, action) => {
      state.overlayVertical = action.payload;
    },
    setOverlayShown: (state, action) => {
      state.overlayShown = action.payload;
    },
    setUnitsMapType: (state, action) => {
      state.unitsMapType = action.payload;
    },
  },
});

export const {
  setActiveMapMarker,
  setMapCenter,
  setOverlayVertical,
  setOverlayShown,
  setUnitsMapType,
} = mapSlice.actions;

export default mapSlice.reducer;
