import styled from "styled-components";
import { useGetCustomersQuery } from "features/customers/redux";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";
import {
  ApplicationsOverviewTable,
  ApplicationsFormModal,
} from "../components";
import { Button, Card, Form } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

const ApplicationsOverviewManagementContainer = styled.div`
  width: 100%;
`;

export const ApplicationsOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  /**
   * Fetching Applications, Customers
   */
  const {
    isLoading: isQApplicationsLoading,
    data: qApplications,
    refetch: qApplicationsRefetch,
  } = useGetApplicationsByFilterQuery();

  const { isLoading: isQCustomersLoading, data: qCustomers } =
    useGetCustomersQuery();

  /**
   * Update Is Page Loaded when Fetching is complete
   */
  useEffect(() => {
    setIsPageLoaded(!isQApplicationsLoading && !isQCustomersLoading);
  }, [isQApplicationsLoading, isQCustomersLoading]);

  return (
    <ApplicationsOverviewManagementContainer>
      <Card
        title={
          <span style={{ fontWeight: "bold" }}>{"List of Applications"}</span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={debounce(async () => {
              setIsModalOpen(true);
            }, 100)}
          >
            New Application
          </Button>
        }
      >
        <ApplicationsOverviewTable
          qApplications={qApplications}
          isPageLoaded={isPageLoaded}
        />
      </Card>
      <ApplicationsFormModal
        form={form}
        title={"New Application Form Modal"}
        refetch={qApplicationsRefetch}
        qCustomers={qCustomers}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewForm={true}
      />
    </ApplicationsOverviewManagementContainer>
  );
};
