import { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { UploadImageModal } from "common";
import { setUserObject } from "../redux";
import { Icon } from "common";
import { notify } from "features/notifications";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { userService } from "features/users/services";
import { uploadS3 } from "utils/uploadS3";
import { ICON_SIZE_MD, S3_CLOSE_X } from "utils/defines";
import { Button } from "common/elements";
import { AuthContext } from "features/auth/contexts";

/* eslint-disable jsx-a11y/anchor-is-valid */

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  padding: 30px;

  // use company color in forms
  form input:focus {
    border-color: ${(props) => props.theme.themeSecondary};
  }
`;

const UserProfileComponent = ({
  hide,
  user,
  email,
  name,
  customerId,
  setUser,
  customers,
}) => {
  const [inputName, setInputName] = useState(name || "");
  const [inputEmail, setInputEmail] = useState(email || "");
  const [viewableImage, setViewableImage] = useState(user.image);
  const { isSuperAdmin } = useContext(AuthContext);
  // const [password, setPassword] = useState("");
  // const [confirmPassword, setConfirmPassword] = useState("");

  const emptyForm = () => ({
    name: "",
    email: "",
    password: "",
    phone: "",
    confirm_password: "",
  });
  const [form, setState] = useState({
    ...emptyForm(),
    ...user,
  });

  const [editImage, setEditImage] = useState(false);
  useEffect(() => {
    if (name) {
      setInputName(name);
    }
    if (email) {
      setInputEmail(email || "");
    }
    if (user.image) {
      setViewableImage(user.image);
    }
  }, [email, name, user]);

  const changesMade = () => JSON.stringify(form) !== JSON.stringify(user);

  const changes = () => {
    let userChanges = {};
    if (email !== inputEmail.trim()) {
      userChanges["email"] = inputEmail.trim();
    }
    if (name !== inputName.trim()) {
      userChanges["name"] = inputName.trim();
    }
    if (passwordChanged()) {
      userChanges["password"] = form.password;
    }

    userChanges["phone"] = form.phone;
    // console.log(userChanges);

    return userChanges;
  };

  const update = (e) => {
    e.preventDefault();
    const userChanges = changes();
    if (!Object.keys(userChanges).length) {
      return;
    }

    userService
      .update({ user: userChanges })
      .then((data) => {
        setUser(data.user);
        hide();
        return data.message;
      })
      .then((message) => {
        // console.log(message);
        notify(message);
      });
  };

  const onUpload = async (imageFile) => {
    try {
      let {
        user,
        signedRequestUrl,
        assetUrl: imageUrl,
      } = await userService.uploadImage({
        fileName: imageFile.name,
        fileType: imageFile.type,
        customerId,
      });
      await uploadS3(imageFile, signedRequestUrl);
      setUser(user);
      setViewableImage(imageUrl);
    } catch (err) {
      console.error(err);
    }
  };

  const updateField = (e) =>
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });

  const passwordAttempted = () =>
    form.password !== "" && form.confirm_password !== "";

  const passwordValid = () => form.password === form.confirm_password;

  const passwordChanged = () => passwordAttempted() && passwordValid();

  return (
    <Wrapper>
      <a onClick={hide} style={{ marginLeft: "auto" }}>
        <Icon
          width={ICON_SIZE_MD}
          height={ICON_SIZE_MD}
          hostedImage={S3_CLOSE_X}
        />
      </a>

      <UploadImageModal
        show={editImage}
        hide={() => setEditImage(false)}
        onUpload={onUpload}
        _viewableImage={viewableImage}
      />
      <form style={{ display: "flex" }}>
        <div>
          <button
            style={{ border: "none" }}
            onClick={(e) => {
              e.preventDefault();
              setEditImage(!editImage);
            }}
          >
            <Icon
              style={{ width: "80px", height: "80px" }}
              hostedImage={viewableImage}
              filename={"default-user.jpg"}
            />
          </button>
        </div>

        <div style={{ padding: "20px", width: "100%" }}>
          <h1>User Profile</h1>
          {isSuperAdmin && <h3>General Settings</h3>}
          <div>
            <label>Username</label>
            <div>
              <input
                value={inputName}
                onChange={(e) => setInputName(e.target.value)}
                disabled={!isSuperAdmin}
              />
            </div>
          </div>

          <div>
            <label>Email address</label>
            <div>
              <input
                value={inputEmail}
                onChange={(e) => setInputEmail(e.target.value)}
                disabled={!isSuperAdmin}
              />
            </div>
          </div>
          {isSuperAdmin && (
            <>
              <div>
                <label>Phone Number</label>
                <div>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={form.phone}
                    onChange={(value) =>
                      updateField({ target: { name: "phone", value } })
                    }
                    disabled={!isSuperAdmin}
                  />
                </div>
              </div>
              <h3>Change Password</h3>
              <div style={{ display: "flex" }}>
                <div style={{ flex: 1, marginRight: "30px" }}>
                  <label>New password</label>
                  <div>
                    <input
                      name="password"
                      type="password"
                      value={form.password}
                      onChange={updateField}
                      disabled={!isSuperAdmin}
                    />
                  </div>
                </div>
                <div style={{ flex: 1 }}>
                  <label>Confirm new password</label>{" "}
                  {passwordAttempted() && !passwordValid() && <span>Bad</span>}
                  {passwordAttempted() && passwordValid() && <span>Good</span>}
                  <div>
                    <input
                      name="confirm_password"
                      type="password"
                      value={form.confirm_password}
                      onChange={updateField}
                    />
                  </div>
                </div>
              </div>
              <Button
                onClick={update}
                disabled={!changesMade()}
                style={{ marginTop: "20px", float: "right" }}
              >
                Save Settings
              </Button>
            </>
          )}
        </div>
      </form>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.db_user,
  email: state.user.email,
  name: state.user.name,
  customerId: state.user.customerId,
  customers: state.user.customers,
});

const mapDispatchToProps = (dispatch) => ({
  setUser: (user) => dispatch(setUserObject(user)),
});

export const UserProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileComponent);
export default UserProfile;
