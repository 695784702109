import SettingsField from "./SettingsField";
// import _ from "lodash";

export const LineRedux = ({ changeSettings, getField }) => {
  return (
    <div>
      {/* DEPRECATED - seems useless
      <SettingsField label="Text">
        <input
          value={getField("text", "")}
          onChange={(e) => changeSettings("text", e.target.value)}
        />
      </SettingsField>
      */}
      <SettingsField label="Basic Mode?">
        <input
          type="checkbox"
          checked={getField("basic", "") ? true : false}
          onChange={(e) => changeSettings("basic", e.target.checked ? 1 : 0)}
        />
      </SettingsField>
      {getField("basic", "") && (
        <>
          <SettingsField label="Relative Interval">
            <select
              value={getField("relativeInterval", "")}
              onChange={(e) =>
                changeSettings("relativeInterval", e.target.value)
              }
            >
              <option value=".5">Last 30 seconds</option>
              <option value="1">Last 1 minutes</option>
              <option value="5">Last 5 minutes</option>
              <option value="15">Last 15 minutes</option>
              <option value="30">Last 30 minutes</option>
              <option value="60">Last 60 minutes</option>
              <option value="1440">Last day</option>
            </select>
          </SettingsField>
          <SettingsField label="Auto Refresh">
            <input
              type="checkbox"
              checked={getField("autoRefresh", "") ? true : false}
              onChange={(e) =>
                changeSettings("autoRefresh", e.target.checked ? 1 : 0)
              }
            />
          </SettingsField>
        </>
      )}
      <SettingsField label="Graph Title">
        <input
          value={getField("graphTitle", "")}
          onChange={(e) => changeSettings("graphTitle", e.target.value)}
        />
      </SettingsField>
      {/* DEPRECATED - using dynamic poll rate now
      <SettingsField label="Refresh Rate">
        <select
          type="checkbox"
          value={getField("refresh_rate", "")}
          onChange={(e) => changeSettings("refresh_rate", e.target.value)}
        >
          <option value="" disabled>
            Select a rate...
          </option>
          <option value="1000">1 second</option>
          <option value="5000">5 seconds</option>
          <option value="15000">15 seconds</option>
          <option value="30000">30 seconds</option>
          <option value="60000">1 minute</option>
        </select>
      </SettingsField>
      */}
    </div>
  );
};
