import styled from "styled-components";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";

/** https://fkhadra.github.io/react-toastify/how-to-style/#how-to-style-with-styled-components` */
export const NotificationContainer = styled(ToastContainer)`
  // https://styled-components.com/docs/faqs#how-can-i-override-styles-with-higher-specificity
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
  }
  .Toastify__progress-bar {
    background: ${(props) => props.theme.themePrimary};
  }
`;
