import _ from "lodash";
import { useState, useContext, useEffect } from "react";
import { AuthExtendSessionModal } from "features/auth/components/AuthExtendSessionModal";
import { AuthContext } from "features/auth/contexts";
import { connect } from "react-redux";
import jwt_decode from "jwt-decode";
import storage from "utils/storage";
import { useInterval } from "utils";
import {
  CHECK_AUTH_EXPIRE_INTERVAL,
  CHECK_AUTH_SESSION_MODAL_TOGGLE_LIMIT,
  INACTIVITY_CHECK_INTERVAL,
  INACTIVITY_THRESHOLD,
} from "utils/defines";

type Props = {
  children: React.ReactNode;
  user: any;
};

const SessionManagerComponent = ({ children, user }: Props) => {
  const authContext = useContext(AuthContext);
  const [timeRemaining, setTimeRemaining] = useState("");
  const [isCloseDisabled, setIsCloseDisabled] = useState(false);
  const [isContinueDisabled, setIsContinueDisabled] = useState(false);
  const [extendSessionModal, setExtendSessionModal] = useState(false);
  const [lastActiveTime, setLastActiveTime] = useState(Date.now());
  const accessToken: any = storage.getAccessToken();
  const accessAuthInfo: any = jwt_decode(accessToken);
  const accessExpDiff = accessAuthInfo?.exp - Date.now() / 1000; // in seconds

  // attach eventListeners to window to check if user is active
  useEffect(() => {
    const handleThrottledEvent = _.throttle(() => {
      // < 15 minutes (in seconds)
      if (accessExpDiff < 60 * 15) setLastActiveTime(Date.now());
    }, INACTIVITY_CHECK_INTERVAL);

    window.addEventListener("click", handleThrottledEvent);
    window.addEventListener("keydown", handleThrottledEvent);

    return () => {
      window.removeEventListener("click", handleThrottledEvent);
      window.removeEventListener("keydown", handleThrottledEvent);
    };
  }, [accessExpDiff]);

  const isUserActive = Date.now() - lastActiveTime < INACTIVITY_THRESHOLD;

  const extendSessionWithRefreshToken = async () => {
    const refreshToken: any = storage.getRefreshToken();
    const { idToken, accessToken, expiresIn } = await refreshAccess(
      user.auth_user_id,
      refreshToken
    );
    await storeSession({ accessToken, idToken, refreshToken, expiresIn });
  };

  useInterval(async () => {
    if (user) {
      // minutes + seconds remaining
      const accessExpDiffMinute = Math.floor(accessExpDiff / 60).toFixed(0);
      const accessExpDiffSeconds = (accessExpDiff % 60)
        .toFixed(0)
        .padStart(2, "0"); // pad single digit (seconds) to format as 01..09

      // update time remaining display
      setTimeRemaining(`${accessExpDiffMinute}:${accessExpDiffSeconds}s`);
      if (
        accessExpDiff >= 0 &&
        accessExpDiff < CHECK_AUTH_SESSION_MODAL_TOGGLE_LIMIT &&
        !isUserActive
      ) {
        // auto logout timer has reached first limit, toggle extension modal
        setExtendSessionModal(true);
      } else if (
        accessExpDiff >= 0 &&
        accessExpDiff < CHECK_AUTH_SESSION_MODAL_TOGGLE_LIMIT &&
        isUserActive &&
        !extendSessionModal
      ) {
        extendSessionWithRefreshToken();
      } else if (accessExpDiff < 0) {
        // auto logout after timer expiration
        await authContext?.signOut();
      }
    }
  }, CHECK_AUTH_EXPIRE_INTERVAL);

  if (!authContext) return <>{children}</>;

  const { refreshAccess, storeSession } = authContext;

  // on Modal Continue
  const onContinueClick = async () => {
    // disable buttons
    setIsCloseDisabled(true);
    setIsContinueDisabled(true);

    // using refresh token, extend acccess token
    const refreshToken: any = storage.getRefreshToken();
    const { idToken, accessToken, expiresIn } = await refreshAccess(
      user.auth_user_id,
      refreshToken
    );
    await storeSession({ accessToken, idToken, refreshToken, expiresIn });

    // get new timer value end (DEBUG PURPOSES)
    const accessAuthInfo: any = jwt_decode(accessToken);
    const accessExpDiff = (accessAuthInfo?.exp - Date.now() / 1000) / 60; // in minutes

    console.log(`Session Extended: ${accessExpDiff.toFixed(1)} minutes`);

    setIsCloseDisabled(false);
    setIsContinueDisabled(false);
    setExtendSessionModal(false);
  };

  return (
    <>
      <AuthExtendSessionModal
        timeRemaining={timeRemaining}
        isShow={extendSessionModal}
        hide={() => null}
        isCloseDisabled={isCloseDisabled}
        isContinueDisabled={isContinueDisabled}
        onContinueClick={onContinueClick}
      />
      {!extendSessionModal && children}
    </>
  );
};

const mapStateToProps = (state: Props) => ({
  user: state.user.auth_user,
});

export const SessionManager = connect(
  mapStateToProps,
  null
)(SessionManagerComponent);
