import Axios from "axios";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

function authRequestInterceptor(config) {
  const token = storage.getAccessToken();
  if (token) {
    config.headers.authorization = `Bearer ${token}`;
  }
  config.headers.Accept = "application/json";
  return config;
}

export const axios = Axios.create({
  baseURL: BASE_API_URL,
});

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const message = error.response?.data?.message || error.message;
    console.error(message);
    if (error?.response?.status === 401) {
      storage.clearAllSessionTokens();
      window.location = "/";
    }
    return Promise.reject(error);
  }
);

axios.interceptors.request.use(authRequestInterceptor);

const { get, post, put, delete: destroy } = axios;
export { get, post, put, destroy };
