import { useEffect, useState } from "react";
import { Col, Row, Table, Space, Button, Form, Breadcrumb, Card } from "antd";
import { useParams, Link } from "react-router-dom";
import {
  useGetApplicationDeviceQuery,
  useGetDeviceTypeQuery,
} from "features/application-devices/redux";
import { useGetCustomersQuery } from "features/customers/redux";
import { useGetApplicationByIDQuery } from "features/applications/redux";

import { ApplicationDeviceViewDetailsTable } from "../components";
import {
  ADMIN_ROUTE_APPLICATIONS_VIEW,
  ADMIN_ROUTE_APPLICATION_DEVICES,
  ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW,
} from "features/application-devices/utils/Constants";
import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";
import { Spinner } from "common/Spinner";

export const ApplicationDevicesView = () => {
  const { id: applicationDeviceID } = useParams();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  /**
   * Fetching Application Device & related information
   */
  const {
    data: qApplicationDevicePair,
    isLoading: isQApplicationDevicePairLoading,
  } = useGetApplicationDeviceQuery(applicationDeviceID);

  const { data: qCustomer, isLoading: isQCustomerLoading } =
    useGetCustomersQuery({
      id: qApplicationDevicePair?.customer_id,
      skip: !qApplicationDevicePair,
    });

  const { data: qApplication, isLoading: isQApplicationLoading } =
    useGetApplicationByIDQuery(qApplicationDevicePair?.product_id, {
      skip: !qApplicationDevicePair,
    });

  const {
    data: qApplicationDeviceType,
    isLoading: isQApplicationDeviceTypeLoading,
  } = useGetDeviceTypeQuery(qApplicationDevicePair?.device_type_id, {
    skip: !qApplicationDevicePair,
  });

  useEffect(() => {
    setIsPageLoaded(
      !isQApplicationDevicePairLoading &&
        !isQCustomerLoading &&
        !isQApplicationLoading &&
        !isQApplicationDeviceTypeLoading
    );
  }, [
    isPageLoaded,
    isQApplicationDevicePairLoading,
    isQCustomerLoading,
    isQApplicationLoading,
    isQApplicationDeviceTypeLoading,
  ]);

  if (!isPageLoaded) return <Spinner />;

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "Application Units Overview",
      href: `/admin/${ADMIN_ROUTE_APPLICATION_DEVICES}`,
    },
    {
      title: `${qApplication.product_name} --- ${qApplicationDeviceType.name}`,
    },
  ];
  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : (
      <Link to={`${currentRoute.href}`}>{currentRoute.title}</Link>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      <Card
        title={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {`${qApplication.product_name} --- ${qApplicationDeviceType.name} Details`}
          </span>
        }
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        bordered={true}
      >
        <ApplicationDeviceViewDetailsTable
          qApplicationDevicePair={qApplicationDevicePair}
          qApplication={qApplication}
          qApplicationDeviceType={qApplicationDeviceType}
          qCustomer={qCustomer}
          isPageLoaded={isPageLoaded}
        />
      </Card>
    </>
  );
};
