import React from "react";
import styled from "styled-components";
import { Dropdown, Menu } from "antd";
import { DownOutlined } from "@ant-design/icons";

const MenuItem = styled.div`
  cursor: pointer;
  padding: 6px;
  &:hover {
    background-color: #f0f0f0;
  }
`;

export const RelativeTimeSelectionList = styled.ul`
  list-style: none;
  margin: 0 20px;
  padding: 0;
  font-size: 14px;
  width: 150px;
  margin-left: 70%;
  position: absolute;
  top: 25px;
`;

export const RelativeTimeSelectionItem = styled.li`
  padding: 2px 5px;
  &:hover {
    background-color: #ccc;
  }
`;

const StyledMenu = styled(Menu)`
  .ant-dropdown-menu-item {
    width: 100%;
  }
`;

const RelativeTimeSelector = ({
  handleDurationSelection,
  handleFetchAndDisplayStartAndEndDate,
}) => {
  const relativeDurationsList = [
    {
      key: 5,
      timeText: "Last 2.5 Minutes",
    },
    {
      key: 10,
      timeText: "Last 5 Minutes",
    },
    {
      key: 30,
      timeText: "Last 15 Minutes",
    },
    {
      key: 60,
      timeText: "Last 30 Minutes",
    },
    {
      key: 180,
      timeText: "Last 1.5 Hours",
    },
    {
      key: 360,
      timeText: "Last 3 Hours",
    },
    {
      key: 720,
      timeText: "Last 6 Hours",
    },
    {
      key: 1440,
      timeText: "Last 12 Hours",
    },
    {
      key: 1440 * 2,
      timeText: "Last Day",
    },
    {
      key: 1440 * 7,
      timeText: "Last 3.5 Days",
    },
    {
      key: 1440 * 30,
      timeText: "Last 15 Days",
    },
    {
      key: 1440 * 90,
      timeText: "Last 45 Days",
    },
    {
      key: 1440 * 365,
      timeText: "Last .5 Year",
    },
    // {
    //   key: 1440 * 365 * 2,
    //   timeText: "Last Year",
    // },
  ];

  const menuItems = (
    <StyledMenu>
      {relativeDurationsList.map((relativeDuration) => (
        <MenuItem
          key={relativeDuration.key}
          style={{ cursor: "pointer", padding: "6px 8px" }}
          onClick={() =>
            handleDurationSelection(
              relativeDuration.key,
              handleFetchAndDisplayStartAndEndDate(
                true,
                relativeDuration.timeText
              )
            )
          }
        >
          {relativeDuration.timeText}
        </MenuItem>
      ))}
    </StyledMenu>
  );

  return (
    <RelativeTimeSelectionList>
      <Dropdown
        items={menuItems}
        overlayStyle={{ width: "30px" }}
        dropdownRender={(menu) => menuItems}
      >
        <p style={{ cursor: "pointer" }}>
          Increments <DownOutlined />
        </p>
      </Dropdown>
    </RelativeTimeSelectionList>
  );
};

export default RelativeTimeSelector;
