import { Table } from "antd";
import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_TCU_VIEW } from "features/tcu/utils/Constants";

export const ApplicationUnitsViewDetailsTable = ({
  qApplicationUnit,
  qTCU,
  isPageLoaded,
}) => {
  const [applicationUnit, setApplicationUnit] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplicationUnitData = {
        ...qApplicationUnit,
        key: qApplicationUnit?.id,
        application_unit_name: qApplicationUnit?.product_sn_name,
        application_unit_serial_number: qApplicationUnit?.serial_number,
        application: qApplicationUnit.product.product_name,
        application_id: qApplicationUnit.product.id,
        tcu: qTCU?.name,
        tcu_id: qTCU?.id,
        tcu_token: qTCU?.token,
        tcu_sn: qTCU?.unit_number,
      };
      setApplicationUnit([updatedApplicationUnitData]);
    }
  }, [isPageLoaded, qApplicationUnit, qTCU]);

  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Application Unit Name",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
      disabledCheckbox: false,
      default: true,
    },
    {
      title: "Application Unit's Serial Number",
      dataIndex: "application_unit_serial_number",
      key: "application_unit_serial_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.application_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Paired TCU",
      dataIndex: "tcu",
      key: "tcu",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_TCU_VIEW}/${record.tcu_id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Paired TCU Token",
      dataIndex: "tcu_token",
      key: "tcu_token",
      disabledCheckbox: false,
      default: false,
    },
  ];

  return (
    <Table columns={columns} dataSource={applicationUnit} pagination={false} />
  );
};
