import styled from "styled-components";
import {
  ApplicationEventStateValuesList,
  ApplicationEventStateValuesForm,
} from "../components";

const EventStatesWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const EventStatesSidebar = styled.div`
  background-color: #fff;
  height: 100%;
  width: 450px;
  padding: 20px;
  border-right: 1px solid #ccc;
`;

const EventStatesBody = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: normal;
`;

const ApplicationsEventStatesView = () => {
  return (
    <EventStatesWrapper>
      <EventStatesSidebar>
        <SectionTitle>Add or Update Value</SectionTitle>
        <ApplicationEventStateValuesForm />
      </EventStatesSidebar>
      <EventStatesBody>
        <SectionTitle>Event State Values</SectionTitle>
        <ApplicationEventStateValuesList />
      </EventStatesBody>
    </EventStatesWrapper>
  );
};

export default ApplicationsEventStatesView;
