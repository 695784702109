import { useEffect, useState } from "react";
import { Modal, Form, Alert, Select } from "antd";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { useUpdateTCUMutation } from "features/tcu/redux";

export const ApplicationUnitsViewTCUPairingFormModal = ({
  form,
  title,
  qApplicationUnit,
  qTCU,
  qUnpairedTCUs,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  refetch,
  isNewForm = false,
  isEditForm = false,
}) => {
  const history = useHistory();

  /**
   * Form Modal Vars
   */
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  const [updateTCU] = useUpdateTCUMutation();

  /**
   * Endpoints
   */

  /**
   * Form Values
   */
  const [formTCUPairingSelectOptions, setFormTCUPairingSelectOptions] =
    useState([]);

  /**
   * Dynamically updating Form's Select Options
   */
  useEffect(() => {
    if (isPageLoaded) {
      let tcuPairingOptions = [{ value: -1, label: "None" }];

      // if current tcu exists, add to options
      if (qTCU) {
        tcuPairingOptions.push({ value: qTCU?.id, label: qTCU?.name });
      }
      qUnpairedTCUs?.forEach((obj) => {
        tcuPairingOptions.push({ value: obj.id, label: obj.name });
      });
      setFormTCUPairingSelectOptions(tcuPairingOptions);
    }
  }, [isPageLoaded, qTCU, qUnpairedTCUs]);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  const onFinishFormModal = async (values) => {
    const { formApplicationUnitTCU } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };
      if (isNewForm && !isEditForm) {
      } else if (isEditForm) {
        if (formApplicationUnitTCU === -1 && qTCU) {
          result = await updateTCU({
            id: qTCU.id,
            product_sn_id: null,
            customer_id: null,
          });
        } else {
          result = await updateTCU({
            id: formApplicationUnitTCU,
            product_sn_id: qApplicationUnit.id,
            customer_id: qApplicationUnit.product.customer_id,
          });
        }
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }

      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        width={750}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{
          autoFocus: true,
          htmlType: "submit",
        }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender // trigger render of Form component
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-tcu-pairing-modal"
            onFinish={async (values) => onFinishFormModal(values)}
            autoComplete="off"
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application Unit's TCU Pair"
          name="formApplicationUnitTCU"
          initialValue={qTCU?.id}
        >
          <Select
            disabled={isOkButtonLoading}
            options={formTCUPairingSelectOptions}
          />
        </Form.Item>
      </Modal>
    </>
  );
};
