import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseChangelogQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/changelog`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const changelogApi = createApi({
  reducerPath: "changelogApi",
  baseQuery: baseChangelogQuery,
  tagTypes: ["changelog"],
  endpoints: (builder) => ({
    getLatestChangelogVersion: builder.query({
      query: () => `/latest_version`,
    }),
    getClientLogs: builder.query({
      query: () => `/client`,
    }),
    getDeveloperLogs: builder.query({
      query: () => `/developer`,
    }),
  }),
});

export const {
  useGetLatestChangelogVersionQuery,
  useGetClientLogsQuery,
  useGetDeveloperLogsQuery,
} = changelogApi;
