import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Input, Row, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";
import { ADMIN_ROUTE_APPLICATION_UNITS_VIEW } from "features/application-devices/utils/Constants";
import { ADMIN_ROUTE_TCU_VIEW } from "features/tcu/utils/Constants";

export const TCUOverviewTable = ({ qTCUs, isPageLoaded }) => {
  const [tcus, setTCUs] = useState([]);
  const [filteredTCUs, setFilteredTCUs] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedTCUs = qTCUs?.map((obj, index) => {
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          customer_name: obj.customer?.name,
          application_unit_name: obj.product_sn?.product_sn_name,
        };
      });
      setTCUs(updatedTCUs);
      setFilteredTCUs(updatedTCUs);
    }
  }, [qTCUs, isPageLoaded]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const searchTableData = tcus.filter((item) => {
      return Object.keys(item).some((key) => {
        return (
          item[key] && item[key].toString().toLowerCase().includes(searchValue)
        );
      });
    });
    setFilteredTCUs(searchTableData);
  };

  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      disabledCheckbox: false,
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "TCU Name",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_TCU_VIEW}/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Unit Number",
      dataIndex: "unit_number",
      key: "unit_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "SIM",
      dataIndex: "sim",
      key: "sim",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.customer_id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Application Unit",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATION_UNITS_VIEW}/${record.product_sn_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      disabledCheckbox: false,
      default: false,
    },
  ];
  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table columns={columns} dataSource={filteredTCUs} />
        </Col>
      </Row>
    </>
  );
};
