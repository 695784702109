import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseApplicationUnitsQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const applicationUnitsApi = createApi({
  reducerPath: "applicationUnitsApi",
  baseQuery: baseApplicationUnitsQuery,
  tagTypes: ["ApplicationUnit", "ApplicationUnits"],
  endpoints: (builder) => ({
    getArchivedProductSNsForCustomer: builder.query({
      query: (customerId) => `/product_sns/archived?customer_id=${customerId}`,
    }),
    getLatestSNDataForCustomer: builder.query({
      query: (customerId) =>
        `/product_sn_data/newest?customer_id=${customerId}`,
    }),
    getApplicationUnitsByFilter: builder.query({
      query: (args) => {
        const { customer_id, is_active } = args || {};
        const params = {};
        if (customer_id !== undefined) params.customer_id = customer_id;
        if (is_active !== undefined) params.is_active = is_active;
        return {
          url: `/product_sns`,
          params: params,
          provideTags: ["ApplicationUnits"],
        };
      },
    }),
    getUnpairedApplicationUnits: builder.query({
      query: () => {
        return {
          url: "/product_sns/unpaired",
          provideTags: ["ApplicationUnits"],
        };
      },
    }),
    getApplicationUnitByID: builder.query({
      query: (id) => `/product_sns/${id}`,
      providesTags: ["ApplicationUnit"],
    }),
    createApplicationUnit: builder.mutation({
      query: (args) => ({
        url: "/product_sns",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["ApplicationUnits"],
    }),
    updateApplicationUnit: builder.mutation({
      query: (args) => ({
        url: `/product_sns/${args.fields.id}`,
        method: "PUT",
        body: { ...args },
      }),
      invalidatesTags: ["ApplicationUnit"],
    }),
    bulkCreateProductSNs: builder.mutation({
      query: (product_sns) => ({
        url: "/product_sns/bulk",
        method: "POST",
        body: product_sns,
      }),
      invalidatesTags: ["ApplicationUnits"],
    }),
  }),
});

export const {
  useBulkCreateProductSNsMutation,
  useGetArchivedProductSNsForCustomerQuery,
  useGetApplicationUnitByIDQuery,
  useGetLatestSNDataForCustomerQuery,
  useGetApplicationUnitsByFilterQuery,
  useGetUnpairedApplicationUnitsQuery,
  useCreateApplicationUnitMutation,
  useUpdateApplicationUnitMutation,
} = applicationUnitsApi;
