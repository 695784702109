import styled from "styled-components";

const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 30px;
  }
`;
const StyledRadioInput = styled.input`
  // to override specificity
  &,
  .modal-field-element & {
    padding-left: 0;
    width: auto;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    margin: 0;
    margin-right: 7px;
    font: inherit;
    color: ${(props) => props.theme.themePrimary};
    width: 1.15em;
    height: 1.15em;
    border: 1px solid #ccc;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    :checked {
      background-color: ${(props) => props.theme.themePrimary};
    }
    ::before {
      content: "";
      width: 0.55em;
      height: 0.55em;
      border-radius: 50%;
      transform: scale(0);
      box-shadow: inset 1em 1em #ffffff;
    }
    :checked::before {
      transform: scale(1);
    }
    :focus {
      outline: 20px ${(props) => props.theme.themePrimary};
      outline-offset: 20px;
      border-color: ${(props) => props.theme.themePrimary};
    }
  }
`;

export const RadioButton = ({
  name,
  id,
  value,
  onChange,
  checked,
  text,
  inputStyle,
  labelStyle,
}) => {
  return (
    <StyledLabel htmlFor={id} style={labelStyle}>
      <StyledRadioInput
        type="radio"
        name={name}
        id={id}
        value={value}
        checked={checked}
        onChange={onChange}
        style={inputStyle}
      />
      {text}
    </StyledLabel>
  );
};
