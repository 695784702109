import { useContext, useState } from "react";
import { Steps } from "antd";
import { customerService } from "features/customers/services";
import { AuthContext } from "features/auth/contexts";
import { useUpsertLayoutsMutation } from "features/grid-layout/redux";
import { useBulkCreateProductSNsMutation } from "features/units/redux";
import { useCreateApplicationMutation } from "features/applications/redux";
import {
  CreateApplicationForm,
  CreateApplicationUnitForm,
  CreateCustomerForm,
  CustomerOnboardingFormSummary,
} from "./CustomerOnboardingTemplates";

export const CustomerOnboardingForm = () => {
  const [onboardingStep, setOnboardingStep] = useState(0);
  const [customer, setCustomer] = useState(null);
  const [application, setApplication] = useState(null);
  const [applicationUnits, setApplicationUnits] = useState(null);
  const [primaryColorHex, setPrimaryColorHex] = useState("#C0C0C0");
  const [secondaryColorHex, setSecondaryColorHex] = useState("#4B4B4B");
  const [createApplication] = useCreateApplicationMutation();
  const [bulkCreateProductSNs] = useBulkCreateProductSNsMutation();
  const [upsertLayouts] = useUpsertLayoutsMutation();

  const { user: currentUser } = useContext(AuthContext);

  const labelColStyle = { span: 8 };
  const wrapperColStyle = { span: 14 };
  const formStyle = { maxWidth: 600 };
  const formVariant = "filled";
  const titleMargin = { marginBottom: "30px" };
  const buttonMarginRight = { marginRight: "10px" };

  const handleCreateCustomerFormSubmit = async (values) => {
    const customerFormValues = {
      ...values,
      color_primary: primaryColorHex,
      color_secondary: secondaryColorHex,
      is_verified: 1,
    };
    const res = await customerService.Create(customerFormValues);
    setCustomer(res);
    await customerService.AddCustomerUser(res.id, currentUser?.auth_user.id);
    await upsertLayouts([{ customer_id: res.id, section_name: "dashboard" }]);
    setOnboardingStep(1);
    setPrimaryColorHex(null);
    setSecondaryColorHex(null);
  };

  const handlePrimaryColorChange = (selectedColor) => {
    setPrimaryColorHex(`#${selectedColor.toHex()}`);
  };

  const handleSecondaryColorChange = (selectedColor) => {
    setSecondaryColorHex(`#${selectedColor.toHex()}`);
  };

  const handleCreateApplicationFormSubmit = async (values) => {
    const newApplication = await createApplication({
      fields: {
        ...values,
        customer_id: customer?.id,
      },
    });
    setApplication(newApplication.data.application);
    setOnboardingStep(2);
  };

  const handleCreateApplicationUnitsInBulk = async (values) => {
    const {
      product_sn_name,
      product_id,
      quantity = values.applications_quantity || 1,
      is_archived = 0,
    } = values;
    const newApplicationUnits = Array.from({ length: quantity }, (_, i) => ({
      product_sn_name: `${product_sn_name}_${i + 1}`,
      product_id,
      is_archived,
    }));
    const res = await bulkCreateProductSNs(newApplicationUnits);
    setApplicationUnits(
      res.data.result.map((unit) => {
        return { ...unit, application_name: application.product_name };
      })
    );
    setOnboardingStep(3);
  };

  const handleCancel = () => {
    setCustomer(null);
    setOnboardingStep(0);
  };

  const handleFinish = () => {
    setCustomer(null);
    setApplication(null);
    setApplicationUnits(null);
    setOnboardingStep(0);
  };

  const handleSkipAndFinish = () => {
    setOnboardingStep(3);
  };

  return (
    <div style={{ maxWidth: "900px" }}>
      <Steps
        size="small"
        current={onboardingStep}
        items={[
          {
            title: "Create Customer",
          },
          {
            title: "Create Application",
          },
          {
            title: "Add Application Units (optional)",
          },
          {
            title: "Done",
          },
        ]}
        style={{ padding: "30px 0 40px 0" }}
      />
      {/* step 1 - Create Customer */}
      {onboardingStep === 0 && (
        <CreateCustomerForm
          currentUser={currentUser}
          formStyle={formStyle}
          formVariant={formVariant}
          handleCreateCustomerFormSubmit={handleCreateCustomerFormSubmit}
          handlePrimaryColorChange={handlePrimaryColorChange}
          handleSecondaryColorChange={handleSecondaryColorChange}
          primaryColorHex={primaryColorHex}
          secondaryColorHex={secondaryColorHex}
          titleMargin={titleMargin}
          wrapperColStyle={wrapperColStyle}
          labelColStyle={labelColStyle}
        />
      )}
      {/* step 2 - Create Application */}
      {onboardingStep === 1 && (
        <CreateApplicationForm
          buttonMarginRight={buttonMarginRight}
          customer={customer}
          formStyle={formStyle}
          formVariant={formVariant}
          handleCancel={handleCancel}
          handleCreateApplicationFormSubmit={handleCreateApplicationFormSubmit}
          titleMargin={titleMargin}
          wrapperColStyle={wrapperColStyle}
        />
      )}
      {/* step 3 - Create Application Units */}
      {onboardingStep === 2 && (
        <CreateApplicationUnitForm
          application={application}
          buttonMarginRight={buttonMarginRight}
          formStyle={formStyle}
          formVariant={formVariant}
          handleCreateApplicationUnitsInBulk={
            handleCreateApplicationUnitsInBulk
          }
          handleSkipAndFinish={handleSkipAndFinish}
          titleMargin={titleMargin}
          wrapperColStyle={wrapperColStyle}
        />
      )}
      {onboardingStep === 3 && (
        <CustomerOnboardingFormSummary
          application={{ ...application, customer_name: customer?.name }}
          applicationUnits={applicationUnits}
          customer={{ ...customer, owner_name: currentUser?.auth_user.name }}
          handleFinish={handleFinish}
        />
      )}
    </div>
  );
};
