import { Button } from "antd";
import {
  ApplicationSummaryTable,
  ApplicationUnitsSummaryTable,
  CustomerSummaryTable,
} from "../CustomerOnboardingSummaryTemplates";

export const CustomerOnboardingFormSummary = ({
  customer,
  application,
  applicationUnits,
  handleFinish,
}) => {
  const titlePadding = { padding: "5px 0" };
  return (
    <div>
      <h2 style={{ marginBottom: "20px" }}>Summary</h2>
      <h5 style={titlePadding}>Customer:</h5>
      <CustomerSummaryTable customer={customer} />
      <h5 style={titlePadding}>Application:</h5>
      <ApplicationSummaryTable application={application} />
      <h5 style={titlePadding}>Application Units:</h5>
      <ApplicationUnitsSummaryTable applicationUnits={applicationUnits} />
      <Button type="primary" onClick={handleFinish}>
        Finish
      </Button>
    </div>
  );
};
