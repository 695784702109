import styled from "styled-components";
import {
  useGetAllApplicationDevicesQuery,
  useGetAllDeviceTypesQuery,
} from "features/application-devices/redux";
import { useGetCustomersQuery } from "features/customers/redux";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";
import { Button, Card, Form } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import {
  ApplicationDevicesTable,
  ApplicationDeviceFormModal,
} from "../components";

const ApplicationDevicesManagementContainer = styled.div`
  width: 100%;
`;

export const ApplicationDevicesOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  /**
   * Fetching Applications, Application Devices, Customers, Application Device Types
   */
  const {
    isLoading: isQApplicationsLoading,
    error: qApplicationsError,
    data: qApplications,
  } = useGetApplicationsByFilterQuery();

  const {
    isLoading: isQApplicationDevicesLoading,
    error: qApplicationDevicesError,
    data: qApplicationDevices,
    refetch: qApplicationDevicesRefetch,
  } = useGetAllApplicationDevicesQuery();

  const {
    isLoading: isQCustomersLoading,
    error: qCustomersError,
    data: qCustomers,
  } = useGetCustomersQuery();

  const {
    isLoading: isQApplicationDeviceTypesLoading,
    error: qApplicationDeviceTypesError,
    data: qApplicationDeviceTypes,
  } = useGetAllDeviceTypesQuery();

  /**
   * Update Is Page Loaded when Fetching is complete
   */
  useEffect(() => {
    setIsPageLoaded(
      !isQApplicationDeviceTypesLoading &&
        !isQApplicationDevicesLoading &&
        !isQApplicationsLoading &&
        !isQCustomersLoading
    );
  }, [
    isQApplicationDeviceTypesLoading,
    isQApplicationDevicesLoading,
    isQApplicationsLoading,
    isQCustomersLoading,
  ]);

  /**
   * Fetching Error Message
   */
  if (
    qApplicationDevicesError ||
    qCustomersError ||
    qApplicationDeviceTypesError ||
    qApplicationsError
  ) {
    return (
      "An error has occured: " +
      qApplicationDevicesError?.message +
      " | " +
      qCustomersError?.message +
      " | " +
      qApplicationDeviceTypesError?.message +
      " | " +
      qApplicationsError?.message
    );
  }

  return (
    <ApplicationDevicesManagementContainer>
      <Card
        title={
          <span style={{ fontWeight: "bold" }}>
            {"List of Application Devices"}
          </span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={debounce(async () => {
              setIsModalOpen(true);
            }, 100)}
          >
            New Application Device
          </Button>
        }
      >
        <ApplicationDevicesTable
          qApplicationDevices={qApplicationDevices}
          isPageLoaded={isPageLoaded}
        />
      </Card>
      <ApplicationDeviceFormModal
        form={form}
        title={"New Application Device Modal"}
        customers={qCustomers}
        applications={qApplications}
        applicationDeviceTypes={qApplicationDeviceTypes}
        refetch={qApplicationDevicesRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewForm={true}
      />
    </ApplicationDevicesManagementContainer>
  );
};
