import * as API from "axios/axios";

export const customerService = {
  Create: (data) => API.post("/api/customers/", data),
  Update: (customerId, data) => API.put(`/api/customers/${customerId}`, data),
  uploadImage: (data) => API.post("/api/customers/image", data),
  CreateIntent: (customerId, data) =>
    API.post(`/api/customers/${customerId}/create-intent`, data),
  ChangePlan: (customerId, data) =>
    API.put(`/api/customers/${customerId}/plan`, data),
  SetPaymentMethodAsDefault: (customerId, data) =>
    API.post(`/api/customers/${customerId}/set-default-paymentmethod`, data),
  ListPaymentMethods: (customerId) =>
    API.get(`/api/customers/${customerId}/payment-methods`),
  ListSubscriptions: (customerId) =>
    API.get(`/api/customers/${customerId}/subscriptions`),
  GetCurrentSubscription: (customerId) =>
    API.get(`/api/customers/${customerId}/current-subscription`),
  ListInvoices: (customerId) =>
    API.get(`/api/customers/${customerId}/invoices`),
  RemovePaymentMethod: (customerId, data) =>
    API.destroy(`/api/customers/${customerId}/payment-methods`, { data }),
  CancelSubscription: (customerId) =>
    API.destroy(`/api/customers/${customerId}/subscriptions`),
  AddCurrentUserToCustomer: (customerId, data) =>
    API.post(`/api/customers/${customerId}/add-current-user`, data),
  AddUserToCustomer: (customerId, data) =>
    API.post(`/api/customers/${customerId}/users`, data),
  AddCustomerUser: (customerId, userId) =>
    API.post(`/api/customers/${customerId}/users/${userId}`),
  GetUsersForCustomer: (customerId) =>
    API.get(`/api/customers/${customerId}/users`),
  GetAllCustomers: () => API.get(`/api/customers`),
};
