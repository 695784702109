import styled from "styled-components";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";
import { Icon } from "common";
import * as Defines from "utils/defines";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export const Image = ({ getSettingsField }) => {
  const customerId = useCustomerId();
  const { data: allProducts } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const backupId = allProducts?.length ? allProducts[0].id : -1;
  const selectedProductId = getSettingsField("selectedProduct", backupId);
  const selectedProduct = allProducts?.length
    ? allProducts.find((product) => product.id === selectedProductId)
    : null;
  const imgUrl = selectedProduct
    ? selectedProduct.image
    : Defines.DEFAULT_PRODUCT_IMAGE;

  return <ImageBox url={imgUrl} />;
};

const ImageBox = ({ url }) => (
  <Wrapper className="image-tile">
    <Icon
      style={{ height: "100%", width: "100%", objectFit: "contain" }}
      hostedImage={url}
    />
  </Wrapper>
);
