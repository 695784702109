export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

export const STRIPE_PUBLISHABLE_KEY =
  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;

export const AMPLIFY_AUTH_CONFIG = {
  identityPoolId: process.env.REACT_APP_PUBSUB_IDENTITYPOOLID,
  region: process.env.REACT_APP_PUBSUB_REGION,
  userPoolId: process.env.REACT_APP_PUBSUB_USERPOOLID,
  userPoolWebClientId: process.env.REACT_APP_PUBSUB_USERPOOLWEBCLIENTID,
};

export const AMPLIFY_STORAGE_CONFIG = {
  // @TODO: ADD KEY TO AWS
  // bucket: process.env.S3_BUCKET,
  AWSS3: {
    bucket: process.env.S3_BUCKET,
    region: "us-west-1",
  },
};
export const AMPLIFY_IOT_PROVIDER_CONFIG = {
  aws_pubsub_region: process.env.REACT_APP_PUBSUB_REGION,
  aws_pubsub_endpoint: process.env.REACT_APP_PUBSUB_ENDPOINT,
};

export const MQTT_KEEPALIVE_TOPIC = process.env.REACT_APP_MQTT_KEEPALIVE_TOPIC;
export const MQTT_BROADCAST_TOPIC = process.env.REACT_APP_MQTT_BROADCAST_TOPIC;

export const BASE_API_URL = process.env.REACT_APP_API_URL;
