import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tcus: [],
  selectedAdminTCU: null,
};

export const tcuSlice = createSlice({
  name: "tcus",
  initialState,
  reducers: {
    setTCUs: (state, action) => {
      state.tcus = action.payload;
    },
  },
});

export const { setTCUs } = tcuSlice.actions;

export default tcuSlice.reducer;
