import SettingsField from "./SettingsField";
import { RadioButton } from "common/elements";

export const DashboardStatusPieChart = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Background">
        <RadioButton
          name="hide_background"
          id="visible"
          value={0}
          checked={!getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 0 : 1)
          }
          text="Visible"
        />
        <RadioButton
          name="hide_background"
          id="hidden"
          value={1}
          checked={getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 1 : 0)
          }
          text="Hidden"
        />
      </SettingsField>
    </div>
  );
};
