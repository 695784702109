import { useState, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { withTheme } from "styled-components";
import { Icon } from "common";
import { connect } from "react-redux";
import { Link, NavLink, Route, Switch } from "react-router-dom";
import Toggle from "react-toggle";
import { Modal } from "common/Modal";
import { UserProfile } from "features/users/components";
import {
  setEditMode,
  setUnitsViewMode,
} from "features/grid-layout/redux/layoutSlice";
import { setActiveMapMarker } from "features/map/redux";
import { setCurrentProduct } from "features/applications/redux";
import {
  setUserCustomerId,
  userLogout,
  setLoggedOut,
} from "features/users/redux";
import * as Defines from "utils/defines";
import { AuthContext } from "features/auth/contexts";

/* eslint-disable jsx-a11y/anchor-is-valid */

const Wrapper = styled.div`
  user-select: none;
  display: flex;
  height: ${(props) => props.theme.headerHeight};
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100vw;
  border-width: 0;
  // 0 bottom border was requested but the change was throwing off box model
  // keeping super light border to preserve alignment
  border-bottom: ${(props) =>
    props.borderless ? "" : "1px solid rgba(128,128,128,0.2)"};
  background-color: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
`;

const LogoWrapper = styled.div`
  height: ${(props) => props.theme.headerHeight};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${(props) => props.theme.sidebarFontFamily};
  font-weight: 200;
  font-size: 25px;
`;

const Logo = styled.div`
  font-family: "PilatWide";
  font-weight: 300;
  // 20220826 - fudge position for battery show demo
  transform: translate(0, -2px);
  font-size: 22px;
  display: flex;
  padding: 10px;
  color: #333;
  white-space: nowrap;
`;

const User = styled.div`
  display: flex;
  flex: auto;
  align-items: center;
  justify-content: flex-end;
`;

const TileLockWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px;

  // when editing is UNLOCKED
  .react-toggle:not(.react-toggle--checked) .react-toggle-track {
    background-color: ${(props) => props.theme.themePrimary};
    border-color: ${(props) => props.theme.themePrimary};
  }
`;

const Dropdown = styled.div`
  display: ${(props) => props.display};
  position: absolute;
  top: ${(props) => props.theme.headerHeight};
  right: 0;
  background-color: #f9f9f9;
  min-width: 180px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  // margin-top: calc(${(props) => props.theme.headerHeight} + 55px);

  & > div:first-of-type {
    margin-top: 0;
  }
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;

const UnitsViewNav = styled.ul`
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  padding-right: 20px;
  margin-right: 20px;
  border-right: 1px solid #ccc;
`;

const UnitsViewNavItem = styled.li`
  padding: 0 1em;
  border-bottom: ${(props) => props.isActive && "5px solid rgba(0, 0, 0, 0.7)"};
  padding: 11px 1em 11px 1em;
  cursor: pointer;
  white-space: nowrap;
`;

const UnitsViewNavIcon = styled(Icon)`
  width: 24px;
  height: auto;
  margin-right: 10px;
  opacity: 0.7;
`;

const CompanySwitcher = ({ customers, onCustomerSelected }) => {
  return customers.map((customer, i) => (
    <DropdownItem
      key={i}
      style={{ opacity: ".67" }}
      onClick={() => onCustomerSelected(customer)}
    >
      <Icon
        style={{
          marginRight: "10px",
          height: "30px",
          width: "30px",
        }}
        hostedImage={customer ? customer.image : null}
        filename={"default-company.png"}
      />
      <a style={{ width: "100%", height: "100%", color: "black" }}>
        {customer.name}
      </a>
    </DropdownItem>
  ));
};

const UnitsViewModeSwitcher = ({
  currentViewMode,
  setViewMode,
  setProductSN,
}) => {
  const viewModeTabs = [
    {
      key: "list",
      title: "List",
      icon: Defines.S3_VIEW_LIST,
    },
    // {
    //   key: "tray",
    //   title: "Tray",
    //   icon: Defines.ICON_UNIT_NAV_TRAY,
    // },
    {
      key: "map",
      title: "Map",
      icon: Defines.S3_GLOBE,
    },
  ];
  return (
    <div style={{ display: "flex" }}>
      <UnitsViewNav>
        {viewModeTabs.map(({ key, title, icon }) => (
          <UnitsViewNavItem
            key={key}
            isActive={currentViewMode === key}
            onClick={() => {
              setViewMode(key);
              setProductSN(null);
            }}
          >
            <UnitsViewNavIcon hostedImage={icon} />
            {title}
          </UnitsViewNavItem>
        ))}
      </UnitsViewNav>
    </div>
  );
};

const TopNav = ({
  borderless,
  user,
  customer,
  setLoggedOut,
  name,
  customers,
  editModeEnabled,
  setEditing,
  setCustomerId,
  setProductSN,
  setActiveMapMarker,
}) => {
  const [dropdownShown, setDropdownShown] = useState(false);

  const { signOut, isSuperAdmin } = useContext(AuthContext);

  const displayName = name ? name.split(" ")[0] : "";
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  const dispatch = useDispatch();
  const unitsViewMode = useSelector((state) => state.layout.unitsViewMode);
  const setViewMode = (viewMode) => dispatch(setUnitsViewMode(viewMode));

  const selectableCustomers = customers;
  const setSelectedCustomer = (nextCustomer) => {
    if (nextCustomer.id === customer.id) return;
    setCustomerId(nextCustomer.id);
    setDropdownShown(false);
    setActiveMapMarker(null);
    setProductSN(null);
    window.location = "/";
  };

  const performLogout = () => {
    setLoggedOut();
    signOut();
  };

  return (
    <Wrapper className="header-nav-wrapper" borderless={borderless}>
      <Modal
        show={profileModalOpen}
        onHide={() => setProfileModalOpen(false)}
        className="modal"
        size="lg"
        centered
      >
        <UserProfile hide={() => setProfileModalOpen(false)} />
      </Modal>
      <NavLink
        className="header-nav-left"
        to="/dashboard"
        style={{ textDecoration: "none", marginLeft: "15px" }}
      >
        <LogoWrapper className="header-nav-logo-wrapper">
          <Icon
            hostedImage={customer ? customer.image : null}
            filename={"default-company.png"}
            style={{ width: "auto", height: "35px" }}
          />
          {/* connect to redux to get live new company name during customer intake */}
          <Logo className="header-nav-logo">
            {customer ? customer.name : "Stafl Fleet"}
          </Logo>
        </LogoWrapper>
      </NavLink>

      <User className="header-nav-right">
        <Switch>
          <Route path="/units">
            <UnitsViewModeSwitcher
              currentViewMode={unitsViewMode}
              setViewMode={setViewMode}
              setProductSN={setProductSN}
            />
          </Route>
        </Switch>

        <TileLockWrapper onClick={() => setEditing(!editModeEnabled)}>
          <span style={{ marginRight: "8px", whiteSpace: "nowrap" }}>
            Layout Lock
          </span>
          <Toggle
            icons={false}
            checked={!editModeEnabled}
            onChange={(e) => setEditing(!editModeEnabled)}
          />
          <Icon
            hostedImage={
              editModeEnabled ? Defines.S3_LOCK_OPEN : Defines.S3_LOCK_CLOSED
            }
            style={{ marginLeft: "3px" }}
            width={Defines.ICON_SIZE_MD}
            height={Defines.ICON_SIZE_MD}
          />
        </TileLockWrapper>

        <Icon
          style={{ height: "50px", width: "50px" }}
          hostedImage={user ? user.image : null}
          filename={"default-user.jpg"}
        />

        <TileLockWrapper
          className="customer-dropdown"
          onMouseEnter={() => {
            setDropdownShown(true);
          }}
          onMouseLeave={() => {
            setDropdownShown(false);
          }}
          style={{ height: "101%", position: "relative", cursor: "pointer" }}
        >
          <span style={{ opacity: ".67", whiteSpace: "nowrap" }}>
            Hello, {displayName}
          </span>
          <Icon
            style={{
              margin: "5px 0px 0px 5px",
              border: "1px solid white",
            }}
            hostedImage={Defines.S3_CARET_DOWN}
            width={Defines.ICON_SIZE_XS}
            height={Defines.ICON_SIZE_XS}
          />
          <Dropdown
            className="customer-dropdown-actual"
            display={dropdownShown ? "block" : "none"}
          >
            <DropdownItem
              style={{ opacity: ".67" }}
              onClick={() => setProfileModalOpen(true)}
            >
              <a
                className="header-nav-left"
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                User Profile
              </a>
            </DropdownItem>
            {isSuperAdmin && (
              <>
                <DropdownItem style={{ opacity: ".67" }}>
                  <Link
                    to="/account"
                    className="header-nav-left"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    Account
                  </Link>
                </DropdownItem>
                <DropdownItem style={{ opacity: ".67" }}>
                  <Link
                    to="/admin"
                    className="header-nav-left"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    Admin
                  </Link>
                </DropdownItem>
              </>
            )}

            {selectableCustomers.length > 1 && (
              <CompanySwitcher
                customers={selectableCustomers}
                onCustomerSelected={setSelectedCustomer}
              />
            )}

            <DropdownItem style={{ opacity: ".67" }} onClick={performLogout}>
              <Icon
                style={{
                  marginRight: "10px",
                }}
                hostedImage={Defines.S3_LOG_OUT}
                height={Defines.ICON_SIZE_XS}
                width={Defines.ICON_SIZE_XS}
              />
              <a style={{ width: "100%", height: "100%", color: "black" }}>
                Logout
              </a>
            </DropdownItem>
          </Dropdown>
        </TileLockWrapper>
      </User>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user.user,
  name: state.user.name,
  email: state.user.email,
  customer: state.user.customers.find((c) => c.id === state.user.customerId),
  customerId: state.user.customerId,
  customers: state.user.customers,
  editModeEnabled: state.layout.editModeEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  setLoggedOut: () => dispatch(setLoggedOut(true)),
  setUserLogout: () => dispatch(userLogout()),
  setEditing: (isEnabled) => dispatch(setEditMode(isEnabled)),
  setCustomerId: (customerId) => dispatch(setUserCustomerId(customerId)),
  setProductSN: () => dispatch(setCurrentProduct(null)),
  setActiveMapMarker: (marker) => dispatch(setActiveMapMarker({ marker })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(TopNav));
