import { Table, Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADMIN_ROUTE_APPLICATION_UNITS_VIEW } from "features/application-devices/utils/Constants";
import { Spinner } from "common/Spinner";

export const ApplicationViewAppUnitsTable = ({
  qApplication,
  qTCUs,
  isPageLoaded,
}) => {
  const [applicationUnits, setApplicationUnits] = useState([]);
  const [filteredApplicationUnits, setFilteredApplicationUnits] = useState([]);

  /**
   * Page Load
   */
  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplicationUnits = qApplication.product_sns?.map(
        (obj, index) => {
          const selectedTCU = qTCUs?.find(
            (item) => item.product_sn_id === obj.id
          );
          return {
            ...obj,
            key: obj.id,
            index: index + 1,
            application_unit: obj.product_sn_name,
            application_unit_serial_number: obj.serial_number,
            application_unit_tcu: selectedTCU ? selectedTCU.name : "",
          };
        }
      );
      setApplicationUnits(updatedApplicationUnits);
      setFilteredApplicationUnits(updatedApplicationUnits);
    }
  }, [qApplication, qTCUs, isPageLoaded]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const searchTableData = applicationUnits.filter((item) => {
      return Object.keys(item).some((key) => {
        return (
          item[key] && item[key].toString().toLowerCase().includes(searchValue)
        );
      });
    });
    setFilteredApplicationUnits(searchTableData);
  };

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      disabledCheckbox: false,
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Application Unit Name",
      dataIndex: "application_unit",
      key: "application_unit",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_APPLICATION_UNITS_VIEW}/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Application Unit Serial Number",
      dataIndex: "application_unit_serial_number",
      key: "application_unit_serial_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Application Unit's TCU",
      dataIndex: "application_unit_tcu",
      key: "application_unit_tcu",
      disabledCheckbox: false,
      default: false,
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table columns={columns} dataSource={filteredApplicationUnits} />
        </Col>
      </Row>
    </>
  );
};
