import { Col, Input, Row, Table } from "antd";
import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

export const CustomersViewCustomerUsersTable = ({
  qCustomerUsers,
  isPageLoaded,
}) => {
  const [customerUsers, setCustomerUsers] = useState([]);
  const [filteredCustomerUsers, setFilteredCustomerUsers] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedCustomerUsers = qCustomerUsers?.map((obj, index) => {
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          user_name: obj.user?.name,
          user_access_level: obj.user?.access_level,
        };
      });
      setCustomerUsers(updatedCustomerUsers);
      setFilteredCustomerUsers(updatedCustomerUsers);
    }
  }, [qCustomerUsers, isPageLoaded]);

  if (!isPageLoaded) return <Spinner />;

  const customerUsersColumns = [
    {
      title: "User",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Access Level",
      dataIndex: "user_access_level",
      key: "user_access_level",
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              //   handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            columns={customerUsersColumns}
            dataSource={filteredCustomerUsers}
          />
        </Col>
      </Row>
    </>
  );
};
