import { createSlice } from "@reduxjs/toolkit";
import storage from "utils/storage";

const initialState = {
  user: {},
  email: null,
  name: null,
  customerId: null,
  customers: [],
  loggedOut: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserLogin: (state, action) => {
      const { db_user, auth_user, customers } = action.payload;
      const getCustomerId = () => {
        const cached = storage.getStoredCustomer();
        if (cached && customers.find((c) => c.id === cached)) return cached;
        const result = customers.length ? customers[0].id : null;
        return result;
      };
      let customer_id = getCustomerId();
      state.user = auth_user;
      state.auth_user = auth_user;
      state.db_user = db_user;
      state.current_customer_id = customer_id;
      state.customerId = customer_id;
      state.customers = customers;
      state.customer = customers.find((c) => c.id === customer_id);
      state.email = db_user.email;
      state.name = db_user.name;
    },
    setUserCustomerId: (state, action) => {
      let new_customer_id = action.payload;
      storage.setStoredCustomer(new_customer_id);
      state.customerId = new_customer_id;
      state.current_customer_id = new_customer_id;
      state.customer = state.customers.find((c) => c.id === new_customer_id);
    },
    userLogout: (state, action) => {
      state.token = null;
      state.email = null;
      state.name = null;
    },
    setUserObject: (state, action) => {
      const { email, name, image } = action.payload;
      state.email = email ? email : state.email;
      state.name = name ? name : state.name;
      state.image = image ? image : state.image;
      state.user = { ...state.user, ...action.payload };
    },
    setLoggedOut: (state, action) => {
      const loggedOut = action.payload;
      if (loggedOut === state.loggedOut) return state;
      state.loggedOut = loggedOut;
    },
  },
});

export const {
  setUserLogin,
  setUserCustomerId,
  userLogout,
  setUserObject,
  setLoggedOut,
} = userSlice.actions;

export default userSlice.reducer;
