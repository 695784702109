import { useContext, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { AuthContext } from "features/auth/contexts";
import { UnitsPage } from "features/units/routes";
import { Dashboard } from "features/grid-layout";
import { ApplicationsPage } from "features/applications/pages";
import { CustomerAccountPage } from "features/customers/pages";
import { TCUPage, TCUUsagePage } from "features/tcu/pages";
import { AdminPage } from "features/admin/pages";
import storage from "utils/storage";
import * as Defines from "utils/defines";
import {
  ConfirmSignUpPage,
  LoginPage,
  ResetPasswordPage,
  SignUpPage,
} from "features/auth/pages";
import { ForgotPasswordPage } from "features/auth/pages/ForgotPasswordPage";

export const AppRoutes = () => {
  const { user, isLoading, isSuperAdmin, isSignIn, setIsSignIn } =
    useContext(AuthContext);

  const UnauthenticatedRoute = ({ component: Component, path, ...rest }) => {
    if (user) {
      if (path === "/" && isSignIn && storage.getLastLocation()) {
        return <Redirect to={storage.getLastLocation()} />;
      }
      return <Redirect to={Defines.DEFAULT_LOGGED_IN_REDIRECT_URL} />;
    }

    if (isLoading) {
      return <></>;
    }

    return (
      <Route
        {...rest}
        path={path}
        render={(props) => <Component {...props} />}
      />
    );
  };

  const AuthenticatedRoute = ({ component: Component, path, ...rest }) => {
    useEffect(() => {
      if (user && !isLoading) setIsSignIn(false);
    }, [isLoading, user, isSuperAdmin]);

    if (isLoading) return <></>;

    if (!user) {
      return <Redirect to={Defines.DEFAULT_LOGGED_OUT_REDIRECT_URL} />;
    } else if (
      !isSuperAdmin &&
      ["/applications", "/tcus", "/usage", "/admin"].includes(path)
    ) {
      return <Redirect to="/" />;
    }

    return (
      <Route
        {...rest}
        path={path}
        render={(props) => <Component {...props} />}
      />
    );
  };

  return (
    <Switch>
      <UnauthenticatedRoute exact path="/signup" component={SignUpPage} />
      <UnauthenticatedRoute
        exact
        path="/confirm-signup"
        component={ConfirmSignUpPage}
      />
      <UnauthenticatedRoute
        exact
        path="/forgot-password"
        component={ForgotPasswordPage}
      />
      <UnauthenticatedRoute
        exact
        path="/reset-password"
        component={ResetPasswordPage}
      />
      <UnauthenticatedRoute exact path="/" component={LoginPage} />
      <AuthenticatedRoute path="/admin" component={AdminPage} />
      <AuthenticatedRoute exact path="/dashboard" component={Dashboard} />
      <AuthenticatedRoute
        exact
        path="/account"
        component={CustomerAccountPage}
      />
      <AuthenticatedRoute exact path="/units" component={UnitsPage} />
      <AuthenticatedRoute
        exact
        path="/applications"
        component={ApplicationsPage}
      />
      <AuthenticatedRoute exact path="/usage" component={TCUUsagePage} />
      <AuthenticatedRoute exact path="/tcus" component={TCUPage} />
      <Route>
        <Redirect to="/" />
      </Route>
    </Switch>
  );
};
