import { createSlice } from "@reduxjs/toolkit";
import { theme as initTheme } from "styles/GlobalStyles";

const initialState = {
  editModeEnabled: false,
  editModalOpen: false,
  layoutId: null,
  editInstanceId: null,
  layouts: {},
  layoutSettings: {},
  widgets: {},
  widgetsForLayout: {},
  currentTheme: initTheme,
  unitsViewMode: "list",
  lineChartSidebarActive: false,
  clickedLayoutAddButton: null,
};

export const layoutsSlice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setLayoutsForSection: (state, action) => {
      state.layouts[action.payload.section] = action.payload.layouts;
    },
    setLayoutsSettings: (state, action) => {
      const { layoutId, settings } = action.payload;
      const existingLayoutSettings = state.layoutSettings[layoutId] || {};

      // Create a new layoutSettings object with updated values
      const updatedLayoutSettings = {
        ...state.layoutSettings, // Copy existing layoutSettings
        [layoutId]: {
          ...existingLayoutSettings, // Copy settings for the specific layoutId
          ...settings, // Merge in new settings
        },
      };

      // Update the entire layoutSettings object in the state
      state.layoutSettings = updatedLayoutSettings;
    },

    setWidgetsForLayout: (state, action) => {
      state.widgets[action.payload.layout] = action.payload.widgets;
    },
    setEditMode: (state, action) => {
      state.editModeEnabled = action.payload;
    },
    setEditModalOpen: (state, action) => {
      state.editModalOpen = action.payload.editModalOpen;
      state.editInstanceId = action.payload.editInstanceId;
    },
    setActiveListLayout: (state, action) => {
      if (action.payload === state.layoutId) return;
      state.layoutId = action.payload;
    },
    setUnitsViewMode: (state, action) => {
      state.unitsViewMode = action.payload;
    },
    setLineChartSidebarActive: (state, action) => {
      state.lineChartsidebarActive = action.payload;
    },
    setClickedAddButtonLayout: (state, action) => {
      state.clickedLayoutAddButton = action.payload;
    },
  },
});

export const {
  setLayoutsForSection,
  setLayoutsSettings,
  setWidgetsForLayout,
  setEditMode,
  setEditModalOpen,
  setClickedAddButtonLayout,
  setActiveListLayout,
  setUnitsViewMode,
  setLineChartSidebarActive,
} = layoutsSlice.actions;

export default layoutsSlice.reducer;
