import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
import {
  useGetWidgetsForCustomerQuery,
  useDeleteWidgetForCustomerMutation,
  useAddWidgetForCustomerMutation,
  useUpdateSingleWidgetInstanceMutation,
  useGetWidgetInstanceSignalsForCustomerQuery,
} from "features/widgets/redux";
import { useAvailableWidgetsOfType } from "features/widgets/hooks";
import { Icon } from "common";
import { setEditModalOpen } from "features/grid-layout/redux/layoutSlice";
import { Modal, ModalBody } from "common/Modal";
import { Widget, WidgetSettings } from "features/widgets";
import * as Defines from "utils/defines";
import {
  FooterWrapper,
  FooterLeft,
  FieldLabel,
  TileConfirmationModal,
} from "features/widgets/components/TileModalComponents";
import { Button } from "common/elements";

const TileModal = ({
  modal_id, // Layout
  layoutID, // Layout/modalProps
  widgetType, // Layout/modalProps
  position: _positionFromProps, // Layout/modalProps
  onCancel, // Layout/modalProps
  show, // Layout/modalProps
  availableXCoordinate,
  isMapModalLayout,
}) => {
  // TODO: given instanceID, find layout ID then determine if full screen
  // redux state
  const editModalOpen = useSelector((state) => state.layout.editModalOpen);
  const _instanceID = useSelector((state) => state.layout.editInstanceId);

  // redux actions
  const dispatch = useDispatch();
  const setModalOpen = (editModalOpen, editInstanceId) =>
    dispatch(setEditModalOpen({ editModalOpen, editInstanceId }));

  // RTK Query
  const customerId = useCustomerId();
  const { data: allWidgetInstances } =
    useGetWidgetsForCustomerQuery(customerId);
  const { data: signalsAndMessages } =
    useGetSignalsForCustomerQuery(customerId);
  const { data: allInstanceSignals } =
    useGetWidgetInstanceSignalsForCustomerQuery(customerId);
  const [deleteWidget] = useDeleteWidgetForCustomerMutation();
  const [addWidget] = useAddWidgetForCustomerMutation();
  const [updateWidget] = useUpdateSingleWidgetInstanceMutation();

  // local state - available widgets and available signals
  const signals = signalsAndMessages ? signalsAndMessages.signals : [];
  const widgets = useAvailableWidgetsOfType(widgetType);

  // local state - instance signals
  const initialChosenSignals = allInstanceSignals
    .filter(
      (instanceSignal) => instanceSignal.widget_instance_id === _instanceID
    )
    .sort((a, b) => a.position - b.position);
  const [chosenSignals, updateChosenSignals] = useState(initialChosenSignals);

  // local state - instance
  const _instance = allWidgetInstances.find(
    (widget) => widget.id === _instanceID
  );

  // if we're editing a widget instance, store its widget_id to state
  const [widgetID, setWidgetID] = useState(
    _instance ? _instance.widget_id : null
  );

  // if we're editing a widget instance, use its dimensions or set
  // default height and width of widget instance to state to 2
  const [height, setHeight] = useState(_instance ? _instance.height : 2);
  const [width, setWidth] = useState(_instance ? _instance.width : 2);

  // if we're editing an instance, save its settings to state
  const [settings, setSettings] = useState(
    _instance ? _instance.settings : "{}"
  );

  // flag for if the saving request operation is still in flight
  const [savingModal, setSavingModal] = useState(false);

  // modal action flags
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // find matching widget in props.widgets, given widgetID
  const widgetForInstance = widgetID
    ? widgets.find((w) => w.id === widgetID)
    : null;

  // set initial params given a widget instance record
  const initializeParams = (record) => {
    setWidgetID(record.id);
    setHeight(2);
    setWidth(2);
  };

  // calculate widget instance params used for editing and creating
  const getInstanceObject = () => {
    return {
      widget_id: widgetID,
      layout_id: layoutID,
      customer_id: 1,
      height,
      width,
      x: _instance
        ? _instance.x
        : isMapModalLayout && !!availableXCoordinate
        ? availableXCoordinate
        : 0,
      // This is why the widget renders at the wrong position!
      y: _instance ? _instance.y : 40,
      position: _positionFromProps,
      settings,
      refresh_rate: _instance ? _instance.refresh_rate : 1,
    };
  };

  // when changing widget type, reinitialize instance params
  const widgetChanged = (e) => {
    // eslint-disable-next-line
    const widget = widgets.find((w) => w.id == e.target.value);
    initializeParams(widget);
  };

  // called when editing a pre-existing chosen signal
  const onSignalSelected = (selectedSignal, index) => {
    const updatedSignals = [...chosenSignals];
    let updatedSignal = {
      signal_id: parseInt(selectedSignal.value),
    };
    if (_instanceID) updatedSignal.widget_instance_id = _instanceID;
    updatedSignals[index] = updatedSignal;
    updateChosenSignals(updatedSignals);
  };

  // called when adding a new signal to chosen signals
  const addNewSignal = (newSignal) => {
    const instanceSignalForCreation = {
      signal_id: parseInt(newSignal.value),
    };
    const updatedSignals = [...chosenSignals, instanceSignalForCreation];
    updateChosenSignals(updatedSignals);
  };

  // called when deleting a pre-existing chosen signal
  const removeWidgetInstanceSignal = (instanceSignal, index) => {
    const updatedSignals = [...chosenSignals];
    updatedSignals.splice(index, 1);
    updateChosenSignals(updatedSignals);
  };

  // delete a widget
  const removeWidget = () => deleteWidget(_instanceID);

  // upon widget creation
  const onCreateWidget = () => {
    let widget_instance = getInstanceObject();
    let widget_instance_signals = chosenSignals;
    addWidget({ widget_instance, widget_instance_signals });
    onCancel();
  };

  // upon widget edit
  const onSaveEditedWidget = async () => {
    setSavingModal(true);
    let editedInstance = getInstanceObject();
    let editedInstanceSignals = chosenSignals;
    editedInstance = { ...editedInstance, id: _instanceID };
    updateWidget({
      widget_instance: editedInstance,
      widget_instance_signals: editedInstanceSignals,
    });
    setSavingModal(false);
    setModalOpen(false);
  };

  // render a widget with dummy values for preview
  const getLivePreviewWidget = () => {
    return (
      <Widget
        instance={{
          ..._instance,
          widget_instance_signals: chosenSignals,
          id: 123,
          widget_id: widgetID,
          settings,
        }}
        editModeEnabled={false}
        isPreview={true}
      />
    );
  };

  // create <option> object for React Select
  const signalToSelectOptions = (signal, index) => ({
    value: `${signal.id}`,
    label: `${signal.name || signal.can_signal_name}${
      signal.symbol ? ` - [${signal.symbol}]` : ""
    }`,
  });

  // return only unselected signals when choosing new instance signals
  const newSignalOptions = signals
    .filter((s) => {
      return !chosenSignals.find((cs) => cs.signal_id === s.id);
    })
    .map(signalToSelectOptions);

  // set parameters if edit modal open or instanceID changes
  // without this useEffect, adding a new tile shows no settings
  useEffect(() => {
    if (!editModalOpen) {
      return;
    }

    setWidgetID(
      _instance ? _instance.widget_id : widgets.length ? widgets[0].id : null
    );
    setHeight(_instance ? _instance.height : 2);
    setWidth(_instance ? _instance.width : 2);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editModalOpen, _instanceID]);

  // if not editing, don't render
  if (!editModalOpen) return null;

  // render
  return (
    <div>
      {/* Live Preview Modal used classname `litmodal-modal2` */}
      {Defines.ENABLE_TILE_PREVIEW_MODAL && (
        <Modal
          className={"litmodal-modal2"}
          show={show}
          onHide={() => {
            onCancel();
          }}
          style={{ opacity: confirmModal || deleteModal ? 0.7 : 1 }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="row">{getLivePreviewWidget()}</div>
          </Modal.Body>
        </Modal>
      )}

      {/* Settings Modal */}
      <Modal
        className={"litmodal-" + modal_id}
        show={show}
        onHide={() => {
          onCancel();
        }}
        style={{
          opacity: confirmModal || deleteModal ? 0.7 : 1,
          marginLeft: Defines.ENABLE_TILE_PREVIEW_MODAL ? "-15%" : 0,
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ alignItems: "center" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            Tile Settings
          </Modal.Title>

          <Icon
            width={Defines.ICON_SIZE_MD}
            height={Defines.ICON_SIZE_MD}
            filename="x_button_icon.svg"
            onClick={onCancel}
            style={{ cursor: "pointer" }}
          />
        </Modal.Header>

        {/* Settings Body */}
        <ModalBody>
          <div className="modal-field-wrapper">
            <FieldLabel className="modal-field-label">Type</FieldLabel>
            {widgets.length ? (
              <select
                className="modal-field-element"
                value={widgetID || 1}
                onChange={widgetChanged}
              >
                {widgets.map((widget, i) => (
                  <option key={i} value={widget.id}>
                    {widget.name}
                  </option>
                ))}
                {!widgets.find((widget) => widget.id === widgetID) ? (
                  <option value={widgetID} disabled>
                    Selected widget unavailable
                  </option>
                ) : null}
              </select>
            ) : (
              <select className="modal-field-element" value={1}>
                <option value={1} disabled>
                  No widgets available
                </option>
              </select>
            )}
          </div>

          {widgetForInstance && widgetForInstance.max_signals > 0 ? (
            <div className="modal-field-wrapper">
              <FieldLabel className="modal-field-label">Signals</FieldLabel>
              {/* add signal */}
              <div
                className="modal-field-element"
                style={{ flexDirection: "column" }}
              >
                {chosenSignals.map((chosenSignal, index) => {
                  // already chosen signals
                  const signalOptions = signals
                    .filter((s) => {
                      return (
                        s.id === chosenSignal.signal_id ||
                        !chosenSignals.find((cs) => cs.signal_id === s.id)
                      );
                    })
                    .map(signalToSelectOptions);
                  return (
                    <div
                      key={index}
                      style={{ display: "flex", marginBottom: "16px" }}
                    >
                      {/* edit previously chosen signals */}
                      <div style={{ flex: "1" }}>
                        <Select
                          value={signalOptions.find(
                            (s) =>
                              parseInt(s.value) ===
                              parseInt(chosenSignal.signal_id)
                          )}
                          onChange={(selectedSignal) =>
                            onSignalSelected(selectedSignal, index)
                          }
                          options={signalOptions}
                        />
                      </div>
                      <div style={{ margin: "auto 0 auto 5px" }}>
                        <Icon
                          width={Defines.ICON_SIZE_MD}
                          height={Defines.ICON_SIZE_MD}
                          hostedImage={Defines.S3_CLOSE_X}
                          onClick={() =>
                            removeWidgetInstanceSignal(chosenSignal, index)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </div>
                  );
                })}
                {widgetForInstance &&
                chosenSignals.length < widgetForInstance.max_signals ? (
                  <div style={{ flex: "1" }}>
                    <Select
                      value={`''`}
                      onChange={addNewSignal}
                      options={newSignalOptions}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}

          <WidgetSettings
            widget_id={widgetID}
            widgetInfo={widgetForInstance}
            settings={settings}
            updateSettings={setSettings}
          />
        </ModalBody>

        {/* Settings Footer */}
        <Modal.Footer style={{ display: "flex", justifyContent: "flexStart" }}>
          {_instanceID ? (
            <FooterWrapper>
              <FooterLeft>
                <Button
                  danger
                  onClick={() => {
                    setDeleteModal(true);
                  }}
                >
                  Delete Tile
                </Button>
              </FooterLeft>
              <Button onClick={onSaveEditedWidget}>
                {savingModal ? "Saving..." : "Accept Changes"}
              </Button>
            </FooterWrapper>
          ) : (
            <Button onClick={onCreateWidget}>Create</Button>
          )}
        </Modal.Footer>
      </Modal>

      {/* Confirmation Modal */}
      <TileConfirmationModal
        showFlag={confirmModal}
        hideAction={() => setConfirmModal(false)}
        clickAction={() => {
          onCancel();
        }}
        buttonText={_instanceID ? "Cancel Changes" : "Discard Tile"}
      />

      {/* Delete Modal */}
      <TileConfirmationModal
        showFlag={deleteModal}
        hideAction={() => setDeleteModal(false)}
        clickAction={() => {
          removeWidget();
          onCancel();
        }}
        buttonText={"Delete Tile"}
        bgColor={Defines.COLOR_ERROR_RED}
      />
    </div>
  );
};

export default TileModal;
