import { Space, Table } from "antd";
import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import { ADMIN_ROUTE_APPLICATION_UNITS_VIEW } from "features/application-devices/utils/Constants";
import { Link } from "react-router-dom";
import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";

export const TCUViewDetailsTable = ({ qTCU, isPageLoaded }) => {
  const [tcuMainDetails, setTCUMainDetails] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedTCUDetails = {
        ...qTCU,
        key: qTCU?.id,
        customer_name: qTCU?.customer?.name,
        application_unit_name: qTCU?.product_sn?.product_sn_name,
      };
      setTCUMainDetails([updatedTCUDetails]);
    }
  }, [isPageLoaded, qTCU]);

  if (!isPageLoaded) return <Spinner />;

  const tcuMainDetailsColumns = [
    {
      title: "TCU Name",
      dataIndex: "name",
      key: "name",
      default: true,
    },
    {
      title: "Unit Number",
      dataIndex: "unit_number",
      key: "unit_number",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
    },
    {
      title: "Current Firmware",
      dataIndex: "firmware_version",
      key: "firmware_version",
    },
  ];

  const tcuModemDetailsColumns = [
    {
      title: "IMEI",
      dataIndex: "imei",
      key: "imei",
    },
    {
      title: "SIM",
      dataIndex: "sim",
      key: "sim",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.customer_id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Paired Application Unit",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATION_UNITS_VIEW}/${record.product_sn_id}`}
        >
          {text}
        </Link>
      ),
    },
  ];

  return (
    <>
      <Space direction="vertical" size={"middle"} style={{ display: "flex" }}>
        <Table
          columns={tcuMainDetailsColumns}
          dataSource={tcuMainDetails}
          pagination={false}
        />
        <Table
          columns={tcuModemDetailsColumns}
          dataSource={tcuMainDetails}
          pagination={false}
        />
      </Space>
    </>
  );
};
