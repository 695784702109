import { useState, useEffect } from "react";
import { Flex, Table } from "antd";
import { useHistory } from "react-router-dom";
import { useGetAllDeviceTypesQuery } from "features/application-devices/redux";
import { Spinner } from "common/Spinner";
import { axios } from "axios/axios";
import { getLatestFirmwareVersion } from "../utils/firmwareUtils";

const FileManagementOverview = () => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const history = useHistory();
  const { data: devices, isLoading: devicesIsLoading } =
    useGetAllDeviceTypesQuery();
  const [updatedDevices, setUpdatedDevices] = useState([]);
  const [updatedDevicesIsLoading, setUpdatedDevicesIsLoading] = useState(true);

  useEffect(() => {
    if (devicesIsLoading) return;

    const fetchData = async () => {
      try {
        const promises = devices?.map(async (device) => {
          const response = await axios.get(
            `/api/firmware/files/${device.name}`
          );
          const latestFirmware = getLatestFirmwareVersion(response?.result);
          return {
            ...device,
            latestVersion: latestFirmware?.version || "n/a",
            numberOfFiles: response?.result?.length,
            lastUpdated: latestFirmware?.lastModified || "n/a",
          };
        });

        const updatedDevices = await Promise.all(promises);
        setUpdatedDevices(updatedDevices);
      } catch (error) {
        console.error("Error fetching device data:", error);
      } finally {
        setUpdatedDevicesIsLoading(false);
      }
    };

    fetchData();
  }, [devices, devicesIsLoading]);

  const deviceColumn = [
    {
      title: "Device Name",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: false,
      onCell: (record, rowIndex) => {
        return {
          onClick: () => history.push(`view/${record.id}`),
          style: {
            textDecoration: hoveredRow === rowIndex ? "underline" : "none",
            cursor: "pointer",
            color: "blue",
          },
          onMouseEnter: () => setHoveredRow(rowIndex),
          onMouseLeave: () => setHoveredRow(),
        };
      },
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
    },
    {
      title: "Latest Version",
      dataIndex: "latestVersion",
      key: "latestVersion",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.latestVersion.localeCompare(b.latestVersion),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Number of Files",
      dataIndex: "numberOfFiles",
      key: "numberOfFiles",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.numberOfFiles.localeCompare(b.numberOfFiles),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      disabledCheckbox: false,
      default: false,
      sorter: (a, b) => a.lastUpdated.localeCompare(b.lastUpdated),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
  ];

  return devicesIsLoading || updatedDevicesIsLoading ? (
    <Flex>
      <Spinner />
    </Flex>
  ) : (
    <Table
      dataSource={updatedDevices}
      columns={deviceColumn}
      rowKey={(record) => record.name}
    />
  );
};

export default FileManagementOverview;
