import styled from "styled-components";
import { useEffect, useState } from "react";
import { Button, Card, Form } from "antd";
import { debounce } from "lodash";
import {
  ApplicationDeviceTypeOverviewTable,
  ApplicationDeviceTypeFormModal,
} from "../components";
import { useGetAllDeviceTypesQuery } from "features/application-devices/redux";
const ApplicationDeviceTypeManagementContainer = styled.div`
  width: 100%;
`;

export const ApplicationDeviceTypesOverview = () => {
  /**
   * Application Device Type Form Modal Var
   */
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  /**
   * Fetching Application Device Types
   */
  const {
    isLoading: isQApplicationDeviceTypesLoading,
    data: qApplicationDeviceTypes,
    refetch: qApplicationDeviceTypesRefetch,
  } = useGetAllDeviceTypesQuery();

  useEffect(() => {
    setIsPageLoaded(!isQApplicationDeviceTypesLoading);
  }, [isPageLoaded, isQApplicationDeviceTypesLoading]);

  return (
    <ApplicationDeviceTypeManagementContainer>
      <Card
        title={
          <span style={{ fontWeight: "bold" }}>
            {"List of Application Device Types"}
          </span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={debounce(async () => {
              setIsModalOpen(true);
            }, 100)}
          >
            New Application Device Type
          </Button>
        }
      >
        <ApplicationDeviceTypeOverviewTable
          qApplicationDeviceTypes={qApplicationDeviceTypes}
          isPageLoaded={isPageLoaded}
        />
      </Card>

      <ApplicationDeviceTypeFormModal
        form={form}
        title={"New Application Device Type Modal"}
        refetch={qApplicationDeviceTypesRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewForm={true}
      />
    </ApplicationDeviceTypeManagementContainer>
  );
};
