import { useState } from "react";
import styled from "styled-components";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetTCUsForCustomerQuery } from "features/tcu/redux";
import {
  ApplicationsPushCommandResponseViewer,
  ApplicationsCustomCommandForm,
  ApplicationsPushCommandUnitSelect,
} from "../components";

const SectionTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: normal;
`;

const CustomCommandsWrapper = styled.div`
  display: flex;
  align-items: stretch;
  height: 100%;
`;

const CommandPrimary = styled.div`
  flex: 1;
  height: 100%;
  background-color #fff;
  border-right: 1px solid #ccc;
  padding: 20px;
`;

const CommandSecondary = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;

const ApplicationsPushCommandView = () => {
  const customerId = useCustomerId();
  const { data: tcus } = useGetTCUsForCustomerQuery(customerId);
  const [selectedTCU, setSelectedTCU] = useState();
  const handleSNSelection = (selectedSN) => {
    const tcuForSN = tcus?.length
      ? tcus.find((tcu) => tcu.product_sn_id === selectedSN.id)
      : null;
    if (tcuForSN) setSelectedTCU(tcuForSN);
  };
  return (
    <CustomCommandsWrapper>
      <CommandPrimary>
        <SectionTitle>Send Custom Command</SectionTitle>
        <ApplicationsPushCommandUnitSelect onSelected={handleSNSelection} />
        <ApplicationsCustomCommandForm tcu={selectedTCU} />
      </CommandPrimary>
      <CommandSecondary>
        <SectionTitle>Responses</SectionTitle>
        <ApplicationsPushCommandResponseViewer tcu={selectedTCU} />
      </CommandSecondary>
    </CustomCommandsWrapper>
  );
};

export default ApplicationsPushCommandView;
