import styled from "styled-components";
import { default as LoadingSpinner } from "react-spinners/BarLoader";
import { withTheme } from "styled-components";
import { STAFL_ACTION } from "styles/GlobalStyles";

const Wrapper = styled.div``;

const LoadingScreen = ({ theme = null, spinning = true }) => (
  <Wrapper>
    {spinning ? (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <LoadingSpinner
          height={6}
          width={160}
          color={theme?.themePrimary || STAFL_ACTION}
        />
      </div>
    ) : null}
  </Wrapper>
);

export default withTheme(LoadingScreen);
