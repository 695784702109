import styled from "styled-components";
import { connect } from "react-redux";
import { GoogleMap } from "features/map";
import { useSelector } from "react-redux";
import { setCurrentProduct } from "features/applications/redux";
import { MainLayout } from "common/layout";
import { ModalUnderlayBG } from "common";
import { UnitsOverlay, UnitsViewList, UnitModal } from "../components";
import { useCallback } from "react";

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  background-repeat: no-repeat;
  background-position: ${(props) =>
    props.currentProductSelected ? "100% 115%" : "top right"};
  background-size: ${(props) =>
    props.currentProductSelected ? "140%" : "cover"};
`;

const UnitsPage = ({ currentProduct, setCurrentProduct }) => {
  const unitsViewMode = useSelector((state) => state.layout.unitsViewMode);
  const renderUnitsViewMode = useCallback((viewMode) => {
    switch (viewMode) {
      case "map":
        return <GoogleMap />;
      case "list":
      default:
        return <UnitsViewList />;
    }
  }, []);

  const modalUnderlayActive = !!currentProduct;
  return (
    <MainLayout>
      <Wrapper
        className="products-wrapper"
        currentProductSelected={!!currentProduct}
      >
        <UnitsOverlay active={unitsViewMode === "map" || !!currentProduct} />
        {renderUnitsViewMode(unitsViewMode)}
        <div>
          {currentProduct && <UnitModal />}
          <ModalUnderlayBG
            disabled={!modalUnderlayActive}
            onClick={() => {
              setCurrentProduct(null);
            }}
          />
        </div>
      </Wrapper>
    </MainLayout>
  );
};

const mapStateToProps = (state) => ({
  currentProduct: state.products.product,
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentProduct: (productId) => dispatch(setCurrentProduct(productId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UnitsPage);
