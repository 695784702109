import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Elements,
  // PaymentElement,
  // useStripe,
  // useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { userService } from "features/users/services";
import { customerService } from "features/customers/services";
import {
  // useCustomerId,
  useCurrentCustomer,
} from "features/customers/hooks/customersHooks";

// import LoadingSpinner from "common/LoadingSpinner";
import { Button } from "common/elements";
import { AuthContext } from "features/auth/contexts";
import { MainLayout } from "common/layout";
import { STRIPE_PUBLISHABLE_KEY } from "config";

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

// const SetupForm = () => {
//   const stripe = useStripe();
//   const elements = useElements();
//   const customerId = useCustomerId();
//   const history = useHistory();

//   const [errorMessage, setErrorMessage] = useState("");
//   const [loading, setLoading] = useState(false);

//   const handleError = (error) => {
//     setLoading(false);
//     setErrorMessage(error.message);
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe) return;
//     if (!customerId) return;

//     setLoading(true);

//     const { error: submitError } = await elements.submit();
//     if (submitError) {
//       handleError(submitError);
//       return;
//     }

//     const res = await customerService.CreateIntent(customerId);
//     const { client_secret: clientSecret } = res;

//     const { setupIntent, error } = await stripe.confirmSetup({
//       elements,
//       clientSecret,
//       confirmParams: {
//         return_url: `${window.location.origin}/account`, // need to figure out where this goes for a redirected payment
//       },
//       redirect: "if_required",
//     });

//     if (error) {
//       handleError(error);
//       return;
//     }

//     const { payment_method } = setupIntent;
//     const setupRes = await customerService.SetPaymentMethodAsDefault(
//       customerId,
//       { payment_method }
//     );

//     history.go(0);
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: "white",
//         borderRadius: "5px",
//         border: "1px solid #777",
//         padding: "20px",
//       }}
//     >
//       <h2>Add Payment Method</h2>
//       {errorMessage && <div>{errorMessage}</div>}
//       <form onSubmit={handleSubmit}>
//         <PaymentElement />
//         <button type="submit" disabled={!stripe || loading}>
//           Submit
//         </button>
//       </form>
//     </div>
//   );
// };

// const PaymentMethods = () => {
//   const customerId = useCustomerId();
//   const history = useHistory();

//   const [paymentMethods, setPaymentMethods] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     setIsLoading(true);
//     const getPaymentMethods = async () => {
//       const res = await customerService.ListPaymentMethods(customerId);
//       setPaymentMethods(res);
//       setIsLoading(false);
//     };
//     getPaymentMethods();
//   }, [customerId]);

//   const handleDeletePaymentMethod = (paymentMethodId) => async (e) => {
//     e.preventDefault();
//     await customerService.RemovePaymentMethod(customerId, { paymentMethodId });
//     history.go(0);
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: "white",
//         borderRadius: "5px",
//         border: "1px solid #777",
//         padding: "20px",
//         marginBottom: "20px",
//       }}
//     >
//       <h2>Payment Methods</h2>
//       {isLoading ? (
//         <p>Loading</p>
//       ) : !paymentMethods.length ? (
//         <p>No Payment Methods</p>
//       ) : (
//         <ul>
//           {paymentMethods.map((paymentMethod) => (
//             <li>
//               ID: {paymentMethod.id}
//               <ul>
//                 <li>Brand: {paymentMethod.card.brand}</li>
//                 <li>Last 4: {paymentMethod.card.last4}</li>
//                 <li>
//                   Expires: {paymentMethod.card.exp_month}/
//                   {paymentMethod.card.exp_year}
//                 </li>
//                 <li>
//                   <Link
//                     to="#"
//                     onClick={handleDeletePaymentMethod(paymentMethod.id)}
//                   >
//                     Delete this payment method
//                   </Link>
//                 </li>
//               </ul>
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

// const SubscriptionList = () => {
//   const customerId = useCustomerId();
//   const history = useHistory();

//   const [subscription, setSubscription] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     setIsLoading(true);
//     const getSubscription = async () => {
//       const res = await customerService.GetCurrentSubscription(customerId);
//       setSubscription(res);
//       setIsLoading(false);
//     };
//     getSubscription();
//   }, [customerId]);

//   const handleDeleteSubscription = async (e) => {
//     e.preventDefault();
//     await customerService.CancelSubscription(customerId);
//     history.go(0);
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: "white",
//         borderRadius: "5px",
//         border: "1px solid #777",
//         padding: "20px",
//         marginBottom: "20px",
//       }}
//     >
//       <h2>Subscription</h2>
//       {isLoading ? (
//         <p>Loading</p>
//       ) : !subscription ? (
//         <p style={{ color: "red", fontWeight: "bold" }}>
//           No Subscription. Please select a plan to continue using our services.
//         </p>
//       ) : !["active", "trialing"].includes(subscription.status) ? (
//         <p style={{ color: "red", fontWeight: "bold" }}>
//           There was a payment failure with your subscription. Please update your
//           payment method.
//         </p>
//       ) : (
//         <div>
//           Subscription ID: {subscription.id}
//           <ul>
//             <li>Status: {subscription.status}</li>
//             <li>Plan: {subscription.plan}</li>
//             <li>
//               <Link to="#" onClick={handleDeleteSubscription}>
//                 Cancel this subscription
//               </Link>
//             </li>
//           </ul>
//         </div>
//       )}
//     </div>
//   );
// };

// const PlanSelection = () => {
//   const customer = useCurrentCustomer();
//   const [selectedPlan, setSelectedPlan] = useState(customer.fleet_plan);
//   const [loading, setLoading] = useState(false);
//   const history = useHistory();

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!customer) return;

//     setLoading(true);

//     await customerService.ChangePlan(customer.id, { selectedPlan });

//     setLoading(false);

//     history.go(0);
//   };

//   return (
//     <div
//       style={{
//         backgroundColor: "white",
//         borderRadius: "5px",
//         border: "1px solid #777",
//         padding: "20px",
//         marginBottom: "20px",
//       }}
//     >
//       <h2>Plan Selection</h2>
//       <form onSubmit={handleSubmit}>
//         <fieldset
//           style={{
//             display: "flex",
//             justifyContent: "space-between",
//             padding: "0 30px",
//           }}
//         >
//           <div>
//             <label style={{ textAlign: "center" }} htmlFor="basic">
//               <strong>Basic</strong>
//               <br />
//               ($50/mo)
//             </label>
//             <input
//               style={{ display: "inline" }}
//               type="radio"
//               checked={selectedPlan === "basic"}
//               onChange={(e) => setSelectedPlan(e.target.value)}
//               id="basic"
//               name="plan"
//               value="basic"
//             />
//           </div>
//           <div>
//             <label style={{ textAlign: "center" }} htmlFor="professional">
//               <strong>Professional</strong>
//               <br />
//               ($100/mo)
//             </label>
//             <input
//               style={{ display: "inline" }}
//               type="radio"
//               checked={selectedPlan === "professional"}
//               onChange={(e) => setSelectedPlan(e.target.value)}
//               id="professional"
//               name="plan"
//               value="professional"
//             />
//           </div>
//           <div>
//             <label style={{ textAlign: "center" }} htmlFor="enterprise">
//               <strong>Enterprise</strong>
//               <br />
//               ($200/mo)
//             </label>
//             <input
//               style={{ display: "inline" }}
//               type="radio"
//               checked={selectedPlan === "enterprise"}
//               onChange={(e) => setSelectedPlan(e.target.value)}
//               id="enterprise"
//               name="plan"
//               value="enterprise"
//             />
//           </div>
//         </fieldset>
//         <small>
//           You will be credited or charged for the prorated amount at the next
//           invoice.
//         </small>
//         {loading && <LoadingSpinner />}
//         <Button type="submit" disabled={!selectedPlan || loading}>
//           Submit
//         </Button>
//       </form>
//     </div>
//   );
// };

// const InvoiceList = () => {
//   const customerId = useCustomerId();

//   const [invoices, setInvoices] = useState([]);
//   const [isLoading, setIsLoading] = useState(false);

//   useEffect(() => {
//     setIsLoading(true);
//     const getInvoices = async () => {
//       const res = await customerService.ListInvoices(customerId);
//       setInvoices(res);
//       setIsLoading(false);
//     };
//     getInvoices();
//   }, [customerId]);

//   return (
//     <div
//       style={{
//         backgroundColor: "white",
//         borderRadius: "5px",
//         border: "1px solid #777",
//         padding: "20px",
//         marginBottom: "20px",
//       }}
//     >
//       <h2>Invoices</h2>
//       {isLoading ? (
//         <p>Loading</p>
//       ) : !invoices.length ? (
//         <p>No Invoices</p>
//       ) : (
//         <ul>
//           {invoices.map((invoice) => (
//             <li key={invoice.id}>
//               Invoice ID: {invoice.id}
//               <ul>
//                 <li>Number: {invoice.number}</li>
//                 <li>Status: {invoice.status}</li>
//                 <li>Paid: {invoice.paid}</li>
//                 <li>Total: ${invoice.total}</li>
//                 <li>Amount due: ${invoice.amount_due}</li>
//                 <li>Amount paid: ${invoice.amount_paid}</li>
//                 <li>Amount remaining: ${invoice.amount_remaining}</li>
//                 <li>Created: {new Date(invoice.created * 1000).toString()}</li>
//                 <li>
//                   Period Start:{" "}
//                   {new Date(invoice.period_start * 1000).toString()}
//                 </li>
//                 <li>
//                   Period End: {new Date(invoice.period_end * 1000).toString()}
//                 </li>
//                 <li>
//                   <a href={invoice.hosted_invoice_url}>Hosted Invoice</a>
//                 </li>
//                 <li>
//                   <a href={invoice.invoice_pdf}>Invoice PDF</a>
//                 </li>
//                 <li>
//                   Items:
//                   <ul>
//                     {invoice.lines.data.map((invoiceItem) => (
//                       <li key={invoiceItem.id}>
//                         ID: {invoiceItem.id}
//                         <ul>
//                           <li>Amount: ${invoiceItem.amount / 100.0}</li>
//                           <li>Description: {invoiceItem.description}</li>
//                           <li>
//                             Period:{" "}
//                             {new Date(
//                               invoiceItem.period.start * 1000
//                             ).toString()}{" "}
//                             -{" "}
//                             {new Date(invoiceItem.period.end * 1000).toString()}
//                           </li>
//                         </ul>
//                       </li>
//                     ))}
//                   </ul>
//                 </li>
//               </ul>
//             </li>
//           ))}
//         </ul>
//       )}
//     </div>
//   );
// };

const AddUserForm = () => {
  const customer = useCurrentCustomer();
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    await customerService.AddUserToCustomer(customer.id, { name, email });
    setIsLoading(false);
    history.go(0);
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        border: "1px solid #777",
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <h2>Add User</h2>
      <form onSubmit={handleSubmit} id="add-user-form">
        <input
          placeholder="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          placeholder="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button form="add-user-form" disabled={isLoading}>
          Add User
        </Button>
      </form>
    </div>
  );
};

const CustomerUsersList = () => {
  const customer = useCurrentCustomer();
  const history = useHistory();
  const { user: currentUser } = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!customer) return;
    setIsLoading(true);
    const getUsers = async () => {
      const res = await customerService.GetUsersForCustomer(customer.id);
      setUsers(res);
      setIsLoading(false);
    };
    getUsers();
  }, [customer]);

  const handleDeleteUser = (userId) => async (e) => {
    e.preventDefault();
    await userService.DeleteUser(userId);
    history.go(0);
  };

  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        border: "1px solid #777",
        padding: "20px",
        marginBottom: "20px",
      }}
    >
      <h2>Users</h2>
      <ul>
        {users.map((user) => (
          <li key={user.id}>
            user: {user.name}
            <ul>
              <li>email {user.email}</li>
              <li>
                {user.id == customer.owner_id ? (
                  "cannot delete owner"
                ) : user.id === currentUser.db_user.id ? (
                  "cannot delete current user"
                ) : (
                  <Link to="#" onClick={handleDeleteUser(user.id)}>
                    Delete User
                  </Link>
                )}
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  );
};

export const CustomerAccountPage = () => {
  const options = {
    mode: "setup",
    currency: "usd",
    paymentMethodTypes: ["card"],
  };

  return (
    <MainLayout>
      <Elements stripe={stripePromise} options={options}>
        <div
          style={{
            height: "100%",
            backgroundColor: "#ccc",
            padding: "20px",
            overflowY: "auto",
          }}
        >
          <CustomerUsersList />
          <AddUserForm />
          {/* <SubscriptionList /> */}
          {/* <PlanSelection /> */}
          {/* <InvoiceList /> */}
          {/* <PaymentMethods /> */}
          {/* <SetupForm /> */}
        </div>
      </Elements>
    </MainLayout>
  );
};
