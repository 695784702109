import { Button, Flex } from "antd";
import {
  MAP_TYPE_HYBRID,
  MAP_TYPE_ROADMAP,
  MAP_TYPE_SATELLITE,
  MAP_TYPE_TERRAIN,
} from "utils/defines";

const activeButtonStyle = {
  borderColor: "#1890ff",
  color: "#1890ff",
};

export const MapTypeButtons = ({ activeButton, setUnitsMapType }) => (
  <Flex
    style={{
      position: "absolute",
      top: "58px",
      right: "50px",
      justifyContent: "space-between",
      width: "350px",
    }}
  >
    <Button
      style={activeButton === MAP_TYPE_ROADMAP ? activeButtonStyle : {}}
      onClick={() => setUnitsMapType(MAP_TYPE_ROADMAP)}
    >
      Roadmap
    </Button>
    <Button
      style={activeButton === MAP_TYPE_SATELLITE ? activeButtonStyle : {}}
      onClick={() => setUnitsMapType(MAP_TYPE_SATELLITE)}
    >
      Satellite
    </Button>
    <Button
      style={activeButton === MAP_TYPE_HYBRID ? activeButtonStyle : {}}
      onClick={() => setUnitsMapType(MAP_TYPE_HYBRID)}
    >
      Hybrid
    </Button>
    <Button
      style={activeButton === MAP_TYPE_TERRAIN ? activeButtonStyle : {}}
      onClick={() => setUnitsMapType(MAP_TYPE_TERRAIN)}
    >
      Terrain
    </Button>
  </Flex>
);
