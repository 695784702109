import { Icon } from "common";
import { IconButton } from "./chartStyles";
import { ICON_SIZE_MD } from "utils/defines";

const LineChartButton = ({
  button,
  iconButtonStyle = {},
  iconStyle = {},
  isSidebarButton = false,
}) => {
  const iconStyling = {
    ...iconStyle,
  };

  return (
    <>
      <IconButton
        selected={isSidebarButton ? false : button.selected}
        onClick={button.onClick || null}
        onMouseEnter={button.onMouseEnter || null}
        onMouseLeave={button.onMouseLeave || null}
        disabled={button.disabled || false}
        sidebarButton={isSidebarButton}
        style={{
          ...iconButtonStyle,
          margin: isSidebarButton && button.selected && "0px 6px -2px 0px",
          borderBottom:
            isSidebarButton && button.selected && "1px solid #444444",
        }}
      >
        <Icon
          style={iconStyling}
          hostedImage={button.hostedURL}
          width={ICON_SIZE_MD}
          height={ICON_SIZE_MD}
        />
      </IconButton>
    </>
  );
};

export default LineChartButton;
