// an attempt to optimize this app, to stop issues with rerendering markers. still doesn't stop children from updating though.
import { Component } from "react";

class MapMarker extends Component {
  render() {
    // this probably should be function-ized or better solution
    // in reality the problem this is trying to fix is that <MapMarker> just needs to
    // be any react component; the previous solution using <div> returned warnings
    // because React was passing React-type props to the DOM element
    let { onClick, style, children } = this.props;
    return (
      <div onClick={onClick} style={style}>
        {children}
      </div>
    );
  }
}
export default MapMarker;
