import { useState, useRef, useEffect } from "react";
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  StyledTable,
  StyledTr,
  StyledTh,
  StyledTd,
  HeaderResizeHandle,
  StyledThInner,
  TableWrap,
  TableUpper,
  TableUpperPrimary,
  TableUpperSecondary,
  TableLower,
} from "./TableComponents";
import { Button, TransparentButton } from "../Button";

export const Table = ({
  columns,
  data,
  title = null,
  onAddNew = null,
  onRowSelected = null,
  showFilterInput,
  searchQuery = null,
  pagination = false,
  defaultSortOrder = {}, // default sort by "id" & "bool of asc/desc"
}) => {
  const [sorting, setSorting] = useState([defaultSortOrder]);
  const [filtering, setFiltering] = useState("");
  const currentColOrder = useRef();
  const tableOptions = {
    columns,
    data,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel({ manualPagination: false }),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  };
  const paginationDisabledOptions = {
    pageCount: -1, // set to -1 if we "don't know how many pages to expect"
    manualPagination: true, // if we disable pagination, we're manually taking over
  };
  const fullTableOptions = pagination
    ? tableOptions // pagination enabled by default
    : { ...tableOptions, ...paginationDisabledOptions }; // disable pagination
  const tableInstance = useReactTable(fullTableOptions);
  // Drag and drop animation styling
  const getItemStyle = ({ isDragging, isDropAnimating }, draggableStyle) => {
    let styles = {
      ...draggableStyle,
      userSelect: "none",
      background: isDragging ? "#c8c8c8" : "none",
    };

    // prevent columns from jumping during drag
    if (!isDragging) {
      styles = { ...styles, ...{ transform: "translate(0,0)" } };
    }

    // cut the "dropping" animation short
    if (isDropAnimating) {
      styles = { ...styles, ...{ transitionDuration: "0.001s" } };
    }

    return styles;
  };

  useEffect(() => {
    setFiltering(searchQuery);
  }, [searchQuery]);

  return (
    <TableWrap>
      <TableUpper>
        <TableUpperPrimary>{title && <h3>{title}</h3>}</TableUpperPrimary>
        <TableUpperSecondary>
          {showFilterInput && (
            <input
              type="text"
              placeholder="Filter Rows"
              value={filtering}
              onChange={(e) => setFiltering(e.target.value)}
            />
          )}
          {/* TODO: Rework Sizing */}
          {onAddNew && <Button onClick={onAddNew}>+ Add</Button>}
        </TableUpperSecondary>
      </TableUpper>
      <StyledTable
        style={{
          // width: tableInstance.getCenterTotalSize(),
          width: "100%",
        }}
      >
        <thead>
          {tableInstance.getHeaderGroups().map((headerGroup) => (
            <DragDropContext
              key={headerGroup.id}
              onDragStart={() => {
                currentColOrder.current = tableInstance
                  .getAllLeafColumns()
                  .map((c) => c.id);
              }}
              onDragUpdate={(dragUpdateObj) => {
                const colOrder = [...currentColOrder.current];
                const sIndex = dragUpdateObj.source.index;
                const dIndex =
                  dragUpdateObj.destination && dragUpdateObj.destination.index;

                if (typeof sIndex === "number" && typeof dIndex === "number") {
                  colOrder.splice(sIndex, 1);
                  colOrder.splice(dIndex, 0, dragUpdateObj.draggableId);
                  tableInstance.setColumnOrder(colOrder);
                  // @TODO: persist column order to DB
                }
              }}
            >
              <Droppable
                droppableId="droppable"
                direction="horizontal"
                type="column"
              >
                {(droppableProvided) => (
                  <StyledTr
                    {...droppableProvided.droppableProps}
                    ref={droppableProvided.innerRef}
                    style={{
                      backgroundColor: "#fff",
                    }}
                  >
                    {headerGroup.headers.map((header) => (
                      <Draggable
                        key={header.id}
                        draggableId={header.id}
                        index={header.index}
                      >
                        {(draggableProvided, snapshot) => (
                          <StyledTh
                            key={header.id}
                            style={{
                              width: header.getSize(),
                              zIndex: 3,
                            }}
                          >
                            <StyledThInner
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              ref={draggableProvided.innerRef}
                              onClick={header.column.getToggleSortingHandler()}
                              style={{
                                ...getItemStyle(
                                  snapshot,
                                  draggableProvided.draggableProps.style
                                ),
                              }}
                            >
                              {header.isPlaceholder ? null : (
                                <>
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                  {{ asc: "↑", desc: "↓" }[
                                    header.column.getIsSorted()
                                  ] ?? null}
                                </>
                              )}
                            </StyledThInner>
                            <HeaderResizeHandle
                              onMouseDown={header.getResizeHandler()}
                              onTouchStart={header.getResizeHandler()}
                            />
                          </StyledTh>
                        )}
                      </Draggable>
                    ))}
                    <th style={{ display: "none" }}>
                      {/* this placeholder is required, but is hidden because it disturbs # of cols when active */}
                      {droppableProvided.placeholder}
                    </th>
                  </StyledTr>
                )}
              </Droppable>
            </DragDropContext>
          ))}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map((row, r_index) => (
            <StyledTr
              key={r_index}
              onClick={onRowSelected && (() => onRowSelected(row.original))}
              onRowSelected={onRowSelected}
            >
              {row.getVisibleCells().map((cell, c_index) => (
                <StyledTd key={c_index}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </StyledTd>
              ))}
            </StyledTr>
          ))}
        </tbody>
      </StyledTable>

      <TableLower>
        {pagination && (
          <div style={{ marginTop: "20px" }}>
            <TransparentButton
              onClick={() => tableInstance.setPageIndex(0)}
              style={{ marginRight: "10px" }}
            >
              First
            </TransparentButton>
            <TransparentButton
              disabled={!tableInstance.getCanPreviousPage()}
              onClick={() => tableInstance.previousPage()}
              style={{ marginRight: "10px" }}
            >
              Previous
            </TransparentButton>
            <TransparentButton
              disabled={!tableInstance.getCanNextPage()}
              onClick={() => tableInstance.nextPage()}
              style={{ marginRight: "10px" }}
            >
              Next
            </TransparentButton>
            <TransparentButton
              onClick={() =>
                tableInstance.setPageIndex(tableInstance.getPageCount() - 1)
              }
            >
              Last
            </TransparentButton>
          </div>
        )}
      </TableLower>
    </TableWrap>
  );
};
