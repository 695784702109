import SettingsField from "./SettingsField";

export const Table = ({ changeSettings, getField }) => {
  return (
    <div>
      <SettingsField label="Title">
        <input
          value={getField("title", "")}
          onChange={(e) => changeSettings("title", e.target.value)}
        />
      </SettingsField>
    </div>
  );
};
