import styled from "styled-components";

import { Icon } from "common";
import { Modal } from "common/Modal";
import { ICON_SIZE_SM, S3_CARET_LEFT, S3_CLOSE_X } from "utils/defines";
import { Button } from "common/elements";

export const FooterWrapper = styled.div`
  width: 100%;
  display: flex;
`;

export const FooterLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const FieldLabel = styled.label`
  font-size: 20px;
  left: 65px;
  transform(35px, 41px);
`;

export const CloseIcon = () => (
  <Icon
    style={{ filter: "invert(1)", marginRight: "6px" }}
    width={ICON_SIZE_SM}
    height={ICON_SIZE_SM}
    hostedImage={S3_CLOSE_X}
  />
);

export const ConfirmationModal = ({ show, hide, children }) => {
  return (
    <Modal
      className="delete-modal"
      show={show}
      onHide={hide}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0px",
          }}
        >
          <h4>Are you sure?</h4>
        </div>
        <FooterWrapper>
          <FooterLeft>
            <Icon
              style={{ marginRight: "6px", cursor: "pointer" }}
              width={ICON_SIZE_SM}
              height={ICON_SIZE_SM}
              hostedImage={S3_CARET_LEFT}
              onClick={hide}
            />
            Continue Editing
          </FooterLeft>
          {children}
        </FooterWrapper>
      </Modal.Body>
    </Modal>
  );
};

export const TileConfirmationModal = ({
  showFlag,
  hideAction,
  clickAction,
  buttonText,
  bgColor,
}) => {
  return (
    <ConfirmationModal show={showFlag} hide={hideAction}>
      <Button danger onClick={clickAction} bgColor={bgColor}>
        {/* <CloseIcon /> */}
        {buttonText}
      </Button>
    </ConfirmationModal>
  );
};
