import { configureStore } from "@reduxjs/toolkit";

import { default as productSNDataReducer } from "features/units/redux/productSNDataSlice";
import { default as userReducer } from "features/users/redux/userSlice";
import { default as productsReducer } from "features/applications/redux/productsSlice";
import { default as mapReducer } from "features/map/redux/mapSlice";
import { default as layoutReducer } from "features/grid-layout/redux/layoutSlice";
import { default as widgetReducer } from "features/widgets/redux/widgetsSlice";
import { default as eventReducer } from "features/events/redux/eventsSlice";
import { default as tcuReducer } from "features/tcu/redux/tcuSlice";

import { tcuApi } from "features/tcu/redux";
import { eventsApi } from "features/events/redux";
import { widgetsApi } from "features/widgets/redux";
import { changelogApi } from "features/changelog/redux";
import { usersApi } from "features/users/redux";
import { applicationsApi } from "features/applications/redux";
import { applicationUnitsApi } from "features/units/redux";
import { customersApi } from "features/customers/redux/customersApi";
import {
  applicationDevicesApi,
  applicationDeviceInstancesApi,
} from "features/application-devices/redux";
import { layoutsApi } from "features/grid-layout/redux";

export default configureStore({
  reducer: {
    layout: layoutReducer,
    map: mapReducer,
    products: productsReducer,
    productSNData: productSNDataReducer,
    user: userReducer,
    widget: widgetReducer,
    events: eventReducer,
    tcu: tcuReducer,
    [tcuApi.reducerPath]: tcuApi.reducer,
    [eventsApi.reducerPath]: eventsApi.reducer,
    [applicationDeviceInstancesApi.reducerPath]:
      applicationDeviceInstancesApi.reducer,
    [widgetsApi.reducerPath]: widgetsApi.reducer,
    [changelogApi.reducerPath]: changelogApi.reducer,
    [usersApi.reducerPath]: usersApi.reducer,
    [applicationsApi.reducerPath]: applicationsApi.reducer,
    [applicationUnitsApi.reducerPath]: applicationUnitsApi.reducer,
    [customersApi.reducerPath]: customersApi.reducer,
    [applicationDevicesApi.reducerPath]: applicationDevicesApi.reducer,
    [layoutsApi.reducerPath]: layoutsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(tcuApi.middleware)
      .concat(eventsApi.middleware)
      .concat(applicationDeviceInstancesApi.middleware)
      .concat(widgetsApi.middleware)
      .concat(changelogApi.middleware)
      .concat(usersApi.middleware)
      .concat(applicationsApi.middleware)
      .concat(applicationUnitsApi.middleware)
      .concat(customersApi.middleware)
      .concat(applicationDevicesApi.middleware)
      .concat(layoutsApi.middleware),
});
