import styled from "styled-components";
import { formatNumber, getTimeSince } from "utils";
import { useEffect, useState } from "react";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 5px;
`;

const Value = styled.div`
  font-weight: 200;
  font-size: ${(props) => (props.isMapModalLayout ? "18px" : "25px")};
  color: ${(props) =>
    props.color === "blue"
      ? props.theme.blueNumberColor
      : props.theme.staflOrange};
`;

const SignalName = styled.div`
  font-size: ${(props) => (props.isMapModalLayout ? "15px" : "20px")};
  font-weight: 200;
  text-align: center;
  padding: 4px 8px;
`;

const DividerBar = styled.div`
  width: 100px;
  border-bottom: 2px solid grey;
`;

// dry
const TextBoxMeta = styled.span`
  font-size: 12px; // 85%;
  color: #aaa;
`;

export const MinMaxDisplay = ({
  instance_signals,
  dataHistory,
  currentProduct: currentProductSN,
  isMapModalLayout, // from Widget
  getSettingsField,
}) => {
  const [staleDataCounter, setStaleDataCounter] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setStaleDataCounter(staleDataCounter < 100 ? staleDataCounter + 1 : 0);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [staleDataCounter]);

  // assumes two signals: Min [signal] and Max [signal]
  // should check if theyre matching; but exit if not 2 signals
  if (instance_signals.length !== 2) return "This module requires two signals.";

  // extract the InstanceSignals from each
  // there should be a more elegant way
  let IS1 = instance_signals[0].IS;
  let IS2 = instance_signals[1].IS;

  // extract the data values from each
  // might be rough temporarily, need a more robust way
  const getDataPointForSignal = (instance_sig) => {
    const dataHistoryForSN = dataHistory[currentProductSN];
    if (!dataHistoryForSN) return null;
    const dataHistoryForSNForSignal = dataHistoryForSN[instance_sig.signal.id];
    if (!dataHistoryForSNForSignal) return null;
    let dataPoint =
      dataHistoryForSNForSignal[dataHistoryForSNForSignal.length - 1];
    return dataPoint ? dataPoint : null;
  };
  let value1 = getDataPointForSignal(IS1)
    ? getDataPointForSignal(IS1).data_value
    : null;
  let value2 = getDataPointForSignal(IS2)
    ? getDataPointForSignal(IS2).data_value
    : null;

  const tsr1 = getDataPointForSignal(IS1)
    ? getDataPointForSignal(IS1).timestamp_recorded
    : null;
  const tsr2 = getDataPointForSignal(IS1)
    ? getDataPointForSignal(IS1).timestamp_recorded
    : null;

  // format for display
  let signalValue1 = formatNumber(value1, IS1.getPrecision());
  let unitSymbol1 = IS1.getUnitSymbol();
  let signalValue2 = formatNumber(value2, IS2.getPrecision());
  let unitSymbol2 = IS2.getUnitSymbol();

  // combine values and units, or null
  let combo1 =
    value1 !== null
      ? signalValue1 + " " + (unitSymbol1 ? unitSymbol1 : "")
      : "- -";
  let combo2 =
    value2 !== null
      ? signalValue2 + " " + (unitSymbol2 ? unitSymbol2 : "")
      : "- -";

  // temporary: assume IS1 and IS2 are related, and use IS1 signal name
  // force format to "Max/Min [Signal]" via replacements
  let signalName = IS1.getSignalName() || "";
  let widgetTitle = getSettingsField("widgetTitle");
  let labelSignalMaxMin = (signalName) =>
    `Max/Min ${signalName.replace("Min", "").replace("Max", "")}`;

  return (
    <Wrapper>
      <Value isMapModalLayout={isMapModalLayout} color={"orange"}>
        {combo1}
      </Value>
      <DividerBar />
      <Value isMapModalLayout={isMapModalLayout} color={"blue"}>
        {combo2}
      </Value>
      <SignalName isMapModalLayout={isMapModalLayout}>
        {widgetTitle ? widgetTitle : labelSignalMaxMin(signalName)}
      </SignalName>
      {tsr1 && !isMapModalLayout ? (
        <TextBoxMeta>{getTimeSince(tsr1)}</TextBoxMeta>
      ) : null}
    </Wrapper>
  );
};
