import { useEffect, useState } from "react";
import { Modal, Form, Input, Alert, Select } from "antd";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import {
  useCreateApplicationMutation,
  useUpdateApplicationMutation,
} from "features/applications/redux";
import { Spinner } from "common/Spinner";
import { batch } from "react-redux";

export const ApplicationsFormModal = ({
  form,
  title,
  qApplication,
  qCustomers,
  refetch,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  isNewForm = false,
  isEditForm = false,
}) => {
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  /**
   * Endpoints
   */
  const [createApplication] = useCreateApplicationMutation();
  const [updateApplication] = useUpdateApplicationMutation();

  /**
   * Form Values
   */
  const [customersFormSelect, setCustomersFormSelect] = useState([]);

  /**
   * Handle onFinish Event
   * @param {*} values
   * @returns
   */
  const onFinishFormModal = async (values) => {
    const {
      formApplicationName,
      formCustomersSelect,
      formApplicationNumber,
      formApplicationImage,
    } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };

      if (isNewForm && !isEditForm) {
        const selectedCustomer = qCustomers.find(
          (item) => item.id === formCustomersSelect
        );
        result = await createApplication({
          fields: {
            product_name: formApplicationName,
            customer_id: selectedCustomer.id,
          },
        });
      } else if (isEditForm) {
        result = await updateApplication({
          fields: {
            id: qApplication?.id,
            product_name: formApplicationName,
            product_number: formApplicationNumber,
            image: formApplicationImage,
          },
        });
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      const customersSelectOptions = qCustomers?.map((obj) => {
        return {
          value: obj.id,
          label: obj.name,
        };
      });
      setCustomersFormSelect(customersSelectOptions);
    }
  }, [isPageLoaded]);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  if (!isPageLoaded) return <Spinner />;

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender
        getContainer={false}
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-app-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application Name"
          name="formApplicationName"
          initialValue={qApplication?.product_name}
          rules={[
            {
              required: true,
              message: "Missing Application!",
            },
          ]}
        >
          <Input disabled={isOkButtonLoading} />
        </Form.Item>
        {isNewForm ? (
          <>
            <Form.Item
              label="Customer Associated"
              name="formCustomersSelect"
              rules={[
                {
                  required: true,
                  message: "Missing Associated Customer!",
                },
              ]}
            >
              <Select
                disabled={isOkButtonLoading}
                options={customersFormSelect}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        {isEditForm ? (
          <>
            <Form.Item
              label="Application Number"
              name="formApplicationNumber"
              initialValue={qApplication?.product_number}
            >
              <Input disabled={isOkButtonLoading} />
            </Form.Item>
            <Form.Item
              label="Application Image (S3 URL)"
              name="formApplicationImage"
              initialValue={qApplication?.image}
            >
              <Input disabled={isOkButtonLoading} />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
      </Modal>
    </>
  );
};
