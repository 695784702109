import { useState, useEffect } from "react";
import styled from "styled-components";
import { withSize } from "react-sizeme";

import { WidgetTitle, BigNumber } from "./TileComponents";
import { tcuService } from "features/tcu/services";

const DataUsageContainer = styled.div`
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DataUsageRow = styled.div`
  max-width: 400px;
  margin: 0 auto;
  display: flex;
  &:first-of-type {
    margin-bottom: 20px;
  }
  &.compressed {
    display: block;
  }
`;

const BigNumberWrapper = styled.div`
  text-align: right;
  margin: 0 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  &.compressed {
    text-align: center;
    align-items: center;
  }
`;

const ModifiedBigNumber = styled(BigNumber)`
  font-size: 3em;
  & span.units {
    font-size: 15px;
    margin-left: 5px;
  }
  &.compressed {
    font-size: 2em;
  }
`;

const NumberLabel = styled.div`
  text-align: left;
  padding-left: 5px;
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  &.compressed {
    text-align: center;
    width: 100%;
  }
`;

const ModifiedWidgetTitle = styled(WidgetTitle)`
  text-align: left;
  font-size: 18px;
  font-weight: 300;
  &.compressed {
    text-align: center;
  }
`;

const calculateTotalFromData = (data) => {
  if (!data) return 0;
  return data.reduce((acc, el) => {
    return acc + el.bytes;
  }, 0);
};

const DashboardDataWidget = ({ size }) => {
  const SIZE_BREAKPOINT = 320;
  const sizeClassName = size.width < SIZE_BREAKPOINT ? "compressed" : "";

  const [data, setData] = useState(null);
  const totalData = data
    ? (calculateTotalFromData(data) / (1024 * 1024.0)).toFixed(2)
    : 0;
  const numDevices = data ? data.length : 1;

  const fetchData = async () => {
    const dataFromAllTCUs = await tcuService.GetFleetUsageThisMonth();
    const dataFromConnectedTCUs = dataFromAllTCUs.filter(
      (device) => device.product_sn_id
    );
    setData(dataFromConnectedTCUs);
  };

  // fetch data on render
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <DataUsageContainer className={sizeClassName}>
      <div>
        <DataUsageRow className={sizeClassName}>
          <BigNumberWrapper className={sizeClassName}>
            <ModifiedBigNumber className={sizeClassName}>
              {totalData}
              <span className="units">MB</span>
            </ModifiedBigNumber>
          </BigNumberWrapper>
          <NumberLabel className={sizeClassName}>
            <ModifiedWidgetTitle className={sizeClassName}>
              Total Data Used This Month
            </ModifiedWidgetTitle>
          </NumberLabel>
        </DataUsageRow>
        <DataUsageRow className={sizeClassName}>
          <BigNumberWrapper className={sizeClassName}>
            <ModifiedBigNumber className={sizeClassName}>
              {(totalData / numDevices).toFixed(2)}
              <span className="units">MB</span>
            </ModifiedBigNumber>
          </BigNumberWrapper>
          <NumberLabel className={sizeClassName}>
            <ModifiedWidgetTitle className={sizeClassName}>
              Avg per Device
            </ModifiedWidgetTitle>
          </NumberLabel>
        </DataUsageRow>
      </div>
    </DataUsageContainer>
  );
};

export const DashboardDataUsage = withSize()(DashboardDataWidget);
