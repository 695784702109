import { useState, useEffect } from "react";
import styled, { useTheme } from "styled-components";
import Plot from "react-plotly.js";
import { withSize } from "react-sizeme";
import { Spinner } from "common/Spinner";

import * as Defines from "utils/defines";
import { WidgetWrapper, WidgetTitle } from "./TileComponents";
import { tcuService } from "features/tcu/services";

const PlotBody = styled.div`
  height: 100%;
`;

const formatChartData = (results, color) => [
  {
    x: results.map((r) => r.date),
    y: results.map((r) => r.value),
    marker: { color },
    type: "bar",
  },
];

const DataUsageBarChart = ({ size }) => {
  const [chartData, setChartData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const theme = useTheme();
  const barColor = theme.themeSecondary;

  let config = { ...Defines.DEFAULT_BARCHART_CONFIG };
  let layout = {
    //...Defines.DEFAULT_BARCHART_LAYOUT,
    responsive: true,
    width: size.width,
    xaxis: {
      title: {
        text: "Dates",
      },
    },
    yaxis: {
      title: {
        text: "Data (MB)",
      },
    },
  };

  const fetchData = async () => {
    const results = await tcuService.GetRecentDailyFleetUsage();
    const formattedChartData = formatChartData(results, barColor);
    setChartData(formattedChartData);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <WidgetWrapper style={{ height: "100%" }}>
      <WidgetTitle>Recent Data Usage</WidgetTitle>
      <PlotBody>
        {isLoading ? (
          <Spinner />
        ) : (
          <Plot
            style={{ display: "inline-block", height: "100%" }}
            //style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
            layout={layout}
            config={config}
            useResizeHandler={true}
            data={chartData}
          />
        )}
      </PlotBody>
    </WidgetWrapper>
  );
};

export const DashboardDataUsageBarChart = withSize()(DataUsageBarChart);
