import styled from "styled-components";

export const FetchingIndicator = styled.div`
  margin-right: 15px;
  padding: 5px;
`;

export const IconButton = styled.button`
  display: flex;
  background-color: ${({ selected }) => (selected ? "rgba(68,68,68,.15)" : "")};
  border-radius: ${({ sidebarButton }) => (sidebarButton ? "none" : "5px")};
  border: ${({ selected }) =>
    selected ? "1px solid rgba(68,68,68,.3)" : "none"};
  disabled: ${({ disabled = false }) => disabled};
  margin: ${({ selected }) => (selected ? "0px 5px -1px -1px" : "0 6px 0 0")};
  opacity: ${({ disabled = false }) => (disabled ? ".2" : "1")};
  padding: 5px;
`;

export const IconButtonActionsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChartAndSidebarContainer = styled.div`
  overflow-x: hidden;
  height: 100%;
`;

export const LabelToolTip = styled.span`
  display: flex;
  flex: 1;
  align-items: center;
`;

export const LineChartBody = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex: 1;
`;

export const LineChartOuter = styled.div`
  align-items: stretch;
  display: flex;
  height: 100%;
`;

export const LineChartToolBar = styled.div`
  align-items: center;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  margin: 5px;
  padding: 5px 10px 0 10px;
`;

export const SlideInAnimation = styled.div`
  transform: translateX(0);
  transition: all 3s ease;
`;

export const SlideOutAnimation = styled.div`
  transform: translateX(-100%);
  transition: all 3s ease;
`;

export const StyledTooltip = styled.div`
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 5px;
  font-size: 11px;
  left: 0;
  opacity: 0;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  width: 200px;
`;

export const TimeControlsContainer = styled.div`
  display: flex;
  position: relative;
`;

export const TimeSelector = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #e8e8e8;
  box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.5);
  display: ${({ show }) => (show ? "flex" : "none")};
  flex-direction: column;
  height: 180px;
  padding: 10px;
  position: absolute;
  right: 0;
  top: 100%;
  width: 450px;
  z-index: 2;
`;

export const TimeSelectorAbsolute = styled.div``;

export const TimeSelectorClickCatcher = styled.div`
  display: ${({ active }) => (active ? "block" : "none")};
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

export const TimeSelectorDropdown = styled.div`
  align-items: center;
  cursor: pointer;
  display: ${({ enabled }) => (enabled ? "flex" : "none")};
  justify-content: center;
  margin-right: 10px;
  white-space: nowrap;
`;

export const TimeSelectorRelative = styled.div`
  padding: 5px;
  width: 100%;
`;
