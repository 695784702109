import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { Checkbox } from "./FormComponents";
import { DateTime } from "common";

const DateTimePicker = styled(DateTime)`
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  margin-left: 8px;
`;

const NoStyleButton = styled.button`
  display: block;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  &:hover {
    text-decoration: underline;
  }
`;

const ApplyTimeRangeButton = styled.button`
  border: 1px solid #ccc;
  margin: 5px 10px 5px 0;
  display: inline-block;
  background-color: #f0f0f0;
`;

export const TimeSelect = ({
  liveTracking,
  setLiveTracking,
  defaultHistorical,
  setHistorical,
  setRange: setParentRange,
  rangeStart: rangeStartFromParent,
  rangeEnd: rangeEndFromParent,
  fetchMode,
  setFetchMode,
}) => {
  const [rangeSelectorVisible, setRangeSelectorVisible] = useState(false);
  const [historical, setIsHistorical] = useState(true);
  const [historicalTime, setHistoricalTime] = useState(defaultHistorical || 5);
  const [internalRangeStart, setInternalRangeStart] = useState(
    rangeStartFromParent || new moment().subtract(5, "minutes")
  );
  const [internalRangeEnd, setInternalRangeEnd] = useState(
    rangeEndFromParent || new moment()
  );

  const historicalOptions = [
    { label: "30 seconds", value: "0.5" },
    { label: "1 minute", value: "1" },
    { label: "5 minutes", value: "5" },
    { label: "15 minutes", value: "15" },
    { label: "30 minutes", value: "30" },
    { label: "60 minutes", value: "60" },
    { label: "2 hours", value: "120" },
    { label: "12 hours", value: "720" },
    { label: "1 day", value: "1440" },
  ];

  useEffect(() => {
    setInternalRangeStart(internalRangeStart);
    setInternalRangeEnd(internalRangeEnd);
  }, [
    rangeStartFromParent,
    rangeEndFromParent,
    internalRangeStart,
    internalRangeEnd,
  ]);

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "space-between",
        minWidth: "300px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        background: "#f0f0f0",
        padding: "10px",
        margin: "5px",
      }}
    >
      <div onClick={() => setRangeSelectorVisible(!rangeSelectorVisible)}>
        <strong>
          {fetchMode === "relative"
            ? `Last ${
                historicalOptions.find(
                  (opt) => parseFloat(opt.value) === parseFloat(historicalTime)
                ).label
              }`
            : `${moment(internalRangeStart).format("lll")} to ${moment(
                internalRangeEnd
              ).format("lll")}`}
        </strong>
      </div>
      {rangeSelectorVisible && (
        <div
          style={{
            position: "absolute",
            right: "0",
            zIndex: 1000,
            background: "#fff",
            border: "1px solid #ccc",
            minWidth: "600px",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <div style={{ display: "flex" }}>
            <div
              style={{ borderRight: "1px solid #ccc", paddingRight: "30px" }}
            >
              <div style={{ fontWeight: "bold" }}>Relative Range</div>
              {historicalOptions.map((opt, i) => (
                <NoStyleButton
                  key={i}
                  onClick={() => {
                    setIsHistorical(true);
                    setFetchMode("relative");
                    setHistoricalTime(opt.value); // set internal selected state
                    setHistorical(opt.value); // send value to parent
                    setRangeSelectorVisible(false); // hide modal on click
                    let newStart = new moment().subtract(
                      parseFloat(opt.value),
                      "minutes"
                    );
                    let newEnd = new moment();
                    setParentRange(newStart, newEnd);
                    setInternalRangeStart(newStart);
                    setInternalRangeEnd(newEnd);
                    setLiveTracking(true);
                  }}
                >
                  Last {opt.label}
                </NoStyleButton>
              ))}
            </div>
            <div style={{ flex: "1", marginLeft: "30px" }}>
              <div style={{ fontWeight: "bold" }}>Absolute Range</div>
              <div style={{ display: "flex", alignItems: "flex-start" }}>
                <DateTimePicker
                  // className={historical ? "rdt-isInactive" : "rdt-isActive"}
                  style={{ marginLeft: "-8px" }}
                  className="rdt-isActive"
                  value={internalRangeStart}
                  onChange={setInternalRangeStart}
                />
                <span>to </span>
                <DateTimePicker
                  // className={historical ? "rdt-isInactive" : "rdt-isActive"}
                  className="rdt-isActive"
                  value={internalRangeEnd}
                  onChange={setInternalRangeEnd}
                />
              </div>
              <div>
                <ApplyTimeRangeButton
                  onClick={() => {
                    setFetchMode("absolute");
                    setIsHistorical(false);
                    setParentRange(
                      new moment(internalRangeStart),
                      new moment(internalRangeEnd)
                    );
                    setRangeSelectorVisible(false);
                  }}
                >
                  Apply Time Range
                </ApplyTimeRangeButton>
                <NoStyleButton
                  style={{ marginTop: "5px" }}
                  onClick={() => {
                    setRangeSelectorVisible(false);
                  }}
                >
                  Cancel
                </NoStyleButton>
              </div>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          marginLeft: "40px",
          display: fetchMode === "relative" ? "block" : "none",
        }}
      >
        <Checkbox
          checked={liveTracking}
          onChange={(e) => setLiveTracking(e.target.checked)}
        >
          Auto Refresh
        </Checkbox>
      </div>
      {/* HIDING FOR DEBUGGING 20220522 <div style={{ marginLeft: "40px" }}>
        <div
          style={{
            display: "inline-block",
            cursor: "pointer",
            width: "30px",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "1rem",
            fontWeight: "bold",
          }}
          onClick={() => {
            let diff = new moment(rangeEndFromParent) - new moment(rangeStartFromParent);
            let newStart = new moment(rangeStartFromParent - diff);
            let newEnd = new moment(rangeStartFromParent);
            setFetchMode("absolute");
            setParentRange(new moment(newStart), new moment(newEnd));
            setInternalRangeStart(newStart);
            setInternalRangeEnd(newEnd);
          }}
        >
          ‹
        </div>
        <div
          style={{
            display: "inline-block",
            cursor: "pointer",
            width: "30px",
            textAlign: "center",
            fontSize: "20px",
            lineHeight: "1rem",
            fontWeight: "bold",
          }}
          onClick={() => {
            let diff = new moment(rangeEndFromParent) - new moment(rangeStartFromParent);
            let newStart = new moment(rangeEndFromParent);
            let newEnd = new moment(rangeEndFromParent) + (diff);
            setFetchMode("absolute");
            setParentRange(new moment(newStart), new moment(newEnd));
            setInternalRangeStart(newStart);
            setInternalRangeEnd(newEnd);
          }}
        >
          ›
        </div>
        <div
          style={{
            display: "inline-block",
            cursor: "pointer",
            width: "30px",
            textAlign: "center",
          }}
          onClick={() => {
            let diff = new moment(rangeEndFromParent) - new moment(rangeStartFromParent);
            let newStart = new moment(rangeStartFromParent) + (diff / 3);
            let newEnd = new moment(rangeEndFromParent) - (diff / 3);
            setFetchMode("absolute");
            setParentRange(new moment(newStart), new moment(newEnd));
            setInternalRangeStart(newStart);
            setInternalRangeEnd(newEnd);
          }}
        >
          <img alt="Zoom in" src="https://fleet-assets.s3.us-west-1.amazonaws.com/images/zoom-in.png"/>
        </div>
        <div
          style={{
            display: "inline-block",
            cursor: "pointer",
            width: "30px",
            textAlign: "center",
          }}
          onClick={() => {
            let diff = new moment(rangeEndFromParent) - new moment(rangeStartFromParent);
            let newStart = new moment(rangeStartFromParent) - (diff / 3);
            let newEnd = new moment(rangeEndFromParent) + (diff / 3);
            setFetchMode("absolute");
            setParentRange(new moment(newStart), new moment(newEnd));
            setInternalRangeStart(newStart);
            setInternalRangeEnd(newEnd);
          }}
        >
          <img alt="Zoom out" src="https://img.icons8.com/material-outlined/24/000000/zoom-out.png"/>
        </div>
      </div> */}
    </div>
  );
};
