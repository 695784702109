import { PubSub } from "aws-amplify";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import {
  useGetAvailableWidgetsForCustomerQuery,
  useGetWidgetTypesForCustomerQuery,
} from "features/widgets/redux";

const sortByWidgetName = (a, b) => {
  if (a.name < b.name) return -1;
  if (a.name > b.name) return 1;
  return 0;
};

export const useAvailableWidgetsOfType = (widgetTypeName) => {
  const customerId = useCustomerId();
  const { data: availableWidgets } =
    useGetAvailableWidgetsForCustomerQuery(customerId);
  const { data: widgetTypes } = useGetWidgetTypesForCustomerQuery(customerId);
  const allWidgetTypeObj = widgetTypes.find(
    (type) => type.name.toLowerCase() === "all"
  );
  const specifiedWidgetTypeObj = widgetTypes.find(
    (type) => type.name.toLowerCase() === widgetTypeName.toLowerCase()
  );
  const validWidgetTypes = [allWidgetTypeObj.id, specifiedWidgetTypeObj.id];
  const validWidgets = availableWidgets
    ? availableWidgets.filter((widget) =>
        validWidgetTypes.includes(widget.widget_type_id)
      )
    : [];
  const sortedWidgets = validWidgets.sort(sortByWidgetName);
  return sortedWidgets;
};

export const useMqttSubscription = ({
  topic,
  onMessageReceived = () => {},
  onMessageError = (error) => console.error(error),
  onSubscriptionComplete = () => console.log("Done"),
}) => {
  // connect to MQTT
  const subscribeToMqtt = (topic) => () => {
    console.log(`subscribing to ${topic}`);
    subscription = PubSub.subscribe(topic).subscribe({
      next: onMessageReceived,
      error: onMessageError,
      complete: onSubscriptionComplete,
    });
  };

  // disconnect
  const unsubscribeFromMqtt = () => {
    console.log(`unsubscribe from mqtt`);
    subscription.unsubscribe();
  };

  // perform subscription
  let subscription = {
    subscribe: subscribeToMqtt(topic),
    unsubscribe: unsubscribeFromMqtt,
  };

  return subscription;
};
