import { Table } from "antd";
import Column from "antd/es/table/Column";

export const CustomerSummaryTable = ({ customer }) => {
  return (
    <Table dataSource={[customer]}>
      <Column title="Name" dataIndex="name" key="name" />
      <Column
        title="Primary Color"
        dataIndex="color_primary"
        key="color_primary"
      />
      <Column
        title="Secondary Color"
        dataIndex="color_secondary"
        key="color_secondary"
      />
      <Column title="Owner Name" dataIndex="owner_name" key="owner_name" />
      {/* <Column title="Is Verified" dataIndex="is_verified" key="is_verified" /> */}
    </Table>
  );
};
