import { Table } from "antd";
import Column from "antd/es/table/Column";

export const ApplicationUnitsSummaryTable = ({ applicationUnits }) => {
  return (
    <Table dataSource={applicationUnits}>
      <Column
        title="Product SN Name"
        dataIndex="product_sn_name"
        key="product_sn_name"
      />
      <Column
        title="Application Name"
        dataIndex="application_name"
        key="application_name"
      />
    </Table>
  );
};
