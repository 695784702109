import { useState, useEffect } from "react";
import styled from "styled-components";
// import Plot from "react-plotly.js";
import { NavLink } from "react-router-dom";
import { axios } from "axios/axios";
import { useDispatch } from "react-redux";
import { Icon } from "common";
import { UploadImageModal } from "common";
import { productService } from "features/applications/services";
import { ProductSNService } from "features/units/services";
import { uploadS3 } from "utils/uploadS3";
import { Button } from "common/elements";
import { setCurrentProduct } from "features/applications/redux/productsSlice";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetTCUsForCustomerQuery } from "features/tcu/redux";
import * as Defines from "utils/defines";
import { Flex, Skeleton } from "antd";

const FormLabel = styled.label`
  opacity: ${({ viewMode }) => viewMode === "retired" && 0.2};
  width: 200px;
`;

const TCUInfo = styled.span`
  display: inline;
  overflow-wrap: break-word;
  width: 150px;
`;

const TCUInfoContainer = styled.div`
  display: flex;
`;

const TCUInfoLabel = styled.span`
  display: inline-block;
  width: 200px;
`;

const Wrapper = styled.div`
  background-color: #f0f0f0;
  height: 100%;
  overflow-y: auto;
`;

// const UsageChart = ({ layout, config, data, viewMode }) => {
//   return (
//     <div style={{ padding: "15px", opacity: viewMode === "retired" && 0.2 }}>
//       <Plot
//         config={config}
//         data={data}
//         layout={layout}
//         style={{
//           display: "inline-block",
//           height: "300px",
//           position: "relative",
//           width: "100%",
//         }}
//       />
//     </div>
//   );
// };

export const ApplicationUnitDetail = ({
  handleSelectedSN,
  product,
  productSN,
  refetchAllArchivedSNs,
  refetchAllProductSNs,
  viewMode,
}) => {
  const customer_id = useCustomerId();
  const { data: allTCUs } = useGetTCUsForCustomerQuery(customer_id);
  const { refetch: refetchSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customer_id,
  });
  const [isSNFormPristine, setIsSNFormPristine] = useState(true);
  const [isEditingSNImage, setIsEditingSNImage] = useState(false);
  const [viewableSNImage, setViewableSNImage] = useState(
    productSN ? productSN.image : null
  );
  const dispatch = useDispatch();
  const tcuForSN =
    productSN && allTCUs?.length
      ? allTCUs.find((tcu) => tcu.product_sn_id === productSN.id)
      : null;
  const showUnitLive = (marker) => dispatch(setCurrentProduct(marker));
  const emptySNForm = () => ({
    is_archived: "",
    product_sn_name: "",
    serial_number: "",
  });
  const [snForm, setSNFormState] = useState({ ...emptySNForm(), ...productSN });
  const updateSNField = (e) => {
    setSNFormState({
      ...snForm,
      [e.target.name]: e.target.value,
    });
    setIsSNFormPristine(false);
  };

  useEffect(() => {
    if (!productSN) return;
    setSNFormState({ ...emptySNForm(), ...productSN });
    setViewableSNImage(productSN.image);
  }, [productSN]);

  const onSNFormSubmit = () => {
    let req = snForm.id
      ? axios.put("/api/product_sns", { product_sn: snForm })
      : axios.post("/api/product_sns", { product_sn: snForm });
    req
      .then(() => handleSelectedSN(snForm))
      .then(() => {
        productService
          .index(customer_id)
          .then((res) => res.products)
          .then(() => {
            refetchAllArchivedSNs();
            refetchAllProductSNs();
            setIsSNFormPristine(true);
          });
      });
  };

  const onSNFormDelete = () => {
    axios
      .delete("/api/product_sns", { data: { product_sn_id: snForm.id } })
      .then(() => {
        productService.index(customer_id).then((res) => res.products);
      })
      .then(() => handleSelectedSN(null));
  };

  const onSNImageUpload = async (imageFile) => {
    try {
      // 1. get S3 presigned url && update product's database object (both!)
      // todo: prevent re-upload of image on modal submit
      let {
        // user,
        signedRequestUrl,
        assetUrl: imageUrl,
      } = await ProductSNService.uploadImage({
        fileName: imageFile.name,
        fileType: imageFile.type,
        product_sn_id: snForm.id,
      });
      // 2. post to s3 signed url
      await uploadS3(imageFile, signedRequestUrl);
      // 3. update local state
      setViewableSNImage(imageUrl);
      setSNFormState({ ...snForm, image: imageUrl });
      refetchSNs();
    } catch (e) {
      // couldnt update image
      // TODO: better error handling / presentation
      console.error(e);
    }
  };

  if (!productSN) return null;
  // const usageByDay = tcuForSN ? tcuForSN.usageByDay : null;
  // let data = usageByDay
  //   ? [
  //       {
  //         x: usageByDay.map((usage) => usage.date),
  //         y: usageByDay.map((usage) => parseInt(usage.bytes / (1000 * 1000.0))),
  //         type: "bar",
  //       },
  //     ]
  //   : [];
  // let config = { staticPlot: true };
  // let layout = {
  //   paper_bgcolor: "rgba(0,0,0,0)",
  //   plot_bgcolor: "rgba(0,0,0,0)",
  //   margin: {
  //     b: 30,
  //     l: 30,
  //     r: 30,
  //     t: 10,
  //   },
  // };

  // @TODO: swap out default image for gray square
  const defaultImage = product.image ? product.image : Defines.S3_VEHICLE_CAR;
  return (
    <>
      <UploadImageModal
        show={isEditingSNImage}
        hide={() => setIsEditingSNImage(false)}
        onUpload={onSNImageUpload}
        _viewableImage={viewableSNImage}
        _defaultImage={defaultImage}
      />
      <Wrapper>
        <div>
          <div>
            <main
              style={{ padding: "25px", maxWidth: "900px", margin: "0 auto" }}
            >
              <div style={{ textAlign: "right" }}></div>
              <div style={{ display: "flex" }}>
                <h4 style={{ padding: "0", margin: "0" }}>General</h4>
                <span
                  style={{
                    marginLeft: "auto",
                    padding: "10px",
                    fontSize: "16px",
                  }}
                >
                  <NavLink
                    to={"/products"}
                    onClick={() => showUnitLive(snForm.id)}
                    style={{ color: "#555", textDecoration: "none" }}
                  >
                    <Icon
                      hostedImage={Defines.S3_VEHICLE_CAR}
                      width={Defines.ICON_SIZE_XS}
                      height={Defines.ICON_SIZE_XS}
                    />{" "}
                    <span
                      style={{ textDecoration: "underline", color: "#555" }}
                    >
                      View Unit Live
                    </span>
                  </NavLink>
                </span>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ flex: "1", padding: "10px 25px" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormLabel
                      htmlFor="product_sn_name"
                      style={{ width: "200px" }}
                      viewMode={viewMode}
                    >
                      Unit Name
                    </FormLabel>
                    <input
                      disabled={viewMode === Defines.RETIRED}
                      name="product_sn_name"
                      onChange={updateSNField}
                      value={snForm.product_sn_name}
                      style={{
                        flex: "1",
                        opacity: viewMode === Defines.RETIRED && 0.2,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormLabel
                      htmlFor="serial_number"
                      style={{ width: "200px" }}
                      viewMode={viewMode}
                    >
                      Serial Number
                    </FormLabel>
                    <input
                      disabled={viewMode === Defines.RETIRED}
                      name="serial_number"
                      onChange={updateSNField}
                      value={snForm.serial_number}
                      style={{
                        flex: "1",
                        opacity: viewMode === Defines.RETIRED && 0.2,
                      }}
                    />
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormLabel
                      htmlFor="productsn_image"
                      style={{ width: "200px" }}
                      viewMode={viewMode}
                    >
                      Image
                    </FormLabel>
                    <a
                      href="/#"
                      style={{
                        color: "#555",
                        flex: "1",
                        fontSize: "90%",
                        opacity: viewMode === Defines.RETIRED && 0.2,
                        textDecoration: "underline",
                      }}
                      onClick={(e) => {
                        if (viewMode === Defines.RETIRED) return;
                        e.preventDefault();
                        setIsEditingSNImage(!isEditingSNImage);
                      }}
                    >
                      Upload new image
                    </a>
                  </div>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <FormLabel htmlFor="is_archived">Status</FormLabel>
                    <select
                      name="is_archived"
                      onChange={updateSNField}
                      value={snForm.is_archived}
                      style={{ flex: "1" }}
                    >
                      <option
                        value={false}
                        key={Defines.ACTIVE}
                        label={"Active"}
                      >
                        Active
                      </option>
                      <option
                        value={true}
                        key={Defines.RETIRED}
                        label={"Retired"}
                      >
                        Retired
                      </option>
                    </select>
                  </div>
                  <div style={{ paddingLeft: "200px", marginTop: "20px" }}>
                    <Button
                      className="accept"
                      disabled={isSNFormPristine}
                      onClick={onSNFormSubmit}
                    >
                      {snForm.id ? "Update" : "Create"}
                    </Button>
                    {Defines.ENABLE_DELETE_PRODUCT_SN_FUNCTIONS && (
                      <div>
                        <small>
                          <a
                            href="/#"
                            onClick={(e) => {
                              e.preventDefault();
                              onSNFormDelete();
                            }}
                          >
                            delete
                          </a>
                        </small>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <div style={{ width: "300px", height: "200px" }}>
                    {viewableSNImage ? (
                      <img
                        src={viewableSNImage}
                        style={{
                          width: "100%",
                          height: "100%",
                          borderRadius: "5px",
                          objectFit: "contain",
                          opacity: viewMode === Defines.RETIRED && 0.2,
                          filter:
                            viewMode === Defines.RETIRED &&
                            0.2 &&
                            "saturate(0)",
                        }}
                        alt={"product"}
                      />
                    ) : (
                      <Flex
                        align={"center"}
                        justify={"center"}
                        style={{ width: "100%", height: "100%" }}
                      >
                        <Skeleton.Image
                          style={{
                            width: "175px",
                            height: "175px",
                          }}
                        />
                      </Flex>
                    )}
                  </div>
                </div>
              </div>
              {/* Details */}
              <div style={{ display: "flex", marginTop: "30px" }}>
                <div style={{ flex: "1" }}>
                  <h4>TCU</h4>
                  {tcuForSN ? (
                    <div
                      style={{
                        padding: "10px 25px",
                        opacity: viewMode === Defines.RETIRED && 0.2,
                      }}
                    >
                      <TCUInfoContainer>
                        <TCUInfoLabel>Name</TCUInfoLabel>{" "}
                        <TCUInfo
                          style={{
                            display: "inline",
                            overflowWrap: "break-word",
                            width: "150px",
                          }}
                        >
                          {tcuForSN.name}
                        </TCUInfo>
                      </TCUInfoContainer>
                      <TCUInfoContainer>
                        <TCUInfoLabel>Unit Number</TCUInfoLabel>{" "}
                        <TCUInfo>{tcuForSN.unit_number}</TCUInfo>
                      </TCUInfoContainer>
                      <TCUInfoContainer>
                        <TCUInfoLabel>IMEI</TCUInfoLabel>{" "}
                        <TCUInfo>{tcuForSN.imei}</TCUInfo>
                      </TCUInfoContainer>
                      <TCUInfoContainer>
                        <TCUInfoLabel>SIM</TCUInfoLabel>{" "}
                        <TCUInfo>{tcuForSN.sim}</TCUInfo>
                      </TCUInfoContainer>
                    </div>
                  ) : (
                    <span
                      style={{ opacity: viewMode === Defines.RETIRED && 0.2 }}
                    >
                      not paired
                    </span>
                  )}
                </div>
                <div>
                  {tcuForSN && (
                    <div
                      style={{
                        width: "300px",
                        height: "200px",
                        opacity: viewMode === Defines.RETIRED && 0.2,
                        filter:
                          viewMode === Defines.RETIRED && 0.2 && "saturate(0)",
                      }}
                    >
                      <img
                        alt="TCU"
                        src="/assets/images/tcu-20210813.png"
                        style={{ width: "100%", height: "auto" }}
                      />
                    </div>
                  )}
                </div>
              </div>

              {/* Usage Chart */}
              {/* <div style={{ margin: "20px 0" }}>
                <h4 style={{ marginBottom: "20px" }}>Usage Chart</h4>
                {tcuForSN ? (
                  <UsageChart
                    layout={layout}
                    config={config}
                    data={data}
                    viewMode={viewMode}
                  />
                ) : (
                  <p style={{ opacity: viewMode === Defines.RETIRED && 0.2 }}>
                    No TCU connected
                  </p>
                )}
              </div> */}
            </main>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default ApplicationUnitDetail;
