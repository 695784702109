import { theme } from "styles/GlobalStyles";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  container-type: inline-size;
`;
const TextValueSpan = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: baseline;
`;

const Value = styled.div`
  color: ${(props) =>
    props.color
      ? `rgba(${props.color.r}, ${props.color.g}, ${props.color.b}, ${props.color.a})`
      : "black"};
  font-weight: 200;
  width: 100%;
  height: 100%;
`;

const ValueWrap = styled.span`
  overflow-wrap: break-word;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
  width: 100%;
  font-family: "PilatWide";
  font-weight: 100;
  font-size: ${theme.widgetFontSize};
`;

export const AnyText = ({ getSettingsField }) => {
  const textValue = getSettingsField("text", "");
  const color = getSettingsField("color", { r: 0, g: 0, b: 0, a: 1 });
  const maxFontSize = 45;
  return (
    <FreeTextBox
      textValue={textValue}
      color={color}
      maxFontSize={maxFontSize}
    />
  );
};

const FreeTextBox = ({ textValue, color, maxFontSize }) => (
  <Wrapper className="any-text-wrapper">
    <TextValueSpan>
      <Value color={color}>
        <ValueWrap>{textValue}</ValueWrap>
      </Value>
    </TextValueSpan>
  </Wrapper>
);
