import { useState } from "react";
import styled from "styled-components";
import ChangelogModal from "./ChangeLogModal";
import { useGetLatestChangelogVersionQuery } from "features/changelog/redux";

const VersionNumber = styled.p`
  cursor: pointer;
  display: flex;
  text-decoration: none;
  font-size: 12px;
  :hover {
    text-decoration: underline;
  }
`;

const ChangelogVersion = () => {
  const { data: contentfulVersionNumber, isLoading } =
    useGetLatestChangelogVersionQuery();
  const [changelogModalOpen, setChangelogModalOpen] = useState(false);

  return (
    <>
      <ChangelogModal
        show={changelogModalOpen}
        onHide={() => setChangelogModalOpen(false)}
      />
      <VersionNumber onClick={() => setChangelogModalOpen(true)}>
        {isLoading ? "v0.0.0" : `v${contentfulVersionNumber?.versionNumber}`}
      </VersionNumber>
    </>
  );
};

export default ChangelogVersion;
