import styled from "styled-components";

const CELL_PADDING = "8px";
const ROW_BG_ALT_1 = "#dddddd";
const ROW_BG_ALT_2 = "#f2f2f2";
const ROW_BG_HOVER = "#dddddd"; // Change hover color if using hover effects
const TABLE_BG_COLOR = "#ffffff";

export const HeaderResizeHandle = styled.div`
  background: rgba(0, 0, 0, 0);
  cursor: col-resize;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  touch-action: none;
  user-select: none;
  width: 2px;
  &:hover {
    background: rgba(0, 0, 0, 0.3);
    width: 5px;
  }
`;

export const TableWrap = styled.div``;

export const TableUpper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TableUpperPrimary = styled.div`
  flex: 1;
`;

export const TableUpperSecondary = styled.div`
  width: 200px;
`;

export const StyledTable = styled.table`
  background-color: ${TABLE_BG_COLOR};
  border-collapse: collapse;
  position: relative;
  width: 100%;
  // Hover effect in case we want to add it back in
  // & tbody tr:hover {
  // background-color: ${ROW_BG_HOVER};
  // }
`;

export const StyledTh = styled.th`
  background-color: #fff;
  box-shadow: inset 0 -1px 0 gray;
  position: sticky;
  top: 0;
`;

export const StyledThInner = styled.div`
  font-weight: bold;
  padding: ${CELL_PADDING};
`;

export const StyledTd = styled.td`
  border-right: 1px solid darkgray;
  padding: ${CELL_PADDING};
  &:nth-last-child(1) {
    border-right: none;
  }
`;

export const StyledTr = styled.tr`
  cursor: ${({ onRowSelected }) =>
    onRowSelected !== null ? "pointer" : "auto"};
  &:nth-child(odd) {
    background-color: ${ROW_BG_ALT_1};
  }
  &:nth-child(even) {
    background-color: ${ROW_BG_ALT_2};
  }
`;

export const TableLower = styled.div`
  display: flex;
  justify-content: space-between;
`;
