import { useState, useEffect, createContext, ReactNode, useRef } from "react";
import jwt_decode from "jwt-decode";
import storage from "utils/storage";
import { useHistory } from "react-router-dom";
import { userService } from "features/users/services";
import { setUserLogin } from "features/users/redux";
import { useDispatch } from "react-redux";

interface Session {
  accessToken: string | null;
  refreshToken: string | null;
  idToken: string | null;
  expiresIn: number | null;
}
interface AuthValue {
  user: object | null; // @TODO Create user interface
  isLoading: boolean;
  isSuperAdmin: boolean;
  isSignIn: boolean;
  setIsSignIn: (value: boolean) => void;
  signIn: (email: string, password: string) => Promise<any>; // @TODO Replace 'any' with the result type
  signOut: () => Promise<void>;
  refreshAccess: (username: string, refreshToken: any) => Promise<any>; // @TODO Replace 'any' with the result type
  storeSession: (session: any) => Promise<any>;
}

const AuthContext = createContext<AuthValue | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any | null>(null); // @TODO Create 'object' interface
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [isSignIn, setIsSignIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const isMounted = useRef(true);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const getCurrentUser = async () => {
    try {
      const idToken: any = storage.getIdToken();
      let accessToken: any = storage.getAccessToken();
      const decodedUser: any = jwt_decode(idToken); // @TODO Create 'object' interface
      const accessAuthInfo: any = jwt_decode(accessToken); // @TODO Create 'object' interface
      const authTokenExpired = accessAuthInfo?.exp < Date.now() / 1000;

      if (authTokenExpired) {
        storage.clearAllSessionTokens();
        setUser(null);
        console.log("AuthTokenExpired Redirect...");
        history.push("/");
        setIsLoading(false);
        return;
      }

      // Continue as necessary

      // This call should be just for the user obj, not all the login info.
      // Separate this call separately after setUser(dbUser).
      // Can this be called in the App component's componentDidMount?
      // Or when [user] is updated in Redux.
      const dbUser = await userService
        .GetLoginDetails({
          email: decodedUser.email,
        })
        .then((res: any) => {
          // Handle the case where customer_id === null (new signup)
          // Currently, it returns the first customer (usually Stafl company).
          const { user: auth_user, db_user, customers } = res;
          const result = { customers, db_user, auth_user };
          dispatch(setUserLogin(result));
          return result;
        })
        .catch((error: any) => {
          console.error(error);
          return null;
        });

      if (dbUser) {
        if (isMounted.current) {
          setIsSuperAdmin(dbUser.auth_user?.access_level === 1);
          setUser(dbUser);
        }
      }
    } catch (err) {
      if (isMounted.current) {
        setUser(null);
      }
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCurrentUser();
  }, [dispatch]);

  const storeSession = async ({
    accessToken,
    idToken,
    refreshToken,
  }: Session) => {
    if (!accessToken || !idToken || !refreshToken) return;
    storage.storeSession({ idToken, accessToken, refreshToken });
  };

  const signIn = async (email: string, password: string) => {
    const signInResult: any = await userService.Login({ email, password }); // @TODO replace 'any' with interface
    setIsSignIn(true);
    await storeSession(signInResult);
    await getCurrentUser();
    return signInResult;
  };

  const signOut = async () => {
    storage.setLastLocation(history.location.pathname);
    const accessToken: any = storage.getAccessToken();
    const accessAuthInfo: any = jwt_decode(accessToken);

    if (accessToken) {
      try {
        const isTokenExpired = accessAuthInfo?.exp < Date.now() / 1000;

        // if accessToken not expired, expire it using cognito
        if (!isTokenExpired) {
          await userService.Logout();
        }
      } catch (error) {
        console.error("Error during logout:", error);
      }
    }

    setUser(null);
    storage.clearAllSessionTokens();
    history.push("/");
  };

  const refreshAccess = async (username: string, refreshToken: any) => {
    const refreshAccessResult: any = await userService.RefreshAccess({
      username,
      refreshToken,
    });
    return refreshAccessResult;
  };

  const authValue: AuthValue = {
    user,
    isLoading,
    isSuperAdmin,
    isSignIn,
    signIn,
    signOut,
    setIsSignIn,
    refreshAccess,
    storeSession,
  };

  return (
    <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };
