import { Button as AntdButton } from "antd";

// How to deal with a form button's type="submit" conflict
// https://stackoverflow.com/questions/41221633/how-to-submit-form-component-in-modal-dialogue-using-antd-react-component-librar

export const Button = ({
  children,
  disabled = false,
  type = "primary",
  onClick,
  style,
  form,
  ...otherProps
}) => (
  <AntdButton
    type={type}
    disabled={disabled}
    onClick={onClick}
    style={{
      ...style,
    }}
    form={form}
    key={form && "submit"}
    htmlType={form && "submit"}
    {...otherProps}
  >
    <span style={{ fontWeight: 400 }}>{children}</span>
  </AntdButton>
);

export const TransparentButton = ({
  children,
  disabled = false,
  onClick,
  style,
  ...otherProps
}) => (
  <AntdButton
    disabled={disabled}
    onClick={onClick}
    style={{
      ...style,
    }}
    {...otherProps}
  >
    <span style={{ fontWeight: 400 }}>{children}</span>
  </AntdButton>
);

export const BorderlessButton = ({
  children,
  disabled = false,
  onClick,
  style,
  ...otherProps
}) => (
  <AntdButton
    type="text"
    disabled={disabled}
    onClick={onClick}
    style={{
      border: "none",
      boxShadow: "none",
      textDecorationLine: "underline",
      ...style,
    }}
    {...otherProps}
  >
    <span style={{ fontWeight: 400 }}>{children}</span>
  </AntdButton>
);
