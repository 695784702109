import { Button, Card, Form } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import {
  useGetCustomersQuery,
  useGetCustomerUsersQuery,
} from "features/customers/redux";
import { CustomersFormModal, CustomersOverviewTable } from "../components";
import { useGetUsersQuery } from "features/users/redux";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";

const CustomersOverviewManagementContainer = styled.div`
  width: 100%;
`;

export const CustomersOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const {
    isLoading: isQCustomersLoading,
    data: qCustomers,
    refetch: qCustomersRefetch,
  } = useGetCustomersQuery();

  const { isLoading: isQApplicationsLoading, data: qApplications } =
    useGetApplicationsByFilterQuery();

  const { isLoading: isQCustomerUsersLoading, data: qCustomerUsers } =
    useGetCustomerUsersQuery();

  const { data: qUsers, isLoading: isQUsersLoading } = useGetUsersQuery();

  useEffect(() => {
    setIsPageLoaded(
      !isQCustomersLoading &&
        !isQCustomerUsersLoading &&
        !isQUsersLoading &&
        !isQApplicationsLoading
    );
  }, [
    isPageLoaded,
    isQCustomersLoading,
    isQCustomerUsersLoading,
    isQUsersLoading,
    isQApplicationsLoading,
  ]);

  return (
    <CustomersOverviewManagementContainer>
      <Card
        title={
          <span style={{ fontWeight: "bold" }}>{"List of Customers"}</span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={debounce(async () => {
              setIsModalOpen(true);
            }, 100)}
          >
            New Customer
          </Button>
        }
      >
        <CustomersOverviewTable
          qCustomers={qCustomers}
          qCustomerUsers={qCustomerUsers}
          qApplications={qApplications}
          isPageLoaded={isPageLoaded}
        />
      </Card>
      <CustomersFormModal
        form={form}
        title={"New Customer Form Modal"}
        qUsers={qUsers}
        refetch={qCustomersRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewForm={true}
      />
    </CustomersOverviewManagementContainer>
  );
};
