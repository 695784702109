import styled from "styled-components";

export const StyledLabel = styled.label`
  min-width: 200px;
`;

export const InputField = styled.input``;

export const Label = (props) => {
  return <StyledLabel {...props} />;
};
