import { useCurrentSNId } from "features/units/hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetTCUsForCustomerQuery } from "features/tcu/redux";

export const useCurrentTCU = () => {
  const currentSNId = useCurrentSNId();
  const customerId = useCustomerId();
  const { data: tcus } = useGetTCUsForCustomerQuery(customerId);
  const currentTCU = tcus?.length
    ? tcus.find((tcu) => tcu.product_sn_id === currentSNId)
    : null;
  return [currentTCU];
};