import { useState } from "react";
import styled from "styled-components";
import Select from "react-select";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetTCUsForCustomerQuery } from "features/tcu/redux";

const FormRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  & > label {
    width: 80px;
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
  }
`;

const UnitSelectForm = styled.div`
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: 5px;
  margin-bottom: 20px;
`;

const makeUnitSelectOptions = (productSNs, tcus) => {
  if (!tcus?.length || !productSNs?.length) return [];
  return productSNs.map((productSN) => {
    const tcuForSN = tcus.find((tcu) => tcu.product_sn_id === productSN.id);
    const labelForSN = `${productSN.product_sn_name} (${
      tcuForSN ? `${tcuForSN.token}` : `No TCU`
    })`;
    return {
      ...productSN,
      value: `${productSN.id}`,
      label: labelForSN,
    };
  });
};

const ApplicationsPushCommandUnitSelect = ({ onSelected, defaultValue }) => {
  const customerId = useCustomerId();
  const { data: productSNs, isSNsLoading } =
    useGetApplicationUnitsByFilterQuery({ customer_id: customerId });
  const { data: tcus, isTCUsLoading } = useGetTCUsForCustomerQuery(customerId);
  const [selectedSN, setSelectedSN] = useState(defaultValue);
  const handleSelection = (selection) => {
    setSelectedSN(selection);
    onSelected(selection);
  };

  if (isSNsLoading) return <div>Loading Product SNs</div>;
  if (isTCUsLoading) return <div>Loading TCUs</div>;
  if (!productSNs || !productSNs.length) return <div>No ProductSNs</div>;

  const unitOptions = makeUnitSelectOptions(productSNs, tcus);
  return (
    <UnitSelectForm>
      <FormRow>
        <label style={{ width: "150px" }}>Product SN</label>
        <div>
          <Select
            options={unitOptions}
            value={selectedSN}
            onChange={handleSelection}
          />
        </div>
      </FormRow>
    </UnitSelectForm>
  );
};

export default ApplicationsPushCommandUnitSelect;
