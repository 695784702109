import { useRef, useEffect, useState } from "react";
import dayjs from "utils/dayjs";
import * as d3 from "d3";
import * as Defines from "utils/defines";
import { Button } from "common/elements";
import { hexToRgba } from "utils";
import { ReactComponent as MeasureCursorIcon } from "./assets/measure_cursor_icon.svg";
import { ReactComponent as DiffCursorIcon } from "./assets/diff_cursor_icon.svg";
import { StyledTooltip } from "../chartStyles";
import { DatePicker } from "antd";

/** helper function for x-axis tick formatting */
const insertLinebreaks = function (d) {
  let el = d3.select(this);
  const words = el.text().split("\n");
  el.text("");

  for (let i = 0; i < words.length; i++) {
    const tspan = el.append("tspan").text(words[i]);
    if (i > 0) tspan.attr("x", 0).attr("dy", "12");
  }
};

export const CursorLine = ({
  x = 0,
  cursorType,
  height,
  passedRef,
  isLive,
  liveColorFilter,
  indicatorMode,
  onClick,
}) => {
  const CURSOR_HITBOX_WIDTH = 20;
  const centerX = x;
  const cursorClassName =
    cursorType === "measure" ? "cursor-measure" : "cursor-diff";
  const cursorColor = "#444444";
  const indicatorPadding = 15;
  return (
    <g ref={passedRef} className={cursorClassName}>
      {cursorType === "measure" && (
        <svg
          x={centerX - Defines.ICON_SIZE_XL / 2}
          y={-4}
          style={{ filter: isLive && liveColorFilter }}
        >
          <image
            href={Defines.S3_CURSOR_MEASURE}
            width={Defines.ICON_SIZE_XL}
            height={Defines.ICON_SIZE_XL}
          />
        </svg>
      )}
      {cursorType === "diff" && (
        <svg x={centerX - Defines.ICON_SIZE_XL / 2} y={height - 29}>
          <image
            href={Defines.S3_CURSOR_DIFFERENCE}
            width={Defines.ICON_SIZE_XL}
            height={Defines.ICON_SIZE_XL}
          />
        </svg>
      )}
      {indicatorMode && (
        <rect
          x={x - indicatorPadding}
          y={cursorType === "measure" ? 0 : height - 20}
          width={2 * indicatorPadding}
          height={indicatorPadding + 5}
          onClick={onClick}
          style={{
            fillOpacity: 0,
            zIndex: "3",
            cursor: "pointer",
          }}
        />
      )}
      {!indicatorMode && (
        <>
          <line
            x1={x}
            y1={cursorType === "measure" ? 10 : 0}
            x2={x}
            y2={cursorType === "measure" ? height : height - 18}
            style={{
              filter: isLive && liveColorFilter,
              strokeWidth: "3",
              stroke: cursorColor,
              fill: "none",
            }}
          />
          <rect
            x={x - CURSOR_HITBOX_WIDTH / 2}
            y={cursorType === "measure" ? 0 : -12}
            width={CURSOR_HITBOX_WIDTH}
            height={cursorType === "measure" ? height : height + 12}
            style={{
              fill: "cursorColor",
              fillOpacity: 0,
              cursor: "ew-resize",
            }}
          />
        </>
      )}
    </g>
  );
};

export const XAxis = ({
  x = 0,
  y = 0,
  scale,
  plotHeight,
  plotWidth,
  tickColor = "rgb(175,175,175)",
}) => {
  const gRef = useRef(null);
  const timeDiff = scale.invert(plotWidth) - scale.invert(0);
  const selectedStop = Defines.TICK_FORMAT_STOPS.find(
    ({ dtickrange: [start, end] }) => timeDiff >= start && timeDiff < end
  );
  const defaultTimeFormat = "%b %Y";

  useEffect(() => {
    const axis = d3
      .axisBottom(scale)
      .tickSize(-plotHeight)
      .tickFormat(
        d3.timeFormat(selectedStop ? selectedStop.value : defaultTimeFormat)
      )
      .ticks(10);
    d3.select(gRef.current)
      .style("font-size", "10px")
      .call(axis)
      .select(".domain") // This selects the axis line
      .style("stroke-width", "0"); // Set the stroke width to 0 to remove the border
    d3.select(gRef.current)
      .selectAll("line")
      .style("stroke-dasharray", "5")
      .style("stroke-width", "1")
      .style("stroke", tickColor);
    d3.select(gRef.current).selectAll("text").each(insertLinebreaks);
  });

  return (
    <>
      {/* Top Line */}
      <line x2={plotWidth} strokeWidth={"1"} stroke="rgb(65, 65, 65)" />
      <g
        className="x-axis"
        ref={gRef}
        transform={`translate(${x},${y})`}
        fill="rgb(245,245,245)"
      />
      {/* Bottom Line */}
      <line
        x2={plotWidth}
        y1={plotHeight}
        y2={plotHeight}
        strokeWidth={"1"}
        stroke="rgb(65, 65, 65)"
      />
    </>
  );
};

export const YAxis = ({
  x = 0,
  y = 0,
  scale,
  color = "black",
  plotWidth,
  plotHeight,
  label = "No Data",
  axisWidth = 55,
  onAxisTransformed = () => {},
  isSelected = false,
  onAxisSelected = () => {},
  previousToSelected,
  lastElement,
  precision = 0,
}) => {
  const gRef = useRef(null);

  const zoomBehavior = d3.zoom().interpolate(d3.interpolate);

  useEffect(() => {
    const axis = d3
      .axisLeft(scale)
      .ticks(12)
      .tickSize(isSelected ? -(plotWidth + -x) : 0)
      .tickFormat((d) => d.toFixed(precision))
      .tickSizeOuter(0);

    d3.select(gRef.current)
      .attr("stroke", color)
      .style("color", "lightgray")
      .style("font-size", "12px")
      .style("font-weight", "normal")
      .style("cursor", "grab")
      .call(axis)
      .selectAll("path")
      .style("stroke", "gray")
      .style(
        "stroke-width",
        isSelected || previousToSelected || lastElement ? "0" : "1.5px"
      );
    d3.select(gRef.current)
      .selectAll("line")
      .style("stroke-dasharray", "1 3")
      .style("stroke-width", "1")
      .style("color", color);
    d3.select(gRef.current)
      .selectAll(".tick")
      .style("opacity", isSelected ? "1" : "0.5");
    d3.select(gRef.current).call(
      zoomBehavior.on("zoom", () => {
        const newZoomState = d3.zoomTransform(gRef.current);
        onAxisTransformed(newZoomState);
        onAxisSelected(); // set chart as selected
      })
    );
  });

  return (
    <g
      className="y-axis"
      ref={gRef}
      transform={`translate(${x},${y})`}
      onClick={() => onAxisSelected()}
    >
      <rect
        fill={color}
        opacity={isSelected ? "0.3" : "0"}
        x={-axisWidth}
        y={-10}
        width={axisWidth}
        height={plotHeight + 20}
        rx={10}
      />
      <text
        style={{ fontSize: "12px", opacity: "1" }}
        className="y-label"
        textAnchor="end"
        transform="translate(-50, 0) rotate(-90)"
      >
        {label}
      </text>
    </g>
  );
};

export const PlotPath = ({
  data,
  xScale,
  yScale,
  strokeColor,
  strokeWidth = 1.25,
  selected = false,
  realTimeTextDisplay,
  dragging,
  symbol,
  measureCursorData,
  diffCursorData,
  isDiffCursorEnabled,
  isTooltipActive,
  isLive,
  lineChartDateRange,
}) => {
  if (!data || !data.length) return null;
  const chunkDataByTimeGap = (data, timeGapThreshold) => {
    const chunks = [];
    let currentChunk = [];

    for (let i = 0; i < data.length; i++) {
      if (currentChunk.length === 0) {
        currentChunk.push(data[i]);
      } else {
        const prevTimestamp = dayjs(data[i - 1].date);
        const currentTimestamp = dayjs(data[i].date);
        const timeDifference = currentTimestamp.diff(prevTimestamp);
        if (timeDifference > timeGapThreshold) {
          chunks.push(currentChunk);
          currentChunk = [data[i]];
        } else {
          currentChunk.push(data[i]);
        }
      }
    }
    if (currentChunk.length > 0) {
      chunks.push(currentChunk);
    }
    return chunks;
  };

  const generatePathData = (chunks, xScale, yScale) => {
    const lineGenerator = d3
      .line()
      .x((d) => {
        const xValue = xScale(d.date);
        return xValue;
      })
      .y((d) => {
        const yValue = yScale(Number(d.value));
        return yValue;
      });
    const paths = chunks.map((chunk) => {
      return lineGenerator(chunk);
    });

    const dottedLines = [];
    for (let i = 0; i < chunks.length - 1; i++) {
      const endOfCurrentChunk = chunks[i][chunks[i].length - 1];
      const startOfNextChunk = chunks[i + 1][0];
      const dottedLine = d3
        .line()
        .x((d) => xScale(d.date))
        .y((d) => yScale(Number(d.value)));

      dottedLines.push(dottedLine([endOfCurrentChunk, startOfNextChunk]));
    }

    return { paths, dottedLines };
  };

  const parsedData = data.map((d) => ({
    ...d,
    date: dayjs(d.date).toDate(),
    value: +d.value, // Convert value to a number
  }));

  const oneHourInMS = 1000 * 60 * 60;
  const oneMinuteInMS = 1000 * 60;

  // Split data into chunks based on time gaps
  const dataChunks = chunkDataByTimeGap(
    parsedData,
    Math.ceil(lineChartDateRange / oneHourInMS) * oneMinuteInMS
  );
  const { paths, dottedLines } = generatePathData(dataChunks, xScale, yScale);
  const lastDataPoint = data[data.length - 1];
  const rectWidth = 15;
  const rectHeight = 20;
  const rectMargin = 5;
  const borderRadius = 5;
  const realtimeTextDataPositionX = -36;
  const symbolLength = symbol ? symbol.length : 0;

  const lastDataPointTextLength =
    8 * (lastDataPoint?.value.length + symbolLength + 2);
  const measureCursorDataTextLength =
    8 * (measureCursorData?.value.length + symbolLength + 2);
  const diffCursorDataTextLength =
    8 * (diffCursorData?.value.length + symbolLength + 2);

  return (
    <g>
      {paths?.map((pathData, index) => {
        return (
          <path
            key={`path-${index}`}
            className="plotPath"
            d={pathData}
            fill="none"
            stroke={strokeColor}
            strokeWidth={selected ? strokeWidth + 0.75 : strokeWidth}
          />
        );
      })}
      {selected &&
        paths?.map((pathData, index) => (
          <path
            key={`plotPath-${index}`}
            className="plotPath"
            d={pathData}
            fill="none"
            stroke={hexToRgba(strokeColor, 0.3)}
            strokeWidth={strokeWidth + 7}
          />
        ))}
      {dottedLines?.map((d, index) => (
        <path
          key={`dotted-line-${index}`}
          className="dottedLine"
          d={d}
          fill="none"
          opacity={"30%"}
          stroke={strokeColor}
          strokeWidth={strokeWidth}
          strokeDasharray="4 4"
        />
      ))}
      {/* Latest Realtime Data */}
      {[Defines.REALTIMETEXT_ALL, Defines.REALTIMETEXT_LIVE].includes(
        realTimeTextDisplay
      ) && (
        <g
          transform={`translate(${xScale(lastDataPoint.date) + 30}, ${
            yScale(lastDataPoint.value) + 9
          })`}
        >
          {selected && (
            <rect
              x={realtimeTextDataPositionX - 5}
              y={-rectHeight - rectMargin - 5}
              width={rectWidth + lastDataPointTextLength + 10 + 25}
              height={rectHeight + 10}
              rx={borderRadius}
              ry={borderRadius}
              fill={strokeColor + 40}
            />
          )}
          <rect
            x={realtimeTextDataPositionX}
            y={-rectHeight - rectMargin}
            width={rectWidth + lastDataPointTextLength + 25}
            height={rectHeight}
            rx={borderRadius}
            ry={borderRadius}
            fill={"white"}
            opacity={0.9}
          />
          <image
            style={{
              width: "20px",
              height: "20px",
              filter:
                "invert(21%) sepia(13%) saturate(13%) hue-rotate(330deg) brightness(99%) contrast(83%)",
            }}
            href={Defines.S3_BROADCAST_ANIMATION}
            x={realtimeTextDataPositionX + lastDataPointTextLength + 10} // Adjust the x-coordinate to place the image to the right
            y={-25}
          />
          <text
            dx={realtimeTextDataPositionX + 10}
            dy="-0.7em"
            textAnchor="left"
            fill={strokeColor}
            style={{ fontWeight: "500", fontSize: "14px" }}
          >
            {lastDataPoint.value} {symbol && symbol}
          </text>
          <rect
            x={realtimeTextDataPositionX + 1}
            y={-rectHeight + 8}
            fill="white"
            width="6"
            height="6"
            stroke={strokeColor}
          />
        </g>
      )}
      {/* Measure Cursor Data */}
      {realTimeTextDisplay === Defines.REALTIMETEXT_ALL &&
        !dragging &&
        measureCursorData &&
        measureCursorData.date !== lastDataPoint.date &&
        !isTooltipActive &&
        !isLive && (
          <g
            transform={`translate(${xScale(measureCursorData.date) + 30}, ${
              yScale(measureCursorData.value) + 9
            })`}
          >
            {selected && (
              <rect
                x={realtimeTextDataPositionX - 5}
                y={-rectHeight - rectMargin - 5}
                width={rectWidth + measureCursorDataTextLength + 10}
                height={rectHeight + 10}
                rx={borderRadius}
                ry={borderRadius}
                fill={strokeColor + 40}
              />
            )}
            <rect
              x={realtimeTextDataPositionX}
              y={-rectHeight - rectMargin}
              width={rectWidth + measureCursorDataTextLength}
              height={rectHeight}
              rx={borderRadius}
              ry={borderRadius}
              fill={strokeColor}
              opacity={0.9}
            />
            <text
              dy="-0.7em"
              dx={realtimeTextDataPositionX + 10}
              textAnchor="left"
              fill={"white"}
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              {measureCursorData.value} {symbol && symbol}
            </text>
            <rect
              x={realtimeTextDataPositionX + 1}
              y={-rectHeight + 8}
              fill="white"
              width="6"
              height="6"
              stroke={strokeColor}
            />
          </g>
        )}
      {/* Diff Cursor Data */}
      {realTimeTextDisplay === Defines.REALTIMETEXT_ALL &&
        !dragging &&
        diffCursorData &&
        diffCursorData.date !== lastDataPoint.date &&
        isDiffCursorEnabled &&
        !isTooltipActive && (
          <g
            transform={`translate(${xScale(diffCursorData.date) + 30}, ${
              yScale(diffCursorData.value) + 9
            })`}
          >
            {selected && (
              <rect
                x={realtimeTextDataPositionX - 5}
                y={-rectHeight - rectMargin - 5}
                width={rectWidth + diffCursorDataTextLength + 10}
                height={rectHeight + 10}
                rx={borderRadius}
                ry={borderRadius}
                fill={strokeColor + 40}
              />
            )}
            <rect
              x={realtimeTextDataPositionX}
              y={-rectHeight - rectMargin}
              width={rectWidth + diffCursorDataTextLength}
              height={rectHeight}
              rx={borderRadius}
              ry={borderRadius}
              fill={strokeColor}
              opacity={0.9}
            />
            <text
              dy="-0.7em"
              dx={realtimeTextDataPositionX + 10}
              textAnchor="left"
              fill={"white"}
              style={{ fontWeight: "500", fontSize: "14px" }}
            >
              {diffCursorData.value} {symbol && symbol}
            </text>
            <rect
              x={realtimeTextDataPositionX + 1}
              y={-rectHeight + 8}
              fill="white"
              width="6"
              height="6"
              stroke={strokeColor}
            />
          </g>
        )}
    </g>
  );
};

export const PlotPointSet = ({
  data,
  xScale,
  yScale,
  strokeColor = "steelblue",
  dotSize = 2,
}) => {
  return data.map((dataPoint, i) => (
    <circle
      key={i}
      fill={strokeColor}
      stroke={"none"}
      r={dotSize}
      cx={xScale(dataPoint.date)}
      cy={yScale(dataPoint.value)}
    />
  ));
};

export const LineChartToolTip = ({ children, passedRef, width }) => (
  <StyledTooltip
    style={{
      background: "#fff",
      border: "1px solid #e8e8e8",
      borderRadius: "10px",
      boxShadow: "0 30px 60px 0px rgba(0,0,0,.6)",
      fontSize: "15px",
      padding: "10px",
      width: `${width}px`,
    }}
    ref={passedRef}
    className="linechart-tooltip"
  >
    {children}
  </StyledTooltip>
);

export const LineChartToolTipItem = ({
  measurePoint,
  index,
  isDiffCursorEnabled,
  selected,
  diffPointData,
}) => (
  <div
    key={measurePoint.signal_id + "tooltip"}
    style={{
      color: measurePoint.chartColor,
      fontWeight: selected ? "900" : "500",
      padding: "0 5px",
      borderRadius: "3px",
      backgroundColor: selected
        ? hexToRgba(measurePoint.chartColor, 0.3)
        : "none",
    }}
  >
    <span
      style={{
        display: "inline-block",
        width: "200px",
        fontWeight: "inherit",
      }}
    >
      {measurePoint.chartLabel}:
    </span>
    <span
      style={{
        display: "inline-block",
        width: "15px",
        fontWeight: "inherit",
      }}
    >
      {index === 0 && (
        <MeasureCursorIcon style={{ width: "15px", opacity: ".8" }} />
      )}
    </span>
    <span
      style={{
        display: "inline-block",
        width: "75px",
        fontWeight: "inherit",
      }}
    >
      {measurePoint.value}
    </span>
    <span
      style={{
        display: "inline-block",
        width: "15px",
        fontWeight: "inherit",
        marginLeft: "-15px",
      }}
    >
      <div
        style={{
          height: "100%",
          margin: "auto",
          transform: "translate(0, -1px)",
        }}
      >
        {index === 0 && isDiffCursorEnabled && (
          <DiffCursorIcon style={{ width: "15px", opacity: ".8" }} />
        )}
      </div>
    </span>
    {isDiffCursorEnabled && (
      <span
        style={{
          display: "inline-block",
          width: "75px",
          fontWeight: "inherit",
        }}
      >
        {`${diffPointData ? diffPointData.value : "- -"}`}
      </span>
    )}
    {isDiffCursorEnabled && (
      <>
        <span
          style={{
            display: "inline-block",
            width: "15px",
            fontWeight: "bold",
            marginLeft: "-15px",
            color: "black",
            opacity: ".8",
          }}
        >
          {index === 0 && "Δ"}
        </span>
        <span
          style={{
            display: "inline-block",
            width: "75px",
            fontWeight: "inherit",
          }}
        >
          {`${
            diffPointData
              ? (diffPointData.value - measurePoint.value).toFixed(2)
              : "- -"
          }`}
        </span>
      </>
    )}
  </div>
);

export const HorizontalCursor = ({ width, passedRef, color = "black" }) => (
  <line
    ref={passedRef}
    className="horizontal-cursor"
    x1="0"
    y1="0"
    x2={width}
    y2="0"
    stroke={color}
    strokeOpacity="0"
  />
);

export const RangePicker = ({ onSubmit, startAndEndDate }) => {
  const [startDate, setStartDate] = useState(
    startAndEndDate ? startAndEndDate[0] : dayjs().subtract(5, "minute")
  );
  const [endDate, setEndDate] = useState(
    startAndEndDate ? startAndEndDate[1] : dayjs()
  );
  const { RangePicker } = DatePicker;

  const emitNewRange = () => {
    onSubmit([startDate.toDate(), endDate.toDate()]);
  };

  const setStart = (newDate) => {
    if (newDate >= endDate) return;
    setStartDate(dayjs(newDate));
  };

  const setEnd = (newDate) => {
    if (newDate <= startDate) return;
    setEndDate(dayjs(newDate));
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const onOk = (date) => {
    if (date && date.length === 2) {
      const startDate = dayjs(date[0]);
      const endDate = dayjs(date[1]);
      setStart(startDate);
      setEnd(endDate);
    }
  };

  const handleChange = (date) => {
    if (date && date.length === 2) {
      setStart(dayjs(date[0]));
      setEnd(dayjs(date[1]));
    }
  };

  return (
    <div style={{ padding: "5px" }}>
      <p>Date Range:</p>
      <RangePicker
        showTime
        use12Hours
        disabledDate={disabledDate}
        onChange={handleChange}
        onCalendarChange={handleChange}
        onOk={onOk}
        defaultValue={[dayjs(startAndEndDate[0]), dayjs(startAndEndDate[1])]}
      />
      <div
        style={{
          marginTop: "20px",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button onClick={emitNewRange}>Apply Date Range</Button>
      </div>
    </div>
  );
};
