import { useEffect, useState } from "react";
import { Col, Row, Table, Card, Button, Form, Breadcrumb, Space } from "antd";
import { useParams, Link } from "react-router-dom";
import { useGetDeviceTypeQuery } from "features/application-devices/redux";
import { Spinner } from "common/Spinner";
import {
  ApplicationDeviceTypeFormModal,
  ApplicationDeviceTypeViewDetailsTable,
} from "../components";
import { ADMIN_ROUTE_APPLICATION_DEVICE_TYPES } from "features/application-devices/utils/Constants";

export const ApplicationDeviceTypesView = () => {
  const { id: applicationDeviceTypeID } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const [form] = Form.useForm();

  const {
    data: qApplicationDeviceType,
    isLoading: isQApplicationDeviceTypeLoading,
    refetch: qApplicationDeviceTypeRefetch,
  } = useGetDeviceTypeQuery(applicationDeviceTypeID);

  useEffect(() => {
    setIsPageLoaded(!isQApplicationDeviceTypeLoading);
  }, [isPageLoaded, isQApplicationDeviceTypeLoading]);

  if (!isPageLoaded) return <Spinner />;

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "Application Device Types Overview",
      href: `/admin/${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES}`,
    },
    { title: `${qApplicationDeviceType.name}` },
  ];

  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute.title}</span>
    ) : (
      <Link to={`${currentRoute.href}`}>{currentRoute.title}</Link>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      <Card
        title={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {`Details`}
          </span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Edit
          </Button>
        }
      >
        <ApplicationDeviceTypeViewDetailsTable
          isPageLoaded={isPageLoaded}
          qApplicationDeviceType={qApplicationDeviceType}
        />
      </Card>
      <ApplicationDeviceTypeFormModal
        form={form}
        title={"Edit Application Device Type Modal"}
        refetch={qApplicationDeviceTypeRefetch}
        applicationDeviceTypeObj={qApplicationDeviceType}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isEditForm={true}
      />
    </>
  );
};
