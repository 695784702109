import _ from "lodash";
import store from "store";
import { dispatchStoreEvents } from "features/events/hooks";
import { applicationUnitsApi, setDataHistory } from "../redux";

/**
 * Used for class components
 * (where hooks don't work)
 */

export const dispatchSetDataHistory = (data) => {
  store.dispatch(setDataHistory(data));
};

/**
 * formats flat array into
 * {
 *  [psn_id]: {
 *    [signal_id]: [...],
 *    [signal_id]: [...],
 *    ...
 *  },
 *  ...
 * }
 */
export function formatForRedux(dataArray) {
  const formattedHistory = {};
  for (let i = 0; i < dataArray.length; i++) {
    const dataPoint = dataArray[i];
    let psn_id = dataPoint.product_sn_id;
    let signal_id = dataPoint.signal_id;
    if (!formattedHistory[psn_id]) formattedHistory[psn_id] = {};
    if (!formattedHistory[psn_id][signal_id])
      formattedHistory[psn_id][signal_id] = [];
    formattedHistory[psn_id][signal_id].push(dataPoint);
  }
  return formattedHistory;
}

/**
 * Callbacks for telemetryMessageHandler$/eventMessageHandler$
 * which take a released buffer, prepares for redux, then dispatches redux actions
 */
export const handlePipedTelemetry = (telemetryBatch) => {
  // flatten any nested arrays
  let flattenedBatch = _.flatten(telemetryBatch);

  // since this is not a functional component, we can't use hooks
  // manual setup connection to redux
  const fullState = store.getState();
  const customerId = fullState.user.customerId;
  const { data: productSNs } =
    applicationUnitsApi.endpoints.getApplicationUnitsByFilter.select({
      customer_id: customerId,
    })(fullState);

  // if we don't have any SNs, nothing is relevant
  if (!productSNs) return;

  // perform filtering;
  // filtering because there are ID clashes between staging and prod;
  // if properly separated, this filtering would not be necessary
  const productSNIds = productSNs?.map((psn) => psn.id);
  const dataPointIsRelevant = (dataPoint) => {
    const psnExists = productSNIds?.includes(dataPoint.product_sn_id);
    const relatedPSN = productSNs?.find(
      (psn) => psn.id === dataPoint.product_sn_id
    );
    const psnExistsForProduct =
      relatedPSN && relatedPSN.product_id === dataPoint.product_id;
    return psnExists && psnExistsForProduct;
  };
  const filteredBatch = flattenedBatch.filter(dataPointIsRelevant);

  // post buffer to redux
  const formattedBatch = formatForRedux(filteredBatch);
  dispatchSetDataHistory(formattedBatch);
};

export const handlePipedEvents = (eventBatch) => {
  // flatten any nested arrays
  let flattenedBatch = _.flatten(eventBatch);
  // validation
  if (!flattenedBatch.length) return;

  // perform filtering here if necessary

  // post buffer to redux
  dispatchStoreEvents(flattenedBatch);
};
