import { Component, useEffect } from "react";
import styled from "styled-components";
import { withSize } from "react-sizeme";
import { Widget } from "features/widgets";
import { Responsive as GridLayout } from "react-grid-layout";
import { GRID_HEIGHT } from "utils/defines";
import "./react-grid-layout-styles.css";
import "./react-resizable-styles.css";
// outer html div
const WidgetWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
class Grid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      breakpoint: "lg",
    };
  }
  mapLayout = (d) => ({
    ...d,
    i: d.id + "",
    w: d.width,
    h: d.height,
    x: d.x,
    y: d.y,
    minW: !this.props.isMapModalLayout ? d.widget.min_width : 2,
    maxW: d.widget.max_width,
    minH: !this.props.isMapModalLayout ? d.widget.min_height : 2,
    maxH: !this.props.isMapModalLayout ? d.widget.max_height : 2,
    static: !this.props.editModeEnabled,
  });
  setDefaultProps = ({ isMapModalLayout }) => ({
    className: "layout",
    layouts: {
      lg: this.props.data.map(this.mapLayout),
    },
    breakpoints: { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 },
    cols: { lg: 12, md: 12, sm: 12, xs: 12, xxs: 1 },
    rowHeight: isMapModalLayout ? 40 : GRID_HEIGHT, // default user const 70
    width: 900,
    preventCollision: isMapModalLayout,
    isBounded: isMapModalLayout,
    onLayoutChange: (updatedLayout) => {
      if (this.state.breakpoint === "xs" || this.state.breakpoint === "xxs")
        return;
      this.saveWidgetInstances(updatedLayout);
    },
    onBreakpointChange: (newBreakpoint, newCols) =>
      this.setBreakpoint(newBreakpoint),
  });
  setBreakpoint = (breakpoint) => this.setState({ breakpoint });
  saveWidgetInstances = (updatedLayout) => {
    const {
      data: widget_instances,
      updateWidgetInstances,
      customerId,
    } = this.props;
    const remappedInstances = widget_instances.map((instance) => {
      const matchingUpdate = updatedLayout.find(
        (widget) => parseInt(widget.i) === parseInt(instance.id)
      );
      return {
        ...instance,
        height: matchingUpdate.h,
        width: matchingUpdate.w,
        x: matchingUpdate.x,
        y: matchingUpdate.y,
      };
    });
    updateWidgetInstances({ widget_instances: remappedInstances, customerId });
  };
  render() {
    const {
      productID,
      data,
      isMapModalLayout = false, // from MapModal > Layout
      size,
    } = this.props;

    return (
      <div
        style={{
          transform: isMapModalLayout ? "translate(0, -5px)" : "",
          height: data.length === 0 && "100%",
        }}
      >
        {data.length === 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: "999",
              height: "100%",
            }}
          >
            <div>
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  marginBottom: "20px",
                  opacity: ".3",
                  textAlign: "center",
                }}
              >
                Empty Tab (Grid Layout)
              </h3>
              <p style={{ fontStyle: "italic", fontSize: "16px" }}>
                No widgets selected. Add a widget to get started.
              </p>
            </div>
          </div>
        )}
        <GridLayout
          {...this.setDefaultProps({ isMapModalLayout })}
          width={size.width}
          isFullscreen={false}
        >
          {data.map(this.mapLayout).map((widgetInstance) => (
            <WidgetWrapper key={widgetInstance.i} className="widget-wrapper">
              <Widget
                instance={widgetInstance}
                isMapModalLayout={isMapModalLayout}
                productID={productID}
              />
            </WidgetWrapper>
          ))}
        </GridLayout>
      </div>
    );
  }
}
export default withSize()(Grid);
