import { Modal } from "common/Modal";
import { Button } from "common/elements";

// Props
interface Props {
  isShow: boolean;
  isCloseDisabled: boolean;
  isContinueDisabled: boolean;
  timeRemaining: string;
  style?: object;
  hide: () => void;
  onContinueClick: () => void;
}

export const AuthExtendSessionModal = (props: Props) => {
  return (
    <>
      <Modal className="modal" show={props.isShow} onHide={props.hide} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Continue Session? Time Remaining: {props.timeRemaining}
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            onClick={props.onContinueClick}
            disabled={props.isContinueDisabled}
            style={{ width: "160px", margin: "auto" }}
            form={null}
            width={undefined}
            height={undefined}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
