import { useState } from "react";
import styled, { withTheme } from "styled-components";
import { axios } from "axios/axios";
import { GetFilter } from "utils/Color";
import { useSelector, useDispatch } from "react-redux";
import { Tooltip } from "react-tooltip";
import {
  setProducts,
  setApplicationsSelectedTab,
  setApplicationsSelectedSN,
} from "../redux";
import { Icon } from "common";
import {
  ICON_SIZE_MD,
  ICON_SIZE_XL,
  MODAL_SIZE,
  S3_BROADCAST,
  S3_CLOSE_X,
  S3_COPY,
  S3_EDIT,
  S3_MAP_PIN_UNIT_A,
  S3_PRODUCT_TCU,
  S3_SETTINGS_UNIT,
  S3_SETTING_APPLICATIONS,
} from "utils/defines";
import { productService } from "features/applications/services";
import {
  Modal,
  ModalBody,
  ModalFieldWrapper,
  ModalLabel,
  ModalField,
  ModalFooter,
} from "common/Modal";

import { ApplicationUnitsListView } from "./ApplicationsUnitsSettingsTab/pages";
import { ApplicationSettingsView } from "./ApplicationsSettingsTab/pages";
import { ApplicationsCANSignalsView } from "./ApplicationsCANSignalsTab/pages";
import { ApplicationsParametersView } from "./ApplicationsParametersTab/pages";
import { ApplicationsEventStatesView } from "./ApplicationsEventStatesTab/pages";
import { ApplicationsPushCommandView } from "./ApplicationsPushCommandTab/pages";
import { ModalUnderlayBG } from "common";

/* product SN modal */

const ProductSNModal = ({ show, hide, productSN }) => {
  const customer_id = useSelector((state) => state.user.customerId);
  const dispatch = useDispatch();
  const updateProducts = (products) => dispatch(setProducts(products));
  const emptyForm = () => ({
    customer_id,
    product_sn_name: "",
    serial_number: "",
  });
  const [form, setState] = useState({ ...emptyForm(), ...productSN });
  const updateField = (e) =>
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });

  const onSubmit = () => {
    let req = form.id
      ? axios.put("/api/product_sns", { product_sn: form })
      : axios.post("/api/product_sns", { product_sn: form });
    req
      .then(() => {
        productService
          .index(customer_id)
          .then((res) => res.products)
          .then(updateProducts);
      })
      .then(() => dispatch(setProducts([])))
      .then(hide);
  };

  const onDelete = () => {
    axios
      .delete("/api/product_sns", { data: { product_sn_id: form.id } })
      .then(() => {
        productService
          .index(customer_id)
          .then((res) => res.products)
          .then(updateProducts);
      })
      .then(() => dispatch(setProducts([])))
      .then(hide);
  };

  if (!productSN) return null;
  return (
    <Modal
      className="modal"
      show={productSN !== null}
      onHide={hide}
      size={MODAL_SIZE}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <span>
          {form.id ? "Edit Device" : "Create Device"}:&nbsp;
          <strong>
            {form.product_sn_name} {form.serial_number && "#"}
            {form.serial_number}
          </strong>
        </span>
      </Modal.Header>
      <ModalBody>
        <ModalFieldWrapper>
          <ModalLabel>Name</ModalLabel>
          <ModalField>
            <input
              value={form.product_sn_name}
              name="product_sn_name"
              onChange={updateField}
            />
          </ModalField>
        </ModalFieldWrapper>

        <ModalFieldWrapper>
          <ModalLabel>Number</ModalLabel>
          <ModalField>
            <input
              value={form.serial_number}
              name="serial_number"
              onChange={updateField}
            />
          </ModalField>
        </ModalFieldWrapper>
      </ModalBody>
      <ModalFooter>
        {form.id && (
          <button className="deny" onClick={onDelete}>
            Delete
          </button>
        )}
        <button className="accept" onClick={onSubmit}>
          {form.id ? "Update" : "Create"}
        </button>
      </ModalFooter>
    </Modal>
  );
};

/* main */

const DETAILHEADWRAPHEIGHT = 100;
const MAPMODALPADDING = 15;

const Wrapper = styled.div`
  position: relative;
  height: 100%;
`;

const ApplicationModalOuter = styled.div`
  padding: ${MAPMODALPADDING}px;
`;

const ApplicationModalCard = styled.div`
  max-width: 1100px;
  margin-left: auto;
  margin-right: auto;
  background-color: #f0f0f0;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  overflow-y: auto;
  height: calc(
    100vh - ${(props) => props.theme.headerHeight} - 30px
  ); // 2 * 15 map modal padding
  position: relative;
`;

const ProductDetailHeadWrap = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: ${DETAILHEADWRAPHEIGHT}px;
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  border-radius: 5px 5px 0 0;

  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const ProductDetailHeader = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  & img.detailheader-image {
    width: 100px;
    height: auto;
    margin-left: 30px;
  }
`;

const ProductInfo = styled.div`
  position: relative;
  & > * {
    margin: 0;
    padding: 0;
  }
`;

const TabsNav = styled.ul`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Tab = styled.li`
  display: flex;
  justify-content: center;
  padding: 8px 10px;
  border-bottom: ${(props) =>
    props.isSelected ? "5px solid #333" : "5px solid transparent"};
  min-width: ${(props) => props.isSelected && "200px"};
  cursor: pointer;
`;

const TabsBody = styled.div`
  height: calc(
    100vh - ${(props) => props.theme.headerHeight} - ${DETAILHEADWRAPHEIGHT}px -
      30px
  ); // 2 * 15 map modal padding
  background-color: #f0f0f0;
  position: relative;
  overflow-y: auto;
  margin-top: ${DETAILHEADWRAPHEIGHT}px;
`;

const TabIcon = styled.span`
  margin: 0 4px;
`;

const ApplicationDetailViewModal = ({
  product,
  serialNumbers,
  archivedSerialNumbers,
  handleSelectedProduct,
  theme,
  refetchAllArchivedSNs,
  refetchAllProductSNs,
}) => {
  const dispatch = useDispatch();

  const DEFAULT_TAB = "settings";
  const selectedTab =
    useSelector((state) => state.products.applicationsSelectedTab) ||
    DEFAULT_TAB;
  const setSelectedTab = (tab) => dispatch(setApplicationsSelectedTab(tab));
  const selectedSN = useSelector(
    (state) => state.products.applicationsSelectedProductSN
  );
  const setSelectedSN = (productSN) =>
    dispatch(setApplicationsSelectedSN(productSN));
  const [addingProductSN, setAddingProductSN] = useState(false);
  const [editingProductSN, setEditingProductSN] = useState(false);

  const showTabContent = (selectedTab) => {
    switch (selectedTab) {
      case "units":
        return (
          <ApplicationUnitsListView
            product={product}
            serialNumbers={serialNumbers}
            archivedSerialNumbers={archivedSerialNumbers}
            selectedSN={selectedSN}
            onProductSNSelected={setSelectedSN}
            setAddingProductSN={setAddingProductSN}
            refetchAllArchivedSNs={refetchAllArchivedSNs}
            refetchAllProductSNs={refetchAllProductSNs}
          />
        );
      case "signals":
        return <ApplicationsCANSignalsView />;
      case "parameters":
        return <ApplicationsParametersView />;
      case "eventStates":
        return <ApplicationsEventStatesView />;
      case "customCommands":
        return <ApplicationsPushCommandView />;
      case "settings":
      default:
        return <ApplicationSettingsView product={product} />;
    }
  };

  // @TODO: Change tabs to a dropdown menu on smaller screen sizes
  // @TODO: Make it so that the selected tab's bottom border has a
  //       transition animation when switching tabs
  const TabItem = ({ tabKey, label, icon }) => (
    <Tab isSelected={selectedTab === tabKey}>
      <span
        onClick={(e) => {
          e.preventDefault();
          setSelectedTab(tabKey);
        }}
        style={{ whiteSpace: "nowrap" }}
        data-tooltip-id="application-detail-tooltip"
        data-tooltip-content={label}
      >
        <TabIcon>
          <Icon hostedImage={icon} width={ICON_SIZE_MD} height={ICON_SIZE_MD} />
        </TabIcon>
        {selectedTab === tabKey && label}
      </span>
    </Tab>
  );

  return (
    <>
      <Wrapper>
        <ModalUnderlayBG
          active={true}
          onClick={() => {
            handleSelectedProduct(null);
          }}
          className="underlayBG"
        />
        <ApplicationModalOuter>
          <ApplicationModalCard>
            <ProductDetailHeadWrap>
              <ProductDetailHeader>
                <img
                  className="detailheader-image"
                  src={product.image || "https://via.placeholder.com/100"}
                  alt="product-logo"
                />
                <div
                  style={{
                    marginLeft: "30px",
                    height: "100%",
                    paddingTop: "15px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <ProductInfo>
                    <Icon
                      style={{
                        filter: GetFilter(theme.themePrimary),
                        position: "absolute",
                        top: "0",
                        left: "0",
                        transform: "translate(-35px, -2px)",
                      }}
                      hostedImage={S3_MAP_PIN_UNIT_A}
                      width={ICON_SIZE_XL}
                      height={ICON_SIZE_XL}
                    />
                    <h1 style={{ fontSize: "1.5rem" }}>
                      {product.product_name}
                    </h1>
                  </ProductInfo>
                  <TabsNav style={{ marginTop: "auto" }}>
                    <TabItem
                      tabKey={"settings"}
                      label={"Application Settings"}
                      icon={S3_SETTING_APPLICATIONS}
                    />
                    <TabItem
                      tabKey={"units"}
                      label={"Unit Settings"}
                      icon={S3_SETTINGS_UNIT}
                    />
                    <TabItem
                      tabKey={"signals"}
                      label={"CAN Signals"}
                      icon={S3_BROADCAST}
                    />
                    <TabItem
                      tabKey={"parameters"}
                      label={"Parameters"}
                      icon={S3_EDIT}
                    />
                    <TabItem
                      tabKey={"eventStates"}
                      label={"Event States"}
                      icon={S3_COPY}
                    />
                    <TabItem
                      tabKey={"customCommands"}
                      label={"TCU CMD"}
                      icon={S3_PRODUCT_TCU}
                    />
                  </TabsNav>
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <div
                    style={{
                      textAlign: "right",
                      paddingRight: "30px",
                      marginBottom: "1rem",
                    }}
                  >
                    <div
                      onClick={(e) => {
                        e.preventDefault();
                        handleSelectedProduct(null);
                      }}
                      style={{
                        cursor: "pointer",
                        padding: "10px",
                        marginRight: "-10px",
                      }}
                    >
                      <Icon
                        width={ICON_SIZE_XL}
                        height={ICON_SIZE_XL}
                        hostedImage={S3_CLOSE_X}
                      />
                    </div>
                  </div>
                </div>
              </ProductDetailHeader>
            </ProductDetailHeadWrap>
            <TabsBody>{showTabContent(selectedTab)}</TabsBody>
          </ApplicationModalCard>
        </ApplicationModalOuter>
        <Tooltip
          place="top"
          id="application-detail-tooltip"
          style={{ zIndex: 10 }}
        />
      </Wrapper>
      {(addingProductSN || editingProductSN) && (
        <ProductSNModal
          show={addingProductSN || editingProductSN}
          hide={() => {
            setAddingProductSN(false);
            setEditingProductSN(false);
          }}
          productSN={
            editingProductSN
              ? selectedSN
              : {
                  product_id: product.id,
                }
          }
        />
      )}
    </>
  );
};

export default withTheme(ApplicationDetailViewModal);
