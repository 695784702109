import { useState, useRef, useEffect, cloneElement, memo } from "react";
import {
  DndContext,
  PointerSensor,
  closestCenter,
  useSensor,
} from "@dnd-kit/core";
import {
  arrayMove,
  horizontalListSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import GridLayout from "./GridLayout";
import styled from "styled-components";
import { Tabs, Input, Modal, Radio } from "antd";
import { LayoutTabsStyling } from "styles/GlobalStyles";
import { setActiveListLayout } from "features/grid-layout/redux/layoutSlice";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useSelector } from "react-redux";
import {
  useGetLayoutsByCustomerIdQuery,
  useUpsertLayoutsMutation,
  useDeleteTabMutation,
} from "features/grid-layout/redux";
import { Spinner } from "common/Spinner";
const { LayoutTabsMain, LayoutTabsView, LayoutTabsViewHeader } =
  LayoutTabsStyling;

const PRODUCT_MODAL_GUTTER = 30;

const W = styled.div`
  height: calc(
    100vh - ${(props) => props.theme.headerHeight} - 80px -
      ${2 * PRODUCT_MODAL_GUTTER}px
  );
`;

/*
 * A LayoutTabs component contains a group (or 1) Layout(s),
 * accessed through a tabbing structure
 *
 * LayoutTabs is invoked in Dashboard and Product
 *
 */
const LayoutTabs = memo(
  ({
    section, // "dashboard" | "products"
    widgetType, // "fleetwide" | "products"
    productID, // from UnitModal
    overlayShown, // from unitModal
    overlayVertical, // from unitModal
    style,
  }) => {
    const customerId = useCustomerId();
    const editModeEnabled = useSelector(
      (state) => state.layout.editModeEnabled
    );
    const [activeDragTab, setActiveDragTab] = useState(0); // Dragged Tab ID
    const [activeTabId, setActiveTabId] = useState(0); // Current Tab ID
    const [isAddTab, setIsAddTab] = useState(false);
    const [isLayoutsUpdating, setIsLayoutsUpdating] = useState(false);
    const [isDeleteTab, setIsDeleteTab] = useState(false);
    const [items, setItems] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [activeKey, setActiveKey] = useState(1);
    const [newTabName, setNewTabName] = useState("");
    const [newTabType, setNewTabType] = useState(null);
    const [isFormValid, setIsFormValid] = useState(false);
    const [editingTabKey, setEditingTabKey] = useState(null);
    const [updateTabName, setUpdateTabName] = useState("");
    const [updateTabCursor, setUpdateTabCursor] = useState(0);
    const [upsertLayouts] = useUpsertLayoutsMutation();
    const [deleteTab] = useDeleteTabMutation();
    const { data: layouts, isLoading } =
      useGetLayoutsByCustomerIdQuery(customerId);

    const getItemByKey = (key) => {
      return items.find((item) => item.key === key);
    };

    const getItemById = (id) => {
      return items.find((item) => item.id === id);
    };

    const inputRef = useRef(null);

    const DraggableTabNode = ({ className, ...props }) => {
      const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
      } = useSortable({
        id: Number(props["data-node-key"]),
        disabled: !editModeEnabled,
      });

      // tab key index
      const dataNodeKey = Number(props["data-node-key"]);

      const handleTabDoubleClick = (key) => {
        if (editModeEnabled) {
          setActiveDragTab(0);
          setEditingTabKey(key);
          setUpdateTabName(getItemByKey(key)?.label); // auto-fill with current label
        }
      };

      const style = {
        ...props.style,
        transform: CSS.Transform.toString(
          transform && { ...transform, scaleX: 1 }
        ),
        transition,
        opacity: isDragging ? 0.5 : 1,
        cursor: editModeEnabled ? "move" : "pointer",
      };

      // copy and extension of tab element to represent tab's children component
      const cloned = cloneElement(props.children, {
        ref: setNodeRef,
        style: style,
        ...attributes,
        ...listeners,
      });

      return (
        <div
          data-original-index={dataNodeKey}
          onDoubleClick={() => handleTabDoubleClick(dataNodeKey)}
        >
          {editingTabKey === dataNodeKey ? (
            <>
              <Input
                ref={inputRef}
                value={updateTabName}
                onChange={(e) => {
                  setUpdateTabName(e.target.value);
                  setUpdateTabCursor(e.target.selectionStart);
                }}
                onPressEnter={() => handleEditTabNameConfirm(dataNodeKey)}
                onBlur={handleEditTabNameCancel}
                style={{ width: "150px", height: "35px" }}
                autoFocus
              />
            </>
          ) : (
            <>{cloned}</>
          )}
        </div>
      );
    };

    // fetch and set top tabs on load
    useEffect(() => {
      if (layouts) {
        const initialItems = layouts
          .filter((layout) => layout.top_tab_position !== null)
          .sort((a, b) => a.top_tab_position - b.top_tab_position)
          .map((item, index) => ({
            id: item.id,
            label: item.top_tab_name,
            key: index + 1,
            closable: editModeEnabled,
            customer_id: customerId,
            section_name: "products",
            top_tab_name: item.top_tab_name,
            top_tab_position: item.top_tab_position,
            product_id: productID,
            is_fullscreen: item.is_fullscreen,
          }));
        setItems(initialItems);
      }
    }, [layouts, editModeEnabled]);

    // Form validation for new tab form
    useEffect(() => {
      setIsFormValid(!!newTabName.trim() && newTabType !== null);
    }, [newTabName, newTabType]);

    // Keeping cursor position for editing tab name
    useEffect(() => {
      if (!inputRef.current) return;
      inputRef.current.setSelectionRange(updateTabCursor, updateTabCursor);
    }, [updateTabName]);

    // on items change
    useEffect(() => {
      // set initial active tab id after load
      if (!isLoading && items) {
        setActiveTabId(getItemByKey(activeKey)?.id);
      }

      // handle on newly added tab
      if (isAddTab) {
        setIsAddTab(false);
        // get last tab in items
        const newActiveTab = [...items].at(-1);
        setActiveKey(newActiveTab?.key);
        setActiveTabId(newActiveTab?.id);
      }

      // handle on newly deleted tab
      if (isDeleteTab) {
        setIsDeleteTab(false);
        // check and update active tab if active tab was deleted
        const lastActiveTab = getItemById(activeTabId);
        if (!lastActiveTab) {
          const newActiveTab = [...items].at(-1);
          setActiveKey(newActiveTab?.key);
          setActiveTabId(newActiveTab?.id);
        }
      }

      // handle on tab change (name, position)
      if (isLayoutsUpdating) {
        const updatedItems = items.map((item, index) => ({
          ...item,
          key: index + 1,
          top_tab_position: index,
        }));

        // get tab that was changed
        let activeTab = getItemByKey(activeKey);
        if (activeDragTab > 0) {
          activeTab = updatedItems.find((item) => item.id === activeDragTab);
        }

        upsertLayouts(updatedItems)
          .unwrap()
          .then(() => {
            setActiveKey(activeTab?.key);
            setActiveTabId(activeTab?.id);
            setActiveDragTab(0);
          })
          .catch((error) => {
            console.error("Error updating layouts:", error);
          })
          .finally(() => {
            setIsLayoutsUpdating(false);
          });
      }
    }, [items, isLoading]);

    const sensor = useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    });

    if (!isLoading && !layouts?.length)
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p style={{ fontSize: "18px" }}>No layout for customer</p>
        </div>
      );

    const getApplicationUnitLayout = () => {
      if (layouts.length === 1) {
        setActiveListLayout(layouts[0].id);
        return (
          <GridLayout
            widgetType={widgetType}
            layoutID={layouts[0].id}
            productID={productID}
            isFullscreen={layouts[0].is_fullscreen}
          />
        );
      }

      const layout = getItemById(activeTabId);

      if (layout) {
        return (
          <GridLayout
            widgetType={widgetType}
            layoutID={layout.id}
            productID={productID}
            isFullscreen={layout.is_fullscreen}
          />
        );
      }
      return null;
    };

    const getDashboardLayout = () => {
      // expecting only one "dashboard" layout per customer
      const dashboardLayout = layouts.find(
        (layout) => layout.section_name === "dashboard"
      );
      if (!dashboardLayout)
        return <div>No dashboard layout found for customer</div>;
      return (
        <GridLayout
          widgetType={widgetType}
          layoutID={dashboardLayout.id}
          productID={productID}
          isFullscreen={false}
        />
      );
    };

    const handleEditTabNameConfirm = (key) => {
      if (updateTabName.trim()) {
        setIsLayoutsUpdating(true);
        const updatedItems = items.map((item) => ({
          ...item,
          top_tab_name:
            item.key === key ? updateTabName.trim() : item.top_tab_name,
          label: item.key === key ? updateTabName.trim() : item.label,
        }));
        setItems(updatedItems);
        setEditingTabKey(null);
        setUpdateTabName("");
      }
    };

    const handleEditTabNameCancel = () => {
      setEditingTabKey(null);
      setUpdateTabName("");
    };

    const handleTabDelete = async (id, tabName) => {
      const result = await deleteTab({
        id,
        customer_id: customerId,
        tab_name: tabName,
      });
      console.log(result);
      setIsDeleteTab(true);
    };

    const handleTabAdd = (tabName, fullscreenOption = 0) => {
      const highestTopTabPos = layouts.reduce((prev, current) =>
        prev.top_tab_position > current.top_tab_position ? prev : current
      ).top_tab_position;
      const layout = {
        customer_id: customerId,
        section_name: section,
        top_tab_name: tabName,
        top_tab_position: highestTopTabPos !== null ? highestTopTabPos + 1 : 0,
        product_id: productID,
        is_fullscreen: fullscreenOption,
      };

      upsertLayouts([layout])
        .unwrap()
        .then(() => {
          setIsAddTab(true);
        })
        .catch((error) => {
          console.error("Error adding tab:", error);
        });
    };

    const handleTabEdit = (targetKey, action) => {
      if (action === "add") {
        setModalOpen(true);
      } else {
        const tab = getItemByKey(targetKey);
        handleTabDelete(tab.id, tab?.top_tab_name);
      }
    };

    const handleDragEnd = ({ active, over }) => {
      if (active.id === over?.id) return;

      setIsLayoutsUpdating(true);
      setActiveDragTab(items.find((i) => i.key === active.id).id);
      setItems((prev) => {
        const activeIndex = prev.findIndex((i) => i.key === active.id);
        const overIndex = prev.findIndex((i) => i.key === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    };

    const handleModalSubmit = () => {
      if (isFormValid) {
        handleTabAdd(newTabName.trim(), newTabType);
        setNewTabName("");
        setNewTabType(null);
        setModalOpen(false);
      }
    };

    // onTabChange -- set Active Key and Tab ID
    const handleTabChange = (e) => {
      const numKey = Number(e);
      setActiveKey(numKey);
      setActiveTabId(getItemByKey(numKey)?.id);
    };

    if (isLoading)
      return (
        <LayoutTabsMain>
          <Spinner />
        </LayoutTabsMain>
      );

    if (section === "dashboard")
      return (
        <LayoutTabsMain section={"dashboard"} className="layout-wrapper">
          {getDashboardLayout()}
        </LayoutTabsMain>
      );

    // @TODO: better logic to determine this is a tabs layout or a dashboard layout or a mapmodal layout
    return layouts?.filter((layout) => layout.section_name === "products")
      .length ? (
      // for units modal
      <LayoutTabsMain
        section={section}
        className="layout-tabs-wrapper"
        style={style}
      >
        <LayoutTabsView>
          <LayoutTabsViewHeader
            className="layout-top-tabs-wrapper"
            overlayShown={overlayShown}
            overlayVertical={overlayVertical}
          >
            <Tabs
              items={items}
              activeKey={activeKey}
              onChange={handleTabChange}
              onEdit={handleTabEdit}
              type={editModeEnabled ? "editable-card" : "card"}
              style={{ width: "100%" }}
              size="small"
              renderTabBar={(tabBarProps, DefaultTabBar) => (
                <DndContext
                  sensors={[sensor]}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                >
                  <SortableContext
                    items={items?.map((item) => item.key)}
                    strategy={horizontalListSortingStrategy}
                  >
                    <DefaultTabBar {...tabBarProps}>
                      {(node) => (
                        <DraggableTabNode {...node.props} key={node.key}>
                          {node}
                        </DraggableTabNode>
                      )}
                    </DefaultTabBar>
                  </SortableContext>
                </DndContext>
              )}
            />
          </LayoutTabsViewHeader>
          <W style={{ paddingTop: "50px" }}>
            {!isLayoutsUpdating && getApplicationUnitLayout()}
          </W>
          <Modal
            title="Add New Tab"
            centered
            open={modalOpen}
            onOk={handleModalSubmit}
            okText={"Submit"}
            onCancel={() => setModalOpen(false)}
            okButtonProps={{ disabled: !isFormValid }}
          >
            <Input
              placeholder="Tab Name*"
              maxLength={20}
              style={{ margin: "20px 0" }}
              onChange={(e) => {
                setNewTabName(e.target.value);
              }}
              value={newTabName}
            />
            <span style={{ fontWeight: "500", marginRight: "10px" }}>
              Layout Type:*
            </span>
            <Radio.Group
              onChange={(e) => {
                setNewTabType(e.target.value);
              }}
              value={newTabType}
            >
              <Radio value={0}>Grid</Radio>
              <Radio value={1}>Full Screen</Radio>
            </Radio.Group>
          </Modal>
        </LayoutTabsView>
      </LayoutTabsMain>
    ) : (
      // for dashboard and map-modal
      <LayoutTabsMain section={section} className="layout-wrapper">
        {getApplicationUnitLayout()}
      </LayoutTabsMain>
    );
  }
);

export default LayoutTabs;
