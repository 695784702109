import styled from "styled-components";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";
import { WidgetTitle, BigNumber } from "./TileComponents";

const PresentationWrapper = styled.div`
  align-items: center;
  container-type: inline-size;
  display: flex;
  height: 100%;
  justify-content: center;
  padding: 20px;
  width: 100%;
`;

const PresentationBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const PresentationContent = styled.div`
  padding: 20px 40px;
`;

const PresentationNumber = styled(BigNumber)`
  flex: 1;
  font-size: clamp(40px, 10cqmin, 50px);
  line-height: 1;
`;

const PresentationTitle = styled(WidgetTitle)`
  flex: 1;
  font-size: clamp(30px, 7cqmin, 40px);
  font-weight: 200;
  padding: 0;
`;

export const DashboardProductPresentation = ({ getSettingsField }) => {
  const customerId = useCustomerId();
  const { data: allProducts } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const { data: allSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });
  const backupId = allProducts?.length ? allProducts[0].id : -1;
  const selectedProductId = getSettingsField("selectedProduct", backupId);
  const productCount = allSNs?.length
    ? allSNs.filter((sn) => sn.product_id === selectedProductId).length
    : 0;
  const widgetTitle = getSettingsField("widgetTitle", "Products");
  return (
    <PresentationWrapper>
      <PresentationBody>
        <PresentationContent>
          <PresentationNumber>{productCount}</PresentationNumber>
          <PresentationTitle>{widgetTitle}</PresentationTitle>
        </PresentationContent>
      </PresentationBody>
    </PresentationWrapper>
  );
};
