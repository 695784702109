import { Provider as StoreProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { Amplify } from "aws-amplify";
import { AWSIoTProvider } from "@aws-amplify/pubsub";
import { AppRoutes } from "routes/AppRoutes";
import store from "store";
import { AuthProvider } from "features/auth/contexts";
import {
  AMPLIFY_AUTH_CONFIG,
  AMPLIFY_IOT_PROVIDER_CONFIG,
  AMPLIFY_STORAGE_CONFIG,
} from "config";

import "styles/css/index.css";
import "styles/css/forms.css";

// configure cognito identity pool and Storage
Amplify.configure({
  Auth: AMPLIFY_AUTH_CONFIG,
  Storage: AMPLIFY_STORAGE_CONFIG,
});

// configure connection to broker via cognito identity pool
Amplify.addPluggable(new AWSIoTProvider(AMPLIFY_IOT_PROVIDER_CONFIG));

const queryClient = new QueryClient();

const AppWithProviders = () => {
  return (
    <StoreProvider store={store}>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <AuthProvider>
            <AppRoutes />
          </AuthProvider>
        </BrowserRouter>
      </QueryClientProvider>
    </StoreProvider>
  );
};

export default AppWithProviders;
