import dayjs from "utils/dayjs";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { useGetDeveloperLogsQuery } from "features/changelog/redux";
import { Typography, Flex, Skeleton } from "antd";

const { Title, Text } = Typography;

export const ChangeLogDeveloperPage = () => {
  const { data: developerLogs, isLoading } = useGetDeveloperLogsQuery();

  const options = {
    renderNode: {
      "embedded-asset-block": (node) => (
        <img alt={"post"} src={node.data.target.fields.file.url} />
      ),
    },
  };

  return (
    <Skeleton active loading={isLoading}>
      {developerLogs?.map((post) => (
        <Flex key={post.id} vertical justify={"center"}>
          <Flex justify={"space-between"} align={"center"} horizontal>
            <Title level={3} style={{ margin: "auto 0" }}>
              {post?.versionNumber}
            </Title>
            <Title level={5} style={{ margin: "auto 0" }}>
              {dayjs(post?.releaseDate).format("MMMM D, YYYY")}
            </Title>
          </Flex>
          {post?.body?.content?.map((document, i) => (
            <Text key={i} style={{ fontSize: "16px" }}>
              {documentToReactComponents(document, options)}
            </Text>
          ))}
        </Flex>
      ))}
    </Skeleton>
  );
};
