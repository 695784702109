import { useEffect, useRef } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { Grid } from "../components/Grid";
import { TileModal } from "features/widgets";
import { Icon } from "common";
import {
  useGetWidgetsForCustomerQuery,
  useUpdateWidgetInstancesMutation,
} from "features/widgets/redux";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import {
  setWidgetsForLayout,
  setEditModalOpen,
  setClickedAddButtonLayout,
} from "features/grid-layout/redux/layoutSlice";
import FullscreenLayout from "./FullscreenLayout";
import { useGetLayoutByIdQuery } from "../redux";
import { MAP_MODAL, PRODUCT_DASHBOARD } from "utils/defines";

const AddTileWrapper = styled.div`
  align-items: center;
  animation: all 1s;
  background-color: ${(props) => props.theme.themePrimary};
  border-radius: 50%;
  bottom: 40px;
  box-shadow: 0px 4px 8px rgba(0,0,0,.25);
  cursor: pointer;
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  fontSize: 40px;
  height: ${(props) => (props.isMapModal ? "44px" : "84px")};
  justify-content: center;
  outline: none;
  position: absolute;
  right: 40px;
  width: ${(props) => (props.isMapModal ? "44px" : "84px")};
}
`;
const Wrapper = styled.div`
  overflow-y: scroll;
  height: 100%;
  background-color: ${({ isFullscreen }) => (isFullscreen ? "white" : "")};
  padding-top: ${({ isMapModalLayout, isFullscreen }) =>
    isMapModalLayout || isFullscreen ? "0" : "5px"};
  -webkit-box-shadow: ${({ isMapModalLayout }) =>
    isMapModalLayout
      ? "none"
      : "inset 0px 30px 20px -30px rgba(0, 0, 0, 0.2);"};
  -moz-box-shadow: ${({ isMapModalLayout }) =>
    isMapModalLayout
      ? "none"
      : "inset 0px 30px 20px -30px rgba(0, 0, 0, 0.2);"};
  box-shadow: ${({ isMapModalLayout }) =>
    isMapModalLayout
      ? "none"
      : "inset 0px 30px 20px -30px rgba(0, 0, 0, 0.2);"};
`;
const normalizeByLayoutId = (acc, el) => {
  if (!acc[el.layout_id]) acc[el.layout_id] = [];
  acc[el.layout_id].push(el);
  return acc;
};

// https://stackoverflow.com/questions/53446020/how-to-compare-oldvalues-and-newvalues-on-react-hooks-useeffect
function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
/*
 * A Layout component wraps the Grid component.
 * It manages the data that is fed to the Grid
 *
 * It is invoked in LayoutTabs and MapModal
 */
const GridLayout = ({
  editModalOpen,
  editModeEnabled,
  clickedAddButton,
  isFullscreen = 0,
  isMapModalLayout, // from MapModal
  layoutID,
  openNotification,
  productID,
  setEditModalOpen,
  setClickedAddButtonLayout,
  setWidgetsForLayout,
  widgetType,
}) => {
  const customerId = useCustomerId();
  const { data: widgetsForCustomer } =
    useGetWidgetsForCustomerQuery(customerId);
  const { data: layout } = useGetLayoutByIdQuery(layoutID);
  const normalizedWidgets = widgetsForCustomer.reduce(normalizeByLayoutId, {});
  const widgetsForLayout = normalizedWidgets[layoutID] || [];
  const prevWidgetsForLayout = usePrevious(widgetsForLayout);
  const [updateWidgetInstances] = useUpdateWidgetInstancesMutation();
  const gridBottom = useRef(null);
  const scrollToGridBottom = () => {
    if (isMapModalLayout) return;
    setTimeout(() => {
      gridBottom?.current?.scrollIntoView({ behavior: "smooth" });
    }, 500);
  };
  const appendWidget = (widget) => {
    setWidgetsForLayout([...widgetsForLayout, widget], layoutID);
  };

  const section =
    layout?.section_name === MAP_MODAL ? MAP_MODAL : PRODUCT_DASHBOARD;

  const modalProps = {
    layoutID,
    appendWidget,
    widgetType,
    isMapModalLayout,
    position: widgetsForLayout.length,
    widgetsForLayout,
    onClose: () => {
      setEditModalOpen(false);
    },
    onCancel: () => {
      setEditModalOpen(false);
    },
    show: editModalOpen,
  };
  useEffect(() => {
    if (!prevWidgetsForLayout || !widgetsForLayout) return;
    if (
      prevWidgetsForLayout.length &&
      widgetsForLayout.length &&
      prevWidgetsForLayout[0].id !== widgetsForLayout[0].id
    )
      return;
    if (
      prevWidgetsForLayout.length &&
      prevWidgetsForLayout.length < widgetsForLayout.length
    )
      scrollToGridBottom();
  }, [widgetsForLayout, prevWidgetsForLayout]);

  // This checks to see if there is an empty space of 2 or more
  const checkForEmptySpot = () => {
    const mapModalMatrix = Array.from({ length: 12 }, () => 0);

    for (let i = 0; i < prevWidgetsForLayout?.length; i++) {
      const start = prevWidgetsForLayout[i].x;
      const end = start + prevWidgetsForLayout[i].width;
      for (let j = start; j <= end - 1; j++) {
        mapModalMatrix[j] = 1;
      }
    }

    for (let k = 0; k < mapModalMatrix?.length - 1; k++) {
      if (mapModalMatrix[k] === 0 && mapModalMatrix[k + 1] === 0) return k;
    }
    return false;
  };

  const handleClick = () => {
    if (!isMapModalLayout) {
      setEditModalOpen(true);
      setClickedAddButtonLayout(PRODUCT_DASHBOARD);
    } else {
      if (checkForEmptySpot() !== false) {
        setEditModalOpen(true);
        setClickedAddButtonLayout(MAP_MODAL);
      } else {
        openNotification("error");
      }
    }
  };

  return (
    <Wrapper
      className={`grid-wrapper ${
        editModeEnabled && !isFullscreen ? "grid-edit" : ""
      }`}
      isFullscreen={isFullscreen}
      isMapModalLayout={isMapModalLayout}
      useTopBorder={!isMapModalLayout}
    >
      {isFullscreen ? (
        <FullscreenLayout data={widgetsForLayout[0]} />
      ) : (
        <Grid
          customerId={customerId}
          data={widgetsForLayout}
          editModeEnabled={editModeEnabled}
          isMapModalLayout={isMapModalLayout}
          productID={productID}
          updateWidgetInstances={updateWidgetInstances}
          widgetType={widgetType}
        />
      )}
      {!(isFullscreen && widgetsForLayout.length >= 1) && (
        <AddTileWrapper
          isMapModal={isMapModalLayout}
          isVisible={editModeEnabled}
          onClick={handleClick}
        >
          <Icon
            filename="StaflSystemsFleet_ICONSET_Plus-Small.svg"
            style={{ filter: "invert(100%) brightness(1000%)" }}
          />
        </AddTileWrapper>
      )}
      {editModalOpen && section === clickedAddButton && (
        <TileModal
          modal_id="modal1"
          {...modalProps}
          availableXCoordinate={checkForEmptySpot()}
        />
      )}
      <div ref={gridBottom} />
    </Wrapper>
  );
};
const mapStateToProps = (state) => ({
  customerId: state.user.customerId,
  clickedAddButton: state.layout.clickedLayoutAddButton,
  editModeEnabled: state.layout.editModeEnabled,
  editModalOpen: state.layout.editModalOpen,
  editInstanceId: state.layout.editInstanceId,
});
const mapDispatchToProps = (dispatch) => ({
  setWidgetsForLayout: (widgets, layout) =>
    dispatch(setWidgetsForLayout({ widgets, layout })),
  setEditModalOpen: (editModalOpen, editWidgetId) =>
    dispatch(setEditModalOpen({ editModalOpen, editWidgetId })),
  setClickedAddButtonLayout: (layout) =>
    dispatch(setClickedAddButtonLayout(layout)),
});
export default connect(mapStateToProps, mapDispatchToProps)(GridLayout);
