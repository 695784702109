import * as API from "axios/axios";

export const userService = {
  SignUp: (data) => API.post("/api/users/", data),
  Login: (data) =>
    API.post("/api/users/login", data).catch((err) => {
      throw new Error(err.response.data.error);
    }),
  Logout: () => API.post("/api/users/logout"),
  RefreshAccess: (data) => API.post("/api/users/refreshToken", data),
  ForgotPassword: (data) => API.post("/api/users/forgotpassword", data),
  ConfirmForgotPassword: (data) =>
    API.post("/api/users/confirmforgotpassword", data).catch((err) => {
      throw new Error(err.response.data.error);
    }),
  ConfirmSignUp: (data) =>
    API.post("/api/users/confirm", data).catch((err) => {
      throw new Error(err.response.data.error.name);
    }),
  RequestNewConfirmationCode: (data) =>
    API.post("/api/users/newconfirmationcode", data),
  CompleteNewPasswordChallenge: (data) =>
    API.post("/api/users/newPasswordChallenge", data).catch((err) => {
      throw new Error(err.response.data.error);
    }),
  GetLoginDetails: (data) => API.post("/api/users/login-details", data),
  DeleteUser: (userId) => API.destroy(`/api/users/${userId}`),
  uploadImage: (data) => API.post("/api/users/image", data),
  update: (params) => API.post("/api/users/update", params),
};
