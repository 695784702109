import { useSelectedProduct } from "features/applications/hooks";
import * as Defines from "utils/defines";
import { Icon } from "common/Icon";
import {
  SidebarWrapper,
  SidebarNav,
  Separator,
  SidebarNavItem,
  ProductsList,
  BottomLogo,
} from "./sidebarComponents";
import { ChangelogVersion } from "features/changelog/components";
import { useContext } from "react";
import { AuthContext } from "features/auth/contexts";

export const Sidebar = () => {
  const { selectedProduct } = useSelectedProduct();
  const { isSuperAdmin } = useContext(AuthContext);
  return (
    <SidebarWrapper className="sidebar-wrapper">
      <SidebarNav>
        <SidebarNavItem
          menuSlug="dashboard"
          label="Dashboard"
          icon={Defines.S3_HOME_SIGNAL}
        />
        <SidebarNavItem
          menuSlug="units"
          label="Units"
          icon={Defines.S3_SETTINGS_UNIT}
        />
        {/* @TODO: Hide until ready */}
        {/* <SidebarNavItem
          menuSlug="usage"
          label="Usage"
          icon={Defines.S3_GRAPH_BAR}
        /> */}

        <Separator />
        {isSuperAdmin && (
          <>
            <SidebarNavItem
              menuSlug="applications"
              label="Applications"
              icon={Defines.S3_SETTING_APPLICATIONS}
              hasChildSelected={!!selectedProduct}
            >
              <ProductsList />
            </SidebarNavItem>
            <SidebarNavItem
              menuSlug="tcus"
              label="TCUs"
              icon={Defines.S3_PRODUCT_TCU}
            />
          </>
        )}
        <BottomLogo>
          <Icon
            style={{ width: "165px" }}
            fill="white"
            height="100%"
            hostedImage={Defines.S3_LOGO}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "right",
              paddingRight: "5px",
              marginTop: "-5px",
              marginBottom: "-20px",
              width: "100%",
            }}
          >
            <ChangelogVersion />
          </div>
        </BottomLogo>
      </SidebarNav>
    </SidebarWrapper>
  );
};
