import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import dayjs from "utils/dayjs";
import { useMqttSubscription } from "features/widgets/hooks/widgetsHooks";

const ResponseViewerWrapper = styled.div`
  height: 100%;
`;

const ResponseDisplay = styled.div`
  height: 95%;
  padding: 10px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  overflow-y: auto;
  font-size: 12px;
  border-radius: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
`;

const ResponseList = styled.ul`
  margin: 0;
  padding: 0;
  lineheight: 1;

  & > li {
    margin-bottom: 5px;
  }
`;

const AlwaysScrollToBottom = () => {
  const elementRef = useRef();
  useEffect(() => elementRef.current.scrollIntoView());
  return <div ref={elementRef} />;
};

const ApplicationsPushCommandResponseViewer = ({ tcu }) => {
  const initMessage = tcu ? `[Init] Token: ${tcu.token}` : "Init (no TCU)";
  const [allStatusMessages, setAllStatusMessages] = useState([initMessage]);

  const setLogMessage = (message, prefix = "*") => {
    const formattedDate = dayjs(Date.now()).format("YY-MM-DD HH:mm:ss");
    const formattedMessage = `[${formattedDate} ${prefix}]: ${message}`;
    setAllStatusMessages((prevMessages) => [...prevMessages, formattedMessage]);
  };

  const handleFleetToTCUMessage = ({ value: payload }) => {
    // the actual message was sent as a regular string
    // but the backend wraps the string as an object to play nicer with aws-amplify
    const messageObj = JSON.parse(JSON.stringify(payload));
    const message = messageObj.data;
    setLogMessage(message, "F->T");
  };

  const handleTCUtoFleetMessage = ({ value: payload }) => {
    const message = JSON.stringify(payload);
    setLogMessage(message, "T->F");
  };

  let fleetToTcuSubscription = useMqttSubscription({
    topic: `dprm/resp/${tcu?.token}/getC/fleet_cmd/fe`,
    onMessageReceived: handleFleetToTCUMessage,
  });

  let tcuToFleetSubscription = useMqttSubscription({
    topic: `dprm/resp/${tcu?.token}/getC/fleet_cmd/ack`,
    onMessageReceived: handleTCUtoFleetMessage,
  });

  useEffect(() => {
    setAllStatusMessages([initMessage]);
    if (!tcu) return;
    fleetToTcuSubscription.subscribe();
    tcuToFleetSubscription.subscribe();
    return () => {
      fleetToTcuSubscription.unsubscribe();
      tcuToFleetSubscription.unsubscribe();
    };
  }, [tcu]);

  if (!tcu) return <div>No TCU for selected SN</div>;

  return (
    <ResponseViewerWrapper>
      <ResponseDisplay>
        <ResponseList>
          {allStatusMessages.map((message, i) => (
            <li
              key={`message-${i}`}
              style={{
                fontWeight:
                  i + 1 === allStatusMessages.length ? "bold" : "normal",
              }}
            >
              {message}
            </li>
          ))}
        </ResponseList>
        <AlwaysScrollToBottom />
      </ResponseDisplay>
    </ResponseViewerWrapper>
  );
};

export default ApplicationsPushCommandResponseViewer;
