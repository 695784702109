import styled, { withTheme } from "styled-components";
import { GetFilter } from "utils/Color";

const StyledImg = styled.img<{ themeColor: string }>`
  filter: ${(props: any) =>
    props.themeColor ? GetFilter(props.themeColor) : "none"};
`;

interface SVGProps {
  style?: React.CSSProperties;
  width?: string;
  height?: string;
  opacity?: string;
  className?: string;
  viewBox?: string;
  hostedImage?: string;
  filename: string;
  theme?: any;
  onClick?(): any;
}

const SVG: React.FC<SVGProps> = ({
  style = {},
  width = "20px",
  height = "20px",
  opacity = "1",
  className = "",
  viewBox = "0 0 32 32",
  hostedImage,
  filename,
  theme,
  onClick,
}) => {
  // if a hosted image is given, it will try to use this first (image from the server, etc)
  // the filename is a default used for local files
  return theme ? (
    <StyledImg
      style={style}
      width={width}
      height={height}
      src={hostedImage ? hostedImage : "/assets/images/" + filename}
      alt=""
      className={className}
      themeColor={theme.themePrimary}
      draggable={false}
      onClick={onClick}
    />
  ) : (
    <img
      style={style}
      width={width}
      height={height}
      className={className}
      src={hostedImage ? hostedImage : "/assets/images/" + filename}
      alt=""
      draggable={false}
      onClick={onClick}
    />
  );
};

export const ThemedIcon = withTheme(SVG);

export const Icon: React.FC<SVGProps> = SVG;
