import { Button, Form, Input, InputNumber, Select, Typography } from "antd";

export const CreateApplicationUnitForm = ({
  application,
  buttonMarginRight,
  handleCreateApplicationUnitsInBulk,
  handleSkipAndFinish,
  wrapperColStyle,
  labelColStyle,
  formStyle,
  formVariant,
  titleMargin,
}) => {
  const [form] = Form.useForm();
  const nameValue = Form.useWatch("product_sn_name", form);
  return (
    <>
      <Form
        form={form}
        initialValues={{
          product_id: application.id,
        }}
        layout="horizontal"
        onFinish={handleCreateApplicationUnitsInBulk}
        style={formStyle}
        variant={formVariant}
        wrapperCol={wrapperColStyle}
        labelCol={labelColStyle}
        titleMargin={titleMargin}
      >
        <h4 style={titleMargin}>Create Application (Optional)</h4>
        <Form.Item
          label="Application Unit Name Prefix"
          name="product_sn_name"
          tooltip="Application unit name will be Application unit name + unit number suffix (i.e TOYOTA_TUG_1, TOYOTA_TUG_2, etc.)"
          required
        >
          <Input placeholder="i.e TOYOTA_TUG" />
        </Form.Item>
        <Typography style={{ marginBottom: "40px" }}>
          <pre>
            Application Unit Name Preview: {nameValue}
            {nameValue && "_1"}
          </pre>
        </Typography>
        <Form.Item
          label="Application ID"
          name="product_id"
          initialValue={application.id}
        >
          <Select disabled placeholder={application.id} />
        </Form.Item>
        <Form.Item
          label="Quantity of Application Units"
          name="applications_quantity"
          required
        >
          <InputNumber min={1} />
        </Form.Item>
        <Form.Item>
          <Button style={buttonMarginRight} onClick={handleSkipAndFinish}>
            Skip and Finish
          </Button>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};
