import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-y: scroll;
`;

export const WidgetTable = styled.div`
  display: flex;
  flex-direction: column;
  // border: 2px solid grey;
  width: 100%;
  height: ${(props) => (props.fullHeight ? "100%" : "auto")};
`;

export const TableTitle = styled.div`
  // border-bottom: 1px solid grey;
  font-size: 20px;
  height: 40px;
  padding: 5px 25px;
`;

export const TableBody = styled.div``;

// HeaderRow and HeaderCell
// are copied from ProductsOverlay but separated
// to prevent coupling
export const HeaderRow = styled.div`
  display: flex;
  border-bottom: 1px solid lightgrey;
  height: 40px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  padding: 0 25px;
`;
export const HeaderCell = styled.div`
  flex: 1;
  display: flex;
  align-self: center;
  font-weight: 500;
`;

export const Row = styled.div`
  display: flex;
  height: 40px;
  align-items: center;
  // border-bottom: 1px solid black;
  // border-bottom: 2px solid grey;
  padding: 0px 25px;
  &:not(:only-child) {
    background-color: rgba(1, 1, 1, 0.05);
  }

  &:nth-child(2n) {
    background-color: transparent;
  }
  &.clickable {
    cursor: pointer;
  }
  &.highlightableRow,
  &.eventRow {
    .time__absolute {
      display: none;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.07);
      .time__absolute {
        display: block;
      }
      .time__relative {
        display: none;
      }
    }
  }
`;

export const RowCell = styled.div`
  // width: 100px;
  text-align: ${(props) => (props.centered ? "center" : "left")};
  flex: 1;

  & .addNoteLink {
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SignalValue = styled.div`
  // width: 100px;
  text-align: left;
  flex: 1;
`;
