import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
const TimSort = require("timsort");

const initialState = {
  dataHistory: {},
  signals: [],
  signalData: {},
  lastUpdated: {},
  fetching: false,
  tcus: [],
  isLive: true,
};

function orderByTimestamp(dataArray) {
  TimSort.sort(
    dataArray,
    (a, b) => parseInt(a.timestamp_recorded) - parseInt(b.timestamp_recorded)
  );
  return dataArray;
}

function mergeSignalData(existingSignalData, newSignalData) {
  // pushing is faster than spread/concat
  for (let dataPoint of newSignalData) {
    existingSignalData.push(dataPoint);
  }

  const sortedData = orderByTimestamp(existingSignalData);

  const uniqData = _.sortedUniqBy(sortedData, "timestamp_recorded");

  return uniqData;
}

function mergePSNData(existingPSNData, newPSNData) {
  let combinedData = Object.assign({}, existingPSNData);

  const newSignalIds = Object.keys(newPSNData);

  for (let signal_id of newSignalIds) {
    if (!combinedData[signal_id]) {
      combinedData[signal_id] = [...newPSNData[signal_id]];
    } else {
      combinedData[signal_id] = mergeSignalData(
        combinedData[signal_id],
        newPSNData[signal_id]
      );
    }
  }

  return combinedData;
}

function combineHistories(oldHistory, newHistory) {
  let combinedHistory = Object.assign({}, oldHistory);

  const newHistoryPSNIds = Object.keys(newHistory);

  for (let psn_id of newHistoryPSNIds) {
    if (!combinedHistory[psn_id]) {
      combinedHistory[psn_id] = Object.assign({}, newHistory[psn_id]);
    } else {
      combinedHistory[psn_id] = mergePSNData(
        combinedHistory[psn_id],
        newHistory[psn_id]
      );
    }
  }
  return combinedHistory;
}

function filterSignalData(signalData, dataIntervals) {
  if (signalData.length < 2) return signalData;

  // always save last data point, which is used for non-historical widgets
  const lastDataPoint = signalData[signalData.length - 1];

  // perform filtering
  // NAIVE - brute force check each interval
  // @TODO - use segment tree or interval tree: https://stackoverflow.com/a/10130840
  // @TODO - save one extra point just outside of range on each side
  const filteredData = [];
  for (let i = 0; i < signalData.length - 1; i++) {
    const dataPoint = signalData[i];
    const { timestamp_recorded } = dataPoint;
    let isInRange = false;
    for (let interval of dataIntervals) {
      const [startDate, endDate] = interval;
      if (timestamp_recorded >= startDate && timestamp_recorded <= endDate) {
        isInRange = true;
      }
    }
    if (isInRange) filteredData.push(dataPoint);
  }
  filteredData.push(lastDataPoint);
  return filteredData;
}

function filterPSNData(psnData, dataIntervals) {
  const filteredData = Object.assign({}, psnData);
  const signalIds = Object.keys(filteredData);

  for (let signal_id of signalIds) {
    filteredData[signal_id] = filterSignalData(
      filteredData[signal_id],
      dataIntervals
    );
  }

  return filteredData;
}

function filterHistories(currentHistory, dataIntervals) {
  const newHistory = Object.assign({}, currentHistory);
  const psnIds = Object.keys(newHistory);

  for (let psn_id of psnIds) {
    newHistory[psn_id] = filterPSNData(newHistory[psn_id], dataIntervals);
  }
  return newHistory;
}

function mergeIntervals(intervals) {
  const mergedIntervals = [];
  // NAIVE - just pass all intervals through
  // TODO - optimize by merging overlapping intervals
  const instanceIds = Object.keys(intervals);
  for (let instanceId of instanceIds) {
    mergedIntervals.push(intervals[instanceId]);
  }
  return mergedIntervals;
}

function limitDataPoints(history, maxPoints) {
  const limitedHistory = {};

  for (const [psn_id, signalData] of Object.entries(history)) {
    limitedHistory[psn_id] = {};

    for (const [signal_id, dataPoints] of Object.entries(signalData)) {
      // If data points exceed maxPoints, remove every 5th data point
      if (dataPoints.length > maxPoints) {
        const step = 5;
        const filteredDataPoints = dataPoints.filter(
          (_, index) => (index + 1) % step !== 0
        );
        limitedHistory[psn_id][signal_id] = filteredDataPoints;
      } else {
        limitedHistory[psn_id][signal_id] = dataPoints;
      }
    }
  }

  return limitedHistory;
}
export const productSNDataSlice = createSlice({
  name: "productSNData",
  initialState,
  reducers: {
    setDataHistory: (state, action) => {
      const newHistory = action.payload;
      if (!newHistory || !Object.keys(newHistory).length) return state;

      let { dataHistory: oldHistory, mergedIntervals } = state;

      // Combine old and new histories
      let combinedHistory = combineHistories(oldHistory, newHistory);

      // Filter combined history based on merged intervals if applicable
      if (mergedIntervals) {
        combinedHistory = filterHistories(combinedHistory, mergedIntervals);
      }

      state.dataHistory = limitDataPoints(combinedHistory, 500);
    },
    setDataInterval: (state, action) => {
      const { instanceId, dateRange } = action.payload;
      const { dataIntervals: currentIntervals } = state;
      const newIntervals = {
        ...currentIntervals,
        [instanceId]: dateRange,
      };
      const newMergedIntervals = mergeIntervals(newIntervals);
      state.dataIntervals = newIntervals;
      state.mergedIntervals = newMergedIntervals;
    },
    setIsLiveMode: (state, action) => {
      // start/stop interval (linechart, map)
      state.isLive = action.payload;
    },
  },
});

export const { setDataHistory, setDataInterval, setIsLiveMode } =
  productSNDataSlice.actions;

export default productSNDataSlice.reducer;
