import { useState } from "react";
import styled from "styled-components";
import { createColumnHelper } from "@tanstack/react-table";
import { useSelectedProduct } from "features/applications/hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
import {
  useGetParametersForProductQuery,
  useUpdateProductParameterMutation,
} from "features/applications/redux";
import { Table, Button } from "common/elements";
import { RateOptionsSelect } from "./RateOptionsSelect";
import { MESSAGE_FILTERS_LABEL } from "utils/defines";
import { Spinner } from "common/Spinner";

const CanMessageFilterWrapper = styled.div`
  height: 100%;
  padding: 20px;
`;

const SectionTitle = styled.h3`
  font-size: 1.4rem;
  font-weight: normal;
`;

const columnHelper = createColumnHelper();

const valIsNotZero = ([key, val]) => val !== "0";

const combineMessageFilterValues = (initValues, newValues) => {
  const combinedUpdates = {
    ...initValues,
    ...newValues,
  };
  const processedUpdates = Object.fromEntries(
    Object.entries(combinedUpdates).filter(valIsNotZero)
  );
  return processedUpdates;
};

const CanMessageFiltersTable = () => {
  const customerId = useCustomerId();
  const { selectedProduct } = useSelectedProduct();
  const { data: productParameters, isLoading: isParametersLoading } =
    useGetParametersForProductQuery(selectedProduct.id);
  const { data: signalsAndMessages, isLoading: isMessagesLoading } =
    useGetSignalsForCustomerQuery(customerId);
  const [updateProductParameter] = useUpdateProductParameterMutation();

  const [isFormPristine, setIsFormPristine] = useState(true);
  const [filterUpdates, setFilterUpdates] = useState({});

  if (isParametersLoading || isMessagesLoading)
    return <Spinner style={{ height: "100%" }} />;

  if (!productParameters.length) return <div>Error loading parameters</div>;
  if (!signalsAndMessages.messages) return <div>Error loading messages</div>;

  const { messages } = signalsAndMessages;
  const messageFiltersParam = productParameters.find(
    (productParameter) => productParameter.name === MESSAGE_FILTERS_LABEL
  );
  if (!messageFiltersParam) return <div>Error loading capture rates</div>;
  const messageFiltersValue = JSON.parse(messageFiltersParam.value);

  const compiledMessageFilterValues = combineMessageFilterValues(
    messageFiltersValue,
    filterUpdates
  );

  const handleFrequencySelection = (decimalId) => (selection) => {
    setIsFormPristine(false);
    setFilterUpdates({
      ...filterUpdates,
      [decimalId]: selection,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProductParameter({
      ...messageFiltersParam,
      value: JSON.stringify(compiledMessageFilterValues),
    });
    setIsFormPristine(true);
  };

  const columns = [
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("hex_id", { header: "ID" }),
    columnHelper.accessor("is_filter_in", { header: "Is Filter In" }),
    columnHelper.accessor("frequency", {
      header: "Frequency",
      cell: ({ row, getValue }) => {
        const { decimal_id } = row.original;
        return (
          <div style={{ minWidth: "90px" }}>
            <RateOptionsSelect
              value={getValue()}
              onOptionSelected={handleFrequencySelection(decimal_id)}
            />
          </div>
        );
      },
    }),
  ];

  const filteredMessages = messages.filter(
    (message) => message.product_id === selectedProduct.id
  );
  const formattedMessageData = filteredMessages.map((message) => {
    const hex_id = `0x${parseInt(message.decimal_id).toString(16)}`;
    const frequency = compiledMessageFilterValues[message.decimal_id];
    const is_filter_in = !!frequency;
    return {
      ...message,
      hex_id,
      is_filter_in,
      frequency: frequency ? parseFloat(frequency).toFixed(1) : "0",
    };
  });

  return (
    <>
      <SectionTitle>CAN Message Filters</SectionTitle>
      <Table
        columns={columns}
        data={formattedMessageData}
        showFilterInput={true}
      />
      <Button onClick={handleSubmit} disabled={isFormPristine}>
        Save
      </Button>
    </>
  );
};

const CanMessageFiltersSection = () => {
  return (
    <CanMessageFilterWrapper>
      {/** @TODO standardize h1-h6s across app
       * use this one as reference for h3
       */}

      <CanMessageFiltersTable />
    </CanMessageFilterWrapper>
  );
};

export default CanMessageFiltersSection;
