import styled from "styled-components";
import * as Defines from "utils/defines";

const CheckboxContainer = styled.label`
  // customize label (container)
  display: block;
  position: relative;
  padding-left: 25px;
  margin-left: 7px;
  margin-bottom: 0;
  cursor: pointer;
  user-select: none;
  font-size: 15px;

  // hide default checkbox
  & input[type="checkbox"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // custom chekbox
  & .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: ${(props) => (props.size ? props.size : "20px")};
    width: ${(props) => (props.size ? props.size : "20px")};
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 5px;

    // create checkmark indicator
    &:after {
      // hidden when unchecked
      content: url(https://img.icons8.com/metro/18/000000/checkmark.png);
      filter: invert(1);
      position: absolute;
      display: none;

      // styling the indicator
      // top: -1px;
      // left: 0px;
      // top: 50%;
      // left: 50%;
      // tranform: translate(-50%, -50%);
      height: ${(props) => (props.size ? props.size : "20px")};
      width: ${(props) => (props.size ? props.size : "20px")};
    }
  }

  // on mouseover, add a bg color
  &:hover input ~ .checkmark {
    background-color: ${Defines.GENERIC_CHECKBOX_BG_COLOR};
    border-color: ${Defines.GENERIC_CHECKBOX_BG_COLOR};
  }

  // when checked, add a bg color
  & input:checked ~ .checkmark {
    background-color: ${Defines.GENERIC_CHECKBOX_BG_COLOR};
    border-color: ${Defines.GENERIC_CHECKBOX_BG_COLOR};
  }

  // show checkmark when checked
  & input:checked ~ .checkmark:after {
    display: block;
  }
`;

const RadioContainer = styled.label`
  // customize label (container)
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  // hide default radio button
  & input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  // create custom radio button
  & .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid grey;
    border-radius: 50%;

    // creating the indicator
    &:after {
      // hidden when not checked
      content: "";
      position: absolute;
      display: none;

      // styling the indicator
      left: 2px;
      top: 2px;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.8);
    }
  }

  // add a background color on hover
  &:hover input ~ .checkmark {
    background-color: ${(props) => props.theme.themeSecondary};
    border-color: ${(props) => props.theme.themeSecondary};
  }

  // when checked, add a different color background
  & input:checked ~ .checkmark {
    background-color: ${(props) => props.theme.themeSecondary};
    border-color: ${(props) => props.theme.themeSecondary};
  }

  // show indictor (dot/circle) when checked
  & input:checked ~ .checkmark:after {
    display: block;
  }
`;

export const RadioButton = ({
  name,
  checked,
  style,
  children: label,
  onClick,
  onChange,
}) => (
  <RadioContainer style={style} onClick={onClick}>
    {label}
    <input type="radio" name={name} checked={checked} onChange={onChange} />
    <span className="checkmark"></span>
  </RadioContainer>
);

export const Checkbox = ({
  name,
  checked,
  style,
  children: label,
  onClick,
  onChange,
  size,
}) => (
  <CheckboxContainer size={size} style={style} onClick={onClick}>
    {label}
    <input type="checkbox" name={name} checked={checked} onChange={onChange} />
    <span className="checkmark"></span>
  </CheckboxContainer>
);
