import { useSelector, useDispatch } from "react-redux";
import styled, { useTheme } from "styled-components";
import { Icon } from "common";
import ProgressBar from "common/ProgressBar";
import { connect } from "react-redux";
import { withTheme } from "styled-components";
import { getIsUnitActive, getTimeSince } from "utils";
import {
  setCurrentProduct as setCurrentProductAction,
  overlaySortUnitName,
} from "features/applications/redux";
import {
  setActiveMapMarker as setActiveMapMarkerAction,
  setOverlayVertical,
  setOverlayShown,
} from "features/map/redux/mapSlice";
import { GetFilter } from "utils/Color";
import { ProductsOverlayStyling } from "styles/GlobalStyles";
import * as Defines from "utils/defines";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetProductSettingsForCustomerQuery } from "features/applications/redux";
import { useSignalUISettingsForProductSN } from "features/applications/hooks";
import { setUnitsViewMode } from "features/grid-layout";
const { Wrapper, Overlay, HeaderRow, HeaderCell, Row, RowCell } =
  ProductsOverlayStyling;

const OverlayButton = styled.button`
  opacity: ${(props) => (props.active ? "1" : ".3")};
  margin: 10px;
  border: none;
  outline: none !important;
  display: flex;
  align-items: center;
`;

const OverlayHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  height: 80px;
  padding: 0 10px;
  font-size: 15px;
`;

const OverlayListWrapper = styled.div`
  flex: 1;
  overflow-y: scroll;
`;

const ContentRowWrapper = styled.div`
  overflow-y: scroll;
  padding: 5px;
`;

const OverlayHeader = ({ verticalView, setVerticalView, setOverlayShown }) => (
  <OverlayHeaderWrapper className="map-overlay-header">
    {/* {Defines.ENABLE_PRODUCTOVERLAY_MODE_SWITCHER ? (
      <>
        <OverlayButton
          className="map-overlay-header-button"
          onClick={() => setVerticalView(true)}
          active={verticalView}
        >
          <Icon
            width={Defines.ICON_SIZE_XS}
            height={Defines.ICON_SIZE_XS}
            // @TODO: replace with S3 URL
            filename="StaflSystemsFleet_ICONSET_List_VerticalSplit.svg"
          />
          <span
            className="map-overlay-header-text"
            style={{ marginLeft: "6px" }}
          >
            Vertical
          </span>
        </OverlayButton>
        <OverlayButton
          className="map-overlay-header-button"
          onClick={() => setVerticalView(false)}
          active={!verticalView}
        >
          <Icon
            width={Defines.ICON_SIZE_XS}
            height={Defines.ICON_SIZE_XS}
            // @TODO: replace with S3 URL
            filename="StaflSystemsFleet_ICONSET_List_HorizontalSplit.svg"
          />
          <span
            className="map-overlay-header-text"
            style={{ marginLeft: "6px" }}
          >
            Horizontal
          </span>
        </OverlayButton>
      </>
    ) : ( */}
    <div
      style={{
        padding: "0 24px",
        transform: "translateX(5px)",
      }}
    >
      <Icon
        width={Defines.ICON_SIZE_MD}
        height={Defines.ICON_SIZE_MD}
        hostedImage={Defines.S3_SIDEBAR_LEFT_UNITS_LIST}
        style={{ marginRight: "2px", marginTop: "2px" }}
      />{" "}
      <span style={{ display: "inline-block", transform: "translateY(2px)" }}>
        Units
      </span>
    </div>
    {/* )} */}
    <span style={{ flex: 1, display: "flex", opacity: "1" }}>
      <OverlayButton
        style={{ marginLeft: "auto", opacity: ".8" }}
        className="map-overlay-close-button"
        onClick={() => setOverlayShown(false)}
        active={true}
      >
        <Icon
          width={Defines.ICON_SIZE_XL}
          height={Defines.ICON_SIZE_XL}
          hostedImage={Defines.S3_CLOSE_X}
        />
      </OverlayButton>
    </span>
  </OverlayHeaderWrapper>
);

const UnitsOverlayRow = ({ productSN }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const activeMarker = useSelector((state) => state.map.marker);
  const mapCenter = useSelector((state) => state.map.mapCenter);
  const currentProduct = useSelector((state) => state.products.product);
  const verticalView = useSelector((state) => state.map.overlayVertical);
  const filteredProducts = useSelector(
    (state) => state.products.filteredProducts
  );
  const setActiveMapMarker = (marker, coordinates) =>
    dispatch(setActiveMapMarkerAction({ marker, coordinates }));
  const setCurrentProduct = (product) =>
    dispatch(setCurrentProductAction(product));

  const getOpacity = (id) =>
    filteredProducts.length && !filteredProducts.includes(id) ? ".3" : "1";
  const iconColor = theme.themePrimary;

  const {
    unitSOCVal,
    unitSOHVal,
    unitRSSIVal,
    unitLatVal,
    unitLngVal,
    unitLastUpdated,
  } = useSignalUISettingsForProductSN(productSN);

  const timeSinceText = unitLastUpdated
    ? getTimeSince(unitLastUpdated)
    : "unknown";

  // DRY pattern
  const isIconNearCenter = () => {
    const DIFFERENCE_THRESHOLD = 0.002;
    const difference = (a, b) => Math.abs(a - b);
    const latIsClose =
      difference(mapCenter.lat, unitLatVal) < DIFFERENCE_THRESHOLD;
    const lngIsClose =
      difference(mapCenter.lng, unitLngVal) < DIFFERENCE_THRESHOLD;
    const result = latIsClose && lngIsClose;
    return result;
  };

  const setViewMode = (viewMode) => dispatch(setUnitsViewMode(viewMode));

  return (
    // Entire Row
    <Row
      className="map-overlay-list-row"
      isActive={activeMarker === productSN.id}
      onClick={() => {
        // if lat,lng are invalid, map does set activeMarker but does nothing on invalid mapCenter
        currentProduct && setCurrentProduct(productSN.id);
        setActiveMapMarker(productSN.id);
      }}
      style={{ height: verticalView ? "60px" : "40px", cursor: "pointer" }}
    >
      {/* Unit Icon */}
      <RowCell style={{ width: "30px", justifyContent: "center" }}>
        <Icon
          style={{
            filter: GetFilter(iconColor),
            marginTop: "-11px",
            marginRight: "-10px",
          }}
          hostedImage={Defines.S3_MAP_PIN_UNIT_A}
        />
      </RowCell>
      {/* Unit Name */}
      <RowCell
        style={{
          opacity: getOpacity(productSN.id),
          paddingLeft: "10px",
          flex: "1",
          overflowY: "auto",
          fontSize: "90%",
          lineHeight: "1",
          overflowX: "hidden", // hide scrollbar on long names
          position: "relative",
        }}
      >
        <div style={{ width: "100%" }}>
          <div
            style={{
              marginBottom: verticalView ? "5px" : "",
              fontWeight: "400",
            }}
          >
            {productSN.product_sn_name}
            <Icon
              style={{
                marginLeft: "10px",
              }}
              hostedImage={
                getIsUnitActive(unitLastUpdated)
                  ? Defines.S3_STATUS_DOT_ON
                  : Defines.S3_STATUS_DOT_OFF
              }
              width={Defines.ICON_SIZE_XS}
              height={Defines.ICON_SIZE_XS}
            />
          </div>
          {verticalView ? (
            <div style={{ marginTop: "10px" }}>
              <ProgressBar
                barHeight={8}
                soc={unitSOCVal}
                soh={100}
                mapMode={true}
              />
              {/* Leaving this here for potential future use */}
              {/* <div style={{ marginTop: "5px", fontSize: "10px" }}>
                <Icon
                  style={{
                    width: "10px",
                    height: "auto",
                    marginRight: "5px",
                    opacity: "0.5",
                    transform: "translate(0, -1px)",
                  }}
                  hostedImage={Defines.S3_ARROWS_ROTATE}
                />
                {timeSinceText}
              </div> */}
            </div>
          ) : null}
        </div>
        {/* Maximize Icon */}
        <div style={{ position: "absolute", top: "10px", right: "10px" }}>
          <span
            className="interaction-icon"
            onClick={(e) => {
              e.stopPropagation();
              setViewMode("map");
              setActiveMapMarker(productSN.id, {
                lat: unitLatVal,
                lng: unitLngVal,
              });
              setCurrentProduct(null);
            }}
          >
            <Icon
              style={{
                transform: "translate(6px, 0)",
                opacity: unitLatVal && unitLngVal ? "1" : "0.2",
              }}
              hostedImage={
                isIconNearCenter()
                  ? Defines.S3_LIST_LOCATION_ON
                  : Defines.S3_LIST_LOCATION_OFF
              }
              width={18}
              height={18}
            />
          </span>
          <span
            className="interaction-icon"
            style={{
              display: "inline-block",
              opacity: !currentProduct ? "1" : "0.2",
            }}
            onClick={() =>
              !currentProduct ? setCurrentProduct(productSN.id) : null
            }
          >
            <Icon
              style={{
                transform: "translate(6px, 0)",
              }}
              hostedImage={Defines.S3_ARROWS_EXPAND}
              width={Defines.ICON_SIZE_XS}
              height={Defines.ICON_SIZE_XS}
            />
          </span>
        </div>
      </RowCell>
      {/* SOC Bar */}
      {!verticalView ? (
        <RowCell
          style={{
            flex: "1",
            opacity: getOpacity(productSN.id),
            fontSize: "75%",
            lineHeight: ".9",
            padding: "0px 10px",
          }}
        >
          {unitSOCVal ? (
            <div style={{ width: "90%" }}>
              <ProgressBar soc={unitSOCVal} soh={100} mapMode={true} />
            </div>
          ) : (
            "unknown"
          )}
        </RowCell>
      ) : null}
      {/* last updated */}
      {!verticalView && (
        <>
          <RowCell style={{ flex: "1", fontSize: "75%", lineHeight: ".9" }}>
            {timeSinceText}
          </RowCell>
          <RowCell style={{ flex: "1", fontSize: "75%", lineHeight: ".9" }}>
            {unitSOCVal ? `${unitSOCVal}%` : "unknown"}
          </RowCell>
          <RowCell style={{ flex: "1", fontSize: "75%", lineHeight: ".9" }}>
            {unitSOHVal ? `${unitSOHVal}%` : "unknown"}
          </RowCell>
          <RowCell style={{ flex: "1", fontSize: "75%", lineHeight: ".9" }}>
            {unitRSSIVal
              ? `${unitRSSIVal} (${Math.floor(
                  (parseFloat(unitRSSIVal) * 100) / 31.0
                )}%)`
              : "unknown"}
          </RowCell>
        </>
      )}
    </Row>
  );
};

const UnitsOverlay = (props) => {
  const {
    active,
    overlaySortUnitName,
    overlayVertical: verticalView, // redux replace useState
    setVertical: setVerticalView, // redux replace useState
    overlayShown, // redux replace useState
    setOverlayShown, // redux replace useState
  } = props;

  const customerId = useCustomerId();
  const { data: productSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });

  // 20230411 - disabling filtering because not being used and old filter function is deprecated
  const filteredSNs = productSNs || [];

  return (
    <Wrapper disabled={!active} className="map-overlay-wrapper">
      <Overlay
        className="map-overlay"
        active={active}
        vertical={verticalView}
        overlayShown={overlayShown}
      >
        <OverlayHeader
          verticalView={verticalView}
          setVerticalView={setVerticalView}
          setOverlayShown={setOverlayShown}
        />
        <OverlayListWrapper className="map-overlay-list">
          {!verticalView ? (
            <HeaderRow className="map-overlay-list-header">
              <HeaderCell style={{ width: "30px", justifyContent: "center" }}>
                {/* Unit Icon */}
              </HeaderCell>
              <HeaderCell
                style={{ flex: "1" }}
                onClick={() => overlaySortUnitName()}
              >
                Unit Name
              </HeaderCell>
              <HeaderCell style={{ flex: "1" }}>Pack SOC</HeaderCell>
              <HeaderCell style={{ flex: "1" }}>
                <Icon
                  style={{
                    marginRight: "5px",
                    opacity: "0.5",
                    transform: "translate(0, -1px)",
                  }}
                  hostedImage={Defines.S3_ARROWS_ROTATE}
                  width={Defines.ICON_SIZE_XS}
                  height={Defines.ICON_SIZE_XS}
                />{" "}
                Updated
              </HeaderCell>
              <HeaderCell style={{ flex: "1" }}>SOC %</HeaderCell>
              <HeaderCell style={{ flex: "1" }}>Pack SOH</HeaderCell>
              <HeaderCell style={{ flex: "1" }}>RSSI</HeaderCell>
            </HeaderRow>
          ) : null}
          <ContentRowWrapper>
            {filteredSNs?.map((productSN) => (
              <UnitsOverlayRow productSN={productSN} key={productSN.id} />
            ))}
          </ContentRowWrapper>
        </OverlayListWrapper>
      </Overlay>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  currentProduct: state.products.product,
  activeMarker: state.map.marker,
  mapCenter: state.map.mapCenter,
  filteredProducts: state.products.filteredProducts,
  filteredProductsSidebar: state.products.filteredProductsSidebar,
  filteredStates: state.products.filteredStatusTypes,
  productsDataMaster: state.products.productsDataMaster,
  customerId: state.user.customerId,
  overlayShown: state.map.overlayShown,
  overlayVertical: state.map.overlayVertical,
  allSignals: state.productSNData.signals,
  dataHistory: state.productSNData.dataHistory,
  productSettings: state.products.productSettings,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveMapMarker: (marker, coordinates) =>
    dispatch(setActiveMapMarkerAction({ marker, coordinates })),
  overlaySortUnitName: () => dispatch(overlaySortUnitName()),
  setCurrentProduct: (product) => dispatch(setCurrentProductAction(product)),
  setVertical: (vertical) => dispatch(setOverlayVertical(vertical)),
  setOverlayShown: (overlayShown) => dispatch(setOverlayShown(overlayShown)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(UnitsOverlay));
