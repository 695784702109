import styled from "styled-components";
import { Icon } from "common";

const HeaderWrapper = styled.div`
  // position: fixed;
  // z-index: 1000;
  // top: 0;
  // background-color: ${(props) =>
    props.activeProduct ? "white" : "rgba(255, 255, 255, .7)"};
  transition: all 0.5s;
  height: ${(props) => props.theme.headerHeight};
  // width: 100vw;
  // border-bottom: ${(props) => props.theme.sidebarBorder};
  display: flex;
`;

const Header = styled.div`
  cursor: pointer;
  display: flex;
  margin-top: auto;
  padding: 10px 20px;
  // display: ${(props) => (props.activeProduct ? "none" : "inherit")};
  user-select: none;
  transition: all 0.5s;
  &[disabled] {
    pointer-events: none;
    opacity: 0;
  }
`;
const HeaderLeft = styled.div`
  display: flex;
`;

const HeaderRight = styled(HeaderLeft)``;

const HeaderItem = styled.span`
  margin: 0px 3px;
`;
const ArrowIcon = styled.span`
  transform: ${(props) =>
    props.showDevices ? "" : "rotate(180deg) translateY(3px)"};
  transition: all 0.7s;
  margin: 0px 3px;
`;

const UnitsHeader = ({ onClickHandler, showDevices, activeProduct }) => (
  <HeaderWrapper activeProduct={activeProduct}>
    <Header
      activeProduct={activeProduct}
      disabled={activeProduct}
      onClick={onClickHandler}
    >
      <HeaderLeft>
        <ArrowIcon showDevices={showDevices}>
          {/* @TODO: replace with S3 URL */}
          <Icon filename="left_arrow_icon.svg" />
        </ArrowIcon>
        <HeaderItem>
          {/* @TODO: replace with S3 URL */}
          <Icon filename="hamburger_icon.svg" />
        </HeaderItem>
        <HeaderItem>
          <span style={{ fontWeight: "500" }}>
            {showDevices ? "Hide List" : "Show List"}
          </span>
        </HeaderItem>
      </HeaderLeft>
    </Header>
  </HeaderWrapper>
);

export default UnitsHeader;
