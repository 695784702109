import { Table } from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW } from "features/application-devices/utils/Constants";
import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";
import { Spinner } from "common/Spinner";

export const ApplicationDeviceViewDetailsTable = ({
  qApplicationDevicePair,
  qCustomer,
  qApplication,
  qApplicationDeviceType,
  isPageLoaded,
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [applicationDeviceDetails, setApplicationDeviceDetails] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplicationDeviceData = {
        ...qApplicationDevicePair,
        key: qApplicationDevicePair.id,
        application: qApplication.product_name,
        application_device_type: qApplicationDeviceType.name,
        customer: qCustomer.name,
      };
      setApplicationDeviceDetails([updatedApplicationDeviceData]);
    }
  }, [
    qApplicationDevicePair,
    qCustomer,
    qApplicationDeviceType,
    qApplication,
    isPageLoaded,
  ]);

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      disabledCheckbox: false,
      default: true,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.product_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Application Device Type",
      dataIndex: "application_device_type",
      key: "application_device_type",
      disabledCheckbox: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW}/${record.device_type_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      disabledCheckbox: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.customer_id}`}>
          {text}
        </Link>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={applicationDeviceDetails}
      bordered
      pagination={false}
    />
  );
};
