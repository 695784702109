import React, { useState } from "react";
import { userService } from "features/users/services";
import { Link } from "react-router-dom";
import {
  AuthFormLink,
  Button,
  ErrorMessage,
  LoginTemplate,
  StyledInput,
} from "./loginComponents";
import LoadingSpinner from "common/LoadingSpinner";

export function ForgotPassword() {
  const [email, setEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await userService.ForgotPassword({ email });
      setSuccess(true);
    } catch (err) {
      if (err instanceof Error) setError(err.message);
    }
    setIsLoading(false);
  };

  const SuccessMessage = () => (
    <div>
      <p>
        <strong>Reset password</strong>
      </p>
      <p>Check your email for the confirmation code to reset your password.</p>
      <p>
        <Link to="/reset-password">I received the confirmation code</Link>
      </p>
    </div>
  );

  return (
    <LoginTemplate>
      {isLoading ? (
        <LoadingSpinner />
      ) : success ? (
        <SuccessMessage />
      ) : (
        <div>
          <p>
            <strong>Forgot Password</strong>
          </p>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <form onSubmit={handleSubmit}>
            <StyledInput
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <p>
              <Link to="/reset-password">
                I already have a confirmation code
              </Link>
            </p>
            <Button type="submit">Submit</Button>
          </form>
          <AuthFormLink to="/">Sign In</AuthFormLink>
        </div>
      )}
    </LoginTemplate>
  );
}
