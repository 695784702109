import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { axios } from "axios/axios";
import styled from "styled-components";
import { notify } from "features/notifications";
import Select from "react-select";
import {
  setProducts,
  setApplicationsSelectedProduct,
  setApplicationsIsEditingProduct,
} from "features/applications/redux/productsSlice";
import { UploadImageModal } from "common";
import { productService } from "features/applications/services";
import { Button } from "common/elements";
import { uploadS3 } from "utils/uploadS3";
import { Spinner } from "common/Spinner";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
import { Skeleton } from "antd";

const SettingsWrapper = styled.div`
  display: flex;
  height: 100%;
`;

const SettingsMain = styled.div`
  flex: 1;
  overflow-y: auto;
`;

export const ApplicationSettingsView = ({ product }) => {
  const customer = useSelector((state) => state.user.customer);
  const customer_id = useSelector((state) => state.user.customerId);
  const dispatch = useDispatch();
  const setSelectedProduct = (product) =>
    dispatch(setApplicationsSelectedProduct(product));
  const setEditingProduct = (isEditing) =>
    dispatch(setApplicationsIsEditingProduct(isEditing));
  const handleSelectedProduct = setSelectedProduct;
  const hide = () => {
    setEditingProduct(false);
  };
  const updateProducts = (products) => dispatch(setProducts(products));
  const [productSettings, setProductSettings] = useState({});
  const [signalOptions, setSignalOptions] = useState([]);
  const [isSignalFormPristine, setIsSignalFormPristine] = useState(true);
  const [isProductFormPristine, setIsProductFormPristine] = useState(true);
  const [viewableImage, setViewableImage] = useState(product.image);
  const emptyForm = () => ({
    customer_id,
    product_name: "",
    product_number: "",
  });
  const [editImage, setEditImage] = useState(false);
  const [form, setFormState] = useState({ ...emptyForm(), ...product });
  const [fetchedData, setFetchedData] = useState(false);
  const updateProductField = (e) => {
    setFormState({
      ...form,
      [e.target.name]: e.target.value,
    });
    setIsProductFormPristine(false);
  };
  const generalRef = useRef(null);
  const signalSettingsRef = useRef(null);

  const onProductSettingsSubmit = async () => {
    let req = form.id ? productService.update : productService.create;
    // perform the update
    await req({ product: form }).then(() => handleSelectedProduct(form));
    // refresh this and all products for rest of app
    await productService
      .index(customer_id)
      .then((res) => res.products)
      .then(updateProducts)
      .then(hide);
    setIsProductFormPristine(true);
  };

  const onUpload = async (imageFile) => {
    try {
      let { signedRequestUrl, assetUrl } = await productService.uploadImage({
        fileName: imageFile.name,
        fileType: imageFile.type,
        product_id: form.id,
      });
      await uploadS3(imageFile, signedRequestUrl);
      const updatedProduct = { ...form, image: assetUrl };
      setViewableImage(assetUrl);
      setFormState(updatedProduct);
      handleSelectedProduct(updatedProduct);
    } catch (e) {
      console.error(e);
    }
  };

  const handleProductSettingsChange = (fieldName) => (returnedOption) => {
    setIsSignalFormPristine(false);
    setProductSettings({
      ...productSettings,
      [fieldName]: parseInt(returnedOption.value),
    });
  };

  const { data, isLoading } = useGetSignalsForCustomerQuery(customer.id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchData = async () => {
    const { signals } = data;
    const blankOption = { value: null, label: "None" };
    const remappedSignalOptions = [
      blankOption,
      ...signals.map(signalToSelectOptions),
    ];
    setSignalOptions(remappedSignalOptions);
    await axios
      .get(`/api/products/settings/${product.id}`)
      .then((data) => {
        const formattedData = data;
        setProductSettings(formattedData);
        setFetchedData(true);
      })
      .catch((err) => {
        console.log(err);
        setFetchedData(true);
      });
  };

  /* load data on mount */
  useEffect(() => {
    if (!isLoading) {
      fetchData();
    }
  }, [isLoading]);

  const signalToSelectOptions = (signal) => ({
    value: parseInt(signal.id),
    label: `${signal.name ? signal.name : signal.can_signal_name}${
      signal.symbol ? ` - [${signal.symbol}]` : ""
    }`,
  });

  const settingsKeys = [
    { name: "soc_signal_id", label: "SOC Signal" },
    { name: "soh_signal_id", label: "SOH Signal" },
    { name: "rssi_signal_id", label: "RSSI Signal" },
    { name: "lat_signal_id", label: "GPS Latitude Signal" },
    { name: "lng_signal_id", label: "GPS Longitude Signal" },
  ];

  const handleSignalUiSubmit = () => {
    axios
      .post("/api/products/settings/update", {
        product_id: product.id,
        settings: productSettings,
      })
      .then(
        () => {
          notify("Signals updated successfully");
          setIsSignalFormPristine(true);
        },
        (err) => notify(`error saving signals: ${err}`)
      );
  };

  const customSelectStyle = {
    control: (provided, state) => ({
      ...provided,
      border: "none",
      borderRadius: "0",
      backgroundColor: "transparent",
      borderBottom: "1.5px solid rgba(0, 0, 0, 0.25)",
      boxShadow: "none",
      "&:hover": {
        borderColor: "rgba(0, 0, 0, 0.25)",
      },
      "&:focus": {
        borderColor: "green",
      },
    }),
  };

  return (
    <>
      <SettingsWrapper>
        <UploadImageModal
          show={editImage}
          hide={() => setEditImage(false)}
          onUpload={onUpload}
          _viewableImage={viewableImage}
        />
        <SettingsMain>
          <div
            style={{
              padding: "25px",
              display: "block",
              maxWidth: "900px",
              margin: "0 auto",
            }}
          >
            <div style={{ padding: "15px", flex: "1" }}>
              <h4 ref={generalRef}>General</h4>

              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ width: "300px", marginRight: "40px" }}>
                  {!viewableImage ? (
                    <Skeleton.Image
                      style={{
                        width: "300px",
                        height: "200px",
                      }}
                    />
                  ) : (
                    <img
                      style={{ width: "100%", height: "auto" }}
                      src={viewableImage}
                      alt="product"
                    />
                  )}
                </div>
                <div style={{ flex: "1" }}>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <label
                      htmlFor="product_name"
                      style={{
                        width: "100px",
                        fontWeight: "bold",
                        display: "inline-block",
                      }}
                    >
                      Name
                    </label>
                    <input
                      style={{ flex: "1" }}
                      value={form.product_name}
                      name="product_name"
                      onChange={updateProductField}
                    />
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <label
                      htmlFor="product_number"
                      style={{
                        width: "100px",
                        fontWeight: "bold",
                        display: "inline-block",
                      }}
                    >
                      Number
                    </label>
                    <input
                      style={{ flex: "1" }}
                      value={
                        form.product_number !== null ? form.product_number : ""
                      }
                      name="product_number"
                      onChange={updateProductField}
                    />
                  </div>
                  <div style={{ display: "flex", marginBottom: "10px" }}>
                    <label
                      htmlFor="product_image"
                      style={{
                        width: "100px",
                        fontWeight: "bold",
                        display: "inline-block",
                      }}
                    >
                      Image
                    </label>
                    <span
                      style={{
                        flex: "1",
                        color: "#555",
                        textDecoration: "underline",
                        fontSize: "90%",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        setEditImage(!editImage);
                      }}
                    >
                      Upload new image
                    </span>
                  </div>
                  <Button
                    disabled={isProductFormPristine}
                    onClick={onProductSettingsSubmit}
                  >
                    {form.id ? "Update" : "Create"}
                  </Button>
                </div>
              </div>
            </div>
            <div style={{ flex: "1", padding: "15px", borderRadius: "5px" }}>
              <h4 ref={signalSettingsRef}>Signal UI Settings</h4>
              {signalOptions?.length > 0 && fetchedData ? (
                <div>
                  <ul>
                    {settingsKeys.map((settingsKey, i) => (
                      <li
                        key={i}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          padding: "1em 0",
                        }}
                      >
                        <div
                          style={{
                            width: "200px",
                            margin: ".25em 1em .25em 0",
                          }}
                        >
                          {settingsKey.label}:
                        </div>
                        <div style={{ flex: "1" }}>
                          <Select
                            styles={customSelectStyle}
                            options={signalOptions}
                            defaultValue={signalOptions?.find(
                              (so) =>
                                so?.value === productSettings[settingsKey?.name]
                            )}
                            onChange={handleProductSettingsChange(
                              settingsKey.name
                            )}
                          />
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      disabled={isSignalFormPristine}
                      onClick={handleSignalUiSubmit}
                    >
                      Save Signals
                    </Button>
                  </div>
                </div>
              ) : (
                <Spinner />
              )}
            </div>
          </div>
        </SettingsMain>
      </SettingsWrapper>
    </>
  );
};
