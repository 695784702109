import styled, { useTheme } from "styled-components";
import { NavLink, useRouteMatch } from "react-router-dom";
import { useSelectedProduct } from "features/applications/hooks";
import * as Defines from "utils/defines";
import { Icon } from "common";
import { GetFilter } from "utils/Color";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";

export const SidebarWrapper = styled.div`
  width: ${(props) => props.theme.sidebarWidth};
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
  user-select: none;
  background-color: ${(props) => props.theme.themeSecondary};
  padding-top: 20px;
  z-index: 1000;
`;
export const SidebarNav = styled.div`
  width: 100%;
  color: white;
  height: calc(100vh - 50px);
`;
export const NavItem = styled.div`
  padding: ${(props) =>
    props.active
      ? props.theme.sidebarPadding
      : props.theme.sidebarPadding} // or inherit
  align-items: center;
  background-color: ${(props) =>
    (props.active && !props.hasChildren) ||
    (props.active && !props.hasChildSelected)
      ? props.theme.sidebarExpandedBGColor
      : "inherit"};
  border-top: ${(props) =>
    props.active && !props.hasChildSelected
      ? props.theme.sidebarBorder
      : props.theme.sidebarBorderInactive};
  border-bottom: ${(props) =>
    props.active && !props.hasChildSelected
      ? props.theme.sidebarBorder
      : props.theme.sidebarBorderInactive};
  border-left: ${(props) =>
    props.active
      ? props.theme.sidebarBorderLeft
      : props.theme.sidebarBorderLeftInactive};
`;
export const NavItemButton = styled.button`
  padding: ${(props) => props.theme.sidebarPadding}
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  background-color: inherit;
  border: none;
  outline: 0 !important;
  cursor: pointer;
  text-align: left;
  font-family: ${(props) => props.theme.sidebarFontFamily};
  font-weight: ${(props) => props.theme.sidebarFontWeight};
  font-style: ${(props) => props.theme.sidebarFontStyle};
  font-size: ${(props) => props.theme.sidebarFontSize};
`;
export const NavButtonSpan = styled.span`
  margin-left: 12px;
  color: ${(props) => props.theme.sidebarTextColor};
`;
export const Separator = styled.div`
  border-bottom: 1px solid white;
  opacity: 0.2;
  margin: 10px;
`;
export const BottomLogo = styled.div`
  position: absolute;
  bottom: 5px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.theme.sidebarWidth};
  opacity: 1;
  filter: brightness(1000%);
  mix-blend-mode: overlay;
`;
export const SidebarItem = styled.div`
  font-size: 12px;
  padding: 3px 0 3px 48px;
  line-height: 1.3;
  margin-left: -20px; // props.theme.sidebarPadding inverted
  display: flex;
  align-items: center;
  background-color: ${(props) => props.selected && "rgba(255,255,255,.15)"};
  border-top: ${(props) =>
    props.selected ? props.theme.sidebarBorder : "1px solid transparent"};
  border-bottom: ${(props) =>
    props.selected ? props.theme.sidebarBorder : "1px solid transparent"};
  color: white;

  &:first-of-type {
    margin-top: 5px;
  }
  &:last-of-type {
    margin-bottom: 10px;
  }
`;

export const SidebarNavItem = ({
  menuSlug, // slug for redux state
  icon,
  label, // display
  children,
  hasChildSelected,
}) => {
  let link = `/${menuSlug}`;
  const { setSelectedProduct } = useSelectedProduct();
  const { path } = useRouteMatch();
  const activeMenu = path.slice(1);

  return (
    <NavItem
      hasChildren={!!children}
      hasChildSelected={hasChildSelected}
      className="sidebar-nav-item-wrapper"
      active={activeMenu === menuSlug}
    >
      <NavLink
        to={link}
        onClick={() => {
          setSelectedProduct(null); // deselect any selected product
        }}
        style={{ textDecoration: "none", width: "100%" }}
      >
        <NavItemButton active={activeMenu === menuSlug}>
          <Icon
            hostedImage={icon}
            style={{
              filter: "invert(100%) brightness(5)",
            }}
            width={Defines.ICON_SIZE_MD}
            height={Defines.ICON_SIZE_MD}
          />
          <NavButtonSpan>{label}</NavButtonSpan>
        </NavItemButton>
      </NavLink>
      {children}
    </NavItem>
  );
};

export const ProductsList = () => {
  const { path } = useRouteMatch();
  const activeMenu = path.slice(1);
  const customerId = useCustomerId();
  const { data: allProducts, isLoading } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const { selectedProduct, setSelectedProduct } = useSelectedProduct();
  const theme = useTheme();

  if (isLoading) return <></>;
  if (!allProducts?.length) return <div>No Products</div>;

  return (
    <div>
      {allProducts.map((product) => {
        const itemIsSelected =
          activeMenu === "applications" && selectedProduct?.id === product?.id;
        return (
          <NavLink
            to={"/applications"}
            style={{
              textDecoration: "none",
              width: "100%",
              background: "none",
            }}
            onClick={(e) => {
              setSelectedProduct(product);
            }}
            key={product.id}
          >
            <SidebarItem key={product.id} selected={itemIsSelected}>
              <Icon
                width={Defines.ICON_SIZE_MD}
                height={Defines.ICON_SIZE_MD}
                style={{
                  filter: GetFilter(theme.themePrimary),
                  marginRight: "10px",
                }}
                hostedImage={Defines.S3_MAP_PIN_UNIT_A}
              />
              {product.product_name}
            </SidebarItem>
          </NavLink>
        );
      })}
    </div>
  );
};
