import { useState } from "react";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import Select from "react-select";

import { tcuService } from "features/tcu/services";
import { RadioButton, Button } from "common/elements";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
import { default as ApplicationsPresetCommands } from "./ApplicationsPresetCommands";

const MESSAGETYPE_DBC = 0;
const MESSAGETYPE_CUSTOM = 1;
const BLANK_CHUNK = "00";
const DEFAULT_CHUNK_VALUES = {
  chunk01: BLANK_CHUNK,
  chunk02: BLANK_CHUNK,
  chunk03: BLANK_CHUNK,
  chunk04: BLANK_CHUNK,
  chunk05: BLANK_CHUNK,
  chunk06: BLANK_CHUNK,
  chunk07: BLANK_CHUNK,
  chunk08: BLANK_CHUNK,
};

const FormRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  & > label {
    width: 80px;
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
  }
`;

const CommandInputForm = styled.form`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 20px;
`;

const isExtendedId = (decimal_id) =>
  parseInt(decimal_id) > 0x80000000 ? 1 : 0;

const generateCanCommandPayloadFromData = (
  tcu,
  messageType,
  selectedMessage,
  isExtendedManual,
  data
) => {
  const token = tcu.token;
  let can_message_id, is_extended, message_dlc;
  if ((messageType === messageType) === MESSAGETYPE_DBC) {
    can_message_id = selectedMessage.decimal_id;
    is_extended = isExtendedId(selectedMessage.decimal_id);
    message_dlc = selectedMessage.length;
  } else {
    can_message_id = data.messageIdManual;
    is_extended = isExtendedManual;
    message_dlc = data.messageDlcManual;
  }
  const message =
    data.chunk01 +
    data.chunk02 +
    data.chunk03 +
    data.chunk04 +
    data.chunk05 +
    data.chunk06 +
    data.chunk07 +
    data.chunk08;
  return {
    token,
    can_message_id,
    is_extended,
    message_dlc,
    message,
  };
};

const messageToSelectOptions = (message) => ({
  ...message,
  value: `${message.id}`,
  label: `${message.decimal_id} - ${message.name}`,
});

const ApplicationsCustomCommandForm = ({ tcu }) => {
  const customerId = useCustomerId();
  const { data: signalsAndMessages, isLoading } =
    useGetSignalsForCustomerQuery(customerId);
  const messages = signalsAndMessages ? signalsAndMessages.messages : [];

  const [messageType, setMessageType] = useState(MESSAGETYPE_CUSTOM);
  const [isExtendedManual, setIsExtendedManual] = useState(0);
  const [selectedMessage, setSelectedMessage] = useState();
  const { register, handleSubmit, setValue, watch } = useForm({
    defaultValues: DEFAULT_CHUNK_VALUES,
  });

  const handleFocus = (e) => {
    const target = e.target.name;
    const currentValue = e.target.value;
    if (currentValue === BLANK_CHUNK) {
      setValue(target, "");
    }
  };

  const handleBlur = (e) => {
    const target = e.target.name;
    const currentValue = e.target.value;
    if (currentValue === "") {
      setValue(target, BLANK_CHUNK);
    }
  };

  const onSubmit = async (formData) => {
    const payload = generateCanCommandPayloadFromData(
      tcu,
      messageType,
      selectedMessage,
      isExtendedManual,
      formData
    );
    await tcuService.SendCANCommand(payload);
  };

  if (!tcu) return <div>No TCU for selected SN</div>;
  if (isLoading) return <div>Loading Messages</div>;

  const messageOptions = messages.map(messageToSelectOptions);

  const isCustomMessageIncomplete =
    !watch("messageIdManual") || !watch("messageDlcManual");

  const isFormIncomplete =
    messageType === MESSAGETYPE_DBC
      ? !selectedMessage
      : isCustomMessageIncomplete;

  return (
    <div>
      <ApplicationsPresetCommands tcu={tcu} />
      <hr />
      <CommandInputForm
        onSubmit={handleSubmit(onSubmit)}
        id="custom-command-form"
      >
        <FormRow>
          <label style={{ width: "150px" }}>Message Type</label>
          <RadioButton
            name="messageType"
            value={MESSAGETYPE_DBC}
            checked={messageType === MESSAGETYPE_DBC}
            onChange={() => setMessageType(MESSAGETYPE_DBC)}
            inputStyle={{ width: "1.15em" }}
            labelStyle={{ flex: "1", opacity: "1" }}
            text="DBC"
          />
          <RadioButton
            name="messageType"
            value={MESSAGETYPE_CUSTOM}
            checked={messageType === MESSAGETYPE_CUSTOM}
            onChange={() => setMessageType(MESSAGETYPE_CUSTOM)}
            inputStyle={{ width: "1.15em" }}
            labelStyle={{ flex: "1", opacity: "1" }}
            text="Custom"
          />
        </FormRow>
        <FormRow style={{ margin: "10px 0" }}>
          <label style={{ width: "150px" }}>Message ID</label>
          <div>
            {messageType === MESSAGETYPE_DBC ? (
              <Select
                value={selectedMessage}
                onChange={setSelectedMessage}
                options={messageOptions}
              />
            ) : (
              <input
                {...register("messageIdManual")}
                placeholder="Decimal ID"
              />
            )}
          </div>
        </FormRow>
        <FormRow>
          <label style={{ width: "150px" }}>ID Format</label>
          {messageType === MESSAGETYPE_DBC ? (
            `${
              selectedMessage
                ? isExtendedId(selectedMessage.decimal_id)
                  ? "Extended"
                  : "Standard"
                : "N/A"
            }`
          ) : (
            <>
              <RadioButton
                name="isExtended"
                value={0}
                checked={isExtendedManual === 0}
                onChange={() => setIsExtendedManual(0)}
                inputStyle={{ width: "1.15em" }}
                labelStyle={{ flex: "1", opacity: "1" }}
                text="Standard"
              />
              <RadioButton
                name="isExtended"
                value={1}
                checked={isExtendedManual === 1}
                onChange={() => setIsExtendedManual(1)}
                inputStyle={{ width: "1.15em" }}
                labelStyle={{ flex: "1", opacity: "1" }}
                text="Extended"
              />
            </>
          )}
        </FormRow>
        <FormRow>
          <label style={{ width: "150px" }}>DLC</label>
          <div>
            {messageType === MESSAGETYPE_DBC ? (
              `${selectedMessage ? selectedMessage.length : "N/A"}`
            ) : (
              <input {...register("messageDlcManual")} placeholder="DLC" />
            )}
          </div>
        </FormRow>
        <FormRow>
          <label style={{ width: "150px" }}>Message</label>
          <div style={{ display: "flex" }}>
            <input
              {...register("chunk01")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B0"
            />
            <input
              {...register("chunk02")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B1"
            />
            <input
              {...register("chunk03")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B2"
            />
            <input
              {...register("chunk04")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B3"
            />
            <input
              {...register("chunk05")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B4"
            />
            <input
              {...register("chunk06")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B5"
            />
            <input
              {...register("chunk07")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B6"
            />
            <input
              {...register("chunk08")}
              maxLength="2"
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder="B7"
            />
          </div>
        </FormRow>
        <Button disabled={isFormIncomplete} form="custom-command-form">
          Send Custom Command Message
        </Button>
      </CommandInputForm>
    </div>
  );
};

export default ApplicationsCustomCommandForm;
