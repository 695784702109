import styled from "styled-components";
import {
  ApplicationDeviceInstancesOverviewTable,
  ApplicationDeviceInstancesFormModal,
} from "../components";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import {
  useGetAllApplicationDevicesQuery,
  useGetApplicationDeviceInstancesByFilterQuery,
} from "features/application-devices/redux";
import { Button, Card, Form } from "antd";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

const ApplicationDeviceInstancesManagementContainer = styled.div`
  width: 100%;
`;

export const ApplicationDeviceInstancesOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  /**
   * Fetching Applications
   */
  const {
    isLoading: isQApplicationDeviceInstancesLoading,
    data: qApplicationDeviceInstances,
    refetch: qApplicationDeviceInstancesRefetch,
  } = useGetApplicationDeviceInstancesByFilterQuery();

  const {
    isLoading: isQApplicationDevicesLoading,
    data: qApplicationDevices,
    refetch: qApplicationDevicesRefetch,
  } = useGetAllApplicationDevicesQuery();

  const {
    isLoading: isQApplicationUnitsLoading,
    data: qApplicationUnits,
    refetch: qApplicationUnitsRefetch,
  } = useGetApplicationUnitsByFilterQuery();

  /**
   * Update Is Page Loaded when Fetching is complete
   */
  useEffect(() => {
    setIsPageLoaded(
      !isQApplicationDeviceInstancesLoading &&
        !isQApplicationDevicesLoading &&
        !isQApplicationUnitsLoading
    );
  }, [
    isQApplicationDeviceInstancesLoading,
    isQApplicationDevicesLoading,
    isQApplicationUnitsLoading,
  ]);

  return (
    <ApplicationDeviceInstancesManagementContainer>
      <Card
        title={
          <span style={{ fontWeight: "bold" }}>
            {"List of Application Device Instances"}
          </span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={debounce(async () => {
              setIsModalOpen(true);
            }, 100)}
          >
            New Application Device Instance
          </Button>
        }
      >
        <ApplicationDeviceInstancesOverviewTable
          isPageLoaded={isPageLoaded}
          qApplicationDeviceInstances={qApplicationDeviceInstances}
        />
      </Card>
      <ApplicationDeviceInstancesFormModal
        form={form}
        title={"New Application Device Instance Form"}
        qApplicationDevices={qApplicationDevices}
        qApplicationUnits={qApplicationUnits}
        refetch={qApplicationDeviceInstancesRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewForm={true}
      />
    </ApplicationDeviceInstancesManagementContainer>
  );
};
