import { Breadcrumb, Button, Card, Col, Form, Row, Space } from "antd";
import { useGetUsersQuery } from "features/users/redux";
import {
  useGetCustomersQuery,
  useGetCustomerUsersQuery,
  useGetCustomersApplicationsQuery,
} from "features/customers/redux";
import { ADMIN_ROUTE_CUSTOMERS } from "features/customers/utils/Constants";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import {
  CustomersViewCustomerUsersTable,
  CustomersViewDetailsTable,
  CustomersViewCustomersApplicationsTable,
  CustomersFormModal,
} from "../components";
import { debounce } from "lodash";
import { CustomerUsersFormModal } from "../components/CustomerUsersFormModal";

export const CustomersView = () => {
  const { id: customersID } = useParams();

  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isCustomerUsersFormModalOpen, setIsCustomerUsersFormModalOpen] =
    useState(false);
  const [
    isCustomersApplicationsFormModalOpen,
    setIsCustomersApplicationsFormModalOpen,
  ] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [editDetailsForm] = Form.useForm();
  const [addUsersForm] = Form.useForm();
  const [addApplicationsForm] = Form.useForm();

  const {
    data: qCustomer,
    isLoading: isQCustomerLoading,
    refetch: qCustomerRefetch,
  } = useGetCustomersQuery({
    id: customersID,
    skip: customersID === "",
  });

  const {
    data: qCustomerUsers,
    isLoading: isQCustomerUsersLoading,
    refetch: qCustomerUsersRefetch,
  } = useGetCustomerUsersQuery({
    customer_id: customersID,
    skip: customersID === "",
  });

  const {
    data: qCustomersApplications,
    isLoading: isQCustomersApplicationsLoading,
    refetch: qCustomersApplicationsRefetch,
  } = useGetCustomersApplicationsQuery({
    customer_id: customersID,
    skip: customersID === "",
  });

  const { data: qUsers, isLoading: isQUsersLoading } = useGetUsersQuery();

  useEffect(() => {
    setIsPageLoaded(
      !isQCustomerLoading &&
        !isQCustomerUsersLoading &&
        !isQCustomersApplicationsLoading &&
        !isQUsersLoading
    );
  }, [
    isPageLoaded,
    isQCustomerLoading,
    isQCustomerUsersLoading,
    isQCustomersApplicationsLoading,
    isQUsersLoading,
  ]);

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "Customers Overview",
      href: `/admin/${ADMIN_ROUTE_CUSTOMERS}`,
    },
    {
      title: `${qCustomer?.name}`,
    },
  ];

  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute?.title}</span>
    ) : (
      <Link to={`${currentRoute?.href}`}>{currentRoute?.title}</Link>
    );
  };

  return (
    <>
      {/* Breadcrumb */}
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      {/* Customer Details */}
      <Row>
        <Col span={24}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`Customer: ${qCustomer?.name} Details`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsEditDetailsModalOpen(true);
                    }, 100)}
                  >
                    Edit Details
                  </Button>
                </Space>
              </>
            }
          >
            <CustomersViewDetailsTable
              qCustomer={qCustomer}
              qCustomerUsers={qCustomerUsers}
              isPageLoaded={isPageLoaded}
            />
          </Card>
        </Col>
      </Row>
      {/* Customer's Users & Applications Details */}
      <Row style={{ marginTop: "16px" }}>
        <Col span={8}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`Customer's Users`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsCustomerUsersFormModalOpen(true);
                    }, 100)}
                  >
                    Add User
                  </Button>
                </Space>
              </>
            }
          >
            <CustomersViewCustomerUsersTable
              qCustomerUsers={qCustomerUsers}
              isPageLoaded={isPageLoaded}
            />
          </Card>
        </Col>
        <Col span={15} offset={1}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`Customer's Applications`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsCustomersApplicationsFormModalOpen(true);
                    }, 100)}
                  >
                    Add Application
                  </Button>
                </Space>
              </>
            }
          >
            <CustomersViewCustomersApplicationsTable
              qCustomersApplications={qCustomersApplications}
              isPageLoaded={isPageLoaded}
            />
          </Card>
        </Col>
      </Row>
      {/* Form Modals */}
      <CustomersFormModal
        form={editDetailsForm}
        title={"Edit Customer Form Modal"}
        qCustomer={qCustomer}
        qUsers={qUsers}
        refetch={qCustomerRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isEditDetailsModalOpen}
        setIsModalOpen={setIsEditDetailsModalOpen}
        isEditForm={true}
      />
      <CustomerUsersFormModal
        form={addUsersForm}
        title={"New Customer User Form Modal"}
        qCustomer={qCustomer}
        qUsers={qUsers}
        refetch={qCustomerUsersRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isCustomerUsersFormModalOpen}
        setIsModalOpen={setIsCustomerUsersFormModalOpen}
        isNewForm={true}
      />
    </>
  );
};
