import { useState, useRef } from "react";
import styled from "styled-components";

import { useUpdateSignalMutation } from "features/applications/redux";
import { useOutsideClickDetector } from "utils/useOutsideClickDetector";
import { S3_CHECKMARK } from "utils/defines";

const PrecisionEditorCell = styled.div`
  & .precision-form-group {
    display: flex;
  }
  & input[type="number"] {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
`;

export const PrecisionEditor = ({ cellProps }) => {
  // object data
  const originalCellValue = cellProps.getValue();
  const row = cellProps.row.original;
  const { id: signalId } = row;

  // rtk query mutation
  const [updateSignal] = useUpdateSignalMutation();

  // local state
  const precisionEditorRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [precisionValue, setPrecisionValue] = useState(originalCellValue);

  // on submit
  const handleSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const signal = {
      id: signalId,
      precision: parseInt(precisionValue),
    };
    await updateSignal({ signal });
    setIsEditing(false);
  };

  // on outside click
  const onClickOutside = () => {
    setPrecisionValue(originalCellValue);
    setIsEditing(false);
  };

  // init event listener
  useOutsideClickDetector(precisionEditorRef, onClickOutside);

  return (
    <PrecisionEditorCell
      ref={precisionEditorRef}
      onClick={() => setIsEditing(true)}
    >
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <div className="precision-form-group">
            <input
              type="number"
              value={precisionValue}
              min="0"
              onChange={(e) => setPrecisionValue(e.target.value)}
            />
            <img src={S3_CHECKMARK} onClick={handleSubmit} />
          </div>
        </form>
      ) : (
        `${originalCellValue}`
      )}
    </PrecisionEditorCell>
  );
};
