import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseUsersQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/users`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const usersApi = createApi({
  reducerPath: "usersApi",
  baseQuery: baseUsersQuery,
  tagTypes: ["users", "Users"],
  endpoints: (builder) => ({
    getUsers: builder.query({
      query: (args) => {
        const { id } = args || {};
        let obj = {
          url: `/`,
          providesTags: ["Users"],
        };
        if (id) {
          obj.url = `/${id}`;
        }
        return obj;
      },
    }),
    getAllSuperAdmins: builder.query({
      query: () => `/?superadmins_only=true`,
      providesTags: ["Users"],
    }),
    getUserByID: builder.query({
      query: (user_id) => `/${user_id}`,
      providesTags: ["Users"],
    }),
  }),
});

export const {
  useGetAllSuperAdminsQuery,
  useGetUserByIDQuery,
  useGetUsersQuery,
} = usersApi;
