import { Table } from "antd";
import { Link, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "common/Spinner";
import moment from "moment-timezone";

export const ApplicationDeviceInstanceViewDetailsTable = ({
  qApplicationDeviceInstance,
  isPageLoaded,
}) => {
  const { path } = useRouteMatch();

  const [applicationDeviceInstance, setApplicationDeviceInstance] = useState(
    []
  );

  useEffect(() => {
    if (isPageLoaded) {
      const applicationDevicePairDisplay = `${qApplicationDeviceInstance.product_sn.product.product_name} -- ${qApplicationDeviceInstance.application_device.device_type.name}`;
      const createdOnDate = new Date(qApplicationDeviceInstance.createdAt);
      const updatedOnDate = new Date(qApplicationDeviceInstance.updatedAt);
      const createdOnFormatted = moment(createdOnDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const updatedOnFormatted = moment(updatedOnDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const updatedApplicationDeviceInstance = {
        ...qApplicationDeviceInstance,
        key: qApplicationDeviceInstance.id,
        application_device: applicationDevicePairDisplay,
        application_unit:
          qApplicationDeviceInstance.product_sn?.product_sn_name,
        application_device_instance_serial_number:
          qApplicationDeviceInstance.serial_number,
        application_device_instance_created_at: createdOnFormatted,
        application_device_instance_updated_at: updatedOnFormatted,
      };
      setApplicationDeviceInstance([updatedApplicationDeviceInstance]);
    }
  }, [qApplicationDeviceInstance, isPageLoaded]);

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Application Device Pair",
      dataIndex: "application_device",
      key: "application_device",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Application Unit",
      dataIndex: "application_unit",
      key: "application_unit",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Serial Number",
      dataIndex: "application_device_instance_serial_number",
      key: "application_device_instance_serial_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Created At",
      dataIndex: "application_device_instance_created_at",
      key: "application_device_instance_created_at",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Updated At",
      dataIndex: "application_device_instance_updated_at",
      key: "application_device_instance_updated_at",
      disabledCheckbox: false,
      default: false,
    },
  ];

  return <Table columns={columns} dataSource={applicationDeviceInstance} />;
};
