import { Button, ColorPicker, Form, Input, Select } from "antd";

export const CreateCustomerForm = ({
  currentUser,
  formStyle,
  formVariant,
  handleCreateCustomerFormSubmit,
  handlePrimaryColorChange,
  handleSecondaryColorChange,
  primaryColorHex,
  secondaryColorHex,
  titleMargin,
  wrapperColStyle,
  labelColStyle,
}) => {
  return (
    <Form
      labelCol={labelColStyle}
      wrapperCol={wrapperColStyle}
      style={formStyle}
      variant={formVariant}
      onFinish={handleCreateCustomerFormSubmit}
      id="create-customer-form"
    >
      <h4 style={titleMargin}>Create Customer</h4>
      <Form.Item
        label="Customer Name"
        name="name"
        rules={[{ required: true, message: "Please input the customer name" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Owner"
        name="owner_id"
        initialValue={currentUser?.auth_user.name}
      >
        <Select disabled={true} />
      </Form.Item>
      <Form.Item label="Is Verified" name="is_verified" initialValue={1}>
        <Select disabled={true} />
      </Form.Item>
      <Form.Item
        label="Primary Color"
        name="color_primary"
        initialValue={"#C0C0C0"}
      >
        <ColorPicker
          format="hex"
          value={primaryColorHex}
          showText
          onChange={handlePrimaryColorChange}
        />
      </Form.Item>
      <Form.Item
        label="Secondary Color"
        name="color_secondary"
        initialValue={"#4B4B4B"}
      >
        <ColorPicker
          format="hex"
          value={secondaryColorHex}
          showText
          onChange={handleSecondaryColorChange}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" form="create-customer-form">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
