import * as API from "axios/axios";

const GetRecentDailyFleetUsage = async () => {
  const results = await API.get(`/api/tcu/usage/daily`);
  const parsedResults = results.reduce((acc, el) => {
    if (!acc[el.date]) acc[el.date] = 0;
    acc[el.date] += el.bytes;
    return acc;
  }, {});
  const formattedResults = Object.keys(parsedResults)
    .sort()
    .map((resultDate) => ({
      date: resultDate,
      value: parsedResults[resultDate] / (1024 * 1024.0),
    }));
  return formattedResults;
};

export const tcuService = {
  GetFleetUsageThisMonth: (data) =>
    API.get(`/api/tcu/usage/current-month`, data),
  GetRecentDailyFleetUsage,
  SendCommand: (data) => API.post(`/api/iot/command`, data),
  SendCANCommand: (data) => API.post(`/api/iot/command/can`, data),
};

export class TcuUploadHandler {
  constructor({ token }) {
    this.token = token;
  }
  async requestDataForDateRange([start, end]) {
    return API.post("/api/iot/upload", {
      token: this.token,
      start,
      end,
    });
  }
  async sendCancel() {
    return API.post("/api/iot/upload/cancel", { token: this.token });
  }
}
