import { useSelector, useDispatch } from "react-redux";
import {
  ApplicationsQuickOverview,
  ApplicationsDetailViewModal,
} from "../components";
import { setApplicationsSelectedProduct } from "../redux/productsSlice";
import { MainLayout } from "common/layout";
import {
  useGetArchivedProductSNsForCustomerQuery,
  useGetApplicationUnitsByFilterQuery,
} from "features/units/redux";
import { useGetApplicationsByFilterQuery } from "../redux";
import { useCustomerId } from "features/customers/hooks/customersHooks";

export const ApplicationsPage = () => {
  const customerId = useCustomerId();
  const selectedProduct = useSelector(
    (state) => state.products.applicationsSelectedProduct
  );
  const { data: allProducts } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const { data: allSNs, refetch: refetchAllProductSNs } =
    useGetApplicationUnitsByFilterQuery({ customer_id: customerId });
  const { data: allArchivedSNs, refetch: refetchAllArchivedSNs } =
    useGetArchivedProductSNsForCustomerQuery(customerId);
  const dispatch = useDispatch();
  const setSelectedProduct = (product) =>
    dispatch(setApplicationsSelectedProduct(product));

  const selectedSerialNumbers =
    selectedProduct && allSNs
      ? allSNs.filter((sn) => sn.product_id === selectedProduct.id)
      : [];
  return (
    <MainLayout>
      {selectedProduct ? (
        <ApplicationsDetailViewModal
          product={selectedProduct}
          serialNumbers={selectedSerialNumbers}
          archivedSerialNumbers={allArchivedSNs}
          handleSelectedProduct={setSelectedProduct}
          refetchAllArchivedSNs={refetchAllArchivedSNs}
          refetchAllProductSNs={refetchAllProductSNs}
        />
      ) : (
        <ApplicationsQuickOverview
          products={allProducts || []}
          serialNumbers={allSNs || []}
          onProductSelected={setSelectedProduct}
        />
      )}
    </MainLayout>
  );
};
