import styled from "styled-components";

const StyledForm = styled.form`
  max-width: 900px;
  margin: 10px auto;
  border-radius: 5px;
  background-color: #f0f0f0;
  padding: 20px;
`;

export const Form = (props) => {
  return <StyledForm {...props} />;
};

export const FormGroup = styled.div`
  display: flex;
`;
