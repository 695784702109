import { Button, Card, Form } from "antd";
import { useGetTCUsByFilterQuery } from "features/tcu/redux";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { TCUFormModal, TCUOverviewTable } from "../components";
import { useGetCustomersQuery } from "features/customers/redux";
import { useGetUnpairedApplicationUnitsQuery } from "features/units/redux";

const TCUOverviewManagementContainer = styled.div`
  width: 100%;
`;

export const TCUOverview = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const {
    isLoading: isQTCUsLoading,
    data: qTCUs,
    refetch: qTCUsRefetch,
  } = useGetTCUsByFilterQuery();
  const { isLoading: isQCustomersLoading, data: qCustomers } =
    useGetCustomersQuery();

  const {
    isLoading: isQUnpairedApplicationUnitLoading,
    data: qUnpairedApplicationUnits,
  } = useGetUnpairedApplicationUnitsQuery();

  /**
   * Update Is Page Loaded when fetching is complete
   */
  useEffect(() => {
    setIsPageLoaded(
      !isQTCUsLoading &&
        !isQCustomersLoading &&
        !isQUnpairedApplicationUnitLoading
    );
  }, [isQTCUsLoading, isQCustomersLoading, isQUnpairedApplicationUnitLoading]);

  return (
    <TCUOverviewManagementContainer>
      <Card
        title={<span style={{ fontWeight: "bold" }}>{"List of TCUs"}</span>}
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        extra={
          <Button
            type="primary"
            onClick={debounce(async () => {
              setIsModalOpen(true);
            }, 100)}
          >
            New TCU
          </Button>
        }
      >
        <TCUOverviewTable qTCUs={qTCUs} isPageLoaded={isPageLoaded} />
      </Card>
      <TCUFormModal
        form={form}
        title={"New TCU Form Modal"}
        qCustomers={qCustomers}
        qApplicationUnits={qUnpairedApplicationUnits}
        refetch={qTCUsRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isNewForm={true}
      />
    </TCUOverviewManagementContainer>
  );
};
