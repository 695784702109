import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseLayoutsQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/layouts`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const layoutsApi = createApi({
  reducerPath: "layoutsApi",
  baseQuery: baseLayoutsQuery,
  tagTypes: ["layouts"],
  endpoints: (builder) => ({
    getLayoutsByCustomerId: builder.query({
      query: (customerId) => `/?customer_id=${customerId}`,
      providesTags: ["Layouts"],
    }),
    getLayoutById: builder.query({
      query: (layoutId) => `/${layoutId}`,
      providesTags: ["Layouts"],
    }),
    upsertLayouts: builder.mutation({
      query: (layouts) => ({
        url: "/",
        method: "PUT",
        body: { layouts },
      }),
      invalidatesTags: ["Layouts"],
    }),
    deleteTab: builder.mutation({
      query: ({ id, customer_id, tab_name }) => ({
        url: "/",
        method: "DELETE",
        body: { id, customer_id, tab_name },
      }),
      invalidatesTags: ["Layouts"],
    }),
  }),
});

export const {
  useGetLayoutsByCustomerIdQuery,
  useGetLayoutByIdQuery,
  useUpsertLayoutsMutation,
  useDeleteTabMutation,
} = layoutsApi;
