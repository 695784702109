import { Table, Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Spinner } from "common/Spinner";
import { useEffect, useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_TCU_VIEW } from "features/tcu/utils/Constants";

export const ApplicationUnitsOverviewTable = ({
  qApplicationUnits,
  qTCUs,
  isPageLoaded,
}) => {
  const { path } = useRouteMatch();
  const [applicationUnits, setApplicationUnits] = useState([]);
  const [filteredApplicationUnits, setFilteredApplicationUnits] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplicationUnits = qApplicationUnits?.map((obj, index) => {
        const tcuPairMatch = qTCUs.find(
          (item) => item.product_sn_id === obj.id
        );
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          application_unit_name: obj.product_sn_name,
          application_unit_serial_number: obj.serial_number,
          application: obj.product ? obj.product.product_name : "",
          application_id: obj.product ? obj.product?.id : "",
          tcu: tcuPairMatch ? tcuPairMatch?.name : "",
          tcu_id: tcuPairMatch ? tcuPairMatch?.id : "",
          tcu_token: tcuPairMatch ? tcuPairMatch?.token : "",
        };
      });
      setApplicationUnits(updatedApplicationUnits);
      setFilteredApplicationUnits(updatedApplicationUnits);
    }
  }, [isPageLoaded, qApplicationUnits, qTCUs]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const searchTableData = applicationUnits.filter((item) => {
      return Object.keys(item).some((key) => {
        return (
          item[key] && item[key].toString().toLowerCase().includes(searchValue)
        );
      });
    });
    setFilteredApplicationUnits(searchTableData);
  };

  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      disabledCheckbox: false,
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Application Unit Name",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
      disabledCheckbox: false,
      render: (text, record) => (
        <Link to={`${path}/view/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Application Unit's Serial Number",
      dataIndex: "application_unit_serial_number",
      key: "application_unit_serial_number",
    },
    {
      title: "Application",
      dataIndex: "application",
      key: "application",
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.application_id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Paired TCU",
      dataIndex: "tcu",
      key: "tcu",
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_TCU_VIEW}/${record.tcu_id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "TCU Token",
      dataIndex: "tcu_token",
      key: "tcu_token",
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table columns={columns} dataSource={filteredApplicationUnits} />
        </Col>
      </Row>
    </>
  );
};
