// styling for modals
import styled from "styled-components";
import { default as BootstrapModal } from "react-bootstrap/Modal";
import { hexToRgb } from "utils/Color";

export const Modal = BootstrapModal;

export const ModalBody = styled(Modal.Body)`
  padding: 34px 60px;
  background-color: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 0.7);

  // use company color in forms
  input:focus {
    border-color: ${(props) => props.theme.themeSecondary};
  }
`;

export const ModalFieldWrapper = styled.div`
  margin: 16px 0px;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  align-items: center;
  label {
    font-size: 20px;
    left: 65px;
  }
`;
export const ModalLabel = styled.label`
  // font-size: 20px;
  // left: 65px;
  flex: 1 1;
  margin: 0;
  margin-right: 15px;
`;
export const ModalField = styled.div`
  margin-left: auto;
  display: flex;
  margin: 16px 0px;
  font-weight: 400;
  font-size: 18px;
  flex: 2;
  display: flex;
  color: rgba(0, 0, 0, 0.7);
  width: 100%;
  select,
  input {
    width: 100%;
    margin: 0;
    border-width: 0px;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.25);
    background-color: transparent;
    z-index: 10000;
    outline: none;
  }
`;
export const ModalSelect = styled.select``;
// export const ModalSelect = styled.select`
//   width: 100%;
//   font-weight: 400;
//   font-size: 18px;
//   border-width: 0px;
//   border-bottom: 1.5px solid rgba(0, 0, 0, 0.25);
//   color: rgba(0, 0, 0, 0.7);
//   background-color: transparent;
//   z-index: 10000;
//   outline: none;
// `;

export const ModalForm = styled.div``;

export const ModalFooter = styled(Modal.Footer)`
  .deny {
    background: none;
    border: none;
    outline: none;
    opacity: 0.67;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    margin-right: auto;
  }
  button.disabled {
    opacity: 0.5;
  }

  button.accept {
    background-color: ${(props) => hexToRgb(props.theme.themePrimary)};

    outline: none;
    color: white;
    font-weight: 400;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
  }
`;
