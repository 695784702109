import styled from "styled-components";
import { useSelector } from "react-redux";
import { CirclePicker as Picker } from "react-color";

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`;
/*
const Swatch = styled.div`
  padding: 5px;
  background: #fff;
  border-radius: 1px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
  display: inline-block;
  cursor: pointer;
`;
const Color = styled.div`
  width: 36px;
  height: 14px;
  border-radius: 2px;
  background: ${(props) =>
    props.rgb
      ? `rgba(${props.color.r},${props.color.g},${props.color.b},${props.color.a})`
      : `hsla(${props.color.h},${props.color.s * 100}%,${
          props.color.l * 100
        }%,1)`};
`;
*/

const ColorPicker = ({
  color,
  onChange,
  colorType = "rgb",
  label = "Color",
}) => {
  const customer = useSelector((state) => state.user.customer);
  const { color_primary, color_secondary } = customer;
  const colors = [
    color_primary,
    color_secondary,
    "#9c27b0",
    "#3f51b5",
    "#03a9f4",
    "#009688",
    "#ffeb3b",
    "#505050",
  ].filter((c) => c); // filter out null colors (haven't been set)

  // const [displayPicker, setDisplayPicker] = useState(false);
  // const handleClick = () => {
  //   setDisplayPicker(!displayPicker);
  // };
  const handleChange = (color) => {
    onChange(color[colorType]);
  };
  return (
    <Wrapper className="color-picker-wrapper">
      {/* <Swatch onClick={handleClick}>
        <Color rgb={colorType === "rgb"} color={color ? color : colors[0]} />
      </Swatch> */}
      {/* {displayPicker ? ( */}
      {/* <span> */}
      <Picker
        className="modal-field-element"
        color={color}
        colors={colors}
        onChangeComplete={handleChange}
      />
      {/* </span> */}
      {/* ) : null} */}
    </Wrapper>
  );
};
export default ColorPicker;
