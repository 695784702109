import { useEffect, useState } from "react";
import { Modal, Form, Input, Alert } from "antd";
import { debounce } from "lodash";
import { useCreateApplicationUnitMutation } from "features/units/redux";
import { Spinner } from "common/Spinner";

export const ApplicationViewNewAppUnitsFormModal = ({
  form,
  title,
  refetch,
  qApplication,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  isNewForm = false,
  isEditForm = false,
}) => {
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  /**
   * Endpoints
   */
  const [createApplicationUnit] = useCreateApplicationUnitMutation();

  /**
   * Handle onFinish Event
   * @param {*} values
   * @returns
   */
  const onFinishFormModal = async (values) => {
    const { formApplicationUnitName, formApplicationUnitSerialNumber } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };

      if (isNewForm && !isEditForm) {
        result = await createApplicationUnit({
          fields: {
            product_id: qApplication?.id,
            product_sn_name: formApplicationUnitName,
            serial_number: formApplicationUnitSerialNumber,
          },
        });
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  if (!isPageLoaded) return <Spinner />;

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-new-app-units-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application Unit Name"
          name="formApplicationUnitName"
          rules={[
            {
              required: true,
              message: "Missing Application Unit Name!",
            },
          ]}
        >
          <Input disabled={isOkButtonLoading} />
        </Form.Item>
        <Form.Item
          label="Application Unit Serial Number"
          name="formApplicationUnitSerialNumber"
        >
          <Input disabled={isOkButtonLoading} />
        </Form.Item>
      </Modal>
    </>
  );
};
