import { useDispatch, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import {
  setClickedAddButtonLayout,
  setEditModalOpen as setModalOpenAction,
} from "features/grid-layout/redux/layoutSlice";
import { Icon } from "common";
import * as Defines from "utils/defines";
import { WidgetInstance } from "./WidgetInstance";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
import { useDuplicateWidgetInstanceMutation } from "features/widgets/redux";

// html div overlay that positions edit icon
const EditWidgetButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
`;
// html button that wraps edit icon
const EditWidgetButton = styled.button`
  border: none;
  outline: none;
  display: block;
`;
// html element used in Widget to wrap Tile
const WidgetOuter = styled.div`
  overflow-y: scroll;
  white-space: wrap;
  width: 100%;
  height: 100%;
  background-color: ${(props) =>
    props.backgroundDisabled || props.isFullscreen ? "" : "white"};
  box-shadow: ${(props) =>
    props.editModeEnabled || props.backgroundDisabled || props.isFullscreen
      ? ""
      : "0px 1px 3px rgba(0, 0, 0, 0.15)"};
  font-family: roboto;
  font-weight: 200;
  border: ${(props) =>
    props.editModeEnabled
      ? "2px dashed rgba(0,0,0,.4)"
      : "2px solid transparent"};
  border-radius: 5px;

  &:hover {
    border: ${(props) =>
      props.editModeEnabled
        ? "2px solid rgba(0,0,0,.7)"
        : "2px solid transparent"};
  }
`;
// edit overlay component
const EditWidgetOverlay = ({
  onClick: onEditClick,
  instanceId,
  isFullscreen,
  isMapModalLayout,
}) => {
  const [duplicateInstance] = useDuplicateWidgetInstanceMutation();
  const onDuplicateClick = () => duplicateInstance(instanceId);
  return (
    <EditWidgetButtonContainer>
      <EditWidgetButton onClick={onEditClick}>
        <Icon hostedImage={Defines.S3_EDIT} />
      </EditWidgetButton>
      {!isFullscreen && !isMapModalLayout && (
        <EditWidgetButton onClick={onDuplicateClick}>
          <Icon hostedImage={Defines.S3_COPY} />
        </EditWidgetButton>
      )}
    </EditWidgetButtonContainer>
  );
};
// smart wrapper around Tile; child of Grid
const Widget = ({
  instance: widgetInstance, // from Grid
  isPreview, // from TileModal
  theme, // withTheme provider
  isMapModalLayout, // from MapModal > Layout > Grid
  isFullscreen = false,
}) => {
  // state
  const dataHistory = useSelector((state) => state.productSNData.dataHistory);
  const customerId = useCustomerId();
  const { data: signalsAndMessages } =
    useGetSignalsForCustomerQuery(customerId);
  const editModeEnabled = useSelector((state) => state.layout.editModeEnabled);
  const currentProduct = useSelector(
    (state) => state.products.product || state.map.marker
  );
  const signals = signalsAndMessages ? signalsAndMessages.signals : [];
  // dispatch
  const dispatch = useDispatch();
  const setModalOpen = (editModalOpen, editInstanceId) =>
    dispatch(setModalOpenAction({ editModalOpen, editInstanceId }));
  const clickedLayoutButton = (layout) =>
    dispatch(setClickedAddButtonLayout(layout));
  // get widget
  const widget = new WidgetInstance(widgetInstance);
  // get widget specific info
  const instanceId = widget.getInstanceId();
  const layoutId = widget.getLayoutId();
  const isBackgroundDisabled = widget.getSettingsField("background_hide", 0);
  // edit modal function
  const openModal = () => {
    setModalOpen(true, instanceId);
    clickedLayoutButton(
      isMapModalLayout ? Defines.MAP_MODAL : Defines.PRODUCT_DASHBOARD
    );
  };
  // set classname for wrapper
  const widgetBoxClassName = `tile-wrapper`;
  // gather props from non-instance sources
  const tileProps = {
    instanceId,
    layoutId,
    dataHistory, // redux
    currentProduct, // redux
    signals, // redux
    isPreview, // TileModal
    theme, // withTheme
    isMapModalLayout, // MapModal > Layout > Grid
  };

  return (
    <WidgetOuter
      className={widgetBoxClassName}
      editModeEnabled={editModeEnabled}
      backgroundDisabled={isBackgroundDisabled}
      isFullscreen={isFullscreen}
    >
      {editModeEnabled ? (
        <EditWidgetOverlay
          onClick={openModal}
          instanceId={instanceId}
          isFullscreen={isFullscreen}
          isMapModalLayout={isMapModalLayout}
        />
      ) : null}
      {widget.TileComponentFromProps(tileProps)}
    </WidgetOuter>
  );
};
export default withTheme(Widget);
