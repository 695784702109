import { Icon } from "common";
import styled from "styled-components";
import dayjs from "utils/dayjs";
import * as Defines from "utils/defines";

// @TODO: Move this logic to features/events

const EventCardContainer = styled.div`
  border-radius: 7px;
  display: flex;
  height: ${({ eventCardHeight }) => eventCardHeight}px;
  margin-top: 14px;
  padding: 5px 5px 5px 7px;
  width: 200px;
  position: absolute;
  top: ${({ top }) => top}px;

  &:hover {
    cursor: pointer;
  }
`;

const EventCardDateTime = styled.p`
  font-size: 13px;
`;

const EventCardIconContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

const EventCardIconSVG = styled.svg`
  margin-right: 5px;
  margin-top: 1px;
`;

const EventCardTextContainer = styled.div`
  justify-content: center;
  font-size: 15px;
  & strong {
    margin: auto auto -5px auto;
  }
  & p {
    margin: auto;
  }
`;

const EventCard = ({
  event,
  goToEventDate,
  activeEvent,
  top,
  eventStateValues,
  getEventStateValueForCustomEvent,
  setShowEvents,
}) => {
  const { title, icon } = Defines.getInfoForEvent(
    event.event_id,
    getEventStateValueForCustomEvent(event, eventStateValues)
  );

  return (
    <EventCardContainer
      onClick={() => {
        goToEventDate({ ...event, index: top / Defines.EVENT_CARD_HEIGHT });
        setShowEvents(true);
      }}
      id={"eventcard" + event?.timestamp_recorded}
      data-top={top}
      style={{
        backgroundColor:
          activeEvent?.timestamp_recorded === event?.timestamp_recorded &&
          "rgba(0, 0, 0, 0.05)",
      }}
      eventCardHeight={Defines.EVENT_CARD_HEIGHT}
      top={top}
    >
      <EventCardIconContainer>
        <EventCardIconSVG width={20} height={20}>
          <image href={icon} width={20} height={20} />
        </EventCardIconSVG>
      </EventCardIconContainer>
      <EventCardTextContainer>
        <strong>{title}</strong>
        <EventCardDateTime>
          {dayjs(event?.timestamp_recorded).format("YYYY MMM DD, H:mm:ss")}
        </EventCardDateTime>
      </EventCardTextContainer>
    </EventCardContainer>
  );
};

export default EventCard;
