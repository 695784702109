import { Table } from "antd";
import Column from "antd/es/table/Column";

export const ApplicationSummaryTable = ({ application }) => {
  return (
    <Table dataSource={[application]}>
      <Column
        title="Product Name"
        dataIndex="product_name"
        key="product_name"
      />
      <Column
        title="Customer Name"
        dataIndex="customer_name"
        key="customer_name"
      />
    </Table>
  );
};
