import { Component } from "react";
import GoogleMapReact from "google-map-react";
import { MapStyling } from "styles/GlobalStyles";
import { dispatchSetDataHistory } from "features/units/utils";
import { setCurrentProduct } from "features/applications/redux";
import { Icon } from "common";
import { GetFilter } from "utils/Color";
import { withTheme } from "styled-components";
import { getDataInDateRange } from "features/units/services";
import { RouteLine } from "features/map";
import moment from "moment";
import styled from "styled-components";
import { DateTime } from "common";
import { formatNumber } from "utils";
import { SettingsBar } from "./TileComponents";
import { connect } from "react-redux";
import _, { orderBy } from "lodash";
import fastEqual from "fast-deep-equal";
import { GOOGLE_MAPS_API_KEY } from "config";
import { hexToRgba } from "utils/Color";
import store from "store";
import { applicationsApi } from "features/applications/redux";
import { applicationUnitsApi } from "features/units/redux";
import {
  DEFAULT_MAP_CENTER,
  S3_MAP_PIN_UNIT_A,
  S3_HIGH_RES_REQUEST_ANIM,
  MAP_TYPE_SATELLITE,
  MAP_TYPE_ROADMAP,
  LINE_CHART_MAP_TYPE,
} from "utils/defines";
import { Button } from "antd";
import { setLayoutsSettings } from "features/grid-layout";

const _USA = { lat: 37.0902, lng: -95.7129 };
// const _cityZoomLevel = 10;
const _countryZoomLevel = 3;
const FULL_MAP_BOUNDS_POINTS = [
  { lat: -90, long: -180 },
  { lat: 90, long: 180 },
];
const DEFAULT_ZOOM_LEVEL = _countryZoomLevel; // 1 = max zoom out, 20 = max zoom in;
const DEFAULT_CENTER = _USA;
const MAP_DECIMAL_RESOLUTION = 5; // 5 and 6 are noisy; 4 is stepwise; 3 is fixed but just a little inaccurate
// const MAX_ROUTE_POINTS = 3200;
const COORD_TOLERANCE = 0.0001; //0.0002;
const MAP_EXPANSION_FACTOR = 0.00015; // 0.01 default; amount to expand bounds by if focused on one point

// TODO: make this dynamic. have a backend endpoint that returns a signal when queried by key
// signals are not immediately in redux. need to fix, then pull from there
// const LAT_SIGNAL_ID = 273; // local-live: 310; fleet-test: 273; old kv: 37
// const LNG_SIGNAL_ID = 274; // local-live: 311; fleet-test: 274; old kv: 38

const DateTimePicker = styled(DateTime)`
  width: 100%;
  background: none;
  outline: none;
  border: none;
  border-bottom: 1px solid grey;
  margin-left: 8px;
`;

const SettingsArea = styled.div`
  height: 10%;
  display: flex;
  z-index: 10;

  label {
    margin: 0;
  }
`;

const InfoWindow = styled.div`
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: initial;
  background-color: white;
  position: absolute;
  transform: translate(10px, -100px);
  padding: 12px;
  width: 200px;

  div {
    display: flex;
    margin: 0 0 10px 0;
  }
  label {
    margin: 0 15px 0 0;
  }
  span {
    margin-left: auto;
  }
`;

// @TODO: center using margin 0 auto instead of hardcoded value
const LastSeenText = styled.p`
  font-style: italic;
  width: 80px;
  margin-top: 10px;
  margin-left: -28px;
  text-align: center;
`;

const MapPinWrapper = styled.div`
  // color: ${(props) => (props.hovered ? "red" : "inherit")};
  transform: translate(-10px, -10px);
`;

const MapPinIconOuter = styled.div`
  cursor: pointer;
`;

const SmallPinOuter = styled.div``;

const DiamondIconWrapper = styled.div`
  color: ${(props) => (props.hovered ? "red" : "inherit")};
`;

const PinDateInfo = styled.div``;

const PinLocationInfo = styled.div``;

// const SettingsBarInner = styled.div`
//   width: 200px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 10;

//   & input[type="checkbox"] {
//     margin-left: 12px;
//   }
// `;

const HistoricalSelect = styled.div`
  z-index: 10;
  display: flex;
  align-items: center;
  & select {
    margin-left: 12px;
  }
`;

const HistoricalDateSelector = styled.div`
  display: flex;
`;

const DateSelectorLabel = styled.label`
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DiamondIcon = ({ small, color, size }) => {
  return small ? (
    <Icon
      hostedImage={S3_MAP_PIN_UNIT_A}
      width={8}
      height={8}
      style={{ transform: "translate(-4px,-7px)" }}
    />
  ) : (
    <Icon
      hostedImage={S3_MAP_PIN_UNIT_A}
      style={{
        filter: GetFilter(color),
      }}
      width={size}
      height={size}
    />
  );
};

const MapPin = ({ pinColor, onClick }) => (
  <MapPinWrapper onClick={onClick}>
    <MapPinIconOuter>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", top: "-3px", left: "-4px" }}>
          <DiamondIcon color={"#FFFFFF"} size={28} />
        </div>
        <div style={{ position: "absolute", top: 0, left: 0 }}>
          <DiamondIcon color={pinColor} />
        </div>
      </div>
    </MapPinIconOuter>
  </MapPinWrapper>
);

const LastSeenPin = ({ pinColor, onClick, timestamp }) => (
  <MapPinWrapper onClick={onClick}>
    <MapPinIconOuter>
      <div style={{ position: "absolute", top: "-4px", left: "-5px" }}>
        <DiamondIcon color={"#080706"} size={30} />
      </div>
      <DiamondIcon color={pinColor} />
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid gray",
          borderRadius: "10px",
          height: "50px",
          left: "-35px",
          position: "absolute",
          top: "25px",
          width: "100px",
          zIndex: "-1",
        }}
      />
      <LastSeenText>
        Last seen: <br />
        {new moment.utc(timestamp).local().format("YYYY MMM DD")} <br />
        <strong>{new moment.utc(timestamp).local().format("HH:mm:ss")}</strong>
      </LastSeenText>
    </MapPinIconOuter>
  </MapPinWrapper>
);

const SmallPin = ({ lat, lng, timestamp_recorded, $hover }) => {
  const DATE_FORMAT = "M/D/YYYY HH:mm:ss.SSS";
  let pinDate = new moment.utc(timestamp_recorded).format(DATE_FORMAT);
  let pinPosition = `(${formatNumber(
    lat,
    MAP_DECIMAL_RESOLUTION
  )}, ${formatNumber(lng, MAP_DECIMAL_RESOLUTION)})`;

  return (
    $hover && (
      <SmallPinOuter>
        <DiamondIconWrapper hovered={$hover}>
          <DiamondIcon small />
        </DiamondIconWrapper>
        <InfoWindow>
          <PinDateInfo>
            <label>Date:</label>
            <span>{pinDate}</span>
          </PinDateInfo>
          <PinLocationInfo>
            <label>Location:</label>
            <span>{pinPosition}</span>
          </PinLocationInfo>
        </InfoWindow>
      </SmallPinOuter>
    )
  );
};

const orderByTimestamp = (dataArray) => {
  const getMillsFromDataPoint = (dataPoint) =>
    new moment.utc(dataPoint.timestamp_recorded).valueOf();
  return orderBy(dataArray, getMillsFromDataPoint, ["asc"]);
};

class _Map extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allRoutes: {},
      allPoints: {},
      fetchingRoute: true,
      settingsOpen: false,
      currentLocation: true,
      historicalTime: 15,
      doFitMapToRoute: true,
      followOnly: false,
      startDate: "",
      endDate: "",
      infoWindowOpen: null,
      lastKnownLocation: {},
      isFetching: false,
      // currentZoom: DEFAULT_ZOOM_LEVEL,
    };
    this.apiIsLoaded = this.apiIsLoaded.bind(this);
    this.getMapBounds = this.getMapBounds.bind(this);
    this.showLoadingIndicator = this.showLoadingIndicator.bind(this);
    this.hideLoadingIndicator = this.hideLoadingIndicator.bind(this);
    this.setDoFitMapToRoute = this.setDoFitMapToRoute.bind(this);
    this.setFollowOnly = this.setFollowOnly.bind(this);
    this.onMapDrag = this.onMapDrag.bind(this);
    // this.handleMapChange = this.handleMapChange.bind(this);
    this.isWithinViewableRange = this.isWithinViewableRange.bind(this);
  }

  showLoadingIndicator() {
    /* this.props.setLocalFetch(true); */
  }
  hideLoadingIndicator() {
    /* this.props.setLocalFetch(false); */
  }

  getPropsFromDataQuery = _.memoize(() => {
    const fullState = store.getState();
    const customerId = fullState.user.customerId;
    const { data: productSNs } =
      applicationUnitsApi.endpoints.getApplicationUnitsByFilter.select({
        customer_id: customerId,
      })(fullState);
    const { data: allProductSettings } =
      applicationsApi.endpoints.getProductSettingsForCustomer.select(
        customerId
      )(fullState);
    return { productSNs, allProductSettings };
  });

  fetchDataForSN = async (productSNId, date_start, date_end) => {
    const { productSNs, allProductSettings } = this.getPropsFromDataQuery();
    const { currentProduct: currentProductSNId } = this.props;

    // do nothing if no current product SN selected
    if (!currentProductSNId || !productSNs) return;
    this.setState({ isFetching: true });
    let dataForSN = [];

    // make sure we set a date range if not provided
    if (!date_start || !date_end) {
      if (this.state.currentLocation) {
        date_start = new moment.utc().add(
          -1 * this.state.historicalTime,
          "minutes"
        );
        date_end = new moment.utc();
      } else {
        date_start = new moment.utc(this.props.getSettingsField("start_date"));
        date_end = new moment.utc(this.props.getSettingsField("end_date"));
      }
    }

    // only show the loading indicator if we really don't have any data
    if (!dataForSN) this.showLoadingIndicator();

    // make sure we have lat and lng signal ids
    const currentProductSN = productSNs?.find(
      (psn) => psn.id === currentProductSNId
    );
    const { lat_signal_id, lng_signal_id } =
      allProductSettings?.find(
        (productSettingObj) =>
          productSettingObj?.product_id === currentProductSN?.product_id
      ) || {};
    if (!lat_signal_id || !lng_signal_id) return;

    getDataInDateRange(productSNId, date_start, date_end, [
      lat_signal_id,
      lng_signal_id,
    ]).then((res) => {
      // state data change kicks off createRoutesFromData()
      this.setState({ isFetching: false });
      return dispatchSetDataHistory(res.data);
    });
  };

  // Use binary search since data is sorted
  getGPSPointsClosestToDate = _.throttle((GPSData, targetDate) => {
    if (!GPSData || !targetDate || GPSData.length === 0) return null;

    let start = 0;
    let end = GPSData.length - 1;

    while (start <= end) {
      const mid = Math.floor((start + end) / 2);
      const currentPoint = GPSData[mid];

      if (currentPoint.timestamp_recorded === targetDate) {
        return currentPoint;
      } else if (currentPoint.timestamp_recorded < targetDate) {
        start = mid + 1;
      } else {
        end = mid - 1;
      }
    }

    const lowerIndex = Math.max(0, end);
    const upperIndex = Math.min(GPSData.length - 1, start);

    const lowerDiff = Math.abs(
      GPSData[lowerIndex].timestamp_recorded - targetDate
    );
    const upperDiff = Math.abs(
      GPSData[upperIndex].timestamp_recorded - targetDate
    );

    return lowerDiff <= upperDiff ? GPSData[lowerIndex] : GPSData[upperIndex];
  }, 50);

  getMeasureCursorPoint = _.throttle(() => {
    const measurePoint = this.getGPSPointsClosestToDate(
      this.state.allPoints[this.props.currentProduct],
      this.props.measureCursorDate
    );
    return measurePoint || {};
  }, 50);

  createRoutesFromData = (latPoints, lngPoints) => {
    // might be a better way to handle this,
    // since we pass every point multiple times
    let allRoutes = {};
    let allPoints = {};

    // const isLat = (d) => d && d.signal_id === LAT_SIGNAL_ID;
    // const isLng = (d) => d && d.signal_id === LNG_SIGNAL_ID;

    // is there a way to package this up properly?
    let latitudes = latPoints; //_.filter(dataArray, isLat);
    let longitudes = lngPoints; // _.filter(dataArray, isLng);

    // consider using _.zip here
    const combinedPoints = _.reduce(
      latitudes,
      (arr, lat) =>
        _.find(
          longitudes,
          (d) => d.timestamp_recorded === lat.timestamp_recorded
        )
          ? [
              ...arr,
              {
                lat: lat.data_value,
                long: _.find(
                  longitudes,
                  (d) => d.timestamp_recorded === lat.timestamp_recorded
                ).data_value,
                timestamp_recorded: lat.timestamp_recorded,
              },
            ]
          : arr,
      []
    );

    // sanitize points
    const validPoints = combinedPoints.filter(this.isValidPoint);
    const sortedPoints = orderByTimestamp(validPoints);
    const reFilteredPoints = _.filter(sortedPoints, this.isWithinViewableRange);

    // resolution can be as high as 6 places
    const roundedPoints = reFilteredPoints.map((point) => ({
      ...point,
      lat: parseFloat(point.lat.toFixed(MAP_DECIMAL_RESOLUTION)),
      long: parseFloat(point.long.toFixed(MAP_DECIMAL_RESOLUTION)),
    }));

    const POINTS_TO_WORK_WITH = roundedPoints;

    // create routes from updated points
    const newRoute = POINTS_TO_WORK_WITH.reduce((total, current, i, arr) => {
      if (arr[i + 1]) {
        if (
          this.isWithinViewableRange(current) &&
          this.isWithinViewableRange(arr[i + 1])
        ) {
          total.push({ origin: current, destination: arr[i + 1] });
        }
      }
      return total;
    }, []);

    allRoutes[this.props.currentProduct] = newRoute;
    allPoints[this.props.currentProduct] = POINTS_TO_WORK_WITH;

    // setting new points and new routes kicks off fitMapToRoute()
    this.setState({
      allRoutes,
      allPoints,
    });
  };

  getCenterFromPoints = _.memoize((points) => {
    if (points && points.length) {
      let x1 = Math.min(...points.map((r) => parseFloat(r.lat))),
        y1 = Math.min(...points.map((r) => parseFloat(r.long))),
        x2 = Math.max(...points.map((r) => parseFloat(r.lat))),
        y2 = Math.max(...points.map((r) => parseFloat(r.long)));
      let result = { lat: x1 + (x2 - x1) / 2, lng: y1 + (y2 - y1) / 2 };
      if (this.isValidPoint(result)) return result;
    }
    return null;
  });

  fitMapToRoute(productSNId) {
    // don't do anything if we haven't specfied which route
    if (!productSNId) return null;

    // if we're not following route (say, after drag), just rerender
    if (!this.state.doFitMapToRoute) {
      return null;
      // return this.forceUpdate(); // is there a better way to handle this?
    }

    // we only want to fit to the visible points
    let points = this.state.allPoints[productSNId];
    let filteredPoints = points && points.filter(this.isWithinViewableRange);

    // center on default unless we actually have a route
    let route_center =
      filteredPoints && filteredPoints.length
        ? this.getCenterFromPoints(filteredPoints)
        : DEFAULT_MAP_CENTER;

    // fit the map even if there's only one point
    if (!filteredPoints || (filteredPoints && !(filteredPoints.length > 0))) {
      return null;
    }

    const { map, maps } = this.state;
    let pointsToBoundTo =
      filteredPoints && filteredPoints.length
        ? filteredPoints
        : FULL_MAP_BOUNDS_POINTS;
    let bounds = this.getMapBounds(map, maps, pointsToBoundTo);

    if (bounds) {
      map.fitBounds(bounds);
    }

    // actually do the setting
    this.setState({ route_center }, () => {
      this.hideLoadingIndicator();
      // 20220420 - set map to state again to force rerender. not great practice, need to investigate
      // this.forceUpdate();
    });
  }

  // an example of bad data looks like {37.0000, 0.0000}
  // actual GPS data should not contain exactly zero
  _isNotZero = (n) => !!parseFloat(n);

  isValidCoord = (c) =>
    c !== null && !isNaN(parseFloat(c)) && this._isNotZero(c);
  isValidPoint = (point) =>
    this.isValidCoord(point.lat) && this.isValidCoord(point.long || point.lng);

  // Return map bounds based on list of places
  getMapBounds(map, maps, points) {
    if (!map || !maps) return null;
    const bounds = new maps.LatLngBounds();
    const extendBoundsToPoint = (point) =>
      this.isValidPoint(point) &&
      bounds.extend(new maps.LatLng(point.lat, point.long));
    points.forEach(extendBoundsToPoint);

    // Don't zoom in too far on only one marker
    // ref: https://stackoverflow.com/a/5345708
    const boundsAreClose =
      bounds?.getNorthEast()?.lat() - bounds?.getSouthWest()?.lat() <
        COORD_TOLERANCE ||
      bounds?.getNorthEast()?.lng() - bounds?.getSouthWest()?.lng() <
        COORD_TOLERANCE;
    if (boundsAreClose) {
      var extendPoint1 = new maps.LatLng(
        bounds.getNorthEast()?.lat() + MAP_EXPANSION_FACTOR,
        bounds.getNorthEast()?.lng() + MAP_EXPANSION_FACTOR
      );
      var extendPoint2 = new maps.LatLng(
        bounds.getNorthEast()?.lat() - MAP_EXPANSION_FACTOR,
        bounds.getNorthEast()?.lng() - MAP_EXPANSION_FACTOR
      );
      bounds.extend(extendPoint1);
      bounds.extend(extendPoint2);
    }

    return bounds;
  }

  recalucluateRoutes() {
    const { productSNs, allProductSettings } = this.getPropsFromDataQuery();
    const { currentProduct: currentProductSNId } = this.props;

    // do nothing if no current product SN selected
    if (!currentProductSNId) return;

    const currentProductSN = productSNs?.find(
      (psn) => psn.id === currentProductSNId
    );

    const { lat_signal_id, lng_signal_id } =
      allProductSettings?.find(
        (productSettingObj) =>
          productSettingObj?.product_id === currentProductSN?.product_id
      ) || {};

    if (!lat_signal_id || !lng_signal_id) return;
    const relevantDataPoints =
      this.props.dataHistory[this.props.currentProduct] || {};

    let latPoints, lngPoints;
    const hasDateBounds =
      this.props.lineChartStartDate && this.props.lineChartEndDate;
    const hasGPSData =
      !!relevantDataPoints[lat_signal_id] &&
      !!relevantDataPoints[lng_signal_id];

    if (hasDateBounds && hasGPSData) {
      latPoints =
        relevantDataPoints[lat_signal_id].filter(
          (latPoint) =>
            latPoint.timestamp_recorded >= this.props.lineChartStartDate &&
            latPoint.timestamp_recorded <= this.props.lineChartEndDate
        ) || [];

      lngPoints =
        relevantDataPoints[lng_signal_id].filter(
          (lngPoint) =>
            lngPoint.timestamp_recorded >= this.props.lineChartStartDate &&
            lngPoint.timestamp_recorded <= this.props.lineChartEndDate
        ) || [];
    } else {
      latPoints = relevantDataPoints[lat_signal_id] || [];
      lngPoints = relevantDataPoints[lng_signal_id] || [];
    }
    this.createRoutesFromData(latPoints, lngPoints);
  }

  getLastKnownGPSData() {
    const { productSNs, allProductSettings } = this.getPropsFromDataQuery();
    const { currentProduct: currentProductSNId } = this.props;
    if (!currentProductSNId || !productSNs) return;

    const currentProductSN = productSNs?.find(
      (psn) => psn.id === currentProductSNId
    );
    const { lat_signal_id, lng_signal_id } =
      allProductSettings?.find(
        (productSettingObj) =>
          productSettingObj?.product_id === currentProductSN?.product_id
      ) || {};

    if (
      !lat_signal_id ||
      !lng_signal_id ||
      !this.props.dataHistory[this.props.currentProduct] ||
      !this.props.dataHistory[this.props.currentProduct] ||
      !this.props.dataHistory[this.props.currentProduct][lat_signal_id] ||
      !this.props.dataHistory[this.props.currentProduct][lng_signal_id]
    )
      return;

    const latDataForCurrentProduct =
      this.props.dataHistory[this.props.currentProduct][lat_signal_id];

    const lngDataForCurrentProduct =
      this.props.dataHistory[this.props.currentProduct][lng_signal_id];

    const lastKnownLat =
      latDataForCurrentProduct[latDataForCurrentProduct.length - 1].data_value;
    const lastKnownLng =
      lngDataForCurrentProduct[lngDataForCurrentProduct.length - 1].data_value;
    const lastKnownTimeStamp =
      latDataForCurrentProduct[latDataForCurrentProduct.length - 1]
        .timestamp_recorded;

    this.setState({
      lastKnownLocation: {
        lat: lastKnownLat || null,
        lng: lastKnownLng || null,
        timestamp_recorded: lastKnownTimeStamp || null,
      },
    });
  }

  componentDidMount() {
    // fetch data as early as possible to start drawing map routes asap
    this.fetchDataForSN(
      this.props.currentProduct,
      this.props.lineChartStartDate,
      this.props.lineChartEndDate
    );
    // do localstorage load of routes?
    this.getLastKnownGPSData();
  }

  componentDidUpdate(prevProps, prevState) {
    //
    // do all shouldComponentUpdate changes / setStates here
    // but make sure they are wrapped in conditionals; otherwise setState -> infinite loop
    //

    let dataHistoryWasUpdated = !fastEqual(
      prevProps.dataHistory,
      this.props.dataHistory
    );

    let productSettingsChanged = !fastEqual(
      prevProps.productSettings,
      this.props.productSettings
    );

    let lastKnownLocationChanged = !fastEqual(
      prevState.lastKnownLocation,
      this.state.lastKnownLocation
    );

    if (lastKnownLocationChanged) {
      this.getLastKnownGPSData();
    }

    let productChanged = prevProps.currentProduct !== this.props.currentProduct;
    let historicalTimeChanged =
      prevState.historicalTime !== this.state.historicalTime;
    let showRecentRouteToggled =
      prevState.currentLocation !== this.state.currentLocation;
    let startOrEndDateChanged =
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate;

    let lineChartStartOrEndDateChanged =
      prevProps.lineChartStartDate?.valueOf() !==
        this.props.lineChartStartDate?.valueOf() ||
      prevProps.lineChartEndDate?.valueOf() !==
        this.props.lineChartEndDate?.valueOf();

    let pointsChanged = !fastEqual(prevState.allPoints, this.state.allPoints);
    let routesChanged = !fastEqual(prevState.allRoutes, this.state.allRoutes);

    if (dataHistoryWasUpdated || productSettingsChanged) {
      this.recalucluateRoutes();
      this.getLastKnownGPSData();
    }

    if (pointsChanged || routesChanged) {
      this.fitMapToRoute(this.props.currentProduct);
    }

    if (productChanged || productSettingsChanged) {
      this.fetchDataForSN(
        this.props.currentProduct,
        this.props.lineChartStartDate,
        this.props.lineChartEndDate
      );
      this.getLastKnownGPSData();
      this.setState({ lastKnownLocation: {} });
    }

    if (historicalTimeChanged) {
      let product = this.props.currentProduct;
      let start_date = new moment.utc().add(
        -1 * this.state.historicalTime,
        "minutes"
      );
      let end_date = new moment.utc();
      this.onHistoricalTimeChange(product, start_date, end_date);
      this.setState({ lastKnownLocation: {} });
      this.getLastKnownGPSData();
    }

    if (showRecentRouteToggled) {
      this.setState({ doFitMapToRoute: true });
      this.fitMapToRoute(this.props.currentProduct);
    }

    if (startOrEndDateChanged) {
      this.fetchDataForSN(
        this.props.currentProduct,
        this.state.startDate,
        this.state.endDate
      );
    }
  }

  onHistoricalTimeChange(product, start_date, end_date) {
    this.fetchDataForSN(product, start_date, end_date);
    this.recalucluateRoutes();
    this.getLastKnownGPSData();
    if (this.state.doFitMapToRoute) this.fitMapToRoute(product);
    // this.setState({ doFitMapToRoute: true });
  }

  bindResizeListener(map, maps, bounds) {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  }

  apiIsLoaded({ map, maps }) {
    this.setState({ map: map, maps: maps, mapLoaded: true });
    /** hiding in case this is useful during layout changes */
    // Bind the resize listener
    // thisbindResizeListener(map, maps, bounds);
  }

  setDoFitMapToRoute(e) {
    // this.setState({ currentLocation: e.target.checked ? 1 : 0 })
    this.setState({
      doFitMapToRoute: e.target.checked,
      followOnly: false,
    });
  }

  setFollowOnly(e) {
    this.setState({
      followOnly: e.target.checked,
      doFitMapToRoute: false,
    });
  }

  onMapDrag(e) {
    if (
      this.state.doFitMapToRoute !== false ||
      this.state.followOnly !== false
    ) {
      this.setState({ doFitMapToRoute: false, followOnly: false });
    }
  }

  // this one is triggered even by auto changes
  // handleMapChange({ center, zoom }) {
  //   let mapWasDragged = !_.isEqual(center, this.state.route_center);
  //   if (mapWasDragged) return this.setState({ center, currentZoom: zoom, doFitMapToRoute: false, followOnly: this.state.followOnly });
  //   this.setState({ currentZoom: zoom })
  // }

  isWithinViewableRange = _.memoize(
    (element) => {
      let { currentLocation, startDate, endDate, historicalTime } = this.state;

      let startMoment = this.props.lineChartStartDate
        ? this.props.lineChartStartDate
        : currentLocation
        ? new moment.utc().add(-1 * historicalTime, "minutes")
        : startDate;
      let endMoment = this.props.lineChartEndDate
        ? this.props.lineChartEndDate
        : currentLocation
        ? new moment.utc().add(0, "seconds")
        : endDate;

      // routeLines have origin/destination, otherwise just a point
      if (element["destination"]) {
        let origin_tsr = element["origin"].timestamp_recorded;
        let destination_tsr = element["destination"].timestamp_recorded;
        let originTime = new moment.utc(origin_tsr);
        let destinationTime = new moment.utc(destination_tsr);
        let originIsValid =
          originTime.isSameOrAfter(startMoment) &&
          originTime.isSameOrBefore(endMoment);
        let destinationIsValid =
          destinationTime.isSameOrAfter(startMoment) &&
          destinationTime.isSameOrBefore(endMoment);
        return originIsValid && destinationIsValid;
      } else {
        let { timestamp_recorded } = element;
        let elementTime = new moment.utc(timestamp_recorded);
        return (
          elementTime.isSameOrAfter(startMoment) &&
          elementTime.isSameOrBefore(endMoment)
        );
      }
    },
    () => [
      this.state.currentLocation,
      this.state.startDate,
      this.state.endDate,
      this.state.historicalTime,
      this.props.lineChartStartDate,
      this.props.lineChartEndDate,
    ]
  );

  render() {
    const {
      currentProduct: currentProductSNId,
      theme,
      setLayoutsSettings,
      lineChartSideBarMapType = MAP_TYPE_ROADMAP,
    } = this.props;
    const {
      allRoutes,
      allPoints,
      settingsOpen,
      currentLocation,
      startDate,
      endDate,
      route_center,
      historicalTime,
      lastKnownLocation,
    } = this.state;

    let points = allPoints[currentProductSNId] || [];
    let filteredPoints = points; //points.filter(this.isWithinViewableRange);

    let route = allRoutes[currentProductSNId] || [];
    let filteredRoute = route; //route.filter(this.isWithinViewableRange);

    let pinLat, pinLng;

    const measureCursorPoint = this.getMeasureCursorPoint();

    if (filteredPoints.length) {
      pinLat = measureCursorPoint.lat
        ? measureCursorPoint.lat
        : filteredPoints[filteredPoints.length - 1].lat;
      pinLng = measureCursorPoint.long
        ? measureCursorPoint.long
        : filteredPoints[filteredPoints.length - 1].long;
    }

    const currentCenter = this.state.followOnly
      ? pinLat && pinLng && { lat: pinLat, lng: pinLng }
      : route_center;

    let showMapPin = true; //currentLocation && filteredRoute.length && filteredPoints.length;
    const haveVisibleRoutes = filteredRoute.length > 0;
    const haveVisiblePoints = filteredPoints.length > 0;
    const haveVisibleElements = haveVisibleRoutes || haveVisiblePoints;
    const drawMapPin = () =>
      showMapPin &&
      pinLat &&
      pinLng && (
        <MapPin
          pinColor={theme.themePrimary}
          lat={pinLat.toFixed(MAP_DECIMAL_RESOLUTION)}
          lng={pinLng.toFixed(MAP_DECIMAL_RESOLUTION)}
        />
      );

    const drawLastKnownLocation = () =>
      lastKnownLocation.lat &&
      lastKnownLocation.lng &&
      lastKnownLocation.timestamp_recorded && (
        <LastSeenPin
          pinColor={"#ffffff"}
          lat={this.state.lastKnownLocation?.lat?.toFixed(
            MAP_DECIMAL_RESOLUTION
          )}
          lng={this.state.lastKnownLocation?.lng?.toFixed(
            MAP_DECIMAL_RESOLUTION
          )}
          timestamp={lastKnownLocation.timestamp_recorded}
        />
      );

    const drawRoutes = _.memoize(
      _.throttle(
        () => {
          return (
            filteredRoute?.length > 0 &&
            filteredRoute.map((r, i) => {
              const maxOpacity = 0.75;
              const minOpacity = 0.1;
              const numSegments = filteredRoute.length;
              const currentOpacity =
                (i / numSegments) * (maxOpacity - minOpacity);
              return (
                <RouteLine
                  key={`${i}-a`}
                  color={
                    hexToRgba(this.props.theme.themePrimary, currentOpacity) ||
                    "#dddddd"
                  }
                  map={this.state.map}
                  maps={this.state.maps}
                  {...r}
                />
              );
            })
          );
        },
        100,
        { leading: true, trailing: true }
      ),
      () => [
        this.state.filteredRoute,
        this.state.map,
        this.state.maps,
        this.props.theme,
      ]
    );

    const drawPoints = _.memoize(
      () => {
        return (
          filteredPoints.length > 0 &&
          filteredPoints.map((p, i) => (
            <SmallPin
              key={i}
              lat={p.lat.toFixed(MAP_DECIMAL_RESOLUTION)}
              lng={p.long.toFixed(MAP_DECIMAL_RESOLUTION)}
              {...p}
            />
          ))
        );
      },
      () => [showMapPin, pinLat, pinLng]
    );

    const setHistoricalTime = (e) => {
      this.setState({ historicalTime: e.target.value });
    };
    const setHistoricalStart = (startDate) => this.setState({ startDate });
    const setHistoricalEnd = (endDate) => this.setState({ endDate });

    const ENABLE_SETTINGS_BAR = false;
    return (
      <div className={`map-tile${settingsOpen ? " settings-open" : ""}`}>
        {ENABLE_SETTINGS_BAR && (
          <SettingsBar
            settingsOpen={settingsOpen}
            setSettingsOpen={() =>
              this.setState({ settingsOpen: !settingsOpen })
            }
          />
        )}
        {ENABLE_SETTINGS_BAR && settingsOpen && (
          <SettingsArea>
            {/* <SettingsBarInner>
              <label>Show Recent Route?</label>
              <input
                type="checkbox"
                onChange={this.setDoFitMapToRoute}
                checked={this.state.currentLocation}
              />
            </SettingsBarInner> */}
            {/*(
              <HistoricalSelect>
                <label>Historical Time</label>
                <select
                  onChange={setHistoricalTime}
                  value={historicalTime}
                >
                  <option value="5">5 minutes</option>
                  <option value="15">15 minutes</option>
                  <option value="30">30 minutes</option>
                  <option value="60">60 minutes</option>
                  <option value="1440">1 day</option>
                </select>
              </HistoricalSelect>
            )*/}
            {!currentLocation ? (
              <>
                <HistoricalDateSelector>
                  <DateSelectorLabel>Start Date</DateSelectorLabel>
                  <DateTimePicker
                    value={startDate}
                    onChange={setHistoricalStart}
                  />
                </HistoricalDateSelector>
                <HistoricalDateSelector>
                  <DateSelectorLabel>End Date</DateSelectorLabel>
                  <DateTimePicker value={endDate} onChange={setHistoricalEnd} />
                </HistoricalDateSelector>
              </>
            ) : null}
          </SettingsArea>
        )}
        {!Object.keys(lastKnownLocation).length && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(255,255,255,0.4)",
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              fontSize: "80%",
              textAlign: "center",
            }}
          >
            No Location Data Found
          </div>
        )}
        <div
          style={{
            position: "absolute",
            zIndex: 99,
            top: "10px",
            left: "10px",
          }}
        >
          <Button
            style={{
              width: "210px",
              borderColor:
                lineChartSideBarMapType === MAP_TYPE_SATELLITE && "#1890ff",
              color:
                lineChartSideBarMapType === MAP_TYPE_SATELLITE && "#1890ff",
            }}
            onClick={() => {
              lineChartSideBarMapType === MAP_TYPE_ROADMAP
                ? setLayoutsSettings({
                    layoutId: this.props.layoutId,
                    settings: {
                      [LINE_CHART_MAP_TYPE]: MAP_TYPE_SATELLITE,
                    },
                  })
                : setLayoutsSettings({
                    layoutId: this.props.layoutId,
                    settings: {
                      [LINE_CHART_MAP_TYPE]: MAP_TYPE_ROADMAP,
                    },
                  });
            }}
          >
            Satellite View
          </Button>
        </div>
        <GoogleMapReact
          style={{
            position: "",
            top: settingsOpen ? "10%" : "0",
            opacity: haveVisibleElements ? "1" : "0.5",
            transition: "opacity 1s ease",
          }}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          draggable={true}
          onDrag={this.onMapDrag}
          // onChange={this.handleMapChange}
          defaultZoom={DEFAULT_ZOOM_LEVEL}
          defaultCenter={DEFAULT_CENTER}
          center={
            Object.keys(this.state.lastKnownLocation).length
              ? {
                  lat: this.state.lastKnownLocation.lat,
                  lng: this.state.lastKnownLocation.lng,
                }
              : currentCenter
          }
          options={{
            styles: MapStyling.GoogleMapStyle_Tile,
            mapTypeId: this.props.lineChartSideBarMapType,
          }}
          onGoogleApiLoaded={this.apiIsLoaded}
          yesIWantToUseGoogleMapApiInternals
        >
          {/* needs to be rendered as direct child of GoogleMap with lat/lng at top prop, otherwise rendering doesnt stick with map */}
          {!haveVisibleElements && drawLastKnownLocation()}
          {haveVisibleRoutes && drawRoutes()}
          {haveVisiblePoints && !this.props.isSidebarMap ? drawPoints() : null}
          {drawMapPin()}
        </GoogleMapReact>
        {!this.props.isSidebarMap && (
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              fontSize: "80%",
              padding: "5px",
              textAlign: "right",
              backgroundColor: "#ffffff",
              borderRadius: "5px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ marginRight: "5px" }}>
                {this.state.isFetching ? (
                  <img src={S3_HIGH_RES_REQUEST_ANIM} alt="Loading" />
                ) : null}
              </div>
              <HistoricalSelect>
                <label style={{ margin: "0" }}>Last</label>
                <select
                  onChange={setHistoricalTime}
                  value={historicalTime}
                  style={{ marginLeft: "5px" }}
                >
                  <option value={1}>1 minute</option>
                  <option value={5}>5 minutes</option>
                  <option value={15}>15 minutes</option>
                  <option value={30}>30 minutes</option>
                  <option value={60}>60 minutes</option>
                  <option value={1440}>1 day</option>
                  <option value={1440 * 7}>1 week</option>
                  <option value={1440 * 30}>1 month</option>
                  <option value={1440 * 90}>3 months</option>
                </select>
              </HistoricalSelect>
              <label
                style={{
                  margin: "0 0 0 20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={this.setDoFitMapToRoute}
                  checked={this.state.doFitMapToRoute}
                  style={{ marginRight: "5px" }}
                />{" "}
                Fit route (autozoom)
              </label>
              <label
                style={{
                  margin: "0 0 0 20px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  onChange={this.setFollowOnly}
                  checked={this.state.followOnly}
                  style={{ marginRight: "10px" }}
                />{" "}
                Just follow
              </label>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, props) => ({
  dataHistory: state.productSNData.dataHistory,
  productSettings: state.products.productSettings,
  productSNs: state.products.productSNs,
  lineChartSideBarMapType:
    state.layout?.layoutSettings[props.layoutId]?.[LINE_CHART_MAP_TYPE],
});

const mapDispatchToProps = (dispatch) => ({
  setCurrentProduct: (product) => dispatch(setCurrentProduct(product)),
  setLayoutsSettings: (newLayoutSettings) =>
    dispatch(setLayoutsSettings(newLayoutSettings)),
});

// Memoize?
const Map = connect(mapStateToProps, mapDispatchToProps)(withTheme(_Map));
export { Map };
