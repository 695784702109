import semver from "semver";
import { Button, Flex, Input, Modal, Space, Table } from "antd";
import { useGetTCUsByFilterQuery } from "features/tcu/redux";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  FirmwareModal,
  FirmwareUploadModal,
} from "features/applications/components/ApplicationFirmwareModal";
import { axios } from "axios/axios";
import { uploadS3 } from "utils/uploadS3";
import { firmwareService } from "../services";
import { notify } from "features/notifications";
import {
  useGetApplicationDeviceQuery,
  useGetDeviceTypeQuery,
  useGetApplicationDeviceInstanceByApplicationUnitIdQuery,
} from "features/application-devices/redux";

const OTAManagerView = () => {
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [firmwareFiles, setFirmwareFiles] = useState([]);
  const [currentOpenFirmware, setCurrentOpenFirmware] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({});

  useEffect(() => {
    const getFileList = async () => {
      try {
        // const bucket = process.env.S3_BUCKET; // Replace with your bucket name
        // const data = await Storage.list("");
        // console.log("DATA", data);
        // console.log(Storage);
        // const filenames = data.map((item) => item.key);
        // setFileList(filenames);
        // console.log("!!! FILENAMES", filenames);
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    getFileList();
  }, []);

  const { id } = useParams();
  const { data: tcuData, isLoading: tcuDataIsLoading } =
    useGetTCUsByFilterQuery({ id: id });

  const { product_sn_id } = tcuData || "";

  const { data: applicationDeviceInstance } =
    useGetApplicationDeviceInstanceByApplicationUnitIdQuery(product_sn_id);

  const { data: applicationDevice } = useGetApplicationDeviceQuery(
    applicationDeviceInstance?.application_device_id
  );

  const { data: deviceType } = useGetDeviceTypeQuery(
    applicationDevice?.device_type_id
  );

  const onUpload = async (firmware) => {
    if (!firmware.file) {
      return notify("File required");
    }
    if (!firmware.version_number) {
      return notify("Version number required");
    }
    console.log("!!! FIRMWARE UPLOAD", firmware);
    // 1. get presigned url
    let { status, signedRequestUrl } = await firmwareService.upload({
      ...firmware,
      fileName: firmware.file.name,
      fileType: firmware.file.type,
    });
    // 2. post to s3 signed url
    await uploadS3(firmware.file, signedRequestUrl);

    notify(status);
    setCurrentOpenFirmware(null);
    setUploadModalOpen(null);

    reloadFiles();
  };

  const onUpdateFirmware = (firmware) => {
    axios
      .put("/api/firmware/files", { firmware })
      .then((json) => notify(json.message))
      .then(() => setCurrentOpenFirmware(null))
      .then(() => reloadFiles());
  };

  const onDeleteFirmware = (firmware_id) => {
    axios
      .delete("/api/firmware/files", { data: { firmware_id, yo: "yeet" } })
      .then((json) => notify(json.message))
      .then(() => setCurrentOpenFirmware(null))
      .then(() => reloadFiles());
  };

  useEffect(() => {
    if (product_sn_id) reloadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_sn_id]);

  const reloadFiles = () => {
    axios
      .get("/api/firmware/files", {
        params: { product_id: product_sn_id },
      })
      .then((json) => {
        setFirmwareFiles(json.result);
      });
  };

  function getLatestFirmwareVersion() {
    if (!firmwareFiles || !firmwareFiles.length) return null; // Handle empty array

    // Sort using semver.sort (assuming valid semantic versions)
    const sortedFiles = firmwareFiles.sort((a, b) =>
      semver.compare(
        JSON.stringify(semver.valid(a.version) || 0),
        JSON.stringify(semver.valid(b.version) || 0),
        { loose: true }
      )
    );
    return sortedFiles;
    // return firmwareFiles[firmwareFiles.length - 1];
  }

  //   useEffect(() => {
  //     console.log("firmwareFiles", firmwareFiles);
  //     console.log("~~~~~~~~~~", getLatestFirmwareVersion());
  //   }, [firmwareFiles]);

  const DeviceTypeColumns = [
    {
      title: "Device Type",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: false,
      onCell: (record, rowIndex) => {
        return {
          //   onClick: () => history.push(`view/${record.id}`),
          style: {
            textDecoration: hoveredRow === rowIndex ? "underline" : "none",
            cursor: "pointer",
            color: "blue",
          },
          onMouseEnter: () => setHoveredRow(rowIndex),
          onMouseLeave: () => setHoveredRow(),
        };
      },
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
    },
    {
      title: "Latest Version",
      dataIndex: "version",
      key: "version",
      disabledCheckbox: false,
      default: true,
      //   sorter: (a, b) => a.version.localeCompare(b.version),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Lastest Notes",
      dataIndex: "latestNotes",
      key: "latestNotes",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.latestNotes.localeCompare(b.latestNotes),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
  ];

  const firmwareColumns = [
    {
      title: "File Name",
      dataIndex: "fileName",
      key: "fileName",
      disabledCheckbox: false,
      default: false,
      onCell: (record, rowIndex) => {
        return {
          onClick: () => {
            console.log(record);
            setModalInfo(record);
            setModalOpen(true);
          },
          style: {
            textDecoration: hoveredRow === rowIndex ? "underline" : "none",
            cursor: "pointer",
            color: "blue",
          },
          onMouseEnter: () => setHoveredRow(rowIndex),
          onMouseLeave: () => setHoveredRow(),
        };
      },
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
    },
    {
      title: "Version",
      dataIndex: "version",
      key: "version",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.version.localeCompare(b.version),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Last Modified",
      dataIndex: "lastModified",
      key: "lastModified",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.lastModified.localeCompare(b.lastModified),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      disabledCheckbox: false,
      default: false,
      sorter: (a, b) => a.notes.localeCompare(b.notes),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
  ];

  return (
    <Flex vertical>
      {/* <div style={{ margin: "25px" }}>
        <p>List of all firmware</p>
        {firmwareFiles &&
          firmwareFiles.map((file, i) => (
            <div
              key={file.name}
              isSelected={currentOpenFirmware === i}
              onClick={() => setCurrentOpenFirmware(i)}
            >
              {file.fileName}
              {file.lastModified}
              {file.is_latest ? " (Latest)" : null}
              {file.is_beta ? " (Beta)" : null}
            </div>
          ))}
      </div> */}
      {/* <Flex vertical>
        <p>Customer ID: {customer_id}</p>
        <p>Firmware Version: {firmware_version}</p>
        <p>Hologram Device ID: {hologram_device_id}</p>
        <p>Hologram Link ID: {hologram_link_id}</p>
        <p>IMEI: {imei}</p>
        <p>Name: {name}</p>
        <p>Product SN ID: {product_sn_id}</p>
        <p>Sim: {sim}</p>
        <p>Status: {status}</p>
        <p>Token: {token}</p>
        <p>Unit Number: {unit_number}</p>
      </Flex> */}
      <Space>
        {" "}
        <Button onClick={() => setUploadModalOpen(true)}>Upload</Button>
      </Space>
      {/* {uploadModalOpen && product_id ? ( */}
      <Table
        dataSource={[
          { ...deviceType, version: getLatestFirmwareVersion()?.version },
        ]}
        columns={DeviceTypeColumns}
      />
      <Table dataSource={firmwareFiles} columns={firmwareColumns} />
      <Modal
        title="Upload {Device Type} Modal"
        centered
        open={modalOpen}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
        footer={[
          <Button key="cancel" danger onClick={() => setModalOpen(false)}>
            Cancel
          </Button>,
          // <Button key="submit" type="primary" loading={loading} onClick={handleOk}>
          //   Submit
          // </Button>,
          <Button
            key="link"
            // href="https://google.com"
            type="primary"
            //   loading={loading}
            //   onClick={handleOk}
          >
            Upload
          </Button>,
        ]}
      >
        <p>Device Type: </p>
        <p>Version: {modalInfo.version}</p>
        <Input type="file" />
      </Modal>
      {uploadModalOpen ? (
        <FirmwareUploadModal
          show={uploadModalOpen}
          hide={() => setUploadModalOpen(false)}
          firmware={{}}
          product_id={product_sn_id}
          onUpload={onUpload}
          notify={notify}
          latestFirmware={getLatestFirmwareVersion()}
        />
      ) : null}
      {currentOpenFirmware !== null ? (
        <FirmwareModal
          show={currentOpenFirmware !== null}
          hide={() => setCurrentOpenFirmware(null)}
          firmware={firmwareFiles[currentOpenFirmware]}
          onUpdateFirmware={onUpdateFirmware}
          onDeleteFirmware={onDeleteFirmware}
          notify={notify}
        />
      ) : null}
    </Flex>
  );
};

export default OTAManagerView;
