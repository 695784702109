import { Table, Row, Col, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link, useRouteMatch } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "common/Spinner";
import moment from "moment-timezone";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW } from "features/application-devices/utils/Constants";

export const ApplicationDeviceInstancesOverviewTable = ({
  qApplicationDeviceInstances,
  isPageLoaded,
}) => {
  const { path } = useRouteMatch();

  const [applicationDeviceInstances, setApplicationDeviceInstances] = useState(
    []
  );
  const [
    filteredApplicationDeviceInstances,
    setFilteredApplicationDeviceInstances,
  ] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplicationDeviceInstances =
        qApplicationDeviceInstances?.map((obj, index) => {
          const applicationDevicePairDisplay = `${obj.product_sn.product.product_name} -- ${obj.application_device.device_type.name}`;
          const createdOnDate = new Date(obj.createdAt);
          const updatedOnDate = new Date(obj.updatedAt);
          const createdOnFormatted = moment(createdOnDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          const updatedOnFormatted = moment(updatedOnDate).format(
            "YYYY-MM-DD HH:mm:ss"
          );
          return {
            ...obj,
            key: obj.id,
            application_name: obj.product_sn.product.product_name,
            application_device_name: obj.application_device.device_type.name,
            application_device_pair: applicationDevicePairDisplay,
            application_unit_name: obj.product_sn?.product_sn_name,
            application_device_instance_serial_number: obj.serial_number,
            application_device_instance_created_at: createdOnFormatted,
            application_device_instance_updated_at: updatedOnFormatted,
            created_datetime: createdOnDate,
            updated_datetime: updatedOnDate,
          };
        });
      setApplicationDeviceInstances(updatedApplicationDeviceInstances);
      setFilteredApplicationDeviceInstances(updatedApplicationDeviceInstances);
    }
  }, [qApplicationDeviceInstances, isPageLoaded]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const searchTableData = applicationDeviceInstances.filter((item) => {
      return Object.keys(item).some((key) => {
        return (
          item[key] && item[key].toString().toLowerCase().includes(searchValue)
        );
      });
    });
    setFilteredApplicationDeviceInstances(searchTableData);
  };

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Application Device Pair",
      dataIndex: "application_device_pair",
      key: "application_device_pair",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`${path}/view/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Application",
      dataIndex: "application_name",
      key: "application_name",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.product_sn.product.id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Application Device",
      dataIndex: "application_device_name",
      key: "application_device_name",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link
          to={`/admin/${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW}/${record.application_device.device_type.id}`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Application Unit",
      dataIndex: "application_unit_name",
      key: "application_unit_name",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Serial Number",
      dataIndex: "application_device_instance_serial_number",
      key: "application_device_instance_serial_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Created At",
      dataIndex: "application_device_instance_created_at",
      key: "application_device_instance_created_at",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Updated At",
      dataIndex: "application_device_instance_updated_at",
      key: "application_device_instance_updated_at",
      disabledCheckbox: false,
      default: false,
      defaultSortOrder: "descend",
      sorter: (a, b) =>
        a.updated_datetime.getTime() - b.updated_datetime.getTime(),
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={filteredApplicationDeviceInstances}
          />
        </Col>
      </Row>
    </>
  );
};
