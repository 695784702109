import { useEffect, useState } from "react";
import { Table } from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Spinner } from "common/Spinner";

export const ApplicationDeviceTypeOverviewTable = ({
  qApplicationDeviceTypes,
  isPageLoaded,
}) => {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [applicationDeviceTypes, setApplicationDeviceTypes] = useState([]);

  // Load and Update Application Device Types List
  useEffect(() => {
    if (isPageLoaded) {
      // update list of application device types to include a key parameter (for js-warning)
      const updatedApplicationDeviceTypes = qApplicationDeviceTypes?.map(
        (obj) => {
          return {
            ...obj,
            key: obj.id,
          };
        }
      );
      setApplicationDeviceTypes(updatedApplicationDeviceTypes);
    }
  }, [isPageLoaded, qApplicationDeviceTypes]);

  if (!isPageLoaded) return <Spinner />;

  /**
   * Columns for Application Device Type Overview Table
   */
  const columns = [
    {
      title: "Application Device Type",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: true,
      render: (text, record) => (
        <Link to={`${path}/view/${record.id}`}>{text}</Link>
      ),
    },
  ];

  return (
    <>
      <Table bordered columns={columns} dataSource={applicationDeviceTypes} />
    </>
  );
};
