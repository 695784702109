// This component's purpose is to work around the React Rules of
// Hook's which says we cannot use a hook in a callback.

import { useEffect } from "react";
import { useSignalUISettingsForProductSN } from "features/applications/hooks";

export const UnitSignalSettingsData = ({ product, onSignalDataReceived }) => {
  const {
    unitSOCVal,
    SOCSignalPrecision,
    unitSOHVal,
    SOHSignalPrecision,
    unitSimplifiedRSSI,
    unitLastUpdated,
  } = useSignalUISettingsForProductSN(product);

  useEffect(() => {
    onSignalDataReceived({
      key: product.id,
      product_sn_name: product.product_sn_name,
      status: unitLastUpdated,
      signal_strength: unitSimplifiedRSSI,
      soc: [unitSOCVal, SOCSignalPrecision],
      soh: [unitSOHVal, SOHSignalPrecision],
      updated: unitLastUpdated,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product.id,
    product.product_sn_name,
    unitLastUpdated,
    unitSOCVal,
    unitSOHVal,
    unitSimplifiedRSSI,
  ]);

  return null;
};
