import styled from "styled-components";

import { tcuService } from "features/tcu/services";
import { Button } from "common/elements";
import {
  TCU_CMD_ID_GENERAL,
  TCU_CMD_ID_NETWORK,
  TCU_CMD_ID_STORAGE,
  TCU_CMD_ID_EVENT,
} from "utils/defines";

const PresetCommandsWrapper = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  & > button {
    flex: 1 1 45%;
    margin: 1%;
    &:active {
      opacity: 0.8;
    }
  }
`;

const ApplicationsPresetCommands = ({ tcu }) => {
  if (!tcu) return null;
  const handleSendCommand = (cmd_id) => async () =>
    await tcuService.SendCommand({ token: tcu?.token, cmd_id });
  return (
    <PresetCommandsWrapper>
      <Button onClick={handleSendCommand(TCU_CMD_ID_GENERAL)}>
        Request TCU Info
      </Button>
      <Button onClick={handleSendCommand(TCU_CMD_ID_NETWORK)}>
        Request Network Info
      </Button>
      <Button onClick={handleSendCommand(TCU_CMD_ID_STORAGE)}>
        Request Storage Info
      </Button>
      <Button onClick={handleSendCommand(TCU_CMD_ID_EVENT)}>
        Request Event Info
      </Button>
    </PresetCommandsWrapper>
  );
};

export default ApplicationsPresetCommands;
