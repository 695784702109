import { useContext, useEffect, useState } from "react";
import { Modal, Form, Input, Alert } from "antd";
import { useHistory } from "react-router-dom";
import { AuthContext } from "features/auth/contexts";
import {
  useAddDeviceTypeMutation,
  useUpdateDeviceTypeMutation,
} from "features/application-devices/redux";
import { debounce } from "lodash";
import { Spinner } from "common/Spinner";

export const ApplicationDeviceTypeFormModal = ({
  form,
  title,
  applicationDeviceTypeObj,
  refetch,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  isNewForm = false,
  isEditForm = false,
}) => {
  /**
   * Application Device Type Form Modal Vars
   */
  const { user: currentUser } = useContext(AuthContext);
  const [addDeviceType] = useAddDeviceTypeMutation();
  const [updateDeviceType] = useUpdateDeviceTypeMutation();
  const [isCreateButtonLoading, setIsCreateButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsCreateButtonLoading(false);
  }, [isModalOpen, form]);

  useEffect(() => {}, [isPageLoaded]);

  /**
   * Create New Application Device Type
   * @param {*} values
   * @returns
   */
  const onFinishFormModal = async (values) => {
    const { formApplicationDeviceTypeName } = values;
    setIsCreateButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };
      if (isNewForm && !isEditForm) {
        result = await addDeviceType({
          name: formApplicationDeviceTypeName,
          creator_id: currentUser.auth_user.id,
          stafl_device_type_code: "",
        });
      } else if (isEditForm && applicationDeviceTypeObj) {
        result = await updateDeviceType({
          id: applicationDeviceTypeObj.id,
          name: formApplicationDeviceTypeName,
        });
      }
      if ("error" in result) {
        setIsCreateButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  if (!isPageLoaded) return <Spinner />;

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{
          autoFocus: true,
          htmlType: "submit",
        }}
        cancelButtonProps={{ disabled: isCreateButtonLoading }}
        confirmLoading={isCreateButtonLoading}
        destroyOnClose
        forceRender // trigger render of Form component
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application Device Type Name"
          name="formApplicationDeviceTypeName"
          initialValue={applicationDeviceTypeObj?.name}
          rules={[
            {
              required: true,
              message: "Missing/Invalid Application Device Type Name!",
            },
          ]}
        >
          <Input disabled={isCreateButtonLoading} />
        </Form.Item>
      </Modal>
    </>
  );
};
