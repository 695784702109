import { Table, Image } from "antd";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";
import { Spinner } from "common/Spinner";

export const ApplicationViewDetailsTable = ({ qApplication, isPageLoaded }) => {
  const [applicationDetails, setApplicationDetails] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplication = {
        ...qApplication,
        key: qApplication?.id,
        application: qApplication?.product_name,
        application_number: qApplication?.product_number,
        customer: qApplication?.customer.name,
        application_image: qApplication?.image,
      };
      setApplicationDetails([updatedApplication]);
    }
  }, [qApplication, isPageLoaded]);

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Application Name",
      dataIndex: "application",
      key: "application",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Application Number",
      dataIndex: "application_number",
      key: "application_number",
      disabledCheckbox: false,
      default: false,
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.customer_id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Image",
      dataIndex: "application_image",
      key: "application_image",
      disabledCheckbox: false,
      default: false,
      render: (text) => <Image width={100} src={text} />,
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={applicationDetails}
      bordered
      pagination={false}
    />
  );
};
