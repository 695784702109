import { useState } from "react";
import styled from "styled-components";
import Select from "react-select";

import { Button } from "common/elements";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";

import { useUpsertEventStateValueMutation } from "features/events/redux";

const FormRow = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  & > label {
    width: 80px;
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
  }
`;

const ValuesFormWrapper = styled.div`
  background-color: #f0f0f0;
  border-radius: 5px;
  padding: 20px;
`;

const signalToSelectOptions = (signal) => ({
  value: `${signal.id}`,
  label: `${signal.name || signal.can_signal_name}${
    signal.symbol ? ` - [${signal.symbol}]` : ""
  }`,
  key: `${signal.key}`,
  signal_id: signal.id,
});

const ApplicationEventStateValuesForm = () => {
  const customerId = useCustomerId();
  const { data: signalsAndMessages } =
    useGetSignalsForCustomerQuery(customerId);
  const [upsertEventStateValue, { isLoading: isUpdating }] =
    useUpsertEventStateValueMutation();
  const [isFormPristine, setIsFormPristine] = useState(true);
  const [selectedSignal, setSelectedSignal] = useState();
  const signals = signalsAndMessages ? signalsAndMessages.signals : [];

  const signalOptions = signals.map(signalToSelectOptions);

  const emptyForm = () => ({
    stateValue: "",
    stateLabel: "",
  });
  const [form, setFormState] = useState({ ...emptyForm() });
  const updateField = (e) => {
    setFormState({
      ...form,
      [e.target.name]: e.target.value,
    });
    setIsFormPristine(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newStateValue = {
      signal_key: selectedSignal.key,
      value: form.stateValue,
      label: form.stateLabel,
    };
    upsertEventStateValue(newStateValue);
    setFormState({ ...emptyForm() });
    setIsFormPristine(true);
  };

  const onSignalSelected = (selection) => {
    setSelectedSignal(selection);
    setIsFormPristine(false);
  };

  return (
    <ValuesFormWrapper>
      <form onSubmit={handleSubmit} id="values-form">
        <div>
          <FormRow>
            <label>Signal</label>
            <div>
              <Select
                value={selectedSignal}
                onChange={onSignalSelected}
                options={signalOptions}
              />
            </div>
          </FormRow>
          <FormRow>
            <label>Value</label>
            <div>
              <input
                value={form.stateValue}
                type="number"
                name="stateValue"
                onChange={updateField}
                placeholder="value"
              />
            </div>
          </FormRow>
          <FormRow>
            <label>Label</label>
            <div>
              <input
                value={form.stateLabel}
                name="stateLabel"
                onChange={updateField}
                placeholder="Label"
              />
            </div>
          </FormRow>
        </div>
        <Button form="values-form" disabled={!selectedSignal || isFormPristine}>
          {isUpdating ? "Saving..." : "Save"}
        </Button>
      </form>
    </ValuesFormWrapper>
  );
};

export default ApplicationEventStateValuesForm;
