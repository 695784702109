import * as Defines from "utils/defines";
import * as d3 from "d3";
import moment from "moment-timezone";

const _defaultChartDataOptions = { chartColors: Defines.DEFAULT_CHART_COLORS };

const hasSignalAttr = (instanceSignal) => instanceSignal.signal;

const formatDataPoint = (precision) => (dp) => ({
  value: dp.data_value.toFixed(precision),
  date: new Date(dp.timestamp_recorded),
});

/** format data for chart given dataHistory and instance signals */
export const composeChartDataFromInstanceSignals = ({
  dataForCurrentSN,
  instance_signals,
  isFiltering,
  options = _defaultChartDataOptions,
}) => {
  // return empty array if no data provided
  // if (!dataForCurrentSN) return [];
  let results = [];
  const validSignals = instance_signals.filter(hasSignalAttr);
  validSignals.forEach((instanceSignal, i) => {
    const { signal } = instanceSignal;
    const unitSymbol = instanceSignal.IS.getUnitSymbol();
    const unitPrecision = instanceSignal.IS.getPrecision();
    const chartLabel = `${signal.name}${unitSymbol ? ` [${unitSymbol}]` : ""}`; // signal.can_signal_name

    let data = dataForCurrentSN ? dataForCurrentSN[signal.id] : [];
    if (isFiltering && data) {
      data = data.filter((ins) => {
        return (
          ins.data_value >= signal.value_min &&
          ins.data_value <= signal.value_max
        );
      });
    }
    results.push({
      signal_id: signal.id,
      data: data && data.length ? data.map(formatDataPoint(unitPrecision)) : [],
      chartColor: options.chartColors[i],
      chartLabel,
      unitPrecision,
      unitSymbol,
    });
  });
  return results;
};

export const getChartPointsClosestToDate = (
  chartData,
  cursorDate,
  isLatest = false
) => {
  const results = [];

  // for every chart
  chartData.forEach((chartObj, chartIndex) => {
    // get chart attrs
    const { signal_id, chartColor, data, chartLabel } = chartObj;
    if (isLatest) {
      results.push({
        ...data[data.length - 1],
        signal_id,
        chartColor,
        chartLabel,
        chartIndex,
      });
      return results;
    }
    if (!data || !data.length) return;

    let closestDataPoint = null;
    let closestDistance = Infinity;
    let low = 0;
    let high = data.length - 1;

    while (low <= high) {
      const mid = Math.floor((low + high) / 2);
      const dataPoint = data[mid];
      const distance = Math.abs(dataPoint.date - cursorDate);

      if (distance < closestDistance) {
        closestDistance = distance;
        closestDataPoint = dataPoint;
      }

      if (dataPoint.date < cursorDate) {
        low = mid + 1;
      } else {
        high = mid - 1;
      }
    }

    // no points nearby? do nothing
    if (!closestDataPoint) return;

    // add this point to the results for mapping
    results.push({
      ...closestDataPoint,
      signal_id,
      chartColor,
      chartLabel,
      chartIndex,
    });
  });

  // return all results
  return results;
};

/** format a date to tooltip format */
export const formatDateforTooltip = (date) =>
  moment(date).format(Defines.TOOLTIP_DATE_FORMAT);

/** set initial zoom transforms for each y axis */
export const initializeYZoomStates = (
  numYAxes,
  initialState = d3.zoomIdentity
) => {
  let zoomStates = {};
  for (let i = 0; i < numYAxes; i++) {
    zoomStates[i] = initialState;
  }
  return zoomStates;
};

/** set initial scales for each y axis */
export const initializeYScales = (numYAxes, initialScale) => {
  let scales = {};
  for (let i = 0; i < numYAxes; i++) {
    scales[i] = initialScale.copy();
  }
  return scales;
};

/** return the configured default d3.zoom() */
export const generateNewZoomBehavior = () =>
  d3.zoom().interpolate(d3.interpolate);

/** given a scale, rescale it using the new zoom state's domain  */
export const rescaleFromZoom = (initScale, newZoomState) => {
  const scaleCopy = initScale.copy();
  const newScaleFromZoom = newZoomState.rescaleX(scaleCopy);
  scaleCopy.domain(newScaleFromZoom.domain());
  return scaleCopy;
};

/** calculate pixel position of date based on axis scale */
export const dateToPositionFromScale = (date, scale) => scale(date);

/** fit scale to time min/max of data */
export const fitXScaleToData = (axisScale, chartData) => {
  chartData.forEach((chartObj) => {
    const { data } = chartObj;
    if (!data || !data.length) return;
    let newExtent = d3.extent(data, function (d) {
      return d.date;
    });
    // prevent zooming into single point
    if (newExtent[0] === newExtent[1])
      newExtent = [
        new Date(newExtent[0].getTime() - 60000),
        new Date(newExtent[0].getTime() + 60000),
      ];
    axisScale.domain(newExtent);
  });
};

/** fit y scales to chart data */
export const fitYScalesToData = (
  axisScales,
  chartData,
  extendYAxisBounds,
  shouldYAxisScaleToZero,
  stackMode = true
) => {
  if (!chartData.length) return;
  // set initial scale values
  chartData.forEach((chartObj, i) => {
    const { data } = chartObj;
    // set all y domains
    let [yMin, yMax] =
      data && data.length
        ? d3.extent(data, function (d) {
            return +d.value;
          })
        : [0, 1];

    // extend the bounds a bit
    if (yMin === yMax || extendYAxisBounds) {
      const delta = Math.abs(yMax - yMin);
      const yDiff = delta ? delta : 1;
      const newYMin = yMin - 0.2 * yDiff;
      const newYMax = yMax + 0.2 * yDiff;
      yMin = newYMin;
      yMax = newYMax;
    }
    // place and scale
    if (stackMode) {
      const newYDiff = Math.abs(yMax - yMin);
      yMax = yMax + i * newYDiff;
      yMin = yMin - (chartData.length - 1 - i) * newYDiff;
    }

    // prevent zooming into single point or enable extension regardless
    axisScales[i] = axisScales[i]
      .copy()
      .domain([shouldYAxisScaleToZero ? 0 : yMin, yMax]);
  });
};

/** rescale y axes based on zooms */
export const fitYScalesToZooms = (axisScales, zoomStates) => {
  // rescale y axes based on zoom
  for (let i = 0; i < Object.keys(axisScales).length; i++) {
    const foundTransform = zoomStates[i] || d3.zoomIdentity;
    const newYScale = foundTransform.rescaleY(axisScales[i]);
    axisScales[i].domain(newYScale.domain());
  }
};

/** place horizontal cursor on plot */
export const revealAndPositionHorizontalCursor = (cursorRef, yPos) => {
  d3.select(cursorRef.current)
    .attr("y1", yPos)
    .attr("y2", yPos)
    .attr("stroke-opacity", 1);
};

/** hide horizontal cursor */
export const hideHorizontalCursor = (cursorRef) => {
  d3.select(cursorRef.current).attr("stroke-opacity", 0);
};

/** place specified tooltip on plot */
export const revealAndPositionTooltip = (tooltipRef, posTop, posLeft) => {
  d3.select(tooltipRef.current)
    .style("opacity", 1)
    .style("display", "block")
    .style("top", `${posTop}px`)
    .style("left", `${posLeft}px`);
};

/** hide cursor tooltip */
export const hideTooltip = (tooltipRef) => {
  d3.select(tooltipRef.current).style("opacity", 0).style("display", "none");
};
