import { useEffect, useState } from "react";
import styled from "styled-components";
import { TCUPageOverviewTable } from "features/tcu/components";
import { MainLayout } from "common/layout";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetTCUsByFilterQuery } from "features/tcu/redux";
import { Spinner } from "common/Spinner";
import { Col, Row } from "antd";

const TCUPageManagementContainer = styled.div`
  width: 100%;
  height: 100%;
`;
export const TCUPage = () => {
  const customerId = useCustomerId();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  const {
    isLoading: isQTCUsLoading,
    data: qTCUs,
    refetch: qTCUsRefetch,
  } = useGetTCUsByFilterQuery(
    { customer_id: customerId },
    { skip: customerId == null }
  );

  useEffect(() => {
    setIsPageLoaded(!isQTCUsLoading);
  }, [isQTCUsLoading]);

  if (!isPageLoaded) return <Spinner />;

  return (
    <MainLayout>
      <TCUPageManagementContainer>
        <Row style={{ marginTop: "16px" }}>
          <Col span={22} offset={1}>
            <TCUPageOverviewTable qTCUs={qTCUs} isPageLoaded={isPageLoaded} />
          </Col>
        </Row>
      </TCUPageManagementContainer>
    </MainLayout>
  );
};
