import { connect, useSelector } from "react-redux";
import styled, { withTheme } from "styled-components";
import { notification } from "antd";
import { GetFilter } from "utils/Color";
import { Icon } from "common";
import { GridLayout } from "features/grid-layout";
import { getTimeSince } from "utils";
import ProgressBar from "common/ProgressBar";
import {
  setCurrentProduct,
  useGetApplicationsByFilterQuery,
} from "features/applications/redux";
import { setActiveMapMarker } from "features/map/redux";
import { useCurrentSNId } from "features/units/hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useSignalUISettingsForProductSN } from "features/applications/hooks";
import { useGetLayoutsByCustomerIdQuery } from "features/grid-layout/redux";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import {
  S3_ARROW_UP,
  S3_ARROW_DOWN,
  S3_MAP_PIN_UNIT_A,
  S3_LIST_LOCATION_ON,
  S3_LIST_LOCATION_OFF,
  S3_ARROWS_ROTATE,
  S3_STATUS_NORMAL,
  S3_STATUS_UNKNOWN,
  ICON_SIZE_XS,
  ICON_SIZE_MD,
  MAP_MODAL,
} from "utils/defines";

// DRY
const DOUBLE_EN_DASH = `${String.fromCharCode(8211)}${String.fromCharCode(
  8211
)}`;

const SOHArrow = styled.div`
  position: absolute;
  top: 0;
  transform: translate(-50%, -70%);

  & > svg {
    width: 10px;
    height: auto;
  }
`;

const OVERLAYVERTICAL_WIDTH = 280;

const Popup = styled.div`
  display: flex;
  position: relative;
  height: 215px;
  padding-top: 15px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  transform: translateX(
    ${(props) =>
      props.overlayShown && props.overlayVertical
        ? `${OVERLAYVERTICAL_WIDTH / 2}px`
        : `0`}
  );
  background-color: white;
  border: ${(props) => props.theme.sidebarBorder};
  box-shadow: 0 0 3px #ccc;
  transition: all 0.4s, height 0.2s ease;
  border-radius: 5px;
  box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &:hover {
    height: 220px;
  }
`;

const PopupHandleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
`;

const PopupHandle = styled.div`
  width: 75px;
  height: 3px;
  background-color: #333;
  opacity: 0.5;
  border-radius: 5px;
  margin: 7px auto;
`;

const PaperDoll = styled.div`
  width: 250px;
  height: 200px; // match mapModal height
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  & img {
    width: 80%;
    height: auto;
  }
`;

const PrimaryVal = styled.div`
  margin-top: -7px;
  font-size: 36px;
  color: rgba(102, 102, 102, 1);
`;

const Top = styled.div`
  display: flex;
  height: 15%;
  padding: 10px 12px 0 12px;
  align-items: center;
  margin-bottom: 0;
`;
const Title = styled.span`
  font-weight: 400;
  font-size: 24px;
  margin-right: 5px;
  position: relative;
`;
const Serial = styled.span`
  margin-left: 10px;
  font-size: 14px;
  align-self: center;
`;
const Icons = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`;

const MapModal = ({
  activeMarker,
  setActiveMapMarker,
  setCurrentProduct,
  setDraggable,
  editModeEnabled,
  theme,
  overlayShown,
  overlayVertical,
  mapCenter,
}) => {
  const customerId = useCustomerId();
  const currentProduct = useCurrentSNId();
  const { data: products } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const { data: productSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });
  const { data: layouts, isLoading: layoutsIsLoading } =
    useGetLayoutsByCustomerIdQuery(customerId);
  const currentMapMarker = useSelector((state) => state.map.marker);
  const productSN = productSNs?.find((p) => p.id === currentMapMarker);
  const {
    unitSOCVal,
    SOCSignalPrecision,
    unitSOHVal,
    SOHSignalPrecision,
    unitLatVal,
    unitLngVal,
    unitLastUpdated,
  } = useSignalUISettingsForProductSN(productSN);
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type) => {
    api[type]({
      message: "Unable to Add Widget",
      description:
        "Create more room (at least 2x2) in order to add a new widget",
      placement: "bottomLeft",
    });
  };

  if (!layoutsIsLoading && !layouts?.length)
    return <div>No layouts for customer</div>;

  const { id: mapModalLayoutId } =
    layouts?.find((layout) => layout.section_name === MAP_MODAL) || {};

  const setDrag = (isDraggable) => {
    if (editModeEnabled) setDraggable(isDraggable);
  };
  if (!activeMarker && !currentProduct)
    return (
      <Popup
        overlayShown={overlayShown}
        overlayVertical={overlayVertical}
        className="map-modal-wrapper"
        style={{ height: "35px", opacity: "0.5" }}
      />
    );
  if (!productSNs || !productSNs.length) return null; // if no product SNs, do nothing
  if (!products || !products.length) return null; // if no products, do nothing
  if (currentProduct) return null; // if a productSN is selected (unit modal is open), don't show
  if (!productSN) return null; // if no productSN selected current map marker, don't show

  const productForPsn = products?.find(
    (product) => product?.id === productSN?.product_id
  );
  const productImage = productForPsn?.image;

  const isIconNearCenter = () => {
    const DIFFERENCE_THRESHOLD = 0.002;
    const difference = (a, b) => Math.abs(a - b);
    const latIsClose =
      difference(mapCenter.lat, unitLatVal) < DIFFERENCE_THRESHOLD;
    const lngIsClose =
      difference(mapCenter.lng, unitLngVal) < DIFFERENCE_THRESHOLD;
    const result = latIsClose && lngIsClose;
    return result;
  };

  const switchToProductSN = (targetProductId) => {
    setActiveMapMarker(targetProductId);
  };

  const previousUnit = () => {
    let index = productSNs?.length ? productSNs.indexOf(productSN) : null;
    if (index === null) return;
    index = index > 0 ? index - 1 : productSNs.length - 1;
    switchToProductSN(productSNs[index].id);
  };

  const nextUnit = () => {
    let index = productSNs?.length ? productSNs.indexOf(productSN) : null;
    if (index === null) return;
    index = index === productSNs.length - 1 ? 0 : index + 1;
    switchToProductSN(productSNs[index].id);
  };

  return (
    <Popup
      className="map-modal-wrapper"
      disabled={currentProduct != null}
      onMouseOver={() => setDrag(false)}
      onMouseOut={() => setDrag(true)}
      overlayShown={overlayShown}
      overlayVertical={overlayVertical}
      onClick={() => {
        if (!editModeEnabled) setCurrentProduct(activeMarker);
      }}
    >
      {contextHolder}
      <PopupHandleWrapper>
        <PopupHandle>&nbsp;</PopupHandle>
      </PopupHandleWrapper>
      <PaperDoll>
        <img src={productImage} alt={"Product"} />
      </PaperDoll>
      <div style={{ flex: "1" }}>
        <Top>
          <div>
            <Title>
              <Icon
                style={{
                  filter: GetFilter(theme.themePrimary),
                  marginRight: "8px",
                  transform: "translate(0px, -2px)",
                  position: "absolute",
                  top: "6px",
                  left: "-26px",
                }}
                hostedImage={S3_MAP_PIN_UNIT_A}
              />
              {productSN?.product_sn_name}
            </Title>
            <Icon
              style={{
                marginLeft: "5px",
                transform: "translate(0, -2px)",
              }}
              hostedImage={
                unitLastUpdated ? S3_STATUS_NORMAL : S3_STATUS_UNKNOWN
              }
            />
            <Serial style={{ margin: "0 20px" }}>
              TCU SN#{productSN?.serial_number}
            </Serial>
            <span>
              <Icon
                style={{
                  marginRight: "5px",
                  opacity: "0.5",
                  transform: "translate(0, -1px)",
                }}
                hostedImage={S3_ARROWS_ROTATE}
                width={ICON_SIZE_XS}
                height={ICON_SIZE_XS}
              />
              <span style={{ fontSize: "13px" }}>
                {getTimeSince(unitLastUpdated)}
              </span>
            </span>
          </div>
          <Icons>
            <div
              style={{
                marginLeft: "auto",
                marginRight: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span
                onClick={(e) => {
                  e.stopPropagation();
                  previousUnit();
                }}
                style={{ cursor: "pointer" }}
              >
                <Icon
                  hostedImage={S3_ARROW_UP}
                  width={ICON_SIZE_XS}
                  height={ICON_SIZE_XS}
                />
                <span style={{ fontSize: "15px", marginLeft: "4px" }}>
                  Prev
                </span>
              </span>

              <span
                onClick={(e) => {
                  e.stopPropagation();
                  nextUnit();
                }}
                style={{ cursor: "pointer" }}
              >
                <Icon
                  hostedImage={S3_ARROW_DOWN}
                  width={ICON_SIZE_XS}
                  height={ICON_SIZE_XS}
                  style={{ marginLeft: "20px" }}
                />
                <span style={{ fontSize: "15px", marginLeft: "4px" }}>
                  Next
                </span>
              </span>

              <span
                onClick={(e) => {
                  e.stopPropagation();
                  if (unitLatVal && unitLngVal)
                    setActiveMapMarker(productSN.id, {
                      lat: unitLatVal,
                      lng: unitLngVal,
                    });
                }}
                style={unitLatVal && unitLngVal ? { cursor: "pointer" } : {}}
              >
                <Icon
                  hostedImage={
                    isIconNearCenter()
                      ? S3_LIST_LOCATION_ON
                      : S3_LIST_LOCATION_OFF
                  }
                  width={ICON_SIZE_MD}
                  height={ICON_SIZE_MD}
                  style={{
                    opacity: unitLatVal && unitLngVal ? "0.7" : "0.2",
                    marginLeft: "20px",
                    marginRight: "-10px",
                  }}
                />
              </span>
            </div>
          </Icons>
        </Top>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 10px 5px 12px",
          }}
        >
          <PrimaryVal percentage={unitSOCVal || 0}>
            {unitSOCVal ? (
              unitSOCVal.toFixed(SOCSignalPrecision)
            ) : (
              <span style={{ color: "#888" }}>{DOUBLE_EN_DASH}</span>
            )}
            <span style={{ fontSize: "32px" }}>%</span>
          </PrimaryVal>
          <div style={{ flex: "1", marginLeft: "15px", position: "relative" }}>
            {unitSOHVal ? (
              <SOHArrow
                style={{ left: `${unitSOHVal.toFixed(SOHSignalPrecision)}%` }}
              >
                <svg
                  aria-hidden="true"
                  width="36"
                  height="36"
                  viewBox="0 0 36 36"
                >
                  <path d="M2 11h32L18 27 2 11Z"></path>
                </svg>
              </SOHArrow>
            ) : null}
            <ProgressBar soc={unitSOCVal} soh={unitSOHVal} />
            <div
              style={{
                padding: "2px 0",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div>State of Charge</div>
              <div>
                <span style={{ fontSize: "70%" }}>State of Health</span>{" "}
                {unitSOHVal ? (
                  unitSOHVal.toFixed(SOHSignalPrecision)
                ) : (
                  <span style={{ color: "#888" }}>{DOUBLE_EN_DASH}</span>
                )}
                %
              </div>
            </div>
          </div>
        </div>
        <div style={{ marginTop: "-15px" }}>
          {mapModalLayoutId ? (
            <GridLayout
              layoutID={mapModalLayoutId}
              productID={productSN.product_id}
              widgetType={"product"}
              isMapModalLayout
              style={{ boxShadow: "none" }}
              openNotification={openNotification}
            />
          ) : (
            <p>No map modal layout available</p>
          )}
        </div>
      </div>
    </Popup>
  );
};

const mapStateToProps = (state) => ({
  mapCenter: state.map.mapCenter,
  activeMarker: state.map.marker,
  editModeEnabled: state.layout.editModeEnabled,
  dataHistory: state.productSNData.dataHistory,
  overlayShown: state.map.overlayShown,
  overlayVertical: state.map.overlayVertical,
});

const mapDispatchToProps = (dispatch) => ({
  setActiveMapMarker: (marker, coordinates) =>
    dispatch(setActiveMapMarker({ marker, coordinates })),
  setCurrentProduct: (product) => dispatch(setCurrentProduct(product)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(MapModal));
