import { useContext } from "react";
import { useDispatch } from "react-redux";
import { setLoggedOut } from "features/users/redux/userSlice";

import { Modal, ModalBody, ModalFooter } from "common/Modal";

import { AuthContext } from "features/auth/contexts";
import { Button } from "common/elements";

export const CustomerUnverifiedModal = () => {
  const dispatch = useDispatch();
  const doReduxLogout = () => dispatch(setLoggedOut(true));
  const { signOut } = useContext(AuthContext);

  const performLogout = (e) => {
    doReduxLogout();
    signOut();
  };

  return (
    <Modal show={true} aria-labelledby="contained-modal-title-vcenter" centered>
      <ModalBody>
        Thank you for signing up. Your company is being verified.
      </ModalBody>
      <ModalFooter>
        <Button className="accept" onClick={performLogout}>
          Log Out
        </Button>
      </ModalFooter>
    </Modal>
  );
};
