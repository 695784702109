import { useState } from "react";
import styled from "styled-components";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
import { useDeleteEventStateValueMutation } from "features/events/redux";
import { useGetEventStatesForProductQuery } from "features/events/redux";
import { useSelectedProduct } from "features/applications/hooks";
import { Icon } from "common";
import { S3_CLOSE_X } from "utils/defines";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { Spinner } from "common/Spinner";

const ListWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
`;

const StateValueListItem = styled.div`
  margin-bottom: 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  max-width: 400px;
  background-color: #fff;
  border: 1px solid rgb(221, 221, 221);
  box-shadow: rgba(0, 0, 0, 0.15) 0 1px 3px;
`;

const StateValueHeader = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  & > small {
    font-weight: 100;
  }
  & > h3 {
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0;
  }
`;

const StateValue = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  &:not(:last-child) {
    border-bottom: 1px solid #ccc;
  }
  & > span {
    margin: 0 10px;
  }
`;

const ApplicationEventStateValuesList = () => {
  const customerId = useCustomerId();
  const { data: signalsAndMessages, isLoading: isSignalsLoading } =
    useGetSignalsForCustomerQuery(customerId);
  const { selectedProduct } = useSelectedProduct();
  const [idToDelete, setIdToDelete] = useState(null);
  const { data: eventStateValues, isLoading: isEventStatesLoading } =
    useGetEventStatesForProductQuery(selectedProduct.id);
  const [deleteStateValue, { isLoading: isDeleting }] =
    useDeleteEventStateValueMutation();

  if (isSignalsLoading || isEventStatesLoading) return <Spinner />;

  const { signals } = signalsAndMessages || {};
  const stateIds = Object.keys(eventStateValues);

  if (!stateIds.length) return <div>No Event States created yet</div>;

  const handleDelete = (value_id) => async () => {
    setIdToDelete(value_id);
    await deleteStateValue(value_id);
  };

  return (
    <ListWrapper>
      {stateIds.map((stateId) => {
        const eventState = eventStateValues[stateId];
        const signalForStateValue = signals?.find(
          (signal) => signal.key === eventState?.signal_key
        );
        return (
          <StateValueListItem>
            <StateValueHeader>
              <h3>{signalForStateValue?.name}</h3>
              <small>Event State ID: {stateId}</small>
            </StateValueHeader>
            {eventState.values.map((stateValue) => (
              <StateValue>
                <span>{stateValue?.value}</span>
                <span>{stateValue?.label}</span>
                <Icon
                  hostedImage={S3_CLOSE_X}
                  style={{
                    opacity:
                      isDeleting && idToDelete === stateValue?.id ? "0.2" : "1",
                    cursor: "pointer",
                  }}
                  onClick={handleDelete(stateValue?.id)}
                />
              </StateValue>
            ))}
          </StateValueListItem>
        );
      })}
    </ListWrapper>
  );
};

export default ApplicationEventStateValuesList;
