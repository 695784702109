import { createSlice } from "@reduxjs/toolkit";
import storage from "utils/storage";

const initialState = {
  product: storage.getStoredProduct(),
  products: [], // products and serials
  productsRaw: [], // products, with no serial/data
  productSNs: [],
  levels: [], // product levels
  productsData: [], // most recent products/serials+product data
  productsDataCurrentMaster: [], // most recent, master object
  productsDataMaster: [], // all products/serials+product data
  filteredProducts: [], // products filtered from the overlay view
  filteredProductsSidebar: [], // products filtered from the sidebar list
  filteredStatusTypes: [], // statuses filtered from the sidebar list

  // user stuff
  userId: null, // assuming already logged in
  productSettings: {},
};

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProducts: (state, action) => {
      const products = action.payload;
      const productSNs = products.reduce((arr, product) => {
        product.product_sns.forEach((psn) => {
          arr.push(psn);
        });
        return arr;
      }, []);

      const productSNData = productSNs.reduce((dict, productSN) => {
        dict[productSN.id] = productSN.product_sn_data;
        return dict;
      }, {});
      state.products = products;
      state.productSNs = productSNs;
      state.productSNData = productSNData;
    },
    setLevels: (state, action) => {
      state.levels = action.payload;
    },
    setCurrentProduct: (state, action) => {
      storage.setStoredProduct(action.payload);
      state.product = action.payload;
    },
    overlaySortUnitName: (state, action) => {
      state.productsData = state.productsData.sort((a, b) => {
        if (a.product_name < b.product_name) {
          return -1;
        }
        if (a.product_name > b.product_name) {
          return 1;
        }
        return 0;
      });
    },
    setApplicationsSelectedProduct: (state, action) => {
      state.applicationsSelectedProduct = action.payload;
    },
    setApplicationsIsEditingProduct: (state, action) => {
      state.applicationsIsEditingProduct = action.payload;
    },
    setApplicationsSelectedSN: (state, action) => {
      state.applicationsSelectedProductSN = action.payload;
    },
    setApplicationsSelectedTab: (state, action) => {
      state.applicationsSelectedTab = action.payload;
    },
    setProductSettings: (state, action) => {
      state.productSettings = action.payload;
    },
    setSignals: (state, action) => {
      // 20220718 response from api/signals/{customer} had updated to { messages, signals }
      // attempt destructure here, but to be handled better somewhere
      const { signals } = action.payload.signals
        ? action.payload.signals
        : action.payload;
      state.signals = signals;
    },
  },
});

export const {
  setProducts,
  setLevels,
  setCurrentProduct,
  overlaySortUnitName,
  setApplicationsSelectedProduct,
  setApplicationsIsEditingProduct,
  setApplicationsSelectedSN,
  setApplicationsSelectedTab,
  setProductSettings,
  setSignals,
} = productsSlice.actions;

export default productsSlice.reducer;
