import React, {
  useState,
  useContext,
  createContext,
  ReactChild,
  ReactChildren,
  useRef,
  useEffect,
} from "react";
import PasswordChecklist from "react-password-checklist";

import { userService } from "features/users/services";
import LoadingSpinner from "common/LoadingSpinner";
import {
  LoginTemplate,
  Button,
  StyledInput,
  ErrorMessage,
  AuthFormLink,
} from "./loginComponents";
import { AuthContext } from "features/auth/contexts";

interface LoginStateContextType {
  error: string;
  setError: (error: string) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  newPassword: string;
  setNewPassword: (newPassword: string) => void;
  confirmNewPassword: string;
  setConfirmNewPassword: (confirmNewPassword: string) => void;
  confirmationCode: string;
  setConfirmationCode: (confirmationCode: string) => void;
  passwordUpdateInfo: any; // @TODO Update with the actual type
  setPasswordUpdateInfo: (passwordUpdateInfo: any) => void; // @TODO Update with the actual type
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  step: string;
  setStep: (step: string) => void;
}

const LoginStateContext = createContext<LoginStateContextType | undefined>(
  undefined
);

interface LoginProps {
  children: ReactChild | ReactChildren;
}

const LoginStateProvider = ({ children }: LoginProps) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordUpdateInfo, setPasswordUpdateInfo] = useState(null);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [step, setStep] = useState("init");

  const value = {
    error,
    setError,
    email,
    setEmail,
    password,
    setPassword,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    confirmationCode,
    setConfirmationCode,
    passwordUpdateInfo,
    setPasswordUpdateInfo,
    isLoading,
    setIsLoading,
    step,
    setStep,
  };
  return (
    <LoginStateContext.Provider value={value}>
      {children}
    </LoginStateContext.Provider>
  );
};

const LoginForm = () => {
  const authContext = useContext(AuthContext);
  const loginContext = useContext(LoginStateContext);
  const isMounted = useRef(true);

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  if (!loginContext) return null;
  if (!authContext) return null;

  const { signIn } = authContext;

  const {
    error,
    setError,
    setIsLoading,
    setStep,
    email,
    setEmail,
    password,
    setPassword,
    setPasswordUpdateInfo,
  } = loginContext;

  const onLoginSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      const signInResult = await signIn(email, password);

      if (signInResult.message === "New password required") {
        setStep("update");
        setPasswordUpdateInfo(signInResult);
      } else {
        if (isMounted.current) {
          setIsLoading(false);
        }
      }
    } catch (err: any) {
      if (isMounted.current) {
        setIsLoading(false);
      }
      if (err.message === "User is not confirmed.") {
        setStep("confirm");
      } else {
        setError(err.message || err.error);
      }
    }
    if (isMounted.current) {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={onLoginSubmit}>
        <StyledInput
          value={email}
          placeholder="Enter your email"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <StyledInput
          type="password"
          value={password}
          placeholder="Enter your password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <div>
          <Button type="submit">Login</Button>
        </div>
        <p>
          <AuthFormLink to="/forgot-password">Forgot Password</AuthFormLink> or{" "}
          <AuthFormLink to="/signup">Sign Up</AuthFormLink>
        </p>
      </form>
    </div>
  );
};

const ConfirmationCodeForm = () => {
  const authContext = useContext(AuthContext);
  const loginContext = useContext(LoginStateContext);

  if (!loginContext) return null;
  if (!authContext) return null;

  const { signIn } = authContext;

  const {
    error,
    setError,
    setIsLoading,
    confirmationCode,
    setConfirmationCode,
    email,
    password,
  } = loginContext;

  const handleConfirmSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await userService.ConfirmSignUp({ email, confirmationCode });
      await signIn(email, password);
    } catch (err) {
      if (err instanceof Error) {
        let errorMessage =
          err.message === "CodeMismatchException"
            ? "Invalid confirmation code"
            : err.message;
        setError(errorMessage);
      }
    }
    setIsLoading(false);
  };
  // @TODO change any into appropriate type
  const requestConfirmationCode = async (e: any) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await userService.RequestNewConfirmationCode({ email });
    } catch (err: any) {
      setError(err.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <p>
        <strong>Enter Confirmation Code</strong>
      </p>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleConfirmSubmit}>
        <StyledInput
          type="text"
          placeholder="Confirmation code"
          value={confirmationCode}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfirmationCode(e.target.value)
          }
        />
        <Button type="submit">Confirm</Button>
        <AuthFormLink to={"#/"} onClick={requestConfirmationCode}>
          Resend Confirmation Code
        </AuthFormLink>
      </form>
    </div>
  );
};

const UpdatePasswordForm = () => {
  const authContext = useContext(AuthContext);
  const loginContext = useContext(LoginStateContext);

  if (!loginContext) return null;
  if (!authContext) return null;

  const { storeSession } = authContext;

  const {
    error,
    setError,
    setIsLoading,
    passwordUpdateInfo,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
  } = loginContext;

  const handleUpdatePassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError("");

    if (newPassword !== confirmNewPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    try {
      const { username, session } = passwordUpdateInfo;
      const newPasswordResult = await userService.CompleteNewPasswordChallenge({
        newPassword,
        username,
        session,
      });
      storeSession(newPasswordResult);
    } catch (err) {
      if (err instanceof Error)
        setError(
          err.message || "An error has occured when updating your password"
        );
    }
    setIsLoading(false);
  };

  return (
    <div>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleUpdatePassword}>
        <div>
          <strong>New Password Required</strong>
        </div>
        <StyledInput
          value={newPassword}
          type="password"
          placeholder="New Password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setNewPassword(e.target.value)
          }
        />
        <StyledInput
          value={confirmNewPassword}
          type="password"
          placeholder="Confirm Password"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setConfirmNewPassword(e.target.value)
          }
        />
        {newPassword && (
          <div style={{ textAlign: "left" }}>
            <PasswordChecklist
              rules={[
                "minLength",
                "specialChar",
                "number",
                "lowercase",
                "capital",
                "match",
              ]}
              minLength={8}
              value={newPassword}
              valueAgain={confirmNewPassword}
              onChange={(isValid) => {}}
            />
          </div>
        )}
        <div>
          <Button type="submit">Update</Button>
        </div>
      </form>
    </div>
  );
};

const LoginPage = () => {
  const { step, isLoading } = useContext(LoginStateContext) || {};
  const { isSignIn } = useContext(AuthContext) || {};

  const renderStep = (step: string | undefined) => {
    switch (step) {
      case "confirm":
        return <ConfirmationCodeForm />;
      case "update":
        return <UpdatePasswordForm />;
      case "init":
      default:
        return <LoginForm />;
    }
  };
  return (
    <LoginTemplate>
      {isLoading || isSignIn ? <LoadingSpinner /> : renderStep(step)}
    </LoginTemplate>
  );
};

export function Login() {
  return (
    <LoginStateProvider>
      <LoginPage />
    </LoginStateProvider>
  );
}
