import { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { ApplicationUnitDetail } from "../components";
import { Icon } from "common";
import { GetFilter } from "utils/Color";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetTCUsForCustomerQuery } from "features/tcu/redux";
import * as Defines from "utils/defines";

const ActiveToggleButton = styled.button`
  background-color: ${({ selected }) =>
    selected ? "rgb(240, 240, 240)" : "white"};
  border: 1px solid lightgray;
  flex-grow: 1;
`;

const ApplicationCardInfo = styled.div`
  text-wrap: nowrap;
  text-overflow: ellipsis;
  width: 250px;
  overflow: hidden;
`;

const ApplicationUnitCardLabel = styled.span`
  display: inline-block;
  width: 70px;
`;

const ListSection = styled.section`
  height: 100%;
  overflow-y: auto;
  width: 328px;
  & a {
    text-decoration: none;
    color: inherit;
  }
`;

const NoUnitsToDisplayMessageContainer = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const UnitSingleDrawer = styled.div`
  background-color: #f0f0f0;
  border-left: 1px solid #ccc;
  display: block;
  flex: 1;
  z-index: 10; // above table header z-index 5
`;

const UnitRow = styled.div`
  background-color: ${(props) => (props.selected ? "#f0f0f0" : "#fff")};
  border-radius: 5px;
  cursor: pointer;
  padding: 10px;
`;

const ApplicationUnitsListView = ({
  archivedSerialNumbers,
  onProductSNSelected,
  product,
  refetchAllArchivedSNs,
  refetchAllProductSNs,
  selectedSN,
  serialNumbers,
  setAddingProductSN,
  theme,
}) => {
  const [viewActive, setViewActive] = useState(Defines.ACTIVE);
  const customerId = useCustomerId();
  const { data: allTCUs } = useGetTCUsForCustomerQuery(customerId);
  const serialNumbersToDisplay =
    viewActive === Defines.ACTIVE ? serialNumbers : archivedSerialNumbers;

  useEffect(() => {
    if (serialNumbersToDisplay.length) {
      onProductSNSelected(serialNumbersToDisplay[0]);
    } else {
      onProductSNSelected(null);
    }
  }, [serialNumbersToDisplay, viewActive]);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          backgroundColor: "#fff",
          display: "flex",
          height: "100%",
        }}
      >
        <ListSection>
          <div style={{ display: "flex", marginTop: "10px", height: "35px" }}>
            <ActiveToggleButton
              onClick={() => setViewActive(Defines.ACTIVE)}
              selected={viewActive === Defines.ACTIVE}
              style={{
                borderRadius: "5px 0 0 5px",
                marginLeft: "10px",
              }}
            >
              <Icon
                hostedImage={Defines.S3_EYE_OPEN}
                style={{
                  marginBottom: "3px",
                  marginRight: "10px",
                }}
              />
              Active
            </ActiveToggleButton>
            <ActiveToggleButton
              style={{
                borderRadius: "0 5px 5px 0",
                marginRight: "10px",
              }}
              selected={viewActive === Defines.RETIRED}
              onClick={() => setViewActive(Defines.RETIRED)}
            >
              <Icon
                hostedImage={Defines.S3_EYE_SLEEP}
                style={{ marginRight: "10px", marginBottom: "3px" }}
              />
              Retired
            </ActiveToggleButton>
          </div>
          <div style={{ padding: "10px 10px" }}>
            {serialNumbersToDisplay.length <= 0 || !selectedSN ? (
              <></>
            ) : (
              serialNumbersToDisplay.map((productSN) => {
                const tcuForSN = allTCUs?.length
                  ? allTCUs.find((tcu) => tcu.product_sn_id === productSN.id)
                  : null;
                return (
                  <UnitRow
                    className={`highlightableRow clickable`}
                    key={product.product_sn_id}
                    onClick={() => {
                      onProductSNSelected(productSN);
                    }}
                    selected={selectedSN.id === productSN.id}
                  >
                    <div style={{ display: "flex" }}>
                      <div>
                        <Icon
                          height={Defines.ICON_SIZE_MD}
                          hostedImage={Defines.S3_MAP_PIN_UNIT_A}
                          style={{
                            filter: GetFilter(theme.themePrimary),
                            marginRight: "10px",
                          }}
                          width={Defines.ICON_SIZE_MD}
                        />
                      </div>
                      <div
                        style={{
                          flex: "1",
                          fontSize: "13px",
                          lineHeight: "1.4",
                        }}
                      >
                        <h4 style={{ fontSize: "1.2rem", marginBottom: "5px" }}>
                          {productSN.product_sn_name}
                        </h4>
                        <ApplicationCardInfo>
                          <ApplicationUnitCardLabel>
                            Serial #
                          </ApplicationUnitCardLabel>{" "}
                          {productSN.serial_number}
                        </ApplicationCardInfo>
                        <ApplicationCardInfo>
                          <ApplicationUnitCardLabel>
                            TCU
                          </ApplicationUnitCardLabel>{" "}
                          {tcuForSN ? tcuForSN.name : "not paired"}
                        </ApplicationCardInfo>
                      </div>
                    </div>
                  </UnitRow>
                );
              })
            )}
            {Defines.ENABLE_ADD_PRODUCT_SN_FUNCTIONS && (
              <div style={{ marginLeft: "37px", marginTop: "15px" }}>
                <div>
                  <a
                    href="/#"
                    onClick={(e) => {
                      e.preventDefault();
                      setAddingProductSN(true);
                    }}
                  >
                    + Add Device
                  </a>
                </div>
              </div>
            )}
          </div>
        </ListSection>
        <UnitSingleDrawer>
          {selectedSN ? (
            <ApplicationUnitDetail
              handleSelectedSN={onProductSNSelected}
              product={product}
              productSN={selectedSN}
              refetchAllArchivedSNs={refetchAllArchivedSNs}
              refetchAllProductSNs={refetchAllProductSNs}
              viewMode={viewActive}
            />
          ) : (
            <NoUnitsToDisplayMessageContainer>
              <em>No {viewActive} units to display</em>
            </NoUnitsToDisplayMessageContainer>
          )}
        </UnitSingleDrawer>
      </div>
    </div>
  );
};

export default withTheme(ApplicationUnitsListView);
