import { useCallback } from "react";
import { useDropzone } from "react-dropzone";

/**
 * The purpose of this component is to facilitate parsing of DBC files (CAN data describer, essentially)
 *
 * from a high level:
 *  1. read CAN data from file upload
 *  2. use regex to parse that file into each CAN signal
 *  3. upload the CAN signals
 *  4. optional: map CAN signals to "our" Signals
 */

export const DBCParser = ({ product_id, onParse }) => {
  /**
   * This is what does all of the regex of course.
   * it does multiple .match() calls before you end up with the "signals" result
   */
  const parseDBC = (dbc) => {
    const globalMatch = /(BO_.*:.*)((\n|\s)*.*SG_.*Vector__XXX)*/g;
    const messageMatch = /BO_\s(\d+)\s(\w+):\s(\d+)\s(\w*)/;
    const splitUpSignals = /SG_.*/g;
    // eslint-disable-next-line no-useless-escape
    const signalMatch =
      /(\w+)\s:\s(\d+)\|(\d+)@(0|1)(\+|\-)\s\((.*),(.*)\)\s\[(.*)\|(.*)\]\s"(.*)"\s(\w*)/;
    try {
      const ret = dbc.match(globalMatch).map((gm) => {
        let message = gm.match(messageMatch);
        message = {
          decimal_id: String(message[1] & 0x1fffffff), // used to calculate both standard and extended can id formats
          name: message[2],
          length: parseInt(message[3]),
          sender: message[4],
          product_id,
        };
        let signals = gm.match(splitUpSignals);
        if (signals) {
          // if you are having trouble understanding what is going on, start by logging the signals variable here and it will make morse sense
          let signalMatches = signals.map((s) => s.match(signalMatch));
          signals = signalMatches.map((s) => {
            /** why does s become null? */
            return !s
              ? null
              : {
                  can_message_id: message.decimal_id,
                  can_signal_name: s[1],
                  bit_start: parseInt(s[2]),
                  bit_length: parseInt(s[3]),
                  // eslint-disable-next-line eqeqeq
                  little_endian: s[4] == 1 ? 1 : 0,

                  // eslint-disable-next-line eqeqeq
                  signed: s[5] == "-" ? 1 : 0,
                  scale: parseFloat(s[6]),
                  offset: parseFloat(s[7]),
                  value_min: s[8], // formatted as string in the db
                  value_max: s[9], // formatted as string in the db
                  unit: s[10],
                  product_id,
                };
          });
          // console.log(signals.map((s) => s.little_endian));
        } else {
          signals = [];
        }
        return { message, signals };
      });
      /** @todo verify correctness, investigate null values in ret */
      const result = ret.reduce((acc, el) => {
        return {
          ...acc,
          [el.message.decimal_id]: el,
        };
      }, {});
      return result;
    } catch (e) {
      /** @todo display error to user */
      console.log("error during dbc upload", e);
      return {};
    }
  };

  const readSingleFile = (file) => {
    // var file = dbcFileRef.current.files[0];
    if (!file) {
      return;
    }
    /** @todo validate file is valid DBC */
    var reader = new FileReader();
    reader.onload = function (e) {
      var contents = e.target.result;
      const parseResults = parseDBC(contents);
      /** @todo how can dbc results contain nulls? see regex */
      // const can_signals = dbc.filter((signal) => signal);
      onParse(parseResults);
    };
    reader.readAsText(file);
  };

  const onDrop = useCallback((acceptedFiles) => {
    /** @todo
     * - handle when improper acceptedFiles length
     * - handle bad files
     * [!] security
     * */
    let file = acceptedFiles[0];
    readSingleFile(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      style={{
        padding: "20px",
        height: "100%",
        minHeight: "100px",
        /** @todo:
         * - use theme colors
         * - enable full page dragdrop
         */
        border: isDragActive ? "2px dashed #5d94d4" : "1px dashed #ccc",
        backgroundColor: isDragActive ? "#c0e1fc" : "#f0f0f0",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {isDragActive ? (
        <span style={{ fontWeight: "bold", color: "#5d94d4" }}>
          Drop your DBC file here
        </span>
      ) : (
        <span>Drag your DBC file here, or click to select a file</span>
      )}
    </div>
  );
};
