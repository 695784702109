import React, { useState, useEffect } from "react";
import { Icon } from "common";
import { Modal, ModalBody } from "common/Modal";
import { Button, BorderlessButton } from "common/elements";

const getFileNameFromUrl = (url) =>
  url ? url.substring(url.lastIndexOf("/") + 1) : null;

export const UploadImageModal = ({
  show,
  onUpload,
  hide,
  _viewableImage,
  _defaultImage,
}) => {
  const [imageFile, setImageFile] = useState();
  const [viewableImage, setViewableImage] = useState(_viewableImage);
  const [imageUploadMessage, setImageUploadMessage] = useState(
    getFileNameFromUrl(_viewableImage)
  );

  useEffect(() => {
    setViewableImage(_viewableImage);
    setImageUploadMessage(
      getFileNameFromUrl(_viewableImage) || "Using default image"
    );
  }, [_viewableImage]);

  const onImageSelected = (event) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const shortName = getFileNameFromUrl(file.name);
      setImageFile(file);
      setViewableImage(URL.createObjectURL(file));
      setImageUploadMessage(shortName);
    }
  };

  const uploadFile = async () => {
    await onUpload(imageFile);
    hide();
  };

  const onCancel = () => {
    setViewableImage(null);
    hide();
  };

  return (
    <Modal
      className="modal"
      show={show}
      onHide={hide}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h4 style={{ margin: "0" }}>Image Upload</h4>
      </Modal.Header>

      <ModalBody style={{ paddingLeft: "40px", paddingRight: "40px" }}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Icon
            style={{ width: "175px", height: "auto", marginRight: "35px" }}
            hostedImage={viewableImage ? viewableImage : _defaultImage}
            filename={"default-user.jpg"}
          />
          <div style={{ flex: "1" }}>
            <label for="productImageUpload">
              <div
                style={{
                  width: "300px",
                  marginBottom: "10px",
                  overflowWrap: "break-word",
                }}
              >
                {imageUploadMessage}
              </div>
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                Select new file...
              </span>
            </label>
            <input
              id="productImageUpload"
              type="file"
              style={{ display: "none" }}
              name="file"
              onChange={onImageSelected}
            />
          </div>
        </div>
      </ModalBody>
      <Modal.Footer>
        <BorderlessButton style={{ marginRight: "auto" }} onClick={onCancel}>
          Cancel
        </BorderlessButton>
        <Button disabled={!imageFile} onClick={uploadFile}>
          {/* <Icon
            style={{ marginRight: "6px", filter: "invert(100%)" }}
            width={ICON_SIZE_MD}
            height={ICON_SIZE_MD}
            hostedImage={S3_CHECKMARK}
          /> */}
          Accept Changes
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
