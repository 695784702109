import styled from "styled-components";

// this includes Styled Components styles that I designated here.
// the global theme and other styles live here, as well as some specific styles

// eventually, i realized that it is simpler to leave specific styles in their react file

// TODO: consolidate from Product.js
const MAP_OUTER_PADDING = 30;
const MAP_OUTER_PADDING_SM = 15; // when vertical view expanded, gutters are smaller
const OVERLAYVERTICAL_WIDTH = 275;
const OVERLAYCLOSED_WIDTH = -5;

export const STAFL_ACTION = "#F58263";
export const STAFL_ACCENT = "#39C1CA";

export const theme = {
  // staflOrange: "rgba(230,160,100,1)", // stafl sytems orange
  staflOrange: STAFL_ACTION,
  staflGreen: STAFL_ACCENT,

  // set colors to stafl colors first then
  // override with company colors via DB
  themePrimary: STAFL_ACTION,
  themeSecondary: STAFL_ACCENT,

  headerHeight: "50px",
  productsHeaderHeight: "6vh",

  sidebarBorder: "1px solid rgba(255,255,255,0.4)",
  sidebarBorderInactive: "solid transparent 1px",
  sidebarBorderLeft: "7px solid rgba(255,255,255,1)",
  sidebarBorderLeftInactive: "solid transparent 7px",
  sidebarTextColor: "white",
  sidebarPadding: "0px 10px",
  sidebarWidth: "200px",
  sidebarBGColor: "rgb(64,143, 78)",
  sidebarExpandedBGColor: "rgba(255,255,255,.12)",

  sidebarFontSize: "18px",
  sidebarFontFamily: "Roboto, Arial",
  sidebarFontWeight: "400",
  sidebarFontStyle: "normal",

  sidebarFilterHeaderFontSize: "11px",
  sidebarFilterHeaderFontFamily: "Roboto, Arial",
  sidebarFilterHeaderFontWeight: "500",

  sidebarFilterFontSize: "12px",
  sidebarFilterFontFamily: "Roboto, Arial",
  sidebarFilterFontWeight: "700",

  overlayIconColumnColor: "rgba(235,235,235,1)",

  appMainWidth: "calc(100vw - 200px)",

  socPercentageColor: "rgb(102,102,102)",
  socPercentageColorLight: "rgb(204,204,204)",
  socBackground: "rgb(204,204,204)",
  socLowPercentageColor: "rgb(234,167,86)",
  socLowPercentageColorLight: "rgb(250,230,207)",
  socLowBackground: "rgb(250, 225, 205)",
  blueNumberColor: "rgba(120,160,200, 1)",

  // add 'container-type: inline-size' to the parent container of the child you want cqw to be based on
  // https://stackoverflow.com/questions/16056591/font-scaling-based-on-size-of-container
  widgetFontSize: "clamp(30px, 7cqmin, 50px)",
};

const PRODUCT_MODAL_HEADER_HEIGHT = 80;

/* Layout Tabs */
export const LayoutTabsStyling = {
  Tester: styled.div`
    height: 20px;
    width: 20px;
    background-color: red;
  `,
  LayoutTabsMain: styled.div`
    height: ${(props) =>
      props.section === "dashboard"
        ? ``
        : `calc(100vh - ${
            props.theme.headerHeight
          } - ${PRODUCT_MODAL_HEADER_HEIGHT}px - ${2 * MAP_OUTER_PADDING}px);`};
    border-radius: 0 0 5px 5px;
    overflow-y: scroll;
    white-space: nowrap;
    width: 100%;
  `,
  LayoutTabsSidebar: styled.div`
    border-right: ${(props) => props.theme.sidebarBorder};
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    width: calc(100vw - ${(props) => props.theme.sidebarWidth});
  `,
  LayoutTabsSidebarItem: styled.div`
    padding: 0px 35px;
    height: 40px;
    display: flex;
    align-items: center;
    &[disabled] {
      background-color: rgb(229, 229, 229);
    }
  `,
  LayoutTabsView: styled.div`
    flex: 5;
    height: calc(
      100vh - 80px - ${(props) => props.theme.headerHeight} - 80px
    ); // 80px = 40px padding top + bottom
    width: 100%;
    border-radius: 50px;
  `,

  LayoutTabsViewHeader: styled.div`
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border-bottom: 1px solid rgb(210, 210, 210);
    display: flex;
    height: 50px;
    padding: 0px 30px;
    position: fixed;
    z-index: 2;
  `,
  LayoutTabsViewHeaderItem: styled.div`
    padding: 10px 30px;
    cursor: pointer;
    border-bottom: ${(props) =>
      props.active ? "solid 5px rgba(0,0,0,.7)" : "solid 5px transparent"};
  `,
};

const ProductsOverlayCell = styled.span`
  padding: 0px 10px;
`;

const HEADER_HEIGHT = 50;

export const ProductsOverlayStyling = {
  Wrapper: styled.div`
    left: 22px;
    opacity: 1;
    position: absolute;
    top: 30px;
    transform: scale(1);
    z-index: 1;
    &[disabled] {
      visibility: hidden;
      opacity: 0;
      transform: scale(0.9);
    }
    transition: all 0.3s;
  `,
  Overlay: styled.div`
    height: ${(props) =>
      props.vertical
        ? props.overlayShown
          ? `calc(100vh - ${HEADER_HEIGHT}px - ${4 * MAP_OUTER_PADDING_SM}px)`
          : `calc(50vh - ${HEADER_HEIGHT}px - 20px)`
        : props.overlayShown
        ? `calc(30vh - ${HEADER_HEIGHT}px)`
        : "20vh"};
    width: ${(props) =>
      props.vertical
        ? `${OVERLAYVERTICAL_WIDTH}px`
        : `calc(100vw - ${props.theme.sidebarWidth} - ${
            2 * MAP_OUTER_PADDING_SM
          }px)`};
    border: ${(props) => props.theme.sidebarBorder};
    z-index: 1;
    background-color: rgba(255, 255, 255, 1);
    font-weight: 400;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.25);
    flex-direction: column;
    transition: opacity 0.2s, height 0.2s, width 0.2s;
    display: ${(props) => (props.overlayShown ? "flex" : "none")};
    visibility: ${(props) => (props.overlayShown ? "inherit" : "hidden")};
    opacity: ${(props) => (props.overlayShown ? 1 : 0)};
  `,
  HeaderRow: styled.div`
    display: flex;
    border-bottom: 1px solid lightgrey;
    height: 40px;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 5;
  `,
  HeaderCell: styled(ProductsOverlayCell)`
    display: flex;
    align-self: center;
    font-size: 90%;
    font-weight: 500;
    padding: 0 10px;
  `,
  HeaderCell2: styled(ProductsOverlayCell)`
    display: flex;
    align-self: center;
    font-weight: 500;
    background-color: ${(props) => props.overlayIconColumnColor};
  `,
  Row: styled.div`
    display: flex;
    align-items: center;
    height: 40px;
    transition: all 0.1s;
    border-radius: 5px;
    background-color: ${(props) =>
      props.isActive ? "rgba(0,0,0,.07)" : "inherit"};
    & .interaction-icon {
      opacity: 0.7;
      padding: 0 5px;
    }
    & .interaction-icon img {
      width: 20px;
      height: 20px;
    }
  `,
  RowCell: styled(ProductsOverlayCell)`
    display: flex;
    align-items: center;

    height: 100%;
  `,
};

export const MapStyling = {
  GoogleMapStyle_Big: [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      stylers: [
        {
          saturation: -70,
        },
        {
          lightness: 0,
        },
        {
          gamma: 1.15,
        },
      ],
    },
    {
      elementType: "labels",
      stylers: [
        {
          gamma: 0.26,
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          lightness: -5,
        },
        {
          saturation: 0,
        },
        {
          hue: "#ffffff",
        },
        {
          gamma: 0,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          lightness: 50,
        },
        {
          saturation: 0,
        },
        {
          hue: "#ffffff",
        },
      ],
    },
    {
      featureType: "administrative.province",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: 0,
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text",
      stylers: [
        {
          lightness: 20,
        },
        {
          visibility: "on",
        },
        {
          color: "#b7b7b7",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text",
      stylers: [
        {
          lightness: 20,
        },
      ],
    },
  ],
  GoogleMapStyle_Tile: [
    {
      featureType: "poi",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      stylers: [
        {
          saturation: -50,
        },
        {
          lightness: 10, // 37
        },
        {
          gamma: 0.9,
        },
      ],
    },
    {
      elementType: "labels",
      stylers: [
        {
          gamma: 0.26,
        },
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road",
      stylers: [
        {
          lightness: 0,
        },
        {
          saturation: 0,
        },
        {
          hue: "#ffffff",
        },
        {
          gamma: 0,
        },
      ],
    },
    {
      featureType: "road",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "geometry",
      stylers: [
        {
          lightness: 20,
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "geometry",
      stylers: [
        {
          lightness: 50,
        },
        {
          saturation: 0,
        },
        {
          hue: "#ffffff",
        },
      ],
    },
    {
      featureType: "administrative.province",
      stylers: [
        {
          visibility: "on",
        },
        {
          lightness: -50,
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text",
      stylers: [
        {
          lightness: 20,
        },
        {
          visibility: "on",
        },
        {
          color: "#b7b7b7",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text.stroke",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "labels.text",
      stylers: [
        {
          lightness: 20,
        },
      ],
    },
  ],
};
