import React from "react";
import styled, { withTheme } from "styled-components";
import { Icon } from "common";

// const lowBatteryLimit = 30;

const DEBUG_ENABLE_ARROW = false;

const Bar = styled.div`
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  height: ${(props) => props.barHeight}px;
  width: 100%;
  // outline: 1px dashed #fff;
  // outline-offset: -10px;
  border: ${(props) =>
    props.simpleMode
      ? !props.soc
        ? "2px dashed rgba(102,102,102,0.5)"
        : ""
      : props.soc
      ? ""
      : "2px dashed rgba(102,102,102,0.5)"};
  background: ${(props) =>
    props.simpleMode
      ? ""
      : props.soc
      ? "repeating-linear-gradient( -45deg, transparent, transparent 3px, rgb(220,220,220) 3px, rgb(220,220,220) 6px ), linear-gradient( to bottom, white, white )"
      : ""}; // "rgb(180,180,180)"
`;

const Arrow = styled.div`
  margin-left: ${(props) => props.leftPadding}%;
`;
const SOCFiller = styled.div`
  position: absolute;
  background: ${(props) =>
    props.soc + props.percentage < 15
      ? "#cc0000"
      : props.soc + props.percentage < 35
      ? "#f57a1c"
      : "rgba(51,51,51,1)"};
  height: ${(props) => props.barHeight}px;
  top: 0;
  left: 0;
  // transition: width 0.2s ease-in;
  // border: 1px solid rgba(102,102,102,1);
  width: ${(props) => (props.soc * props.soh) / 100}%;
  z-index: 1000;
`;
const SOHFiller = styled.div`
  position: absolute;
  background: ${(props) =>
    props.soc + props.percentage < 15
      ? "#cc0000"
      : props.soc + props.percentage < 35
      ? "#f57a1c"
      : props.simpleMode
      ? props.soc
        ? "rgba(51,51,51,0.15)"
        : ""
      : "rgba(224,224,224,1)"};
  height: ${(props) => props.barHeight}px;
  top: 0;
  left: 0;
  // transition: width 0.2s ease-in;
  // border: 1px solid rgba(102,102,102,1);
  width: ${(props) => props.percentage}%;
  borderradius: ${(props) => (props.simpleMode ? "0 2px 2px 0" : "0")};
`;

/* mapmode = quick fix to change styling on map view battery show */
const ProgressBar = ({ soc, soh, offset, barHeight = 8, mapMode = false }) => {
  return (
    <>
      {DEBUG_ENABLE_ARROW && soh != null ? (
        <Arrow leftPadding={Math.max(soh - offset + 0.45, 0)}>
          <Icon
            width={10}
            height={10}
            style={{ transform: "rotateX(180deg)" }}
            filename="progress_arrow.svg"
          />
        </Arrow>
      ) : (
        ""
      )}
      <Bar simpleMode={mapMode} soc={soc} barHeight={barHeight}>
        <SOCFiller
          style={{ borderRadius: "2px 0 0 2px" }}
          soc={soc}
          soh={soh}
          barHeight={barHeight}
          // percentage={85}
          // percentage={Math.min(Math.max(soc, 0), 100)}
          // colorPercentage={Math.max(soc, 0)}
        />

        {soh != null ? (
          <SOHFiller
            light={true}
            soc={soc}
            soh={soh}
            simpleMode={mapMode}
            // soc={soc}
            // percentage={Math.max(soh - soc, 0)}
            percentage={Math.max(soh, 0)}
            barHeight={barHeight}
            // style={{ marginLeft: `${Math.max(soc, 0)}%`, borderRadius: "0 2px 2px 0" }}
          />
        ) : (
          ""
        )}
      </Bar>
    </>
  );
};

export default withTheme(ProgressBar);
