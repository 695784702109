import { useState } from "react";
import { userService } from "features/users/services";
import LoadingSpinner from "common/LoadingSpinner";
import {
  LoginTemplate,
  Button,
  StyledInput,
  ErrorMessage,
  AuthFormLink,
} from "./loginComponents";

export function ConfirmSignUp() {
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await userService.ConfirmSignUp({ email, confirmationCode });
      setSuccess(true);
    } catch (err: any) {
      setError(err.message);
    }
    setIsLoading(false);
  };

  const SuccessMessage = () => (
    <div>
      <p>
        <strong>Confirmation successful!</strong>
      </p>
      <p>You can now log in with your credentials.</p>
      <p>
        <AuthFormLink to="/">Log In</AuthFormLink>
      </p>
    </div>
  );
  return (
    <LoginTemplate>
      {isLoading ? (
        <LoadingSpinner />
      ) : success ? (
        <SuccessMessage />
      ) : (
        <div>
          <p>
            <strong>Confirm Sign Up</strong>
          </p>
          {error && <ErrorMessage>{error}</ErrorMessage>}
          <form onSubmit={handleSubmit} id="confirm-sign-up-form">
            <StyledInput
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setEmail(e.target.value)
              }
            />
            <StyledInput
              type="text"
              placeholder="Confirmation code"
              value={confirmationCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setConfirmationCode(e.target.value)
              }
            />
            <Button form="confirm-sign-up-form">Confirm</Button>
          </form>
        </div>
      )}
    </LoginTemplate>
  );
}
