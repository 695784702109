import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  instanceState: {},
  widgets: {},
};

export const widgetsSlice = createSlice({
  name: "widgets",
  initialState,
  reducers: {
    setWidgets: (state, action) => {
      let { widgetType, widgets: newWidgets } = action.payload;
      state.widgets[widgetType] = newWidgets;
    },
  },
});

export const { setWidgets } = widgetsSlice.actions;

export default widgetsSlice.reducer;
