import { Flex, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

export const Spinner = ({ loading = true }) => (
  <>
    {loading && (
      <Flex
        align="center"
        justify="center"
        style={{ height: "100%", width: "100%" }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 50 }} spin />} />
      </Flex>
    )}
  </>
);
