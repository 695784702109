import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled, { useTheme } from "styled-components";
import { withSize } from "react-sizeme";
import { useHistory } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import { GetFilter } from "utils/Color";

import { MapStyling } from "styles/GlobalStyles";
import * as Defines from "utils/defines";
import { Icon } from "common";
import { useDataHistory } from "features/units/hooks";
import { setUnitsViewMode } from "features/grid-layout/redux/layoutSlice";

import { GOOGLE_MAPS_API_KEY } from "config";

import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetProductSettingsForCustomerQuery } from "features/applications/redux";
import { setCurrentProduct as setCurrentSNId } from "features/applications/redux";
import { setActiveMapMarker } from "features/map/redux";

const MapPin = ({ filter }) => (
  <Icon style={{ filter }} hostedImage={Defines.S3_MAP_PIN_UNIT_A} />
);

const MinimapWrapper = styled.div`
  position: relative;
  width: ${({ size }) => (size && size.width ? `${size.width}px` : `100%`)};
  height: ${({ size }) => (size && size.height ? `${size.height}px` : `100%`)};
  cursor: pointer;
`;

const NoPinsMessage = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 5;
`;

const MapClickCatcher = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: transparent;
  z-index: 5;
  cursor: pointer;
`;

const getCenterFromPoints = (points) => {
  const latSum = points.reduce((latSum, point) => point.lat + latSum, 0);
  const lngSum = points.reduce((lngSum, point) => point.lng + lngSum, 0);
  return {
    lat: latSum / points.length,
    lng: lngSum / points.length,
  };
};

const getMapBounds = (map, maps, points) => {
  if (!map || !maps) return null;
  const bounds = new maps.LatLngBounds();
  const extendBoundsToPoint = (point) =>
    bounds.extend(new maps.LatLng(point.lat, point.lng));
  points.forEach(extendBoundsToPoint);

  // Don't zoom in too far on only one marker
  // ref: https://stackoverflow.com/a/5345708
  var extendPoint1 = new maps.LatLng(
    bounds.getNorthEast()?.lat() + Defines.MAP_EXPANSION_FACTOR,
    bounds.getNorthEast()?.lng() + Defines.MAP_EXPANSION_FACTOR
  );
  var extendPoint2 = new maps.LatLng(
    bounds.getNorthEast()?.lat() - Defines.MAP_EXPANSION_FACTOR,
    bounds.getNorthEast()?.lng() - Defines.MAP_EXPANSION_FACTOR
  );
  bounds.extend(extendPoint1);
  bounds.extend(extendPoint2);

  return bounds;
};

const ReusableMap = ({ points, size }) => {
  const editModeEnabled = useSelector((state) => state.layout.editModeEnabled);
  const history = useHistory();
  const zoomLevel = 5;
  const center = points.length
    ? getCenterFromPoints(points)
    : Defines.DEFAULT_MAP_CENTER;

  const theme = useTheme();

  const dispatch = useDispatch();
  const setViewMode = (viewMode) => dispatch(setUnitsViewMode(viewMode));

  const [map, setMap] = useState();
  const [maps, setMaps] = useState();
  const unselectCurrentSNId = () => dispatch(setCurrentSNId(null));
  const setMapPin = (productSNId) => dispatch(setActiveMapMarker(productSNId));

  useEffect(() => {
    let bounds = getMapBounds(map, maps, points);
    if (bounds) {
      map.fitBounds(bounds);
    }
  }, [map, maps, points]);

  const apiIsLoaded = ({ map, maps }) => {
    setMap(map);
    setMaps(maps);
  };

  const handleMapClick = (e) => {
    if (editModeEnabled) return;
    // set map view
    setViewMode("map");
    // unselect any currently pulled up SN (hide modal)
    unselectCurrentSNId();
    setMapPin(null);
    // go to the units page
    history.push("/units");
  };

  return (
    <div style={{ height: "100%" }}>
      <MinimapWrapper size={size} className="minimapWrapper">
        {!points.length ? (
          <NoPinsMessage>No Recent Location Data</NoPinsMessage>
        ) : null}
        <MapClickCatcher onClick={handleMapClick} />
        <GoogleMapReact
          style={{ cursor: "pointer", background: "clear" }}
          bootstrapURLKeys={{ key: GOOGLE_MAPS_API_KEY }}
          draggable={false}
          zoom={zoomLevel}
          center={{ lat: center.lat, lng: center.lng }}
          options={{ styles: MapStyling.GoogleMapStyle_Big }}
          onGoogleApiLoaded={apiIsLoaded}
          yesIWantToUseGoogleMapApiInternals
        >
          {points.map((mapPin) => (
            <MapPin
              filter={GetFilter(theme.themePrimary)}
              key={mapPin.psnId}
              lat={mapPin.lat}
              lng={mapPin.lng}
            />
          ))}
        </GoogleMapReact>
      </MinimapWrapper>
    </div>
  );
};

const DashboardMapWidget = ({ size }) => {
  const dataHistory = useDataHistory();
  const customerId = useCustomerId();
  const { data: productSNs } = useGetApplicationUnitsByFilterQuery({
    customer_id: customerId,
  });
  const { data: allProductSettings } =
    useGetProductSettingsForCustomerQuery(customerId);

  const locations = [];

  if (!productSNs?.length) return <div>No Product SNs</div>;

  for (let productSN of productSNs) {
    const dataHistoryForPSN = dataHistory[productSN.id];
    const { lat_signal_id, lng_signal_id } =
      allProductSettings.find(
        (productSettingObj) =>
          productSettingObj.product_id === productSN.product_id
      ) || {};

    // GPS Lat
    const unitLatData = dataHistoryForPSN
      ? dataHistoryForPSN[lat_signal_id]
      : null;
    const unitLatVal = unitLatData
      ? unitLatData[unitLatData.length - 1].data_value
      : null;

    // GPS Lng
    const unitLngData = dataHistoryForPSN
      ? dataHistoryForPSN[lng_signal_id]
      : null;
    const unitLngVal = unitLngData
      ? unitLngData[unitLngData.length - 1].data_value
      : null;
    if (unitLatVal && unitLngVal)
      locations.push({ lat: unitLatVal, lng: unitLngVal, psnId: productSN.id });
  }

  return <ReusableMap size={size} points={locations} />;
};

export const DashboardMap = withSize()(DashboardMapWidget);
