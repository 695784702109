import { Button, Form, Input, Select } from "antd";

export const CreateApplicationForm = ({
  buttonMarginRight,
  handleCreateApplicationFormSubmit,
  customer,
  handleCancel,
  formStyle,
  formVariant,
  wrapperColStyle,
  labelColStyle,
}) => {
  return (
    <Form
      onFinish={handleCreateApplicationFormSubmit}
      style={formStyle}
      variant={formVariant}
      wrapperCol={wrapperColStyle}
      labelCol={labelColStyle}
      id="create-application-form"
    >
      <h4 style={{ marginBottom: "30px" }}>Create Application</h4>
      <Form.Item label="Application Name" name="product_name" required>
        <Input />
      </Form.Item>
      <Form.Item label="Product Number" name="product_number">
        <Input />
      </Form.Item>
      <Form.Item
        label="Customer"
        name="customer_id"
        initialValue={customer?.id}
      >
        <Select disabled={true}>
          <Select.Option value={customer?.id}>{customer?.name}</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item>
        <Button style={buttonMarginRight} onClick={handleCancel}>
          Cancel
        </Button>
        <Button type="primary" htmlType="submit" form="create-application-form">
          Create
        </Button>
      </Form.Item>
    </Form>
  );
};
