import { Widget } from "features/widgets";
import { WidgetWrapper } from "features/widgets/widgetlibrary/TileComponents";
import styled from "styled-components";

const AddWidgetMessageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const FullScreenBorderTop = styled.div`
  border-top: 1px solid #e7e7e7;
  border-bottom: 1px solid #d2d2d2;
`;

const FullScreenWrapper = styled.div`
  padding: 5px;
  height: 100%;
  position: relative;
  background-color: white;
`;

const FullscreenLayout = ({ data: widgetInstance }) => {
  return (
    <>
      <FullScreenBorderTop />
      <FullScreenWrapper>
        {widgetInstance ? (
          <WidgetWrapper className="widget-wrapper">
            <Widget
              instance={widgetInstance}
              isMapModalLayout={false}
              productID={widgetInstance.product_id}
              isFullscreen={true}
            />
          </WidgetWrapper>
        ) : (
          <AddWidgetMessageContainer>
            <div>
              <h3
                style={{
                  fontSize: "20px",
                  fontWeight: "normal",
                  marginBottom: "20px",
                  opacity: ".3",
                  textAlign: "center",
                }}
              >
                Empty Tab (Full-Screen Layout)
              </h3>
              <p style={{ fontStyle: "italic", fontSize: "16px" }}>
                No widget selected. Add a widget to get started.
              </p>
            </div>
          </AddWidgetMessageContainer>
        )}
      </FullScreenWrapper>
    </>
  );
};

export default FullscreenLayout;
