import { createSlice } from "@reduxjs/toolkit";
import _ from "lodash";

const initialState = {
  eventHistory: {},
};

function deduplicateEvents(sortedEvents) {
  return _.uniqBy(sortedEvents, "timestamp_recorded");
}

export const eventsSlice = createSlice({
  name: "events",
  initialState,
  reducers: {
    storeEvents: (state, action) => {
      const newEvents = action.payload;
      if (!newEvents || !newEvents.length) return state;

      const { eventHistory: oldEvents } = state;

      let combinedHistory = Object.assign({}, oldEvents);

      for (let eventInstance of newEvents) {
        const { product_sn_id } = eventInstance;

        let existingEventsForSN = combinedHistory[product_sn_id];
        if (!existingEventsForSN) existingEventsForSN = [];

        let combinedEvents = [eventInstance, ...existingEventsForSN];
        combinedHistory[product_sn_id] = combinedEvents;
      }

      // perform deduplication only after combining
      const productSNIds = Object.keys(combinedHistory);
      for (let productSNId of productSNIds) {
        let eventsForSN = combinedHistory[productSNId];
        const uniqEvents = deduplicateEvents(eventsForSN);
        const sortedEvents = uniqEvents.sort(
          (a, b) => b.timestamp_recorded - a.timestamp_recorded
        );
        combinedHistory[productSNId] = sortedEvents;
      }
      state.eventHistory = combinedHistory;
    },
  },
});

export const { storeEvents } = eventsSlice.actions;

export default eventsSlice.reducer;
