import { useState } from "react";
import styled from "styled-components";
import { CanMessageFiltersSection, CaptureRatesSection } from "../components";

const ParametersWrapper = styled.div`
  height: 100%;
  display: flex;
`;

const ParametersSidebar = styled.div`
  width: 250px;
  padding: 20px;
  height: 100%;
  background-color: #fff;
  border-right: 1px solid #ccc;
`;

const ParametersSidebarItem = styled.div`
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  background-color: ${({ active }) => (active ? "#f0f0f0" : "none")};
`;

const ParametersBody = styled.div`
  flex: 1;
  height: 100%;
  overflow-y: auto;
`;

const CAPTURE_RATES = "capture_rates";
const CAN_MESSAGE_FILTERS = "can_cap_filters";

const ApplicationsParametersView = () => {
  const [selectedContent, setSelectedContent] = useState(CAN_MESSAGE_FILTERS);
  const showContent = (selectedContent) => {
    switch (selectedContent) {
      case CAPTURE_RATES:
        return <CaptureRatesSection />;
      case CAN_MESSAGE_FILTERS:
      default:
        return <CanMessageFiltersSection />;
    }
  };
  return (
    <ParametersWrapper>
      <ParametersSidebar>
        <ParametersSidebarItem
          active={selectedContent === CAN_MESSAGE_FILTERS}
          onClick={() => setSelectedContent(CAN_MESSAGE_FILTERS)}
        >
          Can Message Filters
        </ParametersSidebarItem>
        <ParametersSidebarItem
          active={selectedContent === CAPTURE_RATES}
          onClick={() => setSelectedContent(CAPTURE_RATES)}
        >
          Capture Rates
        </ParametersSidebarItem>
      </ParametersSidebar>
      <ParametersBody>{showContent(selectedContent)}</ParametersBody>
    </ParametersWrapper>
  );
};

export default ApplicationsParametersView;
