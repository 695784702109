import * as API from "axios/axios";

// only used in Map widget, where averaging is not desired
export async function getDataInDateRange(
  product_sn_id,
  date_start,
  date_end,
  signal_ids
) {
  return productSNDataService.historical({
    product_sn_id,
    date_start,
    date_end,
    signal_ids,
  });
}

export const productSNDataService = {
  historical: (params) => API.post("/api/product_sn_data/historical", params),
  getDataForExcel: (params) => API.post("/api/product_sn_data/excel", params),
  GetAggregationForSignalOverTime: async (options) =>
    API.post("/api/product_sn_data/aggregation_over_time", options),
};
