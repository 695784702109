import { useSelector } from "react-redux";

import { theme as initTheme } from "styles/GlobalStyles";

export const useCustomers = () => {
  const customers = useSelector((state) => state.user.customers);
  return customers;
};
export const useCustomerId = () => {
  const customerId = useSelector((state) => state.user.customerId);
  return customerId;
};
export const useCurrentCustomer = () => {
  const customers = useSelector((state) => state.user.customers);
  const customerId = useSelector((state) => state.user.customerId);
  const result =
    customers && customerId
      ? customers.find((customer) => customer.id === customerId)
      : null;
  return result;
};
export const useCustomerTheme = () => {
  const customers = useCustomers();
  const customerId = useCustomerId();
  // const theme = initTheme;
  const currentCustomer = customers.find((c) => c.id === customerId);
  const themeWithColors =
    currentCustomer && currentCustomer.color_primary
      ? {
          ...initTheme,
          themePrimary: currentCustomer.color_primary,
          themeSecondary: currentCustomer.color_secondary,
        }
      : initTheme;
  return themeWithColors;
};
