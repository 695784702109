import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const tcuBaseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/tcu`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const tcuApi = createApi({
  reducerPath: "tcuApi",
  baseQuery: tcuBaseQuery,
  tagTypes: ["TCUs", "TCU Pairs"],
  endpoints: (builder) => ({
    getTCUsByID: builder.query({
      query: (args) => {
        const { id } = args || {};
        return {
          url: `/${id}`,
          provideTags: ["TCUs"],
        };
      },
    }),
    getTCUsByFilter: builder.query({
      query: (args) => {
        const { id, customer_id, product_sn_id, token } = args || {};
        const params = { id, customer_id, product_sn_id, token };

        // remove undefined keys from params
        Object.keys(params).forEach(
          (key) => params[key] === undefined && delete params[key]
        );
        return {
          url: `/`,
          params: params,
          provideTags: ["TCUs"],
        };
      },
    }),
    getTCUsForCustomer: builder.query({
      query: (customerId) => `/customers-tcus?customer_id=${customerId}`,
      providesTags: ["TCUs"],
    }),
    getUnpairedTCUs: builder.query({
      query: () => `/unpaired-tcus`,
      providesTags: ["TCUs"],
    }),
    getNextAvailableTCUToken: builder.query({
      query: (args) => ({
        url: "/token/generate",
      }),
    }),
    getTCUPairingHistory: builder.query({
      query: (args) => {
        const { id, tcu_id, product_sn_id } = args || {};
        const params = { id, tcu_id, product_sn_id };
        // remove undefined keys from params
        Object.keys(params).forEach(
          (key) => params[key] === undefined && delete params[key]
        );

        return {
          url: `/pairing-history`,
          params: params,
          provideTags: ["TCU Pairs"],
        };
      },
    }),
    createTCU: builder.mutation({
      query: (args) => ({
        url: "/",
        method: "POST",
        body: args,
      }),
      invalidatesTags: ["TCUs"],
    }),
    updateTCU: builder.mutation({
      query: (args) => {
        const { id } = args || {};
        const obj = {
          url: `/${id}`,
          method: "PUT",
          body: { ...args },
        };
        return obj;
      },
      invalidatesTags: ["TCUs"],
    }),
  }),
});

export const {
  useGetTCUsByIDQuery,
  useGetTCUsByFilterQuery,
  useGetTCUsForCustomerQuery,
  useGetUnpairedTCUsQuery,
  useGetNextAvailableTCUTokenQuery,
  useGetTCUPairingHistoryQuery,
  useCreateTCUMutation,
  useUpdateTCUMutation,
} = tcuApi;
