import {
  useState,
  useContext,
  createContext,
  ReactNode,
  FC,
  ChangeEvent,
  FormEvent,
} from "react";
import PasswordChecklist from "react-password-checklist";

import { userService } from "features/users/services";
import LoadingSpinner from "common/LoadingSpinner";
import {
  LoginTemplate,
  Button,
  StyledInput,
  ErrorMessage,
  AuthFormLink,
} from "./loginComponents";
import { AuthContext } from "features/auth/contexts";

interface SignUpStateType {
  name: string;
  setName: (name: string) => void;
  email: string;
  setEmail: (email: string) => void;
  password: string;
  setPassword: (password: string) => void;
  confirmPassword: string;
  setConfirmPassword: (confirmNewPassword: string) => void;
  error: string;
  setError: (error: string) => void;
  success: boolean;
  setSuccess: (success: boolean) => void;
  isLoading: boolean;
  setIsLoading: (isLoading: boolean) => void;
  confirmationCode: string;
  setConfirmationCode: (confirmationCode: string) => void;
  step: number;
  setStep: (step: number) => void;
}

const SignUpStateContext = createContext<SignUpStateType | undefined>(
  undefined
);

interface SignUpStateProviderProps {
  children: ReactNode;
}

const SignUpStateProvider: FC<SignUpStateProviderProps> = ({ children }) => {
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmationCode, setConfirmationCode] = useState<string>("");
  const [step, setStep] = useState<number>(1);

  const value = {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    setError,
    success,
    setSuccess,
    isLoading,
    setIsLoading,
    confirmationCode,
    setConfirmationCode,
    step,
    setStep,
  };

  return (
    <SignUpStateContext.Provider value={value}>
      {children}
    </SignUpStateContext.Provider>
  );
};

const SignUpForm = () => {
  const signUpState = useContext(SignUpStateContext);

  if (!signUpState) return null;

  const {
    name,
    setName,
    email,
    setEmail,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    error,
    setError,
    setIsLoading,
    setStep,
  } = signUpState;

  const handleSignUpSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError("");

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);
    try {
      await userService.SignUp({ name, email, password });
      setStep(2);
    } catch (err) {
      if (err instanceof Error) setError(err.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <p>
        <strong>Sign Up</strong>
      </p>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleSignUpSubmit}>
        <StyledInput
          type="text"
          placeholder="Name"
          value={name}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setName(e.target.value)
          }
        />
        <StyledInput
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setEmail(e.target.value)
          }
        />
        <StyledInput
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setPassword(e.target.value)
          }
        />
        <StyledInput
          type="password"
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setConfirmPassword(e.target.value)
          }
        />
        {password && (
          <div style={{ textAlign: "left" }}>
            <PasswordChecklist
              rules={[
                "minLength",
                "specialChar",
                "number",
                "lowercase",
                "capital",
                "match",
              ]}
              minLength={8}
              value={password}
              valueAgain={confirmPassword}
              onChange={(isValid) => {}}
            />
          </div>
        )}
        <Button type="submit">Sign Up</Button>
        <AuthFormLink to="/">Log In</AuthFormLink>
      </form>
    </div>
  );
};

const ConfirmationStep = () => {
  const authContext = useContext(AuthContext);
  const signUpStateContext = useContext(SignUpStateContext);

  if (!authContext) return null;
  if (!signUpStateContext) return null;

  const { signIn } = authContext;
  const {
    error,
    setError,
    email,
    password,
    confirmationCode,
    setConfirmationCode,
    setIsLoading,
  } = signUpStateContext;

  const handleConfirmSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await userService.ConfirmSignUp({ email, confirmationCode });
      await signIn(email, password);
    } catch (err) {
      if (err instanceof Error) {
        let errorMessage =
          err.message === "CodeMismatchException"
            ? "Invalid confirmation code"
            : err.message;
        setError(errorMessage);
      }
    }
    setIsLoading(false);
  };

  const requestConfirmationCode = async (e: FormEvent) => {
    e.preventDefault();
    setError("");
    setIsLoading(true);
    try {
      await userService.RequestNewConfirmationCode({ email });
    } catch (err) {
      if (err instanceof Error) setError(err.message);
    }
    setIsLoading(false);
  };

  return (
    <div>
      <p>
        <strong>Enter Confirmation Code</strong>
      </p>
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <form onSubmit={handleConfirmSubmit} id="confirmation-code-form">
        <StyledInput
          type="text"
          placeholder="Confirmation code"
          value={confirmationCode}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            setConfirmationCode(e.target.value)
          }
        />
        <Button form="confirmation-code-form">Confirm</Button>
        <AuthFormLink to="#/" onClick={requestConfirmationCode}>
          Resend Confirmation Code
        </AuthFormLink>
      </form>
    </div>
  );
};

const SignUpPage = () => {
  const signUpStateContext = useContext(SignUpStateContext);

  if (!signUpStateContext) return null;

  const { step, isLoading } = signUpStateContext;

  const renderStep = (step: number) => {
    switch (step) {
      case 2:
        return <ConfirmationStep />;
      case 1:
      default:
        return <SignUpForm />;
    }
  };

  return (
    <LoginTemplate>
      {isLoading ? <LoadingSpinner /> : renderStep(step)}
    </LoginTemplate>
  );
};

export function SignUp() {
  return (
    <SignUpStateProvider>
      <SignUpPage />
    </SignUpStateProvider>
  );
}
