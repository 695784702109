import { useEffect, useState } from "react";
import { Table } from "antd";
import { Link, useHistory, useRouteMatch } from "react-router-dom";
import { Spinner } from "common/Spinner";
import moment from "moment-timezone";

export const ApplicationDeviceTypeViewDetailsTable = ({
  qApplicationDeviceType,
  isPageLoaded,
}) => {
  const [applicationDeviceType, setApplicationDeviceType] = useState([]);

  // Load and Update Application Device Types List
  useEffect(() => {
    if (isPageLoaded) {
      const createdOnDate = new Date(qApplicationDeviceType.createdAt);
      const updatedOnDate = new Date(qApplicationDeviceType.updatedAt);
      const createdOnFormatted = moment(createdOnDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
      const updatedOnFormatted = moment(updatedOnDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );

      // update list of application device types to include a key parameter (for js-warning)
      const updatedApplicationDeviceType = {
        ...qApplicationDeviceType,
        key: qApplicationDeviceType?.id,
        creator: qApplicationDeviceType?.user.name,
        created_on: createdOnFormatted,
        updated_on: updatedOnFormatted,
      };
      setApplicationDeviceType([updatedApplicationDeviceType]);
    }
  }, [isPageLoaded, qApplicationDeviceType]);

  if (!isPageLoaded) return <Spinner />;

  /**
   * Columns for Application Device Type Overview Table
   */
  const columns = [
    {
      title: "Application Device Type",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: true,
    },
    {
      title: "Creator",
      dataIndex: "creator",
      key: "creator",
      disabledCheckbox: false,
    },
    {
      title: "Stafl Device Type Code",
      dataIndex: "stafl_device_type_code",
      key: "stafl_device_type_code",
      disabledCheckbox: false,
    },
    {
      title: "Creation Datetime",
      dataIndex: "created_on",
      key: "created_on",
      disabledCheckbox: false,
    },
    {
      title: "Updated Datetime",
      dataIndex: "updated_on",
      key: "updated_on",
      disabledCheckbox: false,
    },
  ];

  return (
    <>
      <Table
        bordered
        columns={columns}
        dataSource={applicationDeviceType}
        pagination={false}
      />
    </>
  );
};
