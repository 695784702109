import semver from "semver";

export const getLatestFirmwareVersion = (files) => {
  if (!files || !files.length) return null;

  const sortedFiles = files.sort((a, b) =>
    semver.compare(semver.valid(a.version), semver.valid(b.version))
  );
  return sortedFiles[sortedFiles?.length - 1];
};
