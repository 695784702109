import Select from "react-select";

/** @TODO this is repeated - move this and Select from "react-select" into an importable component */
export const customSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    border: "none",
    borderRadius: "0",
    backgroundColor: "transparent",
    borderBottom: "1.5px solid rgba(0, 0, 0, 0.25)",
    boxShadow: "none",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.25)",
    },
    "&:focus": {
      borderColor: "green",
    },
  }),
};

const rateOptions = [
  { label: "- -", value: "0" },
  { label: "0.1 Hz", value: "0.1" },
  { label: "0.2 Hz", value: "0.2" },
  { label: "0.5 Hz", value: "0.5" },
  { label: "1 Hz", value: "1.0" },
  { label: "2 Hz", value: "2.0" },
  { label: "5 Hz", value: "5.0" },
];

export const RateOptionsSelect = ({ id, value, onOptionSelected, style }) => (
  <Select
    id={id}
    styles={customSelectStyle}
    options={rateOptions}
    defaultValue={rateOptions.find(
      (signalOption) => signalOption.value === value
    )}
    onChange={(selection) => onOptionSelected(selection.value)}
  />
);
