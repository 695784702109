import { useEffect, useState } from "react";
import { useRouteMatch, useHistory, useLocation } from "react-router-dom";

import { ADMIN_ROUTE_TCUS } from "features/tcu/utils/Constants";
import { ADMIN_ROUTE_APPLICATIONS } from "features/applications/utils/Constants";

import {
  ADMIN_ROUTE_APPLICATION_UNITS,
  ADMIN_ROUTE_APPLICATION_DEVICES,
  ADMIN_ROUTE_APPLICATION_DEVICE_TYPES,
} from "features/application-devices/utils/Constants";

import { ADMIN_ROUTE_CUSTOMERS } from "features/customers/utils/Constants";

import styled from "styled-components";
import {
  CarOutlined,
  TeamOutlined,
  UsbOutlined,
  HomeOutlined,
  FormOutlined,
  DiffOutlined,
} from "@ant-design/icons";
import { Menu } from "antd";
import { AdminRoutes } from "features/admin/routes";
import { SessionManager } from "providers";

const AdminBody = styled.div`
  display: flex;
  flex-grow: 1;
`;

const AdminContent = styled.div`
  overflow-x: scroll;
  padding: 40px 50px;
  width: 100%;
`;

const AdminContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const AdminPage = () => {
  const { url } = useRouteMatch();
  const { pathname } = useLocation();
  const [currentKey, setCurrentKey] = useState("");
  const [openKeys, setOpenKeys] = useState([]);
  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };
  const items = [
    getItem("Applications", "sub1", <CarOutlined />, [
      getItem("Applications", ADMIN_ROUTE_APPLICATIONS),
      getItem("Application Units", ADMIN_ROUTE_APPLICATION_UNITS),
      getItem(
        "Application Devices",
        ADMIN_ROUTE_APPLICATION_DEVICES,
        <CarOutlined />,
        [
          getItem(
            "Application Device Types",
            ADMIN_ROUTE_APPLICATION_DEVICE_TYPES
          ),
          getItem(
            "Application Device Instances",
            "application-device-instances"
          ),
        ]
      ),
    ]),
    getItem("Customers", "sub2", <TeamOutlined />, [
      getItem("Customers Overview", ADMIN_ROUTE_CUSTOMERS),
      getItem("Customer Onboarding", "customer-onboarding"),
    ]),
    getItem("TCUs", "sub3", <UsbOutlined />, [
      getItem("TCUs", ADMIN_ROUTE_TCUS),
    ]),
    getItem("Firmware", "sub4", <DiffOutlined />, [
      getItem("File Management", "firmware/file_management/overview"),
      getItem("OTA Manager (WIP)", "firmware/ota_manager/overview"),
    ]),
    { type: "divider", style: { borderColor: "#fff", marginBottom: "auto" } },
    getItem("Changelog", "changelog", <FormOutlined />),
    getItem("Home", "Home", <HomeOutlined />),
  ];

  const history = useHistory();

  // dynamically select active menu items on load
  useEffect(() => {
    const pathURL = pathname.replace(`${url}/`, "");
    const pathURLSpl = pathURL.split("/");

    let groupItems = [];
    let activeItem;
    items.forEach((item) => {
      const firstCh = item.children;
      if (firstCh) {
        firstCh.forEach((firstItem) => {
          const secondCh = firstItem.children;
          if (secondCh) {
            let secondItem = secondCh.find((i) => {
              return i.key === pathURLSpl.at(0);
            });
            if (secondItem && !activeItem) {
              activeItem = secondItem;
              groupItems.push(firstItem.key);
              groupItems.push(item.key);
            }
          } else if (firstItem.key === pathURLSpl.at(0) && !activeItem) {
            activeItem = firstItem;
            groupItems.push(item.key);
          } else if (firstItem.key === pathURL && !activeItem) {
            activeItem = firstItem;
            groupItems.push(item.key);
          }
        });
      } else if (item.key === pathURLSpl.at(0) && !activeItem) {
        activeItem = item;
        groupItems.push(item.key);
      } else if (item.key === pathURL && !activeItem) {
        activeItem = item;
        groupItems.push(item.key);
      }
    });
    setCurrentKey(activeItem?.key);
    setOpenKeys(groupItems);
  }, [pathname]);

  const onClick = (e) => {
    setCurrentKey(e.key);
    history.push(e.key === "Home" ? `/` : `${url}/${e.key}`);
  };

  const handleOnOpenChange = (e) => {
    setOpenKeys(e);
  };

  return (
    <SessionManager>
      <AdminContainer>
        <AdminBody>
          <Menu
            theme={"dark"}
            onClick={onClick}
            style={{
              width: 320,
            }}
            selectedKeys={[currentKey]}
            openKeys={openKeys}
            onOpenChange={handleOnOpenChange}
            mode="inline"
            items={items}
          />
          <AdminContent>
            <AdminRoutes />
          </AdminContent>
        </AdminBody>
      </AdminContainer>
    </SessionManager>
  );
};
