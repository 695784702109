import SettingsField from "./SettingsField";
import { RadioButton } from "common/elements";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationsByFilterQuery } from "features/applications/redux";

export const Image = ({ changeSettings, getField }) => {
  const customerId = useCustomerId();
  const { data: allProducts } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  let defaultProductId = allProducts?.length ? allProducts[0].id : -1;
  return (
    <div>
      <SettingsField label="Product">
        <select
          value={getField("selectedProduct", defaultProductId)}
          onChange={(e) => changeSettings("selectedProduct", e.target.value)}
        >
          {allProducts?.map((product) => (
            <option value={product.id}>{product.product_name}</option>
          ))}
        </select>
      </SettingsField>
      <SettingsField label="Background">
        <RadioButton
          name="hide_background"
          id="visible"
          value={0}
          checked={!getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 0 : 1)
          }
          text="Visible"
        />
        <RadioButton
          name="hide_background"
          id="hidden"
          value={1}
          checked={getField("background_hide", 0)}
          onChange={(e) =>
            changeSettings("background_hide", e.target.checked ? 1 : 0)
          }
          text="Hidden"
        />
      </SettingsField>
    </div>
  );
};
