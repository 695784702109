import { useSelector, useDispatch } from "react-redux";
import { setApplicationsSelectedProduct } from "features/applications/redux";
import { useDataHistoryForSNId } from "features/units/hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetProductSettingsForCustomerQuery } from "features/applications/redux";
import { useGetSignalsForCustomerQuery } from "features/applications/redux";
// Products

export const useProducts = () => {
  const allProducts = useSelector((state) => state.products.products);
  return allProducts;
};

export const useSelectedProduct = () => {
  const dispatch = useDispatch();

  const selectedProduct = useSelector(
    (state) => state.products.applicationsSelectedProduct
  );
  const setSelectedProduct = (product) =>
    dispatch(setApplicationsSelectedProduct(product));

  return { selectedProduct, setSelectedProduct };
};

// Product Settings

export const useProductSettings = () => {
  return useSelector((state) => state.products.productSettings);
};

export const useProductSettingsForProductId = (productId) => {
  const productSettings = useProductSettings();
  if (!productId) return {};
  return productSettings[productId] || {};
};

// expecting dataHistoryForPSN object
export const getMaxTimestampForHistory = (dataHistoryForPSN) => {
  const maxTimestamps = [];
  for (let signalId in dataHistoryForPSN) {
    const signalDataArray = dataHistoryForPSN[signalId];
    if (!signalDataArray.length) continue;
    const lastDataPoint = signalDataArray[signalDataArray.length - 1];
    maxTimestamps.push(lastDataPoint.timestamp_recorded);
  }
  return Math.max(...maxTimestamps);
};

export const useSignalUISettingsForProductSN = (productSN) => {
  const customerId = useCustomerId();
  const { data: allProductSettings } =
    useGetProductSettingsForCustomerQuery(customerId);
  const productId = productSN ? productSN.product_id : null;
  const productSNId = productSN ? productSN.id : null;
  const {
    soc_signal_id,
    soh_signal_id,
    rssi_signal_id,
    lat_signal_id,
    lng_signal_id,
  } = allProductSettings
    ? allProductSettings.find(
        (productSettingObj) => productSettingObj.product_id === productId
      ) || {}
    : {};

  const dataHistoryForPSN = useDataHistoryForSNId(productSNId);

  const { data: signalsForCustomer } =
    useGetSignalsForCustomerQuery(customerId);

  const precisionValues = {};

  signalsForCustomer?.signals?.forEach((signal) => {
    switch (signal.id) {
      case soc_signal_id:
        precisionValues.SOCSignalPrecision = signal.precision;
        break;
      case soh_signal_id:
        precisionValues.SOHSignalPrecision = signal.precision;
        break;
      case rssi_signal_id:
        precisionValues.RSSISignalPrecision = signal.precision;
        break;
      case lat_signal_id:
        precisionValues.latSignalPrecision = signal.precision;
        break;
      case lng_signal_id:
        precisionValues.lngSignalPrecision = signal.precision;
        break;
      default:
        break;
    }
  });

  // SOC
  const unitSOCData = dataHistoryForPSN
    ? dataHistoryForPSN[soc_signal_id]
    : null;
  const { SOCSignalPrecision } = precisionValues;
  const unitSOCVal = unitSOCData
    ? unitSOCData[unitSOCData.length - 1].data_value
    : null;

  // SOH
  const unitSOHData = dataHistoryForPSN
    ? dataHistoryForPSN[soh_signal_id]
    : null;
  const { SOHSignalPrecision } = precisionValues;

  const unitSOHVal = unitSOHData
    ? unitSOHData[unitSOHData.length - 1].data_value
    : null;

  // RSSI
  const unitRSSIData = dataHistoryForPSN
    ? dataHistoryForPSN[rssi_signal_id]
    : null;

  const { RSSISignalPrecision } = precisionValues;
  const unitRSSIVal = unitRSSIData
    ? unitRSSIData[unitRSSIData.length - 1].data_value
    : null;

  const calculatedRSSI =
    unitRSSIVal && unitRSSIVal !== "99"
      ? Math.floor((parseInt(unitRSSIVal) * 100) / 31.0)
      : null;
  const unitSimplifiedRSSI = calculatedRSSI
    ? Math.min(calculatedRSSI, 100)
    : null; // clip at 100 if for any reason it goes over (like 99/31=319%)

  // GPS Lat
  const unitLatData = dataHistoryForPSN
    ? dataHistoryForPSN[lat_signal_id]
    : null;
  const { latSignalPrecision } = precisionValues;
  const unitLatVal = unitLatData
    ? unitLatData[unitLatData.length - 1].data_value
    : null;

  // GPS Lng
  const unitLngData = dataHistoryForPSN
    ? dataHistoryForPSN[lng_signal_id]
    : null;
  const { lngSignalPrecision } = precisionValues;
  const unitLngVal = unitLngData
    ? unitLngData[unitLngData.length - 1].data_value
    : null;

  // lastUpdated
  const unitLastUpdated = getMaxTimestampForHistory(dataHistoryForPSN);

  return {
    unitSOCVal,
    SOCSignalPrecision,
    unitSOHVal,
    SOHSignalPrecision,
    unitRSSIVal,
    RSSISignalPrecision,
    unitLatVal,
    latSignalPrecision,
    unitLngVal,
    lngSignalPrecision,
    unitLastUpdated,
    unitSimplifiedRSSI,
    soc_signal_id,
    soh_signal_id,
    rssi_signal_id,
    lat_signal_id,
    lng_signal_id,
  };
};
