import { useState } from "react";
// import { axios } from "axios/axios";
import { connect } from "react-redux";
// import { notify } from "features/notifications";
// import { Button } from "common/elements";
// import { firmwareService } from "features/firmware/services";
// import { uploadS3 } from "utils/uploadS3";
import { Table } from "antd";
import { useHistory } from "react-router-dom";
import { useGetTCUsByFilterQuery } from "features/tcu/redux";
// import { useGetProductSNsByIdQuery } from "features/units/redux";

const OTAManagerOverview = ({ products, customerId }) => {
  const [hoveredRow, setHoveredRow] = useState(null);
  const history = useHistory();

  const { data: qTCUs } = useGetTCUsByFilterQuery();

  const tcuColumns = [
    {
      title: "TCU Name",
      dataIndex: "name",
      key: "name",
      disabledCheckbox: false,
      default: false,
      onCell: (record, rowIndex) => {
        return {
          onClick: () => history.push(`view/${record.id}`),
          style: {
            textDecoration: hoveredRow === rowIndex ? "underline" : "none",
            cursor: "pointer",
            color: "blue",
          },
          onMouseEnter: () => setHoveredRow(rowIndex),
          onMouseLeave: () => setHoveredRow(),
        };
      },
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
    },
    {
      title: "Paired Unit",
      dataIndex: "product_sn_name",
      key: "product_sn_name",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.product_sn_name.localeCompare(b.product_sn_name),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Customer",
      dataIndex: "customer_name",
      key: "customer_name",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.customer_name.localeCompare(b.customer_name),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Token",
      dataIndex: "token",
      key: "token",
      disabledCheckbox: false,
      default: true,
      sorter: (a, b) => a.token.localeCompare(b.token),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "Current TCU Version",
      dataIndex: "firmware_version",
      key: "firmware_version",
      disabledCheckbox: false,
      default: false,
      sorter: (a, b) => a.firmware_version.localeCompare(b.firmware_version),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
  ];

  return (
    <>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "20px 0",
        }}
      > */}
      <Table dataSource={qTCUs} columns={tcuColumns} />
      {/* <Select
          onChange={handleChange}
          options={allProducts?.map((product) => {
            return {
              value: product.id,
              label: product.product_name,
            };
          })}
          style={{ width: "200px" }}
        >
          {allProducts?.map((product) => (
            <Select.Option
              options={[
                {
                  value: product.id,
                  label: <span>{product.product_name}</span>,
                },
              ]}
            />
          ))}
        </Select> */}

      {/* </div> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  products: state.products.products,
  customerId: state.user.customerId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(OTAManagerOverview);
