import { Switch, Route, useRouteMatch } from "react-router-dom";
import { TCUOverview, TCUView } from "features/tcu/admin/pages";
import { Typography } from "antd";
import styled from "styled-components";
import {
  ApplicationDevicesOverview,
  ApplicationDevicesView,
  ApplicationDeviceTypesOverview,
  ApplicationDeviceTypesView,
  ApplicationDeviceInstanceView,
  ApplicationDeviceInstancesOverview,
} from "features/application-devices/admin/pages";
import {
  ApplicationsOverview,
  ApplicationView,
} from "features/applications/admin/pages";
import { CustomerOnboardingForm } from "features/customers/components/admin";

import {
  ApplicationUnitsOverview,
  ApplicationUnitsView,
} from "features/units/admin/pages";

import { ChangeLogDeveloperPage } from "features/changelog/admin";
import OTAManagerOverview from "features/firmware/components/OTAManagerOverview";
import FileManagementView from "features/firmware/components/FileManagementView";
import OTAManagerView from "features/firmware/components/OTAManagerView";
import FileManagementOverview from "features/firmware/components/FileManagementOverview";
import {
  CustomersOverview,
  CustomersView,
} from "features/customers/admin/pages";

import {
  ADMIN_ROUTE_TCUS,
  ADMIN_ROUTE_TCU_VIEW,
} from "features/tcu/utils/Constants";

import {
  ADMIN_ROUTE_APPLICATIONS,
  ADMIN_ROUTE_APPLICATIONS_VIEW,
} from "features/applications/utils/Constants";

import {
  ADMIN_ROUTE_APPLICATION_UNITS,
  ADMIN_ROUTE_APPLICATION_UNITS_VIEW,
  ADMIN_ROUTE_APPLICATION_DEVICES,
  ADMIN_ROUTE_APPLICATION_DEVICES_FORM,
  ADMIN_ROUTE_APPLICATION_DEVICES_VIEW,
  ADMIN_ROUTE_APPLICATION_DEVICE_TYPES,
  ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW,
  ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES,
  ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES_VIEW,
} from "features/application-devices/utils/Constants";
import {
  ADMIN_ROUTE_CUSTOMERS,
  ADMIN_ROUTE_CUSTOMERS_VIEW,
} from "features/customers/utils/Constants";

const { Title } = Typography;

const AdminSection = styled.div``;
const titleLevel = 2;
const titleStyle = { marginBottom: "30px" };

export const AdminRoutes = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/${ADMIN_ROUTE_TCUS}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            TCU Overview
          </Title>
          <TCUOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_TCU_VIEW}/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            TCU View
          </Title>
          <TCUView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_CUSTOMERS}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Customers Overview
          </Title>
          <CustomersOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_CUSTOMERS_VIEW}/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Customers View
          </Title>
          <CustomersView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_UNITS}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Units
          </Title>
          <ApplicationUnitsOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_UNITS_VIEW}/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Units View
          </Title>
          <ApplicationUnitsView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Device Types Overview
          </Title>
          <ApplicationDeviceTypesOverview />
        </AdminSection>
      </Route>
      <Route
        exact
        path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICE_TYPES_VIEW}/:id`}
      >
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Device Types View
          </Title>
          <ApplicationDeviceTypesView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATIONS}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Applications
          </Title>
          <ApplicationsOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATIONS_VIEW}/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application
          </Title>
          <ApplicationView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICES}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Devices Overview
          </Title>
          <ApplicationDevicesOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICES_VIEW}/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Device View
          </Title>
          <ApplicationDevicesView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/application-devices/:id/update`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Update Application Devices
          </Title>
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICES_FORM}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Add Application Device
          </Title>
        </AdminSection>
      </Route>
      <Route exact path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES}`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Device Instances
          </Title>
          <ApplicationDeviceInstancesOverview />
        </AdminSection>
      </Route>
      <Route
        exact
        path={`${path}/${ADMIN_ROUTE_APPLICATION_DEVICE_INSTANCES_VIEW}/:id`}
      >
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Application Device Instance
          </Title>
          <ApplicationDeviceInstanceView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/application-device-instances/:id/update`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Update Application Device Instance
          </Title>
        </AdminSection>
      </Route>
      <Route exact path={`${path}/application-device-instances/form`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Add Application Device Instance
          </Title>
        </AdminSection>
      </Route>
      <Route exact path={`${path}/customer-onboarding`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Customer Onboarding
          </Title>
          <CustomerOnboardingForm />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/changelog`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Developer Changelog
          </Title>
          <ChangeLogDeveloperPage />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/firmware/file_management/overview`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Firmware File Management Overview
          </Title>
          <FileManagementOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/firmware/file_management/view/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            Firmware File Management View
          </Title>
          <FileManagementView />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/firmware/ota_manager/overview`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            OTA Manager Overview
          </Title>
          <OTAManagerOverview />
        </AdminSection>
      </Route>
      <Route exact path={`${path}/firmware/ota_manager/view/:id`}>
        <AdminSection>
          <Title level={titleLevel} style={titleStyle}>
            OTA Manager View
          </Title>
          <OTAManagerView />
        </AdminSection>
      </Route>
    </Switch>
  );
};
