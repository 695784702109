import { useState } from "react";
import styled from "styled-components";
import { Button } from "common/elements";
import { useSelectedProduct } from "features/applications/hooks";
import { RateOptionsSelect } from "./RateOptionsSelect";
import {
  useGetParametersForProductQuery,
  useUpdateProductParameterMutation,
} from "features/applications/redux";
import {
  CAPTURE_RATES_LABEL,
  RATES_RSSI_LABEL,
  RATES_GPS_LABEL,
  RATES_CHKMQ_LABEL,
} from "utils/defines";

const CaptureRatesWrapper = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 0 auto;
`;

const RateItem = styled.div`
  display: flex;
  align-items: center;
  padding: 1em 0;
  & > label {
    width: 200px;
    margin-bottom: 0;
  }
  & > div {
    flex: 1;
  }
`;

const CaptureRatesForm = () => {
  const { selectedProduct } = useSelectedProduct();
  const { data: productParameters, isLoading } =
    useGetParametersForProductQuery(selectedProduct.id);
  const [updateProductParameter] = useUpdateProductParameterMutation();

  const [selectedGPSRate, setSelectedGPSRate] = useState(null);
  const [selectedRSSIRate, setSelectedRSSIRate] = useState(null);
  const [selectedChkmqRate, setSelectedChkmqRate] = useState(null);
  const [isFormPristine, setIsFormPristine] = useState(true);

  if (isLoading) return <div>Loading capture rates...</div>;

  if (!productParameters.length) return <div>Error loading parameters</div>;

  const captureRateParameter = productParameters.find(
    (productParameter) => productParameter.name === CAPTURE_RATES_LABEL
  );

  if (!captureRateParameter) return <div>Error loading capture rates</div>;

  const captureRateValue = JSON.parse(captureRateParameter.value);

  const updateSelection = (type) => (selection) => {
    setIsFormPristine(false);
    switch (type) {
      case RATES_GPS_LABEL:
        setSelectedGPSRate(selection);
        break;
      case RATES_RSSI_LABEL:
        setSelectedRSSIRate(selection);
        break;
      case RATES_CHKMQ_LABEL:
        setSelectedChkmqRate(selection);
        break;
    }
  };

  const currentRSSIRate =
    selectedRSSIRate || captureRateValue[RATES_RSSI_LABEL];
  const currentGPSRate = selectedGPSRate || captureRateValue[RATES_GPS_LABEL];
  const currentChkMqRate =
    selectedChkmqRate || captureRateValue[RATES_CHKMQ_LABEL];

  const compileCaptureRateSelections = () => {
    return {
      [RATES_RSSI_LABEL]: currentRSSIRate,
      [RATES_GPS_LABEL]: currentGPSRate,
      [RATES_CHKMQ_LABEL]: currentChkMqRate,
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    updateProductParameter({
      ...captureRateParameter,
      value: JSON.stringify(compileCaptureRateSelections()),
    });
    setIsFormPristine(true);
  };

  return (
    <form onSubmit={handleSubmit} id={"capture-rate-form"}>
      <RateItem>
        <label htmlFor="rssiRateOptions">RSSI</label>
        <div>
          <RateOptionsSelect
            id="rssiRateOptions"
            value={currentRSSIRate}
            onOptionSelected={updateSelection(RATES_RSSI_LABEL)}
          />
        </div>
      </RateItem>
      <RateItem>
        <label htmlFor="gpsRateOptions">GPS</label>
        <div>
          <RateOptionsSelect
            id="gpsRateOptions"
            value={currentGPSRate}
            onOptionSelected={updateSelection(RATES_GPS_LABEL)}
          />
        </div>
      </RateItem>
      <RateItem>
        <label htmlFor="chkmqRateOptions">Check Message Queue</label>
        <div>
          <RateOptionsSelect
            id="chkmqRateOptions"
            value={currentChkMqRate}
            onOptionSelected={updateSelection(RATES_CHKMQ_LABEL)}
          />
        </div>
      </RateItem>
      <div style={{ textAlign: "right" }}>
        <Button
          form={"capture-rate-form"}
          disabled={isFormPristine}
          style={{ width: "150px" }}
        >
          Save
        </Button>
      </div>
    </form>
  );
};

const CaptureRatesSection = () => {
  return (
    <CaptureRatesWrapper>
      <h3>Capture Rates</h3>
      <CaptureRatesForm />
    </CaptureRatesWrapper>
  );
};

export default CaptureRatesSection;
