import { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";
import { createColumnHelper } from "@tanstack/react-table";
import { notify } from "features/notifications";
import { deepDiffMapper } from "utils";
import { Button, Table, TransparentButton } from "common/elements";
import {
  useGetApplicationsByFilterQuery,
  useGetSignalsForCustomerQuery,
  useUploadDBCForApplicationMutation,
} from "features/applications/redux";
import { DBCParser, PrecisionEditor } from "../components";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { Icon } from "common";
import { S3_FILTER } from "utils/defines";
import { Spinner } from "common/Spinner";

const columnHelper = createColumnHelper();

const ApplicationsCANSignalsContainer = styled.div`
  align-items: stretch;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  position: relative;
`;

const CANSignalsPageHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 20px;
`;

const CANSignalsPageTitle = styled.h4`
  margin: 0;
`;

const DBCCallToAction = styled.div`
  margin-right: 20px;
`;

const DBCUploadContainer = styled.div`
  padding: 25px;
`;

const LegendBullet = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  height: 5px;
  margin-right: 5px;
  width: 5px;
`;

const LegendTile = styled.div`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: flex;
  margin: 0 5px;
  padding: 3px 10px;
`;

const ParsedDataHeader = styled.div`
  align-items: center;
  display: flex;
  font-size: 80%;
  justify-content: flex-end;
  margin: 25px;
`;

const ParsedDataTitle = styled.h2`
  margin: 0 auto 0 0;
`;

const ParsedDataSection = styled.section``;

const ParsedDataResults = styled.div`
  border: 1px solid #f0f0f0;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.05);
  margin: 25px;
  max-height: 27vh;
  overflow-y: auto;
`;

const ParsedDataResultsBody = styled.div`
  background-color: ${({ backgroundColor }) => backgroundColor};
  display: block;
  padding: 5px 10px;
`;

const SignalsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TableContainer = styled.div`
  height: 33vh;
  overflow-y: auto;
`;

const ApplicationsCANSignalsView = () => {
  const customerId = useCustomerId();
  const [parseResults, setParseResults] = useState({});
  const [signalsForProduct, setSignalsForProduct] = useState([]);
  const [selectedCanMessageDecimalId, setSelectedCanMessageDecimalId] =
    useState(null);
  const [viewMode, setViewMode] = useState("list");
  const [product, setProduct] = useState(null);
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [isParseMessageDisplay, setIsParseMessageDisplay] = useState(false);
  const [isParseSignalDisplay, setIsParseSignalDisplay] = useState(false);

  const [mounted, setMounted] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { data: products, isLoading: productsIsLoading } =
    useGetApplicationsByFilterQuery({ customer_id: customerId });
  const { data: allSignals, isLoading: allSignalsIsLoading } =
    useGetSignalsForCustomerQuery(customerId);

  const [uploadDBCForApplication] = useUploadDBCForApplicationMutation();

  const BACKGROUND_CREATED = "rgba(0, 255, 0, 0.15)";
  const BACKGROUND_UPDATED = "rgba(255, 155, 0, 0.3)";
  const BACKGROUND_DELETED = "rgba(255, 0, 0, 0.15)";
  const BACKGROUND_UNCHANGED = "#fafafa";

  const BULLET_COLOR_CREATED = "rgba(0, 255, 0, 1)";
  const BULLET_COLOR_UPDATED = "rgba(255, 155, 0, 1)";
  const BULLET_COLOR_DELETED = "rgba(255, 0, 0, 1)";
  const BULLET_COLOR_UNCHANGED = "#ccc";

  const TEXT_CREATED = "Created";
  const TEXT_UPDATED = "Updated";
  const TEXT_DELETED = "Deleted";
  const TEXT_UNCHANGED = "Unchanged";
  const TEXT_ENTRIES = "Entries";

  const messageColumns = [
    columnHelper.accessor("name", { header: "Name" }),
    columnHelper.accessor("id", { header: "ID" }),
    columnHelper.accessor("length", { header: "DLC" }),
  ];

  const signalColumns = [
    columnHelper.accessor("can_signal_name", { header: "Signal Name" }),
    columnHelper.accessor("signed", { header: "Type" }),
    columnHelper.accessor("bit_start", { header: "Bitpos" }),
    columnHelper.accessor("bit_length", { header: "bit_length" }),
    columnHelper.accessor("scale", { header: "Factor" }),
    columnHelper.accessor("offset", { header: "Offset" }),
    columnHelper.accessor("precision", {
      header: "Precision",
      cell: (cellProps) => <PrecisionEditor cellProps={cellProps} />,
    }),
  ];

  const [parsedMessageBucket, setParsedMessageBucket] = useState({
    created: [],
    updated: [],
    deleted: [],
    unchanged: [],
  });

  const [parsedSignalBucket, setParsedSignalBucket] = useState({
    created: [],
    updated: [],
    deleted: [],
    unchanged: [],
  });

  const addItemToParsedMessage = (bucketName, item) => {
    setParsedMessageBucket((prevBucket) => ({
      ...prevBucket,
      [bucketName]: [...prevBucket[bucketName], item],
    }));
  };
  const addItemToParsedSignal = (bucketName, item) => {
    setParsedSignalBucket((prevBucket) => ({
      ...prevBucket,
      [bucketName]: [...prevBucket[bucketName], item],
    }));
  };
  const addListToParsedSignal = (bucketName, items) => {
    setParsedSignalBucket((prevBucket) => ({
      ...prevBucket,
      [bucketName]: [...prevBucket[bucketName], ...items],
    }));
  };
  const clearParsedMessageBucket = () => {
    setParsedMessageBucket({
      created: [],
      updated: [],
      deleted: [],
      unchanged: [],
    });
  };
  const clearParsedSignalBucket = () => {
    setParsedSignalBucket({
      created: [],
      updated: [],
      deleted: [],
      unchanged: [],
    });
  };

  // on Parsed Results Changed
  useEffect(() => {
    if (Object.keys(parseResults).length > 0) {
      // clear parsed message and signals buckets to allow for new parsing
      clearParsedMessageBucket();
      clearParsedSignalBucket();

      let existingMessages = signalsForProduct.messages;
      let existingSignals = signalsForProduct.signals.filter(
        (s) => s.can_message_id
      );

      // all added or edited messages
      for (let decimal_id of Object.keys(parseResults)) {
        let parsedMessage = parseResults[decimal_id].message;

        let matchedMessage = existingMessages.find(
          (existingMessage) => existingMessage.decimal_id === decimal_id
        );
        if (!matchedMessage) {
          // new signal
          addItemToParsedMessage("created", parsedMessage);
        } else {
          // signal to be updated
          // matched first, then parsed because parsed is newer
          let differenceResults = deepDiffMapper.map(
            parsedMessage,
            matchedMessage
          );
          let somethingActuallyUpdated = Object.keys(differenceResults).reduce(
            (result, attr) =>
              differenceResults[attr].type === "updated" ? true : result,
            false
          );
          // if no difference, push to 'unchanged'
          // otherwise pushed to update
          let bucket = somethingActuallyUpdated ? "updated" : "unchanged";
          addItemToParsedMessage(bucket, differenceResults);
        }
      }

      // all deleted messages
      for (let existingMessage of existingMessages) {
        let matchedParsedMessage = parseResults[existingMessage.decimal_id];
        if (!matchedParsedMessage)
          addItemToParsedMessage("deleted", existingMessage);
      }

      // gather all added or edited signals
      for (let decimal_id of Object.keys(parseResults)) {
        if (
          !existingSignals.find(
            (existingSignal) => existingSignal.can_message_id === decimal_id
          )
        ) {
          // if a parseResult decimal_id isnt found in the database, it is a new message / new signal
          addListToParsedSignal("created", parseResults[decimal_id].signals);
        } else {
          // here the decimal_id (message_id) exists
          // check every signal in this specific decimal_id/msg_id
          for (let parsedSignal of parseResults[decimal_id].signals) {
            let matchedSignal = existingSignals.find(
              (existingSignal) =>
                // match on message id
                existingSignal.can_message_id === parsedSignal.can_message_id &&
                // match on name
                existingSignal.can_signal_name === parsedSignal.can_signal_name
            );
            if (!matchedSignal) {
              // create this signal
              addItemToParsedSignal("created", parsedSignal);
            } else {
              // signal to be updated
              let differenceResults = deepDiffMapper.map(
                parsedSignal,
                matchedSignal
              );
              let somethingActuallyUpdated = Object.keys(
                differenceResults
              ).reduce(
                (result, attr) =>
                  differenceResults[attr].type === "updated" ? true : result,
                false
              );
              // if no difference, push to 'unchanged'
              // otherwise pushed to update
              let bucket = somethingActuallyUpdated ? "updated" : "unchanged";
              addItemToParsedSignal(bucket, differenceResults);
            }
          }
        }
      }

      // gather all deleted signals
      for (let existingSignal of existingSignals) {
        if (
          !Object.keys(parseResults).includes(existingSignal.can_message_id)
        ) {
          // if this signal's msg_id doesnt exist, it's deletable
          addItemToParsedSignal("deleted", existingSignal);
        } else {
          // if the msg_id does exist but the name doesnt match, it's deletable
          let matchedParseResult = parseResults[
            existingSignal.can_message_id
          ].signals.find(
            (parseResult) =>
              parseResult.can_signal_name === existingSignal.can_signal_name
          );
          if (!matchedParseResult)
            addItemToParsedSignal("deleted", existingSignal);
        }
      }

      setParseResults({});
    }
  }, [parseResults]);

  useEffect(() => {
    const isConfirmUploadable = [
      parsedMessageBucket["created"].length == 0,
      parsedMessageBucket["updated"].length == 0,
      parsedMessageBucket["deleted"].length == 0,
      parsedSignalBucket["created"].length == 0,
      parsedSignalBucket["updated"].length == 0,
      parsedSignalBucket["deleted"].length == 0,
    ].every(Boolean);

    setIsParseMessageDisplay(
      [
        parsedMessageBucket["created"].length > 0,
        parsedMessageBucket["updated"].length > 0,
        parsedMessageBucket["deleted"].length > 0,
        parsedMessageBucket["unchanged"].length > 0,
      ].some(Boolean)
    );

    setIsParseSignalDisplay(
      [
        parsedSignalBucket["created"].length > 0,
        parsedSignalBucket["updated"].length > 0,
        parsedSignalBucket["deleted"].length > 0,
        parsedSignalBucket["unchanged"].length > 0,
      ].some(Boolean)
    );

    setIsSaveButtonDisabled(isConfirmUploadable);
  }, [parsedMessageBucket, parsedSignalBucket]);

  // select a product when products loads from redux
  useEffect(() => {
    if (!mounted) {
      setMounted(true);
    }
    if (!products?.length) return;
    onProductSelected(products[0]); // default to first product
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  // select a can message id on load if one is not selected
  useEffect(() => {
    if (
      signalsForProduct &&
      signalsForProduct.messages &&
      signalsForProduct.messages.length &&
      !selectedCanMessageDecimalId
    )
      setSelectedCanMessageDecimalId(signalsForProduct.messages[0].decimal_id);
  }, [signalsForProduct]);

  // Button -- Go to Upload View
  const handleViewUpload = (e) => {
    e.preventDefault();
    setViewMode("upload");
  };

  // Button -- Go to List View AFTER Cancelling
  const handleViewCancelUpload = (e) => {
    e.preventDefault();
    setViewMode("list");
    clearParsedMessageBucket();
    clearParsedSignalBucket();
  };

  // Event -- Set Parsed Results after DBC Parsing
  const handleParse = (results) => {
    // optionally perform other tasks here, like filtering or processing
    // save results to local state
    setParseResults(results);
  };

  const onProductSelected = (product) => {
    setProduct(product);
  };

  // Button -- Confirm and Create/Update Application Unit's Message and Signals
  const handleConfirmUpload = async (e) => {
    setIsSaveButtonDisabled(true);

    const parsedMessages = { ...parsedMessageBucket, unchanged: null };
    const parsedSignals = { ...parsedSignalBucket, unchanged: null };

    let product_id = product.id;
    if (!product_id) throw new Error("no product_id specified");

    const result = await uploadDBCForApplication({
      product_id: product.id,
      messageDifferences: parsedMessages,
      signalDifferences: parsedSignals,
    });
    setIsSaveButtonDisabled(false);
    if ("error" in result) {
      console.error(result.error);
      notify("Error Uploading DBC");
    } else {
      notify("DBC Uploaded Successfully");
      setViewMode("list");
      clearParsedMessageBucket();
      clearParsedSignalBucket();
    }
  };

  useEffect(() => {
    if (!product || !allSignals || !Object.keys(allSignals).length) return;
    let filteredMessages = allSignals.messages.filter(
      (message) => message.product_id === product.id
    );
    let filteredSignals = allSignals.signals.filter(
      (signal) => signal.product_id === product.id
    );
    setSignalsForProduct({
      messages: filteredMessages,
      signals: filteredSignals,
    });
  }, [product, allSignals]);

  /**
   * calculate DBC differences
   * @since 0.2.0
   */
  // note: unchanged and updated have the same object signature
  /**
   * Rules:
   * - Messages are the same if they match on decimal_id; any other change is considered an update
   * - Signals are the same if they match on message_id (parent) amd signal_name; any other change is considered an update.
   */

  // messages, same as signals:
  // get current messages
  // get parsed messages
  // compare and create diff object
  // send object to proper API on submit

  // signals as before:
  // get current signals
  // get parsed signals
  // compare and create diff object
  // send object to proper API on submit

  const handleRowSelect = (row) => {
    setSelectedCanMessageDecimalId(row.decimal_id);
  };

  return (
    <ApplicationsCANSignalsContainer>
      <CANSignalsPageHeader>
        <CANSignalsPageTitle>
          {viewMode === "list" ? `Messages` : `Upload DBC File`}
          {/* @TODO - Fetch name of file and save/display it */}
          {/* <span style={{ visibility: "hidden" }}>
                Current: <strong>filename.dbc</strong> (uploaded
                yyyy-mm-dd:hh:mm:ss)
              </span> */}
        </CANSignalsPageTitle>

        <DBCCallToAction>
          {viewMode === "list" ? (
            <>
              <Icon hostedImage={S3_FILTER} style={{ marginBottom: "5px" }} />
              <input
                type="text"
                placeholder="Filter Messages"
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                value={searchQuery}
                style={{ marginLeft: "5px", marginRight: "20px" }}
              />
              <Button onClick={handleViewUpload}>Upload</Button>
            </>
          ) : (
            <>
              <TransparentButton
                onClick={handleViewCancelUpload}
                style={{
                  marginRight: "10px",
                }}
              >
                Cancel
              </TransparentButton>

              <Button
                disabled={isSaveButtonDisabled}
                onClick={handleConfirmUpload}
              >
                Confirm
              </Button>
            </>
          )}
        </DBCCallToAction>
      </CANSignalsPageHeader>
      {viewMode === "list" && product ? (
        <>
          {productsIsLoading || allSignalsIsLoading ? (
            <Spinner />
          ) : (
            <SignalsContainer>
              <div
                style={{
                  borderBottom: "1px solid #ccc",
                  backgroundColor: "#f0f0f0",
                }}
              >
                <TableContainer>
                  {signalsForProduct &&
                  signalsForProduct.messages &&
                  signalsForProduct.messages.length ? (
                    <Table
                      columns={messageColumns}
                      data={signalsForProduct.messages?.map((message) => {
                        return {
                          ...message,
                          id: `0x
                          ${parseInt(message.decimal_id).toString(16)}`,
                        };
                      })}
                      onAddNew={null}
                      onRowSelected={handleRowSelect}
                      showFilterInput={false}
                      searchQuery={searchQuery}
                      pagination={false}
                    />
                  ) : (
                    <span>No messages yet; upload a DBC file to begin</span>
                  )}
                </TableContainer>
              </div>
              <div style={{ backgroundColor: "#f0f0f0" }}>
                <h4
                  style={{
                    padding: "20px",
                    margin: "0",
                    backgroundColor: "#fff",
                  }}
                >
                  Signals{" "}
                  {selectedCanMessageDecimalId
                    ? `for Message 0x${parseInt(
                        selectedCanMessageDecimalId
                      ).toString(16)} | #${selectedCanMessageDecimalId}`
                    : ``}
                </h4>
                <TableContainer>
                  {signalsForProduct.signals && (
                    <Table
                      columns={signalColumns}
                      data={signalsForProduct.signals?.filter(
                        (s) =>
                          s.can_message_id &&
                          s.can_message_id === selectedCanMessageDecimalId
                      )}
                      onAddNew={null}
                      onRowSelected={null}
                      showFilterInput={false}
                      searchQuery={searchQuery}
                      pagination={false}
                    />
                  )}
                </TableContainer>
              </div>
            </SignalsContainer>
          )}
        </>
      ) : null}
      {viewMode === "upload" && product && (
        <DBCUploadContainer>
          <DBCParser product_id={product.id} onParse={handleParse} />
        </DBCUploadContainer>
      )}
      {viewMode === "upload" && isParseMessageDisplay ? (
        <ParsedDataSection>
          <div style={{ marginBottom: "20px" }}>
            <ParsedDataHeader>
              <ParsedDataTitle>Message Changes</ParsedDataTitle>
              <span>Messages Legend:</span>
              <LegendTile backgroundColor={BACKGROUND_CREATED}>
                <LegendBullet backgroundColor={BULLET_COLOR_CREATED} />
                {TEXT_CREATED} ({parsedMessageBucket["created"].length})
              </LegendTile>
              <LegendTile backgroundColor={BACKGROUND_UPDATED}>
                <LegendBullet backgroundColor={BULLET_COLOR_UPDATED} />
                {TEXT_UPDATED} ({parsedMessageBucket["updated"].length})
              </LegendTile>
              <LegendTile backgroundColor={BACKGROUND_DELETED}>
                <LegendBullet backgroundColor={BULLET_COLOR_DELETED} />
                {TEXT_DELETED} ({parsedMessageBucket["deleted"].length})
              </LegendTile>
              <LegendTile backgroundColor={BACKGROUND_UNCHANGED}>
                <LegendBullet backgroundColor={BULLET_COLOR_UNCHANGED} />
                {TEXT_UNCHANGED} ({parsedMessageBucket["unchanged"].length})
              </LegendTile>
            </ParsedDataHeader>
            <ParsedDataResults>
              {!!parsedMessageBucket["created"].length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_CREATED}>
                  <strong>
                    {TEXT_CREATED} ({parsedMessageBucket["created"].length})
                  </strong>
                  {parsedMessageBucket["created"].map((message) => {
                    return (
                      <div>
                        + [0x{parseInt(message.decimal_id).toString(16)}]{" "}
                        {message.name}
                      </div>
                    );
                  })}
                </ParsedDataResultsBody>
              )}
              {!!parsedMessageBucket["updated"].length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_UPDATED}>
                  <strong>
                    {TEXT_UPDATED} ({parsedMessageBucket["updated"].length})
                  </strong>
                  {parsedMessageBucket["updated"].map((message) => {
                    return (
                      <div>
                        <div>
                          * [0x
                          {parseInt(
                            message.decimal_id.data
                              ? message.decimal_id.data
                              : message.decimal_id
                          ).toString(16)}
                          ]{" "}
                          {message.name.data ? message.name.data : message.name}
                        </div>
                        {Object.keys(message).map((attr) => {
                          if (message[attr].type === "updated")
                            return (
                              <div>
                                <small style={{ marginLeft: "20px" }}>
                                  {attr}: <strong>{message[attr].data}</strong>
                                </small>
                              </div>
                            );
                        })}
                      </div>
                    );
                  })}
                </ParsedDataResultsBody>
              )}
              {!!parsedMessageBucket["deleted"].length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_DELETED}>
                  <strong>
                    {TEXT_DELETED} ({parsedMessageBucket["deleted"].length})
                  </strong>
                  {parsedMessageBucket["deleted"].map((message) => {
                    return (
                      <div>
                        - [0x{parseInt(message.decimal_id).toString(16)}]{" "}
                        {message.name}
                      </div>
                    );
                  })}
                </ParsedDataResultsBody>
              )}
              {!!parsedMessageBucket["unchanged"].length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_UNCHANGED}>
                  <strong>{TEXT_UNCHANGED}</strong>
                  <br />
                  {parsedMessageBucket["unchanged"].length} {TEXT_ENTRIES}
                </ParsedDataResultsBody>
              )}
            </ParsedDataResults>
          </div>
          <div>
            {!!isParseSignalDisplay && (
              <ParsedDataHeader>
                <ParsedDataTitle>Signal Changes</ParsedDataTitle>
                <span>Signal Legend:</span>
                <LegendTile backgroundColor={BACKGROUND_CREATED}>
                  <LegendBullet backgroundColor={BULLET_COLOR_CREATED} />
                  {TEXT_CREATED} ({parsedSignalBucket["created"]?.length})
                </LegendTile>
                <LegendTile backgroundColor={BACKGROUND_UPDATED}>
                  <LegendBullet backgroundColor={BULLET_COLOR_UPDATED} />
                  {TEXT_UPDATED} ({parsedSignalBucket["updated"]?.length})
                </LegendTile>
                <LegendTile backgroundColor={BACKGROUND_DELETED}>
                  <LegendBullet backgroundColor={BULLET_COLOR_DELETED} />
                  {TEXT_DELETED} ({parsedSignalBucket["deleted"]?.length})
                </LegendTile>
                <LegendTile backgroundColor={BACKGROUND_UNCHANGED}>
                  <LegendBullet backgroundColor={BULLET_COLOR_UNCHANGED} />
                  {TEXT_UNCHANGED} ({parsedSignalBucket["unchanged"]?.length})
                </LegendTile>
              </ParsedDataHeader>
            )}
            <ParsedDataResults>
              {!!parsedSignalBucket["created"]?.length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_CREATED}>
                  <strong>
                    {TEXT_CREATED} ({parsedSignalBucket["created"]?.length})
                  </strong>
                  {parsedSignalBucket["created"]?.map((signal) => {
                    return (
                      <div>
                        + [0x{parseInt(signal?.can_message_id).toString(16)}]{" "}
                        {signal?.can_signal_name}
                      </div>
                    );
                  })}
                </ParsedDataResultsBody>
              )}
              {!!parsedSignalBucket["updated"]?.length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_UPDATED}>
                  <strong>
                    {TEXT_UPDATED} ({parsedSignalBucket["updated"].length})
                  </strong>
                  {parsedSignalBucket["updated"]?.map((signal) => {
                    return (
                      <div>
                        <div>
                          * [0x
                          {parseInt(
                            signal?.can_message_id?.data
                              ? signal.can_message_id.data
                              : signal?.can_message_id
                          ).toString(16)}
                          ]{" "}
                          {signal?.can_signal_name?.data
                            ? signal.can_signal_name.data
                            : signal?.can_signal_name}
                        </div>
                        {Object.keys(signal).map((attr) => {
                          if (signal[attr]?.type === "updated")
                            return (
                              <div>
                                <small style={{ marginLeft: "20px" }}>
                                  {attr}: <strong>{signal[attr]?.data}</strong>
                                </small>
                              </div>
                            );
                        })}
                      </div>
                    );
                  })}
                </ParsedDataResultsBody>
              )}
              {!!parsedSignalBucket["deleted"]?.length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_DELETED}>
                  <strong>
                    {TEXT_DELETED} ({parsedSignalBucket["deleted"]?.length})
                  </strong>
                  {parsedSignalBucket["deleted"]?.map((signal) => {
                    return (
                      <div>
                        - [0x{parseInt(signal?.can_message_id)?.toString(16)}]{" "}
                        {signal?.can_signal_name}
                      </div>
                    );
                  })}
                </ParsedDataResultsBody>
              )}
              {!!parsedSignalBucket["unchanged"]?.length && (
                <ParsedDataResultsBody backgroundColor={BACKGROUND_UNCHANGED}>
                  <strong>{TEXT_UNCHANGED}</strong>
                  <br />
                  {parsedSignalBucket["unchanged"]?.length} {TEXT_ENTRIES}
                </ParsedDataResultsBody>
              )}
            </ParsedDataResults>
          </div>
        </ParsedDataSection>
      ) : null}
    </ApplicationsCANSignalsContainer>
  );
};

export default ApplicationsCANSignalsView;
