import styled from "styled-components";
import { ApplicationUnitsOverviewTable } from "../components";
import { Card, Form, Button } from "antd";
import { useEffect, useState } from "react";
import { debounce } from "lodash";

import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { useGetTCUsByFilterQuery } from "features/tcu/redux";

const ApplicationUnitsOverviewManagementContainer = styled.div`
  width: 100%;
`;
export const ApplicationUnitsOverview = () => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  /**
   * Fetching All Application Units, All TCUs
   */
  const { data: qApplicationUnits, isLoading: isQApplicationUnitsLoading } =
    useGetApplicationUnitsByFilterQuery();

  const { data: qTCUs, isLoading: isQTCUsLoading } = useGetTCUsByFilterQuery();

  useEffect(() => {
    setIsPageLoaded(!isQApplicationUnitsLoading && !isQTCUsLoading);
  }, [isPageLoaded, isQApplicationUnitsLoading, isQTCUsLoading]);

  return (
    <ApplicationUnitsOverviewManagementContainer>
      <Card
        title={
          <span style={{ fontWeight: "bold" }}>
            {"List of Application Units"}
          </span>
        }
        bordered={true}
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
      >
        <ApplicationUnitsOverviewTable
          isPageLoaded={isPageLoaded}
          qApplicationUnits={qApplicationUnits}
          qTCUs={qTCUs}
        />
      </Card>
    </ApplicationUnitsOverviewManagementContainer>
  );
};
