import { useEffect, useState } from "react";
import { Modal, Form, Input, Alert, Select } from "antd";
import { debounce } from "lodash";
import { Spinner } from "common/Spinner";
import {
  useCreateApplicationDeviceInstanceMutation,
  useUpdateApplicationDeviceInstanceMutation,
} from "features/application-devices/redux";

export const ApplicationDeviceInstancesFormModal = ({
  form,
  title,
  qApplicationDeviceInstance,
  qApplicationDevices,
  qApplicationUnits,
  refetch,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  isNewForm = false,
  isEditForm = false,
}) => {
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  /**
   * Endpoints
   */
  const [createApplicationDeviceInstance] =
    useCreateApplicationDeviceInstanceMutation();
  const [updateApplicationDeviceInstance] =
    useUpdateApplicationDeviceInstanceMutation();

  /**
   * Form Values
   */
  const [applicationDevicePairFormSelect, setApplicationDevicePairFormSelect] =
    useState([]);
  const [applicationUnitsFormSelect, setApplicationUnitsFormSelect] = useState(
    []
  );

  /**
   * Handle onFinish Event
   * @param {*} values
   * @returns
   */
  const onFinishFormModal = async (values) => {
    const {
      formApplicationDevicePairSelect,
      formApplicationUnitSelect,
      formApplicationDeviceInstanceSerialNumber,
    } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };

      if (isNewForm && !isEditForm) {
        result = await createApplicationDeviceInstance({
          application_device_id: formApplicationDevicePairSelect,
          application_unit_id: formApplicationUnitSelect,
          serial_number: formApplicationDeviceInstanceSerialNumber,
        });
      } else if (isEditForm) {
        result = await updateApplicationDeviceInstance({
          id: qApplicationDeviceInstance.id,
          serial_number: formApplicationDeviceInstanceSerialNumber,
        });
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  /**
   * Handles On Application Device Pair Select Change
   * -> Filter Application Units per the selected Application Device Pair
   * @param {*} value
   */
  const onChangeApplicationDevicePairSelect = (value) => {
    const selectedApplicationDevicePair = qApplicationDevices?.find(
      (item) => item.id === value
    );
    if (selectedApplicationDevicePair) {
      const filteredApplicationUnits = qApplicationUnits?.filter(
        (item) => item.product_id === selectedApplicationDevicePair.product_id
      );
      const filteredApplicationUnitOptions = filteredApplicationUnits?.map(
        (obj) => {
          return {
            value: obj.id,
            label: obj.product_sn_name,
          };
        }
      );
      setApplicationUnitsFormSelect(filteredApplicationUnitOptions);
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      const applicationDevicePairOptions = qApplicationDevices?.map((obj) => {
        return {
          value: obj.id,
          label: `${obj.product.product_name} -- ${obj.device_type.name}`,
        };
      });
      setApplicationDevicePairFormSelect(applicationDevicePairOptions);
    }
  }, [
    isPageLoaded,
    qApplicationDevices,
    qApplicationUnits,
    qApplicationDeviceInstance,
  ]);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  if (!isPageLoaded) return <Spinner />;

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        {isNewForm ? (
          <>
            <Form.Item
              label="Application Device Pair ( Application -- App Device Type )"
              name="formApplicationDevicePairSelect"
              rules={[
                {
                  required: true,
                  message: "Missing Application Device Pair!",
                },
              ]}
            >
              <Select
                disabled={isOkButtonLoading}
                options={applicationDevicePairFormSelect}
                onChange={onChangeApplicationDevicePairSelect}
              />
            </Form.Item>
            <Form.Item
              label="Application Unit"
              name="formApplicationUnitSelect"
              rules={[
                {
                  required: true,
                  message: "Missing Application Unit!",
                },
              ]}
            >
              <Select
                disabled={isOkButtonLoading}
                options={applicationUnitsFormSelect}
              />
            </Form.Item>
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application Device Instance Serial Number"
          name="formApplicationDeviceInstanceSerialNumber"
          initialValue={qApplicationDeviceInstance?.serial_number}
          rules={[
            {
              required: true,
              message: "Missing Application Device Instance Serial Number!",
            },
          ]}
        >
          <Input disabled={isOkButtonLoading} />
        </Form.Item>
      </Modal>
    </>
  );
};
