import { PureComponent } from "react";

export const Lines = ({ lat, lng }) => {
  const MapIcon = () => (
    <>
      {/* horizontal line */}
      <svg
        height="10"
        width="10000"
        style={{ transform: "translate(-4982px,8px)" }}
      >
        <line
          x1="0"
          y1="0"
          x2="10000"
          y2="0"
          strokeDasharray="5 5"
          style={{ stroke: "rgba(169,169,169,.85)", strokeWidth: "2" }}
        />
      </svg>

      {/* vertical line */}
      <svg
        height="10000"
        width="15"
        style={{ transform: "translate(-5px,-5000px)" }}
      >
        <line
          x1="0"
          y1="0"
          x2="0"
          y2="10000"
          strokeDasharray="5 5"
          style={{ stroke: "rgba(169,169,169,.85)", strokeWidth: "2" }}
        />
      </svg>
    </>
  );
  if (lat && lng) {
    return <MapIcon />;
  }
  return null;
};

class Polyline extends PureComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = { strokeWeight: 5 };
  // }
  componentWillUpdate() {
    if (!this.line) return;
    this.line.setMap(null);
  }

  componentWillUnmount() {
    if (!this.line) return;
    this.line.setMap(null);
  }

  getPaths() {
    const { origin, destination } = this.props;
    // console.log(origin, destination);
    return [
      { lat: Number(origin.lat), lng: Number(origin.long) },
      { lat: Number(destination.lat), lng: Number(destination.long) },
    ];
  }

  render() {
    // console.log(this.props);
    if (!this.props.maps) return null;
    const Polyline = this.props.maps.Polyline;
    const Marker = this.props.maps.Marker;
    // const renderedPolyline = {
    //   ...this.renderPolyline(),
    //   strokeWeight: this.state.strokeWeight,
    // };
    const renderedPolyline = this.renderPolyline();
    const paths = { path: this.getPaths() };
    const { origin } = this.props;
    this.line = new Polyline(Object.assign({}, renderedPolyline, paths));
    this.marker = new Marker({
      position: { lat: Number(origin.lat), lng: Number(origin.long) },
      icon: {
        // size: new this.props.maps.Size(5, 5),
        scaledSize: new this.props.maps.Size(5, 5),
        url: "/assets/images/StaflSystemsFleet_ICONSET_SettingsGear.svg",
      },
    });

    // this.marker.setMap(this.props.map);
    this.line.setMap(this.props.map);

    // this.props.maps.event.addListener(this.line, "mouseover", (event) => {
    //   this.setState({ strokeWeight: 1 });
    // });
    // this.props.maps.event.addListener(this.line, "mouseout", (event) => {
    //   this.setState({ strokeWeight: 5 });
    // });
    return null;
  }

  // renderPolyline() {
  //   throw new Error("Implement renderPolyline method");
  // }
}

export class RouteLine extends Polyline {
  // componentDidMount() {
  //   console.log("this", this);
  // }
  renderPolyline() {
    return {
      geodesic: true,
      // strokeColor: this.props.$hover ? "red" : this.props.color || "#000000",
      strokeColor: this.props.color || "#000000",
      strokeOpacity: 50,
      strokeWeight: this.props.isOutline ? 5 : 1.5,
    };
  }
}

export class MarkerObj extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { size: 5 };
  }
  componentWillUpdate() {
    this.marker.setMap(null);
  }

  componentWillUnmount() {
    this.marker.setMap(null);
  }

  // render() {}

  renderNew() {
    const Marker = this.props.maps.Marker;
    this.marker = new Marker({
      position: { lat: Number(this.props.lat), lng: Number(this.props.long) },
      icon: {
        // size: new this.props.maps.Size(5, 5),
        scaledSize: new this.props.maps.Size(this.state.size, this.state.size),
        url: "/assets/images/StaflSystemsFleet_ICONSET_SettingsGear.svg",
      },
    });
    this.marker.setMap(this.props.map);
  }

  render() {
    // this.info = this.props.maps.InfoWindow({
    //   position: { lat: Number(this.props.lat), lng: Number(this.props.long) },
    // });
    this.renderNew();
    return null;
    /*
    const Marker = this.props.maps.Marker;
    this.marker = new Marker({
      position: { lat: Number(this.props.lat), lng: Number(this.props.long) },
      icon: {
        // size: new this.props.maps.Size(5, 5),
        scaledSize: new this.props.maps.Size(this.state.size, this.state.size),
        url: "/assets/images/StaflSystemsFleet_ICONSET_SettingsGear.svg",
      },
    });
    this.marker.setMap(this.props.map);
    const setWindowOpen = () => {
      // this.props.setWindowOpen({ lat: this.props.lat, lng: this.props.long });
      this.setState({ size: 5 });
    };

    const setWindowClosed = () => this.props.setWindowOpen(null);
    this.props.maps.event.addListener(this.marker, "mouseover", function () {
      // this.info.setContent("mycontent(name,msg)");
      // this.info.open(this.props.map, this.marker);
      setWindowOpen();
    });
    this.props.maps.event.addListener(this.marker, "mouseout", function () {
      // this.info.close();
      // setWindowClosed();
    });
    return null;
    */
  }
}
