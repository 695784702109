import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icon } from "common";
import { NotificationContainer } from "features/notifications";
import * as Defines from "utils/defines";

export const LoginWrapper = styled.div`
  align-items: center;
  background-image: url(${Defines.S3_LOGIN_BACKGROUND});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  margin: 0;
  position: relative;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  height: 300px;
  text-align: center;
  transform: translateX(-19vw);
`;

export const LogoContainer = styled.div``;

export const LoginBody = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  & > .inner {
    width: 340px;
  }
`;

export const LoginTemplateContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  height: 100vh;
`;

export const Button = styled.button`
  background: #f58263; // stafl action
  border: none;
  border-radius: 0.4rem;
  box-shadow: 0px 10px 30px 0px rgb(0 0 0 / 30%),
    0px 5px 5px 0px rgb(0 0 0 / 50%);
  color: white;
  font-weight: 600;
  height: 35px;
  margin-bottom: 30px;
  margin-top: 30px;
  transition: all 0.3s;
  width: 120px;

  &:hover {
    box-shadow: 0px 5px 2px 0px rgb(240 130 100 / 45%);
    filter: contrast(1.1);
  }
}`;

export const StyledInput = styled.input`
  form &:not([type="checkbox"]) {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 40px;
    margin-bottom: 10px;
    padding-left: 15px;
    padding: 10px;
    width: 100%;
    &::placeholder {
      font-weight: 500;
    }
    &:focus {
      border-bottom: 2px solid #18becc;
      box-shadow: 0 10px 15px -15px #18becc;
      color: #02b2c2;
    }
  }
}`;

export const ErrorMessage = styled.p`
  color: red;
`;

export const AuthFormLink = styled(Link)`
  font-size: 90%;
  display: block;
  color: #777;
  &:hover {
    color: #777;
  }
`;

export const ViewportWrapper = styled.div`
  background: #f2f4f7;
  width: 100vw;
  background-color: rgb(255, 255, 255);
  user-select: none;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // use company color in forms
  input:focus {
    border-color: ${(props) => props.theme.themeSecondary};
  }
`;

export const LoginLogo = () => (
  <Icon width="400px" height="auto" hostedImage={Defines.S3_LOGO} filename="" />
);

export const LoginTemplate: React.FC = ({ children }) => {
  return (
    <LoginTemplateContainer>
      <ViewportWrapper className="viewport-wrapper">
        <LoginWrapper>
          <Header>
            <LogoContainer>
              <Link to="/">
                <LoginLogo />
              </Link>
            </LogoContainer>
            <LoginBody>
              <div className="inner">{children}</div>
            </LoginBody>
          </Header>
        </LoginWrapper>
      </ViewportWrapper>
      <NotificationContainer position="bottom-right" />
    </LoginTemplateContainer>
  );
};
