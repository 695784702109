import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/application_device_instances`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const applicationDeviceInstancesApi = createApi({
  reducerPath: "applicationDeviceInstancesApi",
  baseQuery,
  tagTypes: ["ApplicationDeviceInstance", "ApplicationDeviceInstances"],
  endpoints: (builder) => ({
    getApplicationDeviceInstancesByFilter: builder.query({
      query: (args) => {
        const params = {};
        return {
          url: `/`,
          params: params,
          provideTags: ["ApplicationDeviceInstances"],
        };
      },
    }),
    getApplicationDeviceInstanceByID: builder.query({
      query: (id) => ({
        url: `/${id}`,
        providesTags: ["ApplicationDeviceInstance"],
      }),
    }),
    createApplicationDeviceInstance: builder.mutation({
      query: (args) => ({
        url: `/`,
        method: "POST",
        body: args,
        provideTags: ["ApplicationDeviceInstances"],
      }),
    }),
    updateApplicationDeviceInstance: builder.mutation({
      query: (args) => {
        const { id } = args || {};
        const obj = {
          url: `/${id}`,
          method: "PUT",
          body: { ...args },
        };
        return obj;
      },
      invalidatesTags: ["ApplicationDeviceInstance"],
    }),
    getApplicationDeviceInstanceByApplicationUnitId: builder.query({
      query: (applicationUnitId) => `/application_unit/${applicationUnitId}`,
      providesTags: ["Application Device Instances"],
    }),
    getApplicationDeviceInstanceByDeviceTypeId: builder.query({
      query: (deviceTypeId) => `/device_type/${deviceTypeId}`,
      providesTags: ["Application Device Instances"],
    }),
  }),
});

export const {
  useGetApplicationDeviceInstancesByFilterQuery,
  useGetApplicationDeviceInstanceByIDQuery,
  useUpdateApplicationDeviceInstanceMutation,
  useCreateApplicationDeviceInstanceMutation,
  useGetApplicationDeviceInstanceByApplicationUnitIdQuery,
  useGetApplicationDeviceInstanceByDeviceTypeIdQuery,
} = applicationDeviceInstancesApi;
