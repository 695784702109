import { useEffect, useState } from "react";
import styled from "styled-components";

import { Icon } from "common";
import { Modal } from "common/Modal";
import { useCurrentTCU } from "features/tcu/hooks/tcuHooks";
import { useGetDeviceInfoForSNQuery } from "features/application-devices/redux";
import { ICON_SIZE_MD } from "utils/defines";

const INFO_TYPE_TRANSLATIONS = {
  1: "General",
  2: "Network",
  3: "Storage",
  4: "Event Log",
};

const TokenTagline = styled.div`
  font-size: 60%;
  font-weight: 100;
`;

const DeviceInfoBlockOuter = styled.div`
  padding: 20px;
  height: 100%;
`;

const DeviceInfoBlockContent = styled.pre`
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
  height: 100%;
  overflow-y: auto;
`;

const DeviceInfoBody = styled.div`
  display: flex;
  align-items: stretch;
`;

const DeviceInfoNav = styled.div`
  width: 200px;
  background-color: #fff;
  border-right: 1px solid #ccc;
`;

const DeviceInfoContent = styled.div`
  flex: 1;
  background-color: #f0f0f0;
  height: 60vh;
`;

const DeviceInfoNavList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const DeviceInfoListItem = styled.li`
  cursor: pointer;
  padding: 10px;
  background-color: ${({ active }) => (active ? "#f0f0f0" : "#fff")};
  margin: 10px;
  border-radius: 5px;
`;

const DeviceInfoBlock = ({ data }) => {
  return (
    <DeviceInfoBlockOuter>
      {data ? (
        <DeviceInfoBlockContent>
          {JSON.stringify(data, null, 2)}
        </DeviceInfoBlockContent>
      ) : (
        <div>No Data</div>
      )}
    </DeviceInfoBlockOuter>
  );
};

export const DeviceInfoModal = ({ productSN, show, onCancel }) => {
  const [tcu] = useCurrentTCU();
  const [isSkip, setIsSkip] = useState(false);

  if (productSN && isSkip) {
    setIsSkip(false);
  } else if (!productSN && !isSkip) {
    setIsSkip(true);
  }

  const { data, isLoading, refetch } = useGetDeviceInfoForSNQuery(
    productSN?.id,
    { skip: isSkip }
  );
  const token = tcu && tcu.token;

  const [selectedInfoType, setSelectedInfoType] = useState("1");

  useEffect(() => {
    if (productSN) refetch();
  }, [productSN, selectedInfoType, show]);

  if (isLoading) return null;

  const drawNavList = () => {
    return Object.entries(INFO_TYPE_TRANSLATIONS).map(([id, label]) => (
      <DeviceInfoListItem
        active={selectedInfoType === id}
        key={id}
        onClick={() => setSelectedInfoType(id)}
      >
        {label}
      </DeviceInfoListItem>
    ));
  };

  return (
    <Modal
      show={show}
      onHide={onCancel}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ alignItems: "center" }}>
        <Modal.Title>
          Device Info - {productSN?.product_sn_name}
          <TokenTagline>
            {token ? `Token: ${token}` : "No TCU attached"}
          </TokenTagline>
        </Modal.Title>
        <Icon
          width={ICON_SIZE_MD}
          height={ICON_SIZE_MD}
          filename="x_button_icon.svg"
          onClick={onCancel}
          style={{ cursor: "pointer" }}
        />
      </Modal.Header>

      <DeviceInfoBody>
        <DeviceInfoNav>
          <DeviceInfoNavList>{drawNavList()}</DeviceInfoNavList>
        </DeviceInfoNav>
        <DeviceInfoContent>
          <DeviceInfoBlock
            type={selectedInfoType}
            data={data ? data[selectedInfoType] : null}
          />
        </DeviceInfoContent>
      </DeviceInfoBody>
    </Modal>
  );
};
