// import { useHistory, useLocation } from "react-router-dom";
// import { useCustomerId } from "features/customers/hooks/CustomersHooks";
// import { useGetSubscriptionForCustomerQuery } from "features/api/apiSlice";

export const CustomerSubscriptionGuard = ({ children }) => {
  // const customerId = useCustomerId();
  // const { data: currentSubscription } =
  //   useGetSubscriptionForCustomerQuery(customerId);
  // const history = useHistory();
  // const location = useLocation();
  // const validSubscription = currentSubscription
  //   ? ["active", "trialing"].includes(currentSubscription.status)
  //   : false;
  // if (!validSubscription && location.pathname !== "/account") {
  //   history.push("/account");
  // }
  return <div>{children}</div>;
};
