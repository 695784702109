import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useGetApplicationByIDQuery } from "features/applications/redux";
import { useGetTCUsByFilterQuery } from "features/tcu/redux";

import { Card, Row, Col, Space, Breadcrumb, Form, Table, Button } from "antd";
import { Spinner } from "common/Spinner";
import { ADMIN_ROUTE_APPLICATIONS } from "features/applications/utils/Constants";
import {
  ApplicationViewDetailsTable,
  ApplicationViewAppUnitsTable,
  ApplicationsFormModal,
  ApplicationViewNewAppUnitsFormModal,
} from "../components";
import { debounce } from "lodash";

export const ApplicationView = () => {
  const { id: applicationID } = useParams();
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isNewAppUnitsModalOpen, setIsNewAppUnitsModalOpen] = useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [editDetailsForm] = Form.useForm();
  const [newAppUnitForm] = Form.useForm();

  /**
   * Fetching Application Unit & related information
   */
  const {
    data: qApplication,
    isLoading: isQApplicationLoading,
    refetch: qApplicationRefetch,
  } = useGetApplicationByIDQuery(applicationID);

  const {
    data: qTCUs,
    isLoading: isQTCUsLoading,
    refetch: qTCUsRefetch,
  } = useGetTCUsByFilterQuery(
    { customer_id: qApplication?.customer_id },
    {
      skip: !qApplication,
    }
  );

  useEffect(() => {
    setIsPageLoaded(!isQApplicationLoading && !isQTCUsLoading);
  }, [isPageLoaded, isQApplicationLoading, isQTCUsLoading]);

  if (!isPageLoaded) return <Spinner />;

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "Application Overview",
      href: `/admin/${ADMIN_ROUTE_APPLICATIONS}`,
    },
    {
      title: `${qApplication?.product_name}`,
    },
  ];

  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute?.title}</span>
    ) : (
      <Link to={`${currentRoute?.href}`}>{currentRoute?.title}</Link>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`${qApplication?.product_name} Details`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsEditDetailsModalOpen(true);
                    }, 100)}
                  >
                    Edit Details
                  </Button>
                </Space>
              </>
            }
          >
            <ApplicationViewDetailsTable
              isPageLoaded={isPageLoaded}
              qApplication={qApplication}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Space style={{ padding: 8 }}>
          <div></div>
        </Space>
      </Row>
      <Row>
        <Col span={16}>
          <Card
            title={
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {`List of ${qApplication?.product_name} Application Units`}
              </span>
            }
            styles={{
              header: {
                backgroundColor: "#D3D3D3",
              },
              body: {
                backgroundColor: "#C0C0C0",
              },
            }}
            bordered={true}
            extra={
              <>
                <Space style={{ marginRight: 8 }}>
                  <Button
                    type="primary"
                    onClick={debounce(async () => {
                      setIsNewAppUnitsModalOpen(true);
                    }, 100)}
                  >
                    New App Unit
                  </Button>
                </Space>
              </>
            }
          >
            <ApplicationViewAppUnitsTable
              isPageLoaded={isPageLoaded}
              qApplication={qApplication}
              qTCUs={qTCUs}
            />
          </Card>
        </Col>
      </Row>
      <ApplicationsFormModal
        form={editDetailsForm}
        title={"Edit Application Details"}
        qApplication={qApplication}
        refetch={qApplicationRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isEditDetailsModalOpen}
        setIsModalOpen={setIsEditDetailsModalOpen}
        isEditForm={true}
      />
      <ApplicationViewNewAppUnitsFormModal
        form={newAppUnitForm}
        title={"New App Units Form"}
        qApplication={qApplication}
        refetch={qApplicationRefetch}
        isPageLoaded={isPageLoaded}
        isModalOpen={isNewAppUnitsModalOpen}
        setIsModalOpen={setIsNewAppUnitsModalOpen}
        isNewForm={true}
      />
    </>
  );
};
