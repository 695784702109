import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BASE_API_URL } from "config";
import storage from "utils/storage";

const baseCustomersQuery = fetchBaseQuery({
  baseUrl: `${BASE_API_URL}/api/customers`,
  prepareHeaders: (headers) => {
    const accessToken = storage.getAccessToken();
    if (accessToken) headers.set("Authorization", `Bearer ${accessToken}`);
    return headers;
  },
});

export const customersApi = createApi({
  reducerPath: "customersApi",
  baseQuery: baseCustomersQuery,
  tagTypes: ["Customers", "CustomerUsers", "CustomersApplications"],
  endpoints: (builder) => ({
    getCustomers: builder.query({
      query: (args) => {
        const { id } = args || {};
        let obj = {
          url: `/`,
          providesTags: ["Customers"],
        };
        if (id) {
          obj.url = `/${id}`;
        }
        return obj;
      },
    }),
    getCustomerUsers: builder.query({
      query: (args) => {
        const { id, customer_id, user_id } = args || {};
        let obj = {
          url: `/customer-users`,
          providesTags: ["CustomerUsers"],
        };
        if (id) {
          obj.url = `/customer-users/${id}`;
        }
        if (customer_id) {
          obj.url = `/customer-users/customers/${customer_id}`;
        }
        if (user_id) {
          obj.url = `/customer-users/users/${user_id}`;
        }
        return obj;
      },
    }),
    getCustomersApplications: builder.query({
      query: (args) => {
        const { customer_id } = args || {};
        let obj = {
          url: `/applications`,
          providesTags: ["CustomersApplications"],
        };
        if (customer_id) {
          obj.url = `/applications/${customer_id}`;
        }
        return obj;
      },
    }),
    getSubscriptionForCustomer: builder.query({
      query: (customerId) => `/${customerId}/current-subscription`,
    }),
    updateCustomer: builder.mutation({
      query: (args) => {
        const { id } = args || {};
        const obj = {
          url: `/${id}`,
          method: "PUT",
          body: { ...args },
        };
        return obj;
      },
      invalidatesTags: ["Customer"],
    }),
    createCustomerUser: builder.mutation({
      query: (args) => {
        const { customer_id } = args || {};
        const obj = {
          url: `/customer-users/customers/${customer_id}`,
          method: "POST",
          body: { ...args },
        };
        return obj;
      },
      invalidatesTags: ["CustomerUsers"],
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useGetCustomerUsersQuery,
  useGetCustomersApplicationsQuery,
  useGetSubscriptionForCustomerQuery,
  useUpdateCustomerMutation,
  useCreateCustomerUserMutation,
} = customersApi;
