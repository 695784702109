import { WidgetWrapper, WidgetTitle, WidgetBody } from "./TileComponents";
import { WidgetTable, TableBody, Row, RowCell } from "./TileComponents/Table";

const FleetParameters = () => (
  <>
    <WidgetTitle>Fleet Parameters</WidgetTitle>
    <WidgetBody>
      <WidgetTable fullHeight>
        <TableBody>
          <Row>
            <RowCell style={{ flexBasis: "80%" }}>Ping Rate</RowCell>
            <RowCell style={{ flexBasis: "20%" }} centered>
              30s
            </RowCell>
          </Row>
          <Row>
            <RowCell style={{ flexBasis: "80%" }}>Firmware Version</RowCell>
            <RowCell style={{ flexBasis: "20%" }} centered>
              1.0.32
            </RowCell>
          </Row>
        </TableBody>
      </WidgetTable>
    </WidgetBody>
  </>
);

export const DemoSampleContent = ({ getSettingsField }) => {
  const contentSelection = parseInt(
    getSettingsField("selectedDemoContent", "0")
  );
  const renderSelectedContent = {
    0: <FleetParameters />,
  };

  return (
    <WidgetWrapper>{renderSelectedContent[contentSelection]}</WidgetWrapper>
  );
};
