import { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";

import { useGetApplicationUnitByIDQuery } from "features/units/redux";
import {
  useGetUnpairedTCUsQuery,
  useGetTCUsByFilterQuery,
} from "features/tcu/redux";

import { Card, Row, Col, Space, Breadcrumb, Form, Button } from "antd";
import { Spinner } from "common/Spinner";
import { ADMIN_ROUTE_APPLICATION_UNITS } from "features/application-devices/utils/Constants";
import {
  ApplicationUnitsViewDetailsTable,
  ApplicationUnitsViewDetailsFormModal,
  ApplicationUnitsViewTCUPairingFormModal,
} from "../components";
import { debounce } from "lodash";

export const ApplicationUnitsView = () => {
  const { id: applicationUnitID } = useParams();
  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] = useState(false);
  const [isEditTCUPairingModalOpen, setIsEditTCUPairingModalOpen] =
    useState(false);
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [form] = Form.useForm();

  /**
   * Fetching Application Unit & related information
   */
  const {
    data: qApplicationUnit,
    isLoading: isQApplicationUnitLoading,
    refetch: qApplicationUnitRefetch,
  } = useGetApplicationUnitByIDQuery(applicationUnitID);
  const {
    data: qTCU,
    isLoading: isQTCULoading,
    refetch: qTCURefetch,
  } = useGetTCUsByFilterQuery(
    {
      product_sn_id: qApplicationUnit?.id,
    },
    {
      skip: !qApplicationUnit,
    }
  );
  const { data: qUnpairedTCUs, isLoading: isQUnpairedTCUsLoading } =
    useGetUnpairedTCUsQuery();

  useEffect(() => {
    setIsPageLoaded(
      !isQApplicationUnitLoading && !isQTCULoading && !isQUnpairedTCUsLoading
    );
  }, [
    isPageLoaded,
    isQApplicationUnitLoading,
    isQTCULoading,
    isQUnpairedTCUsLoading,
  ]);

  if (!isPageLoaded) return <Spinner />;

  const breadcrumbItems = [
    { title: "Home", href: "/admin/" },
    {
      title: "Application Units Overview",
      href: `/admin/${ADMIN_ROUTE_APPLICATION_UNITS}`,
    },
    {
      title: `${qApplicationUnit?.product_sn_name}`,
    },
  ];

  const breadcrumbItemRender = (currentRoute, params, items, paths) => {
    const isLast = currentRoute?.href === items[items.length - 1]?.href;
    return isLast ? (
      <span>{currentRoute?.title}</span>
    ) : (
      <Link to={`${currentRoute?.href}`}>{currentRoute?.title}</Link>
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Space style={{ marginBottom: 16 }}>
            <Breadcrumb
              itemRender={breadcrumbItemRender}
              items={breadcrumbItems}
            />
          </Space>
        </Col>
      </Row>
      <Card
        title={
          <span
            style={{
              fontWeight: "bold",
            }}
          >
            {`${qApplicationUnit?.product_sn_name} Details`}
          </span>
        }
        styles={{
          header: {
            backgroundColor: "#D3D3D3",
          },
          body: {
            backgroundColor: "#C0C0C0",
          },
        }}
        bordered={true}
        extra={
          <>
            <Space style={{ marginRight: 8 }}>
              <Button
                type="primary"
                onClick={debounce(async () => {
                  setIsEditDetailsModalOpen(true);
                }, 100)}
              >
                Edit Details
              </Button>
            </Space>
            <Button
              type="default"
              onClick={debounce(async () => {
                setIsEditTCUPairingModalOpen(true);
              }, 100)}
            >
              Edit TCU Pairing
            </Button>
          </>
        }
      >
        <ApplicationUnitsViewDetailsTable
          qApplicationUnit={qApplicationUnit}
          qTCU={qTCU}
          isPageLoaded={isPageLoaded}
        />
      </Card>
      <ApplicationUnitsViewDetailsFormModal
        form={form}
        title={"Edit Application Unit Details Form Modal"}
        refetch={qApplicationUnitRefetch}
        qApplicationUnit={qApplicationUnit}
        isModalOpen={isEditDetailsModalOpen}
        setIsModalOpen={setIsEditDetailsModalOpen}
        isEditForm={true}
      />
      <ApplicationUnitsViewTCUPairingFormModal
        form={form}
        title={"Edit Application Unit TCU Pair Form Modal"}
        refetch={qTCURefetch}
        qApplicationUnit={qApplicationUnit}
        qTCU={qTCU}
        qUnpairedTCUs={qUnpairedTCUs}
        isPageLoaded={isPageLoaded}
        isModalOpen={isEditTCUPairingModalOpen}
        setIsModalOpen={setIsEditTCUPairingModalOpen}
        isEditForm={true}
      />
    </>
  );
};
