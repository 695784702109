export class InstanceSignal {
  constructor(signal, newestDataArray) {
    this.signal = signal || {};
    this.signal_unit = this.signal && this.signal.signal_unit;
    this.signal_data = {};
    if (this.signal.id) {
      let data = newestDataArray.filter((d) => d.signal_id === this.signal.id);
      if (data.length > 1) this.signal_data = data;
      if (data.length) this.signal_data = data[0];
    }
  }
  getSignalName() {
    return this.signal.name || this.signal.can_signal_name;
  }
  getSignalMinValue() {
    return parseFloat(this.signal.value_min);
  }
  getSignalMaxValue() {
    return parseFloat(this.signal.value_max);
  }
  getUnitSymbol() {
    return this.signal_unit && this.signal_unit.symbol;
  }
  getPrecision() {
    return this.signal.precision;
  }
}
