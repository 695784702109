import { useEffect, useRef, useState } from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import dayjs from "dayjs";
import { InfoCircleOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Checkbox,
  DatePicker,
  Flex,
  Input,
  List,
  Modal,
  Progress,
  Tooltip,
  Typography,
} from "antd";
import { useCustomerId } from "features/customers/hooks";
import {
  useGetApplicationsByFilterQuery,
  useGetSignalsForCustomerQuery,
} from "features/applications/redux";
import { productSNDataService } from "../services";

export const DataExporterModal = ({ open, onCancel, productSN }) => {
  const customerId = useCustomerId();
  const { data: signalsData, isLoading: signalsIsLoading } =
    useGetSignalsForCustomerQuery(customerId);
  const [isLoading, setIsLoading] = useState(false);
  //   error is currently unused
  const [error, setError] = useState(null);
  const [checkedSignals, setCheckedSignals] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [filteredSignals, setFilteredSignals] = useState(signalsData?.signals);
  const [filterInputValue, setFilterInputValue] = useState("");
  const [includeFormattedDate, setIncludeFormattedDate] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [progressBarPercentage, setProgressBarPercentage] = useState(0);
  const [fullDataRequest, setFullDataRequest] = useState(false);
  const { RangePicker } = DatePicker;
  const { data: productData } = useGetApplicationsByFilterQuery({
    customer_id: customerId,
  });
  const cancelExportRef = useRef(false);
  const progressIntervalRef = useRef(null);

  useEffect(() => {
    setFilteredSignals(signalsData?.signals);
  }, [signalsIsLoading, signalsData]);

  const fillEmptyCellsWithPreviousData = (data, headers) => {
    const lastKnownValues = {};

    return data.map((item) => {
      const newItem = {};
      headers.forEach((header) => {
        if (
          item[header] === undefined ||
          item[header] === null ||
          item[header] === ""
        ) {
          newItem[header] =
            lastKnownValues[header] !== undefined
              ? lastKnownValues[header]
              : "";
        } else {
          newItem[header] = item[header];
          lastKnownValues[header] = item[header];
        }
      });
      return newItem;
    });
  };

  const fetchData = async () => {
    setIsLoading(true);
    setError(null);
    setLoadingMessage("Fetching Data...");
    setProgressBarPercentage(0);
    cancelExportRef.current = false;

    // Increment Progress Bar by 5% every second up until 50%
    progressIntervalRef.current = setInterval(() => {
      setProgressBarPercentage((prev) => Math.min(prev + 5, 50)); // Increment progress by 5 up to 25%
    }, 1000);

    try {
      const res = await productSNDataService.getDataForExcel({
        product_sn_id: productSN.id,
        date_start: new Date(startDate),
        date_end: new Date(endDate),
        signal_ids: checkedSignals.map((signal) => signal.id),
      });

      clearInterval(progressIntervalRef.current);
      setProgressBarPercentage(25);

      const signalIdToNameMap = checkedSignals.reduce((map, signal) => {
        map[signal.id] = signal.name;
        return map;
      }, {});

      //   Flatten the data
      const flatData = [];

      for (const productSN in res.data) {
        if (cancelExportRef.current) {
          return;
        }
        if (res.data.hasOwnProperty(productSN)) {
          const signals = res.data[productSN];
          for (const signal_id in signals) {
            if (signals.hasOwnProperty(signal_id)) {
              const data = signals[signal_id];
              for (const record of data) {
                const signal_name = signalIdToNameMap[signal_id] || signal_id;
                flatData.push({
                  signal_name,
                  ...record,
                });
              }
            }
          }
        }
      }

      setLoadingMessage("Exporting Data...");
      setProgressBarPercentage(50);

      const transformedData = [];
      const processChunk = (start) => {
        const chunkSize = 2000; // Number of records to process per frame
        const end = Math.min(start + chunkSize, flatData.length);

        for (let i = start; i < end; i++) {
          if (cancelExportRef.current) {
            return;
          }
          const curr = flatData[i];
          const {
            signal_name,
            timestamp_recorded,
            product_sn_id,
            signal_id,
            data_value,
            ...rest
          } = curr;
          const existingRecord = transformedData.find(
            (record) => record.timestamp_recorded === timestamp_recorded
          );

          if (existingRecord) {
            existingRecord[signal_name] = data_value;
          } else if (includeFormattedDate) {
            transformedData.push({
              timestamp_recorded,
              formatted_timestamp_recorded: dayjs(timestamp_recorded).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              [signal_name]: data_value,
              ...rest,
            });
          } else {
            transformedData.push({
              timestamp_recorded,
              [signal_name]: data_value,
              ...rest,
            });
          }

          // Update progress bar percentage
          setProgressBarPercentage(
            (50 + ((i + 1) / flatData.length) * 50).toFixed()
          );
        }
        // Using requestionAnimationFrame to update progress bar UI
        if (end < flatData.length) {
          requestAnimationFrame(() => processChunk(end));
        } else {
          exportToExcel(transformedData);
          setIsLoading(false);
          setProgressBarPercentage(100);
          clearInterval(progressIntervalRef.current);
        }
      };

      requestAnimationFrame(() => processChunk(0));
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      setProgressBarPercentage(0);
    }
  };

  const ensureHeadersExist = (data, headers) => {
    return data.map((item) => {
      const newItem = { ...item };
      headers.forEach((header) => {
        if (!(header in newItem)) {
          newItem[header] = "";
        }
      });
      return newItem;
    });
  };

  const exportToExcel = (data) => {
    const productForProductSN = productData.find(
      (product) => product.id === productSN.product_id
    );

    // Info on the top left corner of exported excel sheet
    const metadata = [
      ["Application", productForProductSN.product_name],
      ["Application Unit", productSN.product_sn_name],
      ["Start Date:", new Date(startDate).toLocaleString()],
      ["End Date:", new Date(endDate).toLocaleString()],
      [], // Empty row for spacing
    ];

    // Extract headers from data
    const headers = data.length > 0 ? Object.keys(data[0]) : [];

    const signalNames = checkedSignals.map((signal) => signal.name);
    // Ensure all headers are included
    const allHeaders = new Set([
      "timestamp_recorded",
      ...headers,
      ...signalNames,
    ]);

    if (includeFormattedDate) {
      allHeaders.add("formatted_timestamp_recorded");
    }

    const allHeadersArray = Array.from(allHeaders);

    let combinedData;
    if (data.length === 0) {
      // If data is empty, include only metadata and headers
      combinedData = [...metadata, allHeadersArray];
    } else {
      // Sort data by timestamp_recorded
      const sortedData = data.sort(
        (a, b) =>
          new Date(a.timestamp_recorded) - new Date(b.timestamp_recorded)
      );

      // Ensure all headers exist in each item
      const ensuredData = ensureHeadersExist(sortedData, allHeadersArray);

      // This fills empty data cells with most recent previous data value
      const filledData = fillEmptyCellsWithPreviousData(
        ensuredData,
        allHeadersArray
      );
      // Combine metadata, headers, and data
      combinedData = [
        ...metadata,
        allHeadersArray,
        ...filledData.map((item) =>
          allHeadersArray.map((header) => item[header] || "")
        ),
      ];
    }

    // Convert combined data to worksheet
    const worksheet = XLSX.utils.aoa_to_sheet(combinedData);

    // Create a new workbook and append the worksheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Application Unit Data");

    // Write the workbook to a buffer
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Create a Blob from the buffer and trigger the download
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Format the date for the file name
    const formattedStartDate = startDate.format("MMDDYYYY_HH:mm:ss");
    const formattedEndDate = endDate.format("MMDDYYYY_HH:mm:ss");

    // Name of the file that will be saved
    saveAs(
      blob,
      `${productSN.product_sn_name}_${formattedStartDate}-${formattedEndDate}.xlsx`
    );
  };

  const setStart = (newDate) => {
    if (newDate >= endDate && newDate !== null) return;
    setStartDate(newDate);
  };

  const setEnd = (newDate) => {
    if (newDate <= startDate && newDate !== null) return;
    setEndDate(newDate);
  };

  const disabledDate = (current) => {
    return current && current > dayjs().endOf("day");
  };

  const handleRangePickerOk = (date) => {
    if (date && date.length === 2) {
      setStart(date[0]);
      setEnd(date[1]);
    }
  };

  const handleRangePickerOnChange = (date) => {
    if (date && date.length === 2) {
      setStart(date[0]);
      setEnd(date[1]);
    }
  };

  const handleCheckboxClick = (event) => {
    const signal = event.target.value;
    const signalId = signal.id;

    setCheckedSignals((prevCheckedSignals) => {
      if (prevCheckedSignals.some((item) => item.id === signalId)) {
        return prevCheckedSignals.filter((item) => item.id !== signalId);
      } else {
        return [...prevCheckedSignals, signal];
      }
    });
  };

  const handleFilterSignals = (event) => {
    setFilterInputValue(event.target.value);
    const filteredSignalsData = signalsData.signals.filter((signal) =>
      signal.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setFilteredSignals(filteredSignalsData);
  };

  const handleClearAllClick = () => {
    setFilteredSignals(signalsData?.signals);
    setCheckedSignals([]);
    setStart(null);
    setEnd(null);
    setFilterInputValue("");
    setIncludeFormattedDate(false);
    setFullDataRequest(false);
  };

  const handleIncludeFormattedDate = (e) => {
    if (e.target.checked) setIncludeFormattedDate(true);
    else if (!e.target.checked) setIncludeFormattedDate(false);
  };

  const handleFullDataRequest = (e) => {
    if (e.target.checked) setFullDataRequest(true);
    else if (!e.target.checked) setFullDataRequest(false);
  };

  const handleCancelExport = () => {
    cancelExportRef.current = true;
    setIsLoading(false);
    setProgressBarPercentage(0);
    setLoadingMessage("");
    clearInterval(progressIntervalRef.current);
  };

  const handleRemoveListItem = (removedListItem) => {
    setCheckedSignals((prevCheckedSignals) => {
      if (prevCheckedSignals.some((item) => item.id === removedListItem.id)) {
        return prevCheckedSignals.filter(
          (item) => item.id !== removedListItem.id
        );
      }
    });
  };

  return (
    <Modal
      open={open}
      onCancel={onCancel}
      footer={null}
      destroyOnClose
      centered
      width={850}
      maskClosable
      title={
        <Flex style={{ alignItems: "center" }}>
          <Typography.Title level={3} style={{ margin: "auto 0" }}>
            Export Data to Excel{" "}
            <Tooltip
              title="Exporting may take a long time depending on the volume of data"
              placement="bottom"
            >
              <InfoCircleOutlined
                style={{ marginLeft: "5px", fontSize: "18px" }}
              />
            </Tooltip>
          </Typography.Title>
        </Flex>
      }
    >
      {isLoading && (
        <div
          style={{
            backgroundColor: "white",
            border: "1px solid lightgray",
            borderRadius: "10px",
            height: "220px",
            left: "50%",
            padding: "50px",
            position: "absolute",
            top: "50%",
            transform: "translate(-50%, -50%)",
            width: "500px",
            zIndex: "9",
          }}
        >
          <Flex vertical style={{ marginTop: "30px", width: "100%" }}>
            <Typography.Text style={{ fontSize: "15px" }}>
              {loadingMessage}
            </Typography.Text>
            <Progress percent={progressBarPercentage} status="active" />
            <Flex
              justify={"flex-end"}
              style={{ marginTop: "40px", marginRight: "-20px" }}
            >
              <Button onClick={handleCancelExport} type="default">
                Cancel
              </Button>
            </Flex>
          </Flex>
        </div>
      )}
      <Flex style={{ height: "630px", padding: "30px" }} vertical>
        <Typography.Text strong>Select Date Range:*</Typography.Text>
        <RangePicker
          disabled={isLoading}
          disabledDate={disabledDate}
          onChange={handleRangePickerOnChange}
          onCalendarChange={handleRangePickerOnChange}
          onOk={handleRangePickerOk}
          showTime
          style={{ margin: "15px 0" }}
          use12Hours
        />

        <Flex>
          <Flex
            vertical
            style={{
              height: "450px",
            }}
            flex={1}
          >
            <Flex style={{ marginBottom: "20px" }}>
              <Input
                placeholder="Filter Signals"
                style={{
                  height: "25px",
                  width: "258px",
                }}
                value={filterInputValue}
                onChange={handleFilterSignals}
                disabled={isLoading}
              />
              <Button
                disabled={isLoading}
                onClick={handleClearAllClick}
                size={"small"}
                style={{
                  margin: "auto 0 auto 10px",
                }}
              >
                Clear All
              </Button>
            </Flex>
            <Flex
              style={{
                border: "1px solid lightgray",
                borderRadius: "5px",
                marginRight: "15px",
                width: "90%",
                overflowY: "scroll",
                scrollbarWidth: "thin",
              }}
            >
              <List
                dataSource={filteredSignals}
                style={{ width: "100%" }}
                size={"small"}
                renderItem={(signal) => (
                  <List.Item>
                    <Checkbox
                      onChange={handleCheckboxClick}
                      value={signal}
                      checked={checkedSignals.some(
                        (item) => item.id === signal.id
                      )}
                      disabled={isLoading}
                    >
                      {signal.name}
                    </Checkbox>
                  </List.Item>
                )}
              />
            </Flex>
          </Flex>
          <Flex vertical flex={1} style={{ height: "450px" }}>
            <Flex vertical style={{ marginTop: "2px", marginBottom: "15px" }}>
              <Flex wrap={"wrap"}>
                <Checkbox
                  checked={includeFormattedDate}
                  disabled={isLoading}
                  onChange={handleIncludeFormattedDate}
                  style={{ marginLeft: "20px" }}
                >
                  Include Formatted Date
                </Checkbox>
                <Checkbox
                  onChange={handleFullDataRequest}
                  style={{ marginLeft: "10px" }}
                  checked={fullDataRequest}
                  disabled={isLoading}
                >
                  Request Full Data{" "}
                  <Tooltip
                    title={"Fills empty cells with closest previous data"}
                  >
                    <InfoCircleOutlined
                      style={{ marginLeft: "3px", fontSize: "14px" }}
                    />
                  </Tooltip>
                </Checkbox>
              </Flex>
            </Flex>
            <Flex
              vertical
              style={{
                borderRadius: "5px",
                marginLeft: "10px",
                overflow: "auto",
                padding: "5px 0 0 20px",
              }}
            >
              <Typography.Title level={5} style={{ marginBottom: "10px" }}>
                Columns will Include:
              </Typography.Title>
              <list style={{ overflowY: "auto", scrollbarWidth: "thin" }}>
                <li>
                  <Typography.Text>timestamp_recorded*</Typography.Text>
                </li>
                {includeFormattedDate && (
                  <li style={{ paddingTop: "3px" }}>
                    <Typography.Text>
                      formatted_timestamp_recorded{" "}
                      <CloseOutlined
                        style={{
                          fontSize: "12px",
                          marginLeft: "4px",
                        }}
                        onClick={() => setIncludeFormattedDate(false)}
                      />
                    </Typography.Text>
                  </li>
                )}
                {checkedSignals?.map((checkedSignal) => (
                  <li
                    key={checkedSignal.id}
                    onClose={() => handleRemoveListItem(checkedSignal)}
                    style={{ paddingTop: "3px" }}
                  >
                    <Typography.Text>
                      {checkedSignal.name}
                      <CloseOutlined
                        style={{ fontSize: "12px", marginLeft: "4px" }}
                        onClick={() => handleRemoveListItem(checkedSignal)}
                      />
                    </Typography.Text>
                  </li>
                ))}
              </list>
            </Flex>
          </Flex>
        </Flex>
        <Flex justify={"flex-end"} style={{ marginTop: "30px" }}>
          <Button
            onClick={fetchData}
            disabled={isLoading || !startDate || !endDate}
            type={"primary"}
            style={{
              width: "150px",
              marginRight: "-20px",
            }}
          >
            Export to Excel
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};
