/**
 * Widget Instance class to handle instance signals and the like
 */
import _ from "lodash";

import * as TILE_LIBRARY from "../widgetlibrary";
import * as Defines from "utils/defines";
import { InstanceSignal } from "./InstanceSignal";

// constructor
export class WidgetInstance {
  constructor(widgetInstance) {
    if (!widgetInstance) throw new Error("No widget instance provided");
    this.instance = widgetInstance;
    this.componentName = widgetInstance.widget.component_name;
  }
}

// return instance id
WidgetInstance.prototype.getInstanceId = function () {
  return this.instance.id;
};

// return layout id
WidgetInstance.prototype.getLayoutId = function () {
  return this.instance.layout_id;
};

// compose Instance Signal array from data and signals
WidgetInstance.prototype.composeInstanceSignals = function ({
  signals,
  dataHistory,
  currentProduct,
}) {
  const attachInstanceSignal = (instanceSignal) => {
    const matchingSignal = _.find(
      signals,
      (signal) => signal.id === instanceSignal.signal_id
    );
    const signalHistory = dataHistory[currentProduct]
      ? dataHistory[currentProduct][instanceSignal.signal_id] || []
      : [];
    const IS = new InstanceSignal(matchingSignal, signalHistory);
    return { ...instanceSignal, IS };
  };
  return _.map(this.instance.widget_instance_signals, attachInstanceSignal);
};

// get the value of a settings field
WidgetInstance.prototype.getSettingsField = function (
  field,
  _default,
  callback = (arg) => arg
) {
  // get the setting
  const settings = JSON.parse(this.instance.settings) || {};
  const setting = settings[field];

  // if the setting is found and the callback returns something, return the result
  // default callback just returns the arg
  if (setting && callback(setting)) {
    return callback(setting);
  }

  // if nothing found, return the default value given
  return _default;
};

// get function from given widget_id
WidgetInstance.prototype.getTileComponent = function () {
  const tileName = this.instance.widget.component_name;
  // return function
  return tileName ? TILE_LIBRARY[tileName] : null;
};

// generate instance props given instance signals
WidgetInstance.prototype.generateInstanceProps = function (passedProps) {
  // make instance signals from given props
  const instanceSignals = this.composeInstanceSignals(passedProps);

  // calculate height and width
  const height = passedProps.isPreview
    ? 300
    : this.instance.height * Defines.GRID_HEIGHT * 0.9;
  const width = passedProps.isPreview ? 600 : this.instance.width * 115;

  // return all props together
  return {
    instance: this.instance,
    instance_signals: instanceSignals,
    height,
    width,
    getSettingsField: this.getSettingsField.bind(this),
  };
};

// return react component for tile
WidgetInstance.prototype.TileComponentFromProps = function (passedProps) {
  // get the returned Function
  const TileComponent = this.getTileComponent();
  if (!TileComponent) return <div>No tile for specified widget_id</div>;

  // generate props specific to this instance
  const instanceProps = this.generateInstanceProps(passedProps);

  // return component
  return <TileComponent {...passedProps} {...instanceProps} />;
};
