import { useEffect, useState } from "react";
import { Modal, Form, Input, Alert, Select } from "antd";
import { useHistory } from "react-router-dom";
import { debounce } from "lodash";
import { useAddApplicationDeviceMutation } from "features/application-devices/redux";
import { Spinner } from "common/Spinner";

export const ApplicationDeviceFormModal = ({
  form,
  title,
  applications,
  applicationDeviceTypes,
  refetch,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  isNewForm = false,
  isEditForm = false,
}) => {
  const history = useHistory();
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  /**
   * Endpoints
   */
  const [addApplicationDevice] = useAddApplicationDeviceMutation();

  /**
   * Form Values
   */
  const [applicationsFormSelect, setApplicationsFormSelect] = useState([]);
  const [
    applicationDeviceTypesFormSelect,
    setApplicationDeviceTypesFormSelect,
  ] = useState([]);

  /**
   * Handle onFinish Event
   * @param {*} values
   * @returns
   */
  const onFinishFormModal = async (values) => {
    const { formApplicationSelect, formApplicationDeviceTypeSelect } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };

      if (isNewForm && !isEditForm) {
        const selectedApplication = applications.find(
          (item) => item.id === formApplicationSelect
        );
        const selectedApplicationDeviceType = applicationDeviceTypes.find(
          (item) => item.id === formApplicationDeviceTypeSelect
        );
        result = await addApplicationDevice({
          device_type_id: selectedApplicationDeviceType.id,
          product_id: selectedApplication.id,
          customer_id: selectedApplication.customer_id,
        });
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsShowFormErrorMessage(false);
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      const applicationsOptions = applications?.map((obj) => {
        return {
          value: obj.id,
          label: obj.product_name,
        };
      });
      const applicationDeviceTypesOptions = applicationDeviceTypes?.map(
        (obj) => {
          return {
            value: obj.id,
            label: obj.name,
          };
        }
      );

      setApplicationsFormSelect(applicationsOptions);
      setApplicationDeviceTypesFormSelect(applicationDeviceTypesOptions);
    }
  }, [isPageLoaded, applications, applicationDeviceTypes]);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  if (!isPageLoaded) return <Spinner />;

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application"
          name="formApplicationSelect"
          rules={[
            {
              required: true,
              message: "Missing Application!",
            },
          ]}
        >
          <Select
            disabled={isOkButtonLoading}
            options={applicationsFormSelect}
          />
        </Form.Item>
        <Form.Item
          label="Application Device Type"
          name="formApplicationDeviceTypeSelect"
          rules={[
            {
              required: true,
              message: "Missing Application Device Type!",
            },
          ]}
        >
          <Select
            disabled={isOkButtonLoading}
            options={applicationDeviceTypesFormSelect}
          />
        </Form.Item>
      </Modal>
    </>
  );
};
