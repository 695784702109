import { useState } from "react";
import { useSelector } from "react-redux";
import { MODAL_SIZE } from "utils/defines";

import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalFieldWrapper,
  ModalLabel,
  ModalField,
} from "common/Modal";
import { Button } from "antd";

export const FirmwareModal = ({
  show,
  hide,
  firmware,
  onUpdateFirmware,
  onDeleteFirmware,
}) => {
  const [versionNumber, setVersionNumber] = useState(firmware.version_number);
  const [isLatest, setIsLatest] = useState(firmware.is_latest);
  const [isBeta, setIsBeta] = useState(firmware.is_beta);
  const changesMade = () => {
    return (
      versionNumber !== firmware.version_number ||
      firmware.is_latest !== isLatest ||
      firmware.is_beta !== isBeta
    );
  };
  const updateFirmware = () => {
    let updatedFirmware = {
      id: firmware.id,
      firmware_type_id: firmware.firmware_type_id,
    };
    if (versionNumber !== firmware.version_number) {
      updatedFirmware.version_number = versionNumber;
    }
    if (isLatest !== firmware.is_latest) {
      updatedFirmware.is_latest = isLatest;
    }
    if (isBeta !== firmware.is_beta) {
      updatedFirmware.is_beta = isBeta;
    }
    onUpdateFirmware(updatedFirmware);
  };

  return (
    <Modal
      show={show}
      onHide={hide}
      size={MODAL_SIZE}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <ModalBody>
        <div className="form-group">
          <ModalFieldWrapper>
            <ModalLabel>Version Number</ModalLabel>
            <ModalField>
              {/* <span>{firmware.version_number}</span> */}
              <input
                value={versionNumber}
                onChange={(e) => setVersionNumber(e.target.value)}
              />
            </ModalField>
          </ModalFieldWrapper>

          {/* <ModalFieldWrapper>
            <ModalLabel>Latest version?</ModalLabel>
            <ModalField>
              <input
                type="checkbox"
                checked={isLatest}
                onChange={() => setIsLatest(isLatest ? 0 : 1)}
              ></input>
            </ModalField>
          </ModalFieldWrapper> */}

          {/* <ModalFieldWrapper>
            <ModalLabel>Is Beta Release?</ModalLabel>
            <ModalField>
              <input
                type="checkbox"
                checked={isBeta}
                onChange={() => setIsBeta(isBeta ? 0 : 1)}
              ></input>
            </ModalField>
          </ModalFieldWrapper> */}

          <ModalFieldWrapper>
            <ModalLabel>Uploaded File</ModalLabel>
            <ModalField>
              <span>
                <a href={firmware.file_name} download>
                  {firmware.file_name}
                </a>
              </span>
            </ModalField>
          </ModalFieldWrapper>

          <ModalFieldWrapper>
            <ModalLabel>Token</ModalLabel>
            <ModalField>
              <span>{firmware.token}</span>
            </ModalField>
          </ModalFieldWrapper>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          className="deny"
          onClick={() => onDeleteFirmware(firmware.id)}
          style={{ marginRight: "auto" }}
        >
          Delete
        </button>
        {changesMade() ? (
          <button className="accept" onClick={updateFirmware}>
            Update Firmware
          </button>
        ) : null}
      </ModalFooter>
    </Modal>
  );
};

export const FirmwareUploadModal = ({
  show,
  hide,
  onUpload,
  product_id,
  deviceType,
  allFirmware,
}) => {
  const [versionNumber, setVersionNumber] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const customerId = useSelector((state) => state.user.customerId);
  const onFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  // validation to check version format is correct
  // (numbers only with length of up to 2 numbers following ".")
  // and checks to see if firmware version already exists
  const validateInput = (input) => {
    const regex = /^\d+(\.\d+){0,2}$/;
    if (!regex.test(input)) {
      return false;
    }
    return !allFirmware?.some((firmware) => firmware.version === input);
  };

  return (
    <Modal
      className="delete-modal"
      show={show}
      onHide={hide}
      size={MODAL_SIZE}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <h3>Upload Firmware Files</h3>
      </Modal.Header>
      <ModalBody>
        <div className="form-group">
          <ModalFieldWrapper>
            <ModalLabel>Upload files</ModalLabel>
            <ModalField>
              <input type="file" name="file" onChange={onFileChange} />
            </ModalField>

            {/* <Progress max="100" color="success" value={100}>
              {Math.round(loaded, 2)}%
            </Progress> */}
          </ModalFieldWrapper>

          <ModalFieldWrapper>
            <ModalLabel>Version Number</ModalLabel>
            <ModalField>
              <input
                value={versionNumber}
                onChange={(e) => setVersionNumber(e.target.value)}
              />
            </ModalField>
          </ModalFieldWrapper>

          {/* <ModalFieldWrapper>
            <ModalLabel>Is Beta Release?</ModalLabel>
            <ModalField>
              <input
                type="checkbox"
                checked={isBeta}
                onChange={() => setIsBeta(isBeta ? 0 : 1)}
              ></input>
            </ModalField>
          </ModalFieldWrapper> */}
          <div>
            <Button
              className="accept"
              type="primary"
              onClick={() => {
                if (validateInput(versionNumber)) {
                  onUpload({
                    is_latest: null,
                    version_number: versionNumber,
                    customer_id: customerId,
                    product_id,
                    deviceType,
                    // is_beta: isBeta,
                    firmware_family_type_id: null,
                    firmware_type_id: null,
                    file: selectedFile,
                  });
                } else {
                  console.error("invalid input");
                }
              }}
            >
              Submit
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
