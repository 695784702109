// const storagePrefix = "sts.fleet.";
const idTokenKey = "fleet.it";
const accessTokenKey = "fleet.at";
const refreshTokenKey = "fleet.rt";
const storedProductKey = "state.products.product";
const storedCustomerKey = "state.user.customer";
const lastLocationKey = "state.last.location";
const unitsListSettings = "state.units-list.settings";

const storage = {
  getIdToken: function () {
    return localStorage.getItem(idTokenKey);
  },
  getAccessToken: function () {
    return localStorage.getItem(accessTokenKey);
  },
  getRefreshToken: function () {
    return localStorage.getItem(refreshTokenKey);
  },
  setIdToken: function (token) {
    localStorage.setItem(idTokenKey, token);
  },
  setAccessToken: function (token) {
    localStorage.setItem(accessTokenKey, token);
  },
  setRefreshToken: function (token) {
    localStorage.setItem(refreshTokenKey, token);
  },
  storeSession: function ({ idToken, accessToken, refreshToken }) {
    this.setIdToken(idToken);
    this.setAccessToken(accessToken);
    this.setRefreshToken(refreshToken);
  },
  clearIdToken: function () {
    localStorage.removeItem(idTokenKey);
  },
  clearAccessToken: function () {
    localStorage.removeItem(accessTokenKey);
  },
  clearRefreshToken: function () {
    localStorage.removeItem(refreshTokenKey);
  },
  clearAllSessionTokens: function () {
    this.clearIdToken();
    this.clearAccessToken();
    this.clearRefreshToken();
  },
  setStoredProduct: function (product) {
    localStorage.setItem(storedProductKey, product);
  },
  getStoredProduct: function () {
    const storedProduct = parseInt(localStorage.getItem(storedProductKey));
    return isNaN(storedProduct) ? null : storedProduct;
  },
  setStoredCustomer: function (customerId) {
    localStorage.setItem(storedCustomerKey, customerId);
  },
  getStoredCustomer: function () {
    return parseInt(localStorage.getItem(storedCustomerKey));
  },
  setLastLocation: function (loc) {
    localStorage.setItem(lastLocationKey, loc);
  },
  getLastLocation: function () {
    return localStorage.getItem(lastLocationKey);
  },
  setUnitsListSettings: function (settings) {
    return localStorage.setItem(unitsListSettings, settings);
  },
  getUnitsListSettings: function () {
    return localStorage.getItem(unitsListSettings);
  },
};

export default storage;
