import { useEffect, useState } from "react";
import dayjs from "dayjs";
import storage from "utils/storage";
import { GetFilter } from "utils/Color";
import * as Defines from "utils/defines";
import { Spinner } from "common/Spinner";
import { SearchOutlined } from "@ant-design/icons";
import { useTheme } from "styled-components";
import { getTimeSince, getSignalStrengthIcon, getIsUnitActive } from "utils";
import { Icon } from "common";
import { useCurrentProductSN } from "features/units/hooks";
import { useCustomerId } from "features/customers/hooks/customersHooks";
import { useGetApplicationUnitsByFilterQuery } from "features/units/redux";
import { SummaryRow } from "./UnitsViewComponents";
import { UnitSignalSettingsData } from "./UnitSignalSettingsData";
import { useGetTCUsForCustomerQuery } from "features/tcu/redux";
import { useGetAllDeviceTypesQuery } from "features/application-devices/redux";
import {
  useGetAllApplicationDevicesQuery,
  useGetApplicationDeviceInstancesByFilterQuery,
} from "features/application-devices/redux";
import {
  Button,
  Checkbox,
  Col,
  Flex,
  Input,
  Progress,
  Row,
  Space,
  Table,
  Tooltip,
} from "antd";

const UnitsViewList = () => {
  const [_, setSelectedProductSN] = useCurrentProductSN();
  const [filteredTableData, setFilteredTableData] = useState([]);
  const [filterMenuVisible, setFilterMenuVisible] = useState(false);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [signalData, setSignalData] = useState([]);
  const [sortedInfo, setSortedInfo] = useState({});
  const [tableData, setTableData] = useState([]);
  const [checkedList, setCheckedList] = useState([]);

  const customerId = useCustomerId();
  const themeColor = useTheme().themePrimary;
  const { data: productSNs, isLoading: productSNsIsLoading } =
    useGetApplicationUnitsByFilterQuery({
      customer_id: customerId,
      is_active: true,
    });
  const { data: allTCUs, isLoading: allTCUsIsLoading } =
    useGetTCUsForCustomerQuery(customerId);
  const { data: allDeviceTypes, isLoading: allDeviceTypesIsLoading } =
    useGetAllDeviceTypesQuery();
  const {
    data: allApplicationDeviceInstances,
    isLoading: allApplicationDeviceInstancesIsLoading,
  } = useGetApplicationDeviceInstancesByFilterQuery();
  const {
    data: allApplicationDevices,
    isLoading: allApplicationDeviceIsLoading,
  } = useGetAllApplicationDevicesQuery();

  const dataIsLoading = [
    productSNsIsLoading,
    allTCUsIsLoading,
    allDeviceTypesIsLoading,
    allApplicationDeviceInstancesIsLoading,
    allApplicationDeviceIsLoading,
  ].some(Boolean);

  useEffect(() => {
    if (!dataIsLoading) {
      const updatedSignalData = signalData?.map((signal) => {
        const matchingTCU = allTCUs?.find((tcu) => {
          return tcu.product_sn_id === signal.key;
        });
        const matchingApplicationDeviceInstances =
          allApplicationDeviceInstances?.find(
            (applicationDeviceInstance) =>
              applicationDeviceInstance.application_unit_id === signal.key
          );
        const matchingApplicationDevice = allApplicationDevices?.find(
          (applicationDevice) =>
            applicationDevice?.id ===
            matchingApplicationDeviceInstances?.application_device_id
        );
        const matchingDeviceType = allDeviceTypes?.find(
          (deviceType) =>
            deviceType.id === matchingApplicationDevice?.device_type_id
        );
        return {
          ...signal,
          tcuName: matchingTCU ? matchingTCU.name : "",
          tcuToken: matchingTCU ? matchingTCU.token : "",
          deviceType: matchingDeviceType ? matchingDeviceType.name : "",
          serialNumber: matchingApplicationDeviceInstances
            ? matchingApplicationDeviceInstances.serial_number
            : "",
        };
      });
      setFilteredTableData(updatedSignalData);
      setTableData(updatedSignalData);
      if (searchText.length > 0) {
        handleSearch(searchText);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allTCUs,
    allApplicationDeviceInstances,
    allApplicationDevices,
    allDeviceTypes,
    dataIsLoading,
    searchText,
    signalData,
  ]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMenuVisible]);

  useEffect(() => {
    const savedSortedInfo = localStorage.getItem("sortedUnitsList");
    if (savedSortedInfo) {
      const parsedSortedInfo = JSON.parse(savedSortedInfo);
      setSortedInfo({
        columnKey: parsedSortedInfo.columnKey,
        order: parsedSortedInfo.order,
      });
    }
  }, [setSortedInfo]);

  useEffect(() => {
    const defaultCheckedList =
      JSON.parse(storage.getUnitsListSettings()) ??
      columns?.map((item) => item.default && item.key);
    setCheckedList(defaultCheckedList);
  }, []);

  const handleSort = (pagination, filters, sorter) => {
    const newSortedInfo = {
      columnKey: sorter.columnKey,
      order: sorter.order,
    };
    setSortedInfo(newSortedInfo);
    localStorage.setItem("sortedUnitsList", JSON.stringify(newSortedInfo));
  };

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      disabledCheckbox: true,
      sorter: (a, b) => a.status - b.status,
      render: (text) => (
        <Tooltip
          title={`Last Updated: ${text ? getTimeSince(text) : "unknown"}`}
        >
          <span>
            <Icon
              hostedImage={
                getIsUnitActive(text)
                  ? Defines.S3_STATUS_DOT_ON
                  : Defines.S3_STATUS_DOT_OFF
              }
              width={Defines.ICON_SIZE_XS}
              height={Defines.ICON_SIZE_XS}
            />
          </span>
        </Tooltip>
      ),
      width: "30px",
    },
    {
      title: "Signal Strength",
      dataIndex: "signal_strength",
      key: "signal_strength",
      disabledCheckbox: true,
      defaultSortOrder:
        sortedInfo.columnKey === "signal_strength" ? sortedInfo.order : null,
      render: (text) => (
        <Flex vertical={false}>
          <Icon
            hostedImage={getSignalStrengthIcon(text)}
            style={{ display: "inline" }}
          />
          <span style={{ marginLeft: "5px" }}>{`${
            text ? text + "%" : "unknown"
          }`}</span>
        </Flex>
      ),
      sorter: (a, b) => a.signal_strength - b.signal_strength,
      width: "150px",
    },
    {
      title: "Unit Name",
      dataIndex: "product_sn_name",
      key: "product_sn_name",
      disabledCheckbox: true,
      defaultSortOrder:
        sortedInfo.columnKey === "product_sn_name" ? sortedInfo.order : null,
      onCell: (record, rowIndex) => {
        return {
          onClick: () => setSelectedProductSN(record.key),
          style: {
            textDecoration: hoveredRow === rowIndex ? "underline" : "none",
            cursor: "pointer",
            color: "blue",
          },
          onMouseEnter: () => setHoveredRow(rowIndex),
          onMouseLeave: () => setHoveredRow(),
        };
      },
      sorter: (a, b) => a.product_sn_name.localeCompare(b.product_sn_name),
      render: (text) => (
        <Flex vertical={false} align="center">
          <Icon
            style={{
              filter: GetFilter(themeColor),
              marginRight: "5px",
            }}
            hostedImage={Defines.S3_MAP_PIN_UNIT_A}
          />{" "}
          <p style={{ margin: "auto 0" }}>{text}</p>
        </Flex>
      ),
      width: "250px",
    },
    {
      title: "Paired TCU",
      dataIndex: "tcuName",
      key: "tcuName",
      disabledCheckbox: false,
      defaultSortOrder:
        sortedInfo.columnKey === "tcuName" ? sortedInfo.order : null,
      sorter: (a, b) => a.tcuName.localeCompare(b.tcuName),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "TCU Token",
      dataIndex: "tcuToken",
      key: "tcuToken",
      disabledCheckbox: false,
      defaultSortOrder:
        sortedInfo.columnKey === "tcuToken" ? sortedInfo.order : null,
      sorter: (a, b) => a.tcuToken.localeCompare(b.tcuToken),
      render: (text) => <p style={{ margin: "auto 0" }}>{text}</p>,
      width: "150px",
    },
    {
      title: "State of Charge",
      dataIndex: "soc",
      key: "soc",
      disabledCheckbox: false,
      defaultSortOrder:
        sortedInfo.columnKey === "soc" ? sortedInfo.order : null,
      render: (text) => (
        <Progress
          format={(percent) => `${text[0] ? Number(percent).toFixed(1) : 0}%`}
          percent={text[0]?.toFixed(text[1] || 1)}
          strokeColor={themeColor}
          style={{ paddingRight: "20px" }}
        />
      ),
      sorter: (a, b) => a.soc[0] - b.soc[0],
    },
    {
      title: "State of Health",
      dataIndex: "soh",
      key: "soh",
      disabledCheckbox: false,
      defaultSortOrder:
        sortedInfo.columnKey === "soh" ? sortedInfo.order : null,
      render: (text) => (
        <p style={{ margin: "auto 0" }}>{`${
          text[0] ? text[0].toFixed(text[1] || 1) + "%" : "unknown"
        }`}</p>
      ),
      sorter: (a, b) => a.soh[0] - b.soh[0],
      width: "150px",
    },
    {
      title: "Updated",
      dataIndex: "updated",
      key: "updated",
      disabledCheckbox: true,
      defaultSortOrder:
        sortedInfo.columnKey === "updated" ? sortedInfo.order : null,
      sorter: (a, b) =>
        Math.floor(a.updated / 60000) - Math.floor(b.updated / 60000),
      render: (text) => (
        <Tooltip
          overlayInnerStyle={{ width: "180px" }}
          title={`Last Updated: ${
            text !== -Infinity
              ? dayjs(text).format("MM-DD-YYYY hh:mm:ss A")
              : "Unknown"
          }`}
        >
          {getTimeSince(text)}
        </Tooltip>
      ),
      width: "150px",
    },
  ];

  const handleSignalDataReceived = (newData) => {
    setSignalData((prevData) => {
      const index = prevData.findIndex((item) => item.key === newData.key);
      if (index !== -1) {
        const updatedData = [...prevData];
        updatedData[index] = newData;
        return updatedData;
      } else {
        return [...prevData, newData];
      }
    });
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".filter-menu") && filterMenuVisible) {
      setFilterMenuVisible(false);
    }
  };

  const handleSearch = (text) => {
    const lowerSearchText = text.toLowerCase();
    setFilteredTableData(
      tableData.filter((signal) =>
        Object.keys(signal).some(
          (signalKey) =>
            signal[signalKey] &&
            signal[signalKey].toString().toLowerCase().includes(lowerSearchText)
        )
      )
    );
  };

  const options = columns
    .filter((item) => !item.disabledCheckbox)
    .map(({ key, title }) => ({
      label: title,
      value: key,
    }));

  const newColumns = columns.map((item) => ({
    ...item,
    hidden: !item.disabledCheckbox && !checkedList.includes(item.key),
  }));

  return (
    <Flex
      vertical
      style={{ backgroundColor: "#f0f0f0", height: "100%", padding: "50px" }}
    >
      {dataIsLoading ? (
        <Spinner />
      ) : (
        <>
          {productSNs.map((product) => (
            <UnitSignalSettingsData
              key={product.id}
              onSignalDataReceived={handleSignalDataReceived}
              product={product}
            />
          ))}
          <Row>
            <Col span={16}>
              <Flex>
                <SummaryRow />
              </Flex>
            </Col>
            <Col span={8}>
              <Row gutter={8} style={{ padding: "24px 0" }}>
                <Col span={17}>
                  <Input
                    prefix={
                      <SearchOutlined
                        className="site-form-item-icon"
                        style={{ color: "lightgray" }}
                      />
                    }
                    placeholder={`Search`}
                    onChange={(e) => {
                      setSearchText(e.target.value);
                      handleSearch(e.target.value);
                    }}
                    value={searchText}
                  />
                </Col>
                <Col span={6}>
                  <Button
                    className="filter-button"
                    onClick={(event) => {
                      event.stopPropagation();
                      setFilterMenuVisible((prevState) => !prevState);
                    }}
                  >
                    Column Filter
                  </Button>
                  {filterMenuVisible && (
                    <Space
                      className={`filter-menu`}
                      style={{
                        backgroundColor: "white",
                        border: "1px solid lightgray",
                        borderRadius: "10px",
                        padding: "10px",
                        position: "absolute",
                        right: 0,
                        top: "calc(100% + 5px)",
                        width: "165px",
                        zIndex: "999",
                      }}
                    >
                      <Checkbox.Group
                        className={`filter-menu`}
                        options={options}
                        onChange={(value) => {
                          setCheckedList(value);
                          storage.setUnitsListSettings(JSON.stringify(value));
                        }}
                        defaultValue={checkedList.filter(
                          (item) => !item.disabledCheckbox
                        )}
                        value={checkedList}
                      />
                    </Space>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                bordered
                columns={newColumns}
                dataSource={filteredTableData}
                pagination={true}
                size="large"
                style={{ zIndex: 0, userSelect: "text" }}
                onChange={handleSort}
              />
            </Col>
          </Row>
        </>
      )}
    </Flex>
  );
};

export default UnitsViewList;
