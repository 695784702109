import { Col, Input, Row, Table } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ADMIN_ROUTE_APPLICATIONS_VIEW } from "features/applications/utils/Constants";
import { ADMIN_ROUTE_CUSTOMERS_VIEW } from "features/customers/utils/Constants";
import { Spinner } from "common/Spinner";

export const ApplicationsOverviewTable = ({ qApplications, isPageLoaded }) => {
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);

  useEffect(() => {
    if (isPageLoaded) {
      const updatedApplications = qApplications?.map((obj, index) => {
        return {
          ...obj,
          key: obj.id,
          index: index + 1,
          application: obj.product_name,
          customer: obj.customer?.name,
          num_of_application_units: obj.product_sns.length,
        };
      });
      setApplications(updatedApplications);
      setFilteredApplications(updatedApplications);
    }
  }, [qApplications, isPageLoaded]);

  const handleSearch = (value) => {
    const searchValue = value.toLowerCase();
    const searchTableData = applications.filter((item) => {
      return Object.keys(item).some((key) => {
        return (
          item[key] && item[key].toString().toLowerCase().includes(searchValue)
        );
      });
    });
    setFilteredApplications(searchTableData);
  };

  /**
   * Fetching Wait
   */
  if (!isPageLoaded) return <Spinner />;

  const columns = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      default: false,
      sorter: (a, b) => a.index - b.index,
    },
    {
      title: "Application Name",
      dataIndex: "application",
      key: "application",
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_APPLICATIONS_VIEW}/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      disabledCheckbox: false,
      default: false,
      render: (text, record) => (
        <Link to={`/admin/${ADMIN_ROUTE_CUSTOMERS_VIEW}/${record.customer_id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Number of App Units",
      dataIndex: "num_of_application_units",
      key: "num_of_application_units",
      default: true,
      defaultSortOrder: "descend",
      sorter: (a, b) => a.num_of_application_units - b.num_of_application_units,
    },
  ];

  return (
    <>
      <Row>
        <Col span={10} offset={14}>
          <Input
            prefix={
              <SearchOutlined
                className="site-form-item-icon"
                style={{ color: "lightgray" }}
              />
            }
            placeholder={`Search`}
            onChange={(e) => {
              handleSearch(e.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "16px" }}>
        <Col span={24}>
          <Table columns={columns} dataSource={filteredApplications} />
        </Col>
      </Row>
    </>
  );
};
