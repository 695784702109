import { useEffect, useState } from "react";
import { Modal, Form, Input, Alert } from "antd";
import { debounce } from "lodash";
import { useUpdateApplicationUnitMutation } from "features/units/redux";

export const ApplicationUnitsViewDetailsFormModal = ({
  form,
  title,
  refetch,
  qApplicationUnit,
  isModalOpen,
  setIsModalOpen,
  isNewForm = false,
  isEditForm = false,
}) => {
  /**
   * Application Unit View Details Form Modal Vars
   */
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);
  const [updateApplicationUnitDetails] = useUpdateApplicationUnitMutation();

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  const onFinishFormModal = async (values) => {
    const { formApplicationUnitName, formApplicationUnitSerialNumber } = values;
    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };
      if (isNewForm && !isEditForm) {
      } else if (isEditForm) {
        result = await updateApplicationUnitDetails({
          fields: {
            id: qApplicationUnit?.id,
            product_sn_name: formApplicationUnitName,
            serial_number: formApplicationUnitSerialNumber,
          },
        });
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  return (
    <>
      <Modal
        title={title}
        open={isModalOpen}
        width={750}
        okText={isNewForm ? "Create" : "Save"}
        okButtonProps={{
          autoFocus: true,
          htmlType: "submit",
        }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender // trigger render of Form component
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-details-modal"
            onFinish={async (values) => onFinishFormModal(values)}
            autoComplete="off"
          >
            {dom}
          </Form>
        )}
      >
        {isShowFormErrorMessage ? (
          <>
            <Alert
              message="Error"
              description={formErrorMessage}
              type="error"
              showIcon
            />
            <br />
          </>
        ) : (
          <></>
        )}
        <Form.Item
          label="Application Unit Name"
          name="formApplicationUnitName"
          initialValue={qApplicationUnit?.product_sn_name}
          rules={[
            {
              required: true,
              message: "Missing/Invalid Application Unit Name!",
            },
          ]}
        >
          <Input disabled={isOkButtonLoading} />
        </Form.Item>
        <Form.Item
          label="Application Unit Serial Number"
          name="formApplicationUnitSerialNumber"
          initialValue={qApplicationUnit?.serial_number}
        >
          <Input disabled={isOkButtonLoading} />
        </Form.Item>
      </Modal>
    </>
  );
};
