import { Alert, Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { Spinner } from "common/Spinner";
import { useCreateCustomerUserMutation } from "features/customers/redux";
import { debounce } from "lodash";
import { useEffect, useState } from "react";

export const CustomerUsersFormModal = ({
  form,
  title,
  qCustomer,
  qUsers,
  isPageLoaded,
  isModalOpen,
  setIsModalOpen,
  refetch,
  isNewForm = false,
  isEditForm = false,
}) => {
  const [isOkButtonLoading, setIsOkButtonLoading] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState("");
  const [isShowFormErrorMessage, setIsShowFormErrorMessage] = useState(false);

  /**
   * Hooks
   */

  const [createCustomerUser] = useCreateCustomerUserMutation();

  /**
   * Form Values
   */
  const [usersFormSelect, setUsersFormSelect] = useState([]);

  /**
   * On Finish
   * @param {*} values
   */
  const onFinishFormModal = async (values) => {
    const { formUserSelect } = values;

    setIsOkButtonLoading(true);
    try {
      let result = { error: { data: { message: "Empty Form" } } };

      const selectedUser = qUsers.find((item) => item.id === formUserSelect);

      if (isNewForm && !isEditForm) {
        result = await createCustomerUser({
          customer_id: qCustomer.id,
          user_id: selectedUser?.id,
        });
      } else if (isEditForm) {
      }
      if ("error" in result) {
        setIsOkButtonLoading(false);
        setFormErrorMessage(result.error.data.message);
        setIsShowFormErrorMessage(true);
        return;
      }
      refetch();
      setIsModalOpen(false);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  useEffect(() => {
    if (isPageLoaded) {
      let usersSelectOptions = [{ label: "None", value: 0 }];
      const loadedUsersSelectOptions = qUsers?.map((obj) => {
        return {
          value: obj.id,
          label: obj.name,
        };
      });
      usersSelectOptions = usersSelectOptions.concat(loadedUsersSelectOptions);
      setUsersFormSelect(usersSelectOptions);
    }
  }, [isPageLoaded]);

  useEffect(() => {
    if (isModalOpen) form.resetFields();
    setFormErrorMessage("");
    setIsShowFormErrorMessage(false);
    setIsOkButtonLoading(false);
  }, [isModalOpen, form]);

  if (!isPageLoaded) return <Spinner />;

  /**
   * Show Form Error Message
   * @returns
   */
  const showFormErrorMessage = () => {
    if (isShowFormErrorMessage) {
      return (
        <>
          <Alert
            message="Error"
            description={formErrorMessage}
            type="error"
            showIcon
          />
          <br />
        </>
      );
    }
    return <></>;
  };

  return (
    <>
      <Modal
        title={title}
        width={500}
        open={isModalOpen}
        okText={isNewForm ? "Add" : "Save"}
        okButtonProps={{ autoFocus: true, htmlType: "submit" }}
        cancelButtonProps={{ disabled: isOkButtonLoading }}
        confirmLoading={isOkButtonLoading}
        destroyOnClose
        forceRender
        onCancel={debounce(async () => {
          setIsModalOpen(false);
        }, 100)}
        modalRender={(dom) => (
          <Form
            layout="vertical"
            form={form}
            name="form-customer-user-modal"
            onFinish={async (values) => onFinishFormModal(values)}
          >
            {dom}
          </Form>
        )}
      >
        {showFormErrorMessage()}
        <Row>
          <Col span={24}>
            <Form.Item
              label="User"
              name="formUserSelect"
              initialValue={0}
              rules={[
                {
                  required: true,
                  message: "Missing User!",
                },
              ]}
            >
              <Select disabled={isOkButtonLoading} options={usersFormSelect} />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );
};
